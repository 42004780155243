/* eslint-disable react/no-array-index-key */
import React, { useCallback, useMemo, useState } from 'react';

import { SectionList, ContainerList, ListBox, ContentList } from './styles';

import { IContentSection } from './index';
import { IconAddCircleBlue, IconLessCircleBlue } from '../../../styles/icons';

interface IList {
  section: IContentSection;
}

interface IListItems {
  title: string;
  description: string;
}

const RenderList: React.FC<IList> = ({ section }) => {
  const [opens, setOpens] = useState<Array<number>>([]);
  const list = useMemo<IListItems[]>(() => {
    const listJSON = JSON.parse(section.content) as IListItems[];
    if (listJSON) {
      return listJSON;
    }
    return [];
  }, [section.content]);

  const handleDropdown = useCallback(
    (index: number) => {
      const findIndex = opens.filter(item => item === index);
      if (findIndex.length > 0) {
        const newOpens = opens.filter(item => item !== index);
        setOpens(newOpens);
        return;
      }

      setOpens([...opens, index]);
    },
    [opens],
  );

  return (
    <SectionList>
      {list.map((item, i: number) => (
        <ContainerList key={`list-${i}`} onClick={() => handleDropdown(i)}>
          <ListBox>
            <div>{item.title}</div>
            {opens.includes(i) ? <IconLessCircleBlue /> : <IconAddCircleBlue />}
          </ListBox>
          <ContentList className={`${!opens.includes(i) && `hidden`}`}>
            <div
              dangerouslySetInnerHTML={{
                __html: item.description,
              }}
            />
          </ContentList>
        </ContainerList>
      ))}
    </SectionList>
  );
};

export default RenderList;
