/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, {
  useState,
  useRef,
  useEffect,
  useCallback,
  InputHTMLAttributes,
} from 'react';

import { useField } from '@unform/core';
import { Editor } from '@tinymce/tinymce-react';
import '@wiris/mathtype-tinymce5';
import { uploadFiles } from '../../../services/api';
import { useAuth } from '../../../hooks/auth';

import { Container } from './styles';
import { removePaste } from '../../../utils/removePasteTinymce';

interface IInputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  label: string;
}

const EditorField: React.FC<IInputProps> = ({
  label,
  name,
  value: fieldValue,
  placeholder,
  ...rest
}) => {
  const { user } = useAuth();
  const inputRef = useRef<HTMLInputElement>(null);
  const { fieldName, defaultValue, error, registerField } = useField(name);
  const [value, setValue] = useState(defaultValue && defaultValue);

  useEffect(() => {
    if (defaultValue) setValue(defaultValue);
  }, [defaultValue, setValue]);

  useEffect(() => {
    if (fieldValue) setValue(fieldValue);
  }, [fieldValue, setValue]);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  const handleChange = useCallback(
    content => {
      setValue(content);
    },
    [setValue],
  );

  return (
    <Container isErrored={!!error}>
      <label>{label}</label>
      <br />
      <br />
      <Editor
        initialValue={defaultValue || value}
        init={{
          placeholder,
          height: 48,
          inline: false,
          menubar: true,
          statusbar: false,
          mode: 'textarea',
          editor_selector: 'textarea',
          content_style: `
              h1 {
                font-size: 2em;
                margin: 0.67em 0;
                color: #2c3038;
                border-bottom: 1px dashed #e0dede;
              }
              .resource {
                width: 80% !important;
                margin: 14px auto;
                text-align: center;
                padding: 18px;
                background: #F6C866;
                border-radius: 5px;
                color: #3D3939;
                font-weight: bold;
                border: 1px solid #F6C866;
                position: relative;
              }
              .latex {
                font-family: monospace;
                background-color: #ccc;
              }
              .resource::after{
                content: '';
                top: 8px;
                left: 2%;
                position: absolute;
                border-radius: 5px;
                font-weight: bold;
                border: 1px dashed #F1F2F6;
                background: transparent;
                width: 96%;
                height: 40px;
              }
            `,
          block_formats:
            'Parágrafo=p; Tópico=h1; Subtópico=h2; Título=h3; Subtítulo=h4; Latex=latex',
          plugins: [
            'advlist autolink lists link image charmap print preview anchor',
            'searchreplace visualblocks code fullscreen',
            'insertdatetime media table paste code help wordcount',
            'charmap codesample media emoticons autoresize tiny_mce_wiris mathtype-editor',
            'paste',
          ],
          external_plugins: {
            tiny_mce_wiris:
              'https://www.wiris.net/demo/plugins/tiny_mce/plugin.min.js',
          },
          ...removePaste,
          toolbar: `formatselect | interaction | bold italic | forecolor backcolor |
          alignleft aligncenter alignright alignjustify | bullist numlist |
         link | image image-format | add-mathtype `,
          contextmenu: 'link image imagetools table spellchecker',
          images_upload_handler(blobInfo, success) {
            async function LoadImageServer() {
              const formData = new FormData();

              formData.append('images', blobInfo.blob());

              const response = await uploadFiles({
                formData,
                provider_id: user.provider?.id as string,
                type: 'images',
              });
              success(response?.data[0].url);
            }
            LoadImageServer();
          },
        }}
        onEditorChange={handleChange}
      />
      <input hidden value={value} ref={inputRef} {...rest} />
      {error && <small>{error}</small>}
    </Container>
  );
};

export default EditorField;
