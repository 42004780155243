import styled from 'styled-components';

export const Container = styled.div`
  margin: 0;
  padding: 0;
  p {
    &.units {
      font-size: 0.95rem;
      color: var(--aurea);
      strong {
        font-weight: bold;
      }
    }
  }
`;
export const ContainerFlex = styled.div`
  padding: 20px 18px;
  margin: 0;
  max-height: calc(100vh - 210px);
  overflow-y: auto;
`;

export const EmptyTitle = styled.h3`
  text-align: center;
  margin: 20px 0px;
`;

export const Subject = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px;
  margin-top: 20px;
  border-radius: 5px;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2);
`;
export const SubjectContent = styled.div``;
export const SubjectOptions = styled.div`
  align-self: center;
`;

export const RowGroup = styled.div`
  width: 100%;
  > div:first-child {
    width: 54%;
    margin-right: 12px;
    float: left;
  }

  > div:last-child {
    width: 42%;
    float: right;
    margin-top: 52px;
    > div {
      > div {
        min-height: 42px; // React Selec Hight
      }
    }
  }
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;

  div {
    width: 100%;
  }
`;

export const Bottom = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  margin-top: 24px;

  div + div {
    margin-left: 18px;
  }
`;

export const Both = styled.div`
  clear: both;
`;
