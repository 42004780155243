/* eslint-disable no-nested-ternary */
import styled from 'styled-components';
import { shade, lighten } from 'polished';

interface IStatus {
  status?: string;
  buttonOpacity?: boolean;
}

export const Container = styled.div`
  display: flex;
  width: 100%;
  & > main {
    width: 100%;
  }
  .th-center {
    text-align: -webkit-center !important;
  }
`;

export const Content = styled.div``;

export const Main = styled.main`
  display: flex;
  flex: 1;
  height: 100vh;
  overflow-y: auto;
`;

export const MainContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const Body = styled.div`
  width: 100%;
  max-width: 950px;
  margin: 35px auto 0 auto;
  section.search {
    margin-top: 30px;
    margin-bottom: 41px;
    width: 252px;
  }
  th.CheckBoxAll {
    margin-right: 50px;
  }
`;

export const Title = styled.header`
  display: flex;
  align-items: center;
  h1 {
    font-size: 2.25rem;
    margin-right: 30px;
  }
`;

export const TableContainer = styled.table`
  /* margin-left: 25px; */
  border-collapse: separate;
  text-align: center;
  .th-value {
    width: 130px;
  }
  tr {
    margin-top: 10px;
  }
  td.td-provider {
    a {
      text-decoration: none;
    }
    section {
      display: flex;
      align-items: center;
      img {
        width: 34px;
        height: 34px;
        border-radius: 50%;
      }
      p {
        margin-left: 15px;
        color: #3d3939;
        font-family: 'Source Sans Pro';
        font-size: 16px;
      }
    }
  }
  td.total {
    p {
      color: #8d8585;
      font-size: 14px;
    }
    span {
      color: #f6c866;
      font-size: 14px;
      font-family: 'Source Sans Pro';
    }
  }
  button {
    color: #3d3939;
    font-weight: 700;
    font-size: 12px;
    font-family: 12px;
    background: #e0dede;
    border-radius: 5px;
    border: 1px solid transparent;
    padding: 7px 21px;
    margin-left: 13px;
    transition: all 0.2s;
    &:hover {
      background: ${shade(0.2, '#E0DEDE')};
    }
  }
  button.manage {
    background: #f6c866;
    &:hover {
      background: ${shade(0.2, '#F6C866')};
    }
  }
`;

export const TrContainer = styled.tr<IStatus>`
  padding: 0;
  td {
    background: #ffffff;
    border-top: 1px solid #e0dede;
    border-bottom: 1px solid #e0dede;
  }
  td:first-child {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    border-left: 1px solid #e0dede;
  }
  td:last-child {
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;
    border-right: 1px solid #e0dede;
  }
  div.provider-container {
    display: flex;
    align-items: center;
    img {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      object-fit: cover;
    }
    section {
      flex-direction: column;
      margin-left: 19px;
      h3 {
        color: #3d3939;
        font-family: 'Source Sans Pro';
        font-weight: 700;
        font-size: 1rem;
      }
      p {
        font-size: 12px;
        font-family: 'Source Sans Pro';
        color: #8d8585;
      }
    }
  }
  div.units-container {
    display: flex;
    align-items: center;
    p.units {
      margin-left: 4px;
      color: #f6c866;
      font-family: 'Source Sans Pro';
      font-size: 14px;
      span {
        font-weight: 700;
        margin-right: 4px;
      }
    }
  }
  td.pay {
    span {
      background: ${props =>
        props.status === 'active'
          ? '#6BC19645'
          : props.status === 'pending'
          ? lighten(0.25, '#DE8A47')
          : lighten(0.25, '#CC593E')};
      color: ${props =>
        props.status === 'active'
          ? '#6BC196'
          : props.status === 'pending'
          ? '#DE8A47 '
          : '#CC593E'};
      padding: 4px 14px;
      border-radius: 5px;
      font-family: 'Source Sans Pro';
      font-weight: 700;
      font-size: 12px;
    }
  }
  span.status {
    background: ${props =>
      props.status === 'active'
        ? '#6BC19645'
        : props.status === 'waiting'
        ? lighten(0.25, '#DE8A47')
        : lighten(0.25, '#CC593E')};
    padding: 4px 29px;
    border-radius: 5px;
    color: ${props =>
      props.status === 'active'
        ? '#6BC196'
        : props.status === 'waiting'
        ? '#DE8A47 '
        : '#CC593E'};
    font-weight: 700;
    font-size: 12px;
  }
  span.price {
    color: #8d8585;
    font-family: 'Source Sans Pro';
    font-weight: 700;
  }
  button.cancel-rent {
    display: flex;
    align-items: center;
    color: #3d3939;
    color: #ffffff;
    font-size: 14px;
    font-weight: 700;
    font-family: 'Source Sans Pro';
    padding: 7px 12px;
    border-radius: 5px;
    background: #ffffff;
    background: #cc593e;
    border: 1px solid #e0dede;
    transition: all 0.2s;
    svg {
      margin-right: 8px;
    }
    &:hover {
      background: ${shade(0.1, '#FFFFFF')};

      background: ${shade(0.1, '#CC593E')};
    }
  }
  td.provider-rents-control {
    section {
      display: flex;
      align-items: center;
      img {
        width: 34px;
        height: 34px;
        border-radius: 50%;
        margin-right: 15px;
      }
      p {
        color: #3d3939;
        font-size: 16px;
        font-family: 'Source Sans Pro';
      }
    }
  }
  td.value-rents-control {
    p {
      color: #f6c866;
      font-family: 'Source Sans Pro';
      font-size: 14px;
      > span {
        color: #8d8585;
      }
    }
  }
  td.send-rents-control {
    button {
      padding: 0 0 4px 0;
      color: #8d8585;
      border-radius: 0;
      background: transparent;
      border-bottom: 1px dashed #8d8585;
      font-weight: 700;
      transition: all 0.2s;
      &:hover {
        opacity: 0.5;
      }
    }
  }
  td.cancel-rents-control {
    button {
      display: flex;
      align-items: center;
      border: 1px solid #e0dede;
      justify-content: center;
      background: transparent;
      color: 12px;
      font-size: #3d3939;
      font-family: 'Source Sans Pro';
      font-weight: 700;
      opacity: ${props => (props.buttonOpacity ? 0.5 : 1)};
      cursor: ${props => (props.buttonOpacity ? 'initial' : 'pointer')};
      &:hover {
        background: ${props =>
          props.buttonOpacity ? 'transparent' : shade(0.1, '#FFFF')};
      }
      svg {
        margin-right: 8px;
      }
    }
  }
`;
