/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React, {
  useState,
  useCallback,
  useEffect,
  useMemo,
  Fragment,
} from 'react';
import { BeatLoader } from 'react-spinners';
import { Link } from 'react-router-dom';
import { AxiosResponse } from 'axios';
import {
  Container,
  Main,
  MainContent,
  Body,
  Content,
  Title,
  SelectContainer,
  ModalContent,
  ModalContentToSuspend,
  ButtonContainer,
} from './styles';
import NavTop from '../../components/template/NavTop';
import InputSearch from '../../components/forms/InputSearch';
import {
  AddButton,
  TableContent,
  Options,
  OptionLeft,
} from '../../components/template/TemplateManager/styles';
import Tabs, { TabItem } from '../../components/template/Tabs';
import { getSellersSearch, putSellersStatus } from '../../services/api';
import { saveManageSellersLog } from '../../services/logs';
import { TableContainer, TrContainer } from '../myRents/styles';
import iconUser from '../../assets/icons/icon-user-circle.svg';
import { IconAddCircle } from '../../styles/icons';
import Checkbox, { CheckBoxAll } from '../../components/forms/Checkbox';
import { useToast } from '../../hooks/toast';
import { useAuth } from '../../hooks/auth';
import ChoiceEllipse from '../../components/forms/ChoiceEllipse';
import Modal from '../../components/template/Modal';
import ModalEditSeller from '../../components/template/ModalEditSeller';
import ModalLtiConnection from '../../components/template/ModalLtiConnection';

import Timer from '../../utils/timer';

export interface ISellersSearch {
  user: {
    id: string;
    name: string;
    email: string;
    avatar_url: string;
  };
  seller: {
    id: string;
    cnpj: string;
    status: string;
    name: string;
    plans_seller: {
      planId: string;
    };
  };
  provider: {
    company_name: string;
    valueCount: string;
  };
}

const statusOptions = [
  { id: '1', value: 'active', label: 'Ativos' },
  { id: '2', value: 'pending', label: 'Pendentes' },
  { id: '3', value: 'suspended', label: 'Suspenso' },
];

const queryTimer = new Timer();

const SellerControl: React.FC = () => {
  const { user } = useAuth();
  const { addToast } = useToast();
  const [search, setSearch] = useState('');
  const [currentTab, setCurrentTab] = useState('tab-02');
  const [checks, setChecks] = useState<Array<string>>([]);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState('all');
  const [ordernation, setOrdernation] = useState('ASC');
  const [isOpenManage, setIsOpenManage] = useState(false);
  const [isOpenToSuspend, setIsOpenToSuspend] = useState(false);
  const [isOpenLtiModal, setIsOpenLtiModal] = useState(false);
  const [itemToRemove, setItemToRemove] = useState<ISellersSearch>();
  const [valueSelect, setValueSelect] = useState('');
  const [changeStatus, setChangeStatus] = useState('');
  const [sellersSearch, setSellersSearch] = useState<ISellersSearch[]>([]);
  const [modalEditSeller, setModalEditSeller] = useState(false);
  const [currentSellerEdit, setCurrentSellerEdit] = useState<ISellersSearch>();
  const [currentSellerLti, setCurrentSellerLti] = useState<any>();
  const [providersInitalCopy, setProvidersInitalCopy] = useState<
    ISellersSearch[]
  >([]);

  const handleTab = useCallback(
    (id: string) => {
      if (currentTab !== id) setLoading(true);
      if (currentTab === 'tab-02') {
        setOrdernation('ASC');
      } else {
        setOrdernation('DESC');
      }
      setCurrentTab(id);
    },
    [currentTab],
  );

  const handleEditSeller = useCallback(() => {
    setModalEditSeller(!modalEditSeller);
  }, [modalEditSeller]);

  const handleOpenModalLti = useCallback(() => {
    setIsOpenLtiModal(!isOpenLtiModal);
  }, [isOpenLtiModal]);

  const handleSetCreateSeller = useCallback(() => {
    setCurrentSellerEdit({} as ISellersSearch);
    handleEditSeller();
  }, [handleEditSeller]);

  const handleSetEditSeller = useCallback(
    async (provider: ISellersSearch) => {
      setCurrentSellerEdit(provider);
      handleEditSeller();
    },
    [handleEditSeller],
  );

  const handleSetEditLti = useCallback(
    async (provider: ISellersSearch) => {
      setCurrentSellerLti(provider);
      handleOpenModalLti();
    },
    [handleEditSeller],
  );

  const handleSetProvider = useCallback((provider: ISellersSearch[]) => {
    setSellersSearch(
      provider.map(item => {
        return {
          ...item,
        };
      }),
    );
  }, []);

  // memo
  const urlUserType = useMemo(() => {
    const type = [
      { id: '1', name: 'adm' },
      { id: '2', name: 'diretor' },
    ];
    let res = type.find(item => item.id === user.type)?.name;
    if (!res) {
      res = 'provider';
    }
    return res;
  }, [user.type]);

  const statusOptionsParse = useMemo(() => {
    return statusOptions.map(item => {
      if (item.value === 'all') {
        return {
          ...item,
          label: `${item.label} (${providersInitalCopy.length})`,
        };
      }
      return {
        ...item,
        label: `${item.label} (${
          providersInitalCopy.filter(
            provider => provider.seller.status === item.value,
          ).length
        })`,
      };
    });
  }, [providersInitalCopy]);

  useEffect(() => {
    const load = async (): Promise<void> => {
      try {
        setLoading(true);
        let data: AxiosResponse;
        if (status === 'all') {
          data = await getSellersSearch({
            order: ordernation,
            companyName: search,
          });
          setProvidersInitalCopy(data.data);
        } else {
          data = await getSellersSearch({
            order: ordernation,
            status,
            companyName: search,
          });
          const loadInitial = await getSellersSearch({
            order: ordernation,
            // status: status,
            // companyName: search
          });
          setProvidersInitalCopy(loadInitial.data);
        }
        handleSetProvider(data.data);
        setChecks([]);
      } catch (error) {
        addToast({
          title: 'Ops...',
          type: 'error',
          description:
            'Algo inesperado aconteceu, por favor recarrege sua página.',
        });
      } finally {
        setLoading(false);
      }
    };
    queryTimer.register(() => load());
  }, [
    search,
    changeStatus,
    currentTab,
    ordernation,
    status,
    urlUserType,
    addToast,
    handleSetProvider,
  ]);

  const handleCheck = useCallback(check => {
    setChecks(check);
  }, []);

  const handleStatus = useCallback((value: string) => {
    setStatus(value);
  }, []);

  const handleOpenModalManage = useCallback(
    (item: ISellersSearch) => {
      setItemToRemove(item);
      setIsOpenManage(!isOpenManage);
    },
    [isOpenManage],
  );

  const handleOpenModalToSuspend = useCallback(
    (item: ISellersSearch) => {
      setIsOpenToSuspend(!isOpenToSuspend);
      setItemToRemove(item);
    },
    [isOpenToSuspend],
  );

  const handleChangeStatus = useCallback(
    (sellerId: string) => {
      if (valueSelect !== '') {
        const load = async (): Promise<void> => {
          try {
            const response = await putSellersStatus(sellerId, {
              status: valueSelect,
            });

            if (response.data.message) {
              setChangeStatus(valueSelect);
            }

            saveManageSellersLog({
              userId: user.id,
              action: 'updated_seller_status',
              status: valueSelect,
              sellerId,
            });

            addToast({
              type: 'success',
              title: 'Status alterado com sucesso',
            });
          } catch (error) {
            addToast({
              title: 'Ops...',
              type: 'error',
              description:
                'Algo inesperado aconteceu, por favor recarrege sua página.',
            });
          }
        };
        load();
        setIsOpenManage(!isOpenManage);
        setValueSelect('');
      }
    },
    [valueSelect, user.id, isOpenManage, addToast],
  );

  const handleSuspend = useCallback(
    (itemSeller: ISellersSearch) => {
      const load = async (): Promise<void> => {
        try {
          const response = await putSellersStatus(itemSeller.seller.id, {
            status: 'suspended',
          });

          if (response.data.message) {
            setChangeStatus('suspended');
          }

          saveManageSellersLog({
            userId: user.id,
            action: 'updated_seller_status',
            status: 'suspended',
            sellerId: itemSeller.seller.id,
          });

          addToast({
            title: 'Sucesso!',
            type: 'success',
            description: 'Status alterado com sucesso',
          });
        } catch (error) {
          addToast({
            title: 'Ops...',
            type: 'error',
            description:
              'Algo inesperado aconteceu, por favor recarrege sua página.',
          });
        }
      };
      load();
      setIsOpenToSuspend(!isOpenToSuspend);
    },
    [addToast, isOpenToSuspend, user.id],
  );

  return (
    <>
      <ModalEditSeller
        isOpen={modalEditSeller}
        setIsOpen={handleEditSeller}
        seller={currentSellerEdit}
      />
      <ModalLtiConnection
        isOpen={isOpenLtiModal}
        setIsOpen={handleOpenModalLti}
        seller={currentSellerLti}
      />

      <Container>
        {itemToRemove && (
          <Modal
            isOpen={isOpenManage}
            setIsOpen={() => handleOpenModalManage(itemToRemove)}
          >
            <ModalContent cancel>
              <h1 className="modalTitle">
                Gerenciar status de {itemToRemove.seller.name}
              </h1>
              <SelectContainer>
                <label htmlFor="select-status" className="selectTitle">
                  Alterar status para
                </label>
                <select
                  value={valueSelect}
                  onChange={e => setValueSelect(e.target.value)}
                  name="select-status"
                  id="select-status"
                >
                  <option value="">Selecionar</option>
                  <option value="active">Ativo</option>
                  <option value="pending">Pendente</option>
                  <option value="suspended">Suspenso</option>
                </select>
              </SelectContainer>
              <section>
                <button
                  type="button"
                  onClick={() => handleOpenModalManage(itemToRemove)}
                  className="cancel"
                >
                  Cancelar
                </button>
                <button
                  type="button"
                  onClick={() => handleChangeStatus(itemToRemove.seller.id)}
                >
                  Salvar
                </button>
              </section>
            </ModalContent>
          </Modal>
        )}
        {itemToRemove && (
          <Modal
            isOpen={isOpenToSuspend}
            setIsOpen={() => handleOpenModalToSuspend(itemToRemove)}
          >
            <ModalContentToSuspend cancel>
              <h1 className="modalTitle">
                Tem certeza que deseja suspender {itemToRemove.seller.name}?
              </h1>
              <section>
                <button
                  type="button"
                  onClick={() => handleSuspend(itemToRemove)}
                  className="cancel"
                >
                  SIM
                </button>
                <button
                  type="button"
                  onClick={() => handleOpenModalToSuspend(itemToRemove)}
                >
                  NÂO
                </button>
              </section>
            </ModalContentToSuspend>
          </Modal>
        )}
        <Main>
          <MainContent>
            <NavTop />
            <Body className="body slideDown">
              <Content>
                <Title>
                  <h1>Instituições</h1>
                  <ChoiceEllipse
                    name="status"
                    itens={statusOptionsParse}
                    defaultValue={status}
                    handleStatus={handleStatus}
                  />
                </Title>
                <Options>
                  <div className="search">
                    <InputSearch
                      name="search"
                      onChange={e => setSearch(e.target.value)}
                      placeholder="Pesquisar"
                      value={search}
                    />
                  </div>

                  <OptionLeft style={{ flexDirection: 'row-reverse' }}>
                    <AddButton onClick={handleSetCreateSeller}>
                      <IconAddCircle /> Nova Instituição
                    </AddButton>
                  </OptionLeft>
                </Options>
              </Content>
              <TableContent>
                <Tabs
                  itens={[
                    { id: 'tab-01', label: 'A-Z' },
                    { id: 'tab-02', label: 'Mais Recentes' },
                  ]}
                  defaultValue={currentTab}
                  handleTab={handleTab}
                />
                {loading ? (
                  <div className="loading">
                    <BeatLoader size={16} />
                  </div>
                ) : (
                  <>
                    <TabItem id="tab-01" defaultValue={currentTab}>
                      <TableContainer>
                        <thead>
                          <tr>
                            <th>
                              <CheckBoxAll
                                ids={sellersSearch.map(item => item.seller.id)}
                                handleCheckAll={handleCheck}
                              />
                            </th>
                            <th>Instituição</th>
                            <th>Status</th>
                            <th />
                          </tr>
                        </thead>
                        <tbody>
                          {currentTab === 'tab-01' &&
                            sellersSearch.map((provider: ISellersSearch) => (
                              <Fragment key={provider.seller.id}>
                                <TrContainer
                                  status={provider.seller.status}
                                  className="no-cursor no-hover"
                                >
                                  <td>
                                    <Checkbox
                                      name="check"
                                      handleCheck={handleCheck}
                                      checks={checks}
                                      id={provider.seller.id}
                                    />
                                  </td>
                                  {/* provider */}
                                  <td className="td-provider">
                                    <Link
                                      to={`/seller/rents-control/${provider.seller.id}`}
                                    >
                                      <section>
                                        <img
                                          src={
                                            provider.user.avatar_url
                                              ? provider.user.avatar_url
                                              : iconUser
                                          }
                                          alt=""
                                        />
                                        <p>{provider.seller.name}</p>
                                      </section>
                                    </Link>
                                  </td>
                                  <td className="pay">
                                    {provider.seller.status === 'active' ? (
                                      <span className="status">ATIVO</span>
                                    ) : provider.seller.status === 'pending' ? (
                                      <span className="status">PENDENTE</span>
                                    ) : (
                                      <span className="status">SUSPENSO</span>
                                    )}
                                  </td>
                                  {/* <td className="total">
                                    <span>R$</span>{' '}
                                    {provider.provider.valueCount}
                                    /mês
                                  </td> */}
                                  <td>
                                    <section>
                                      <button
                                        onClick={() =>
                                          handleOpenModalManage(provider)
                                        }
                                        type="button"
                                        className="manage"
                                      >
                                        Gerenciar
                                      </button>
                                      <button type="button">Suspender</button>
                                    </section>
                                  </td>
                                </TrContainer>
                              </Fragment>
                            ))}
                        </tbody>
                      </TableContainer>
                    </TabItem>
                    <TabItem id="tab-02" defaultValue={currentTab}>
                      <TableContainer>
                        <thead>
                          <tr>
                            <th>
                              <CheckBoxAll
                                ids={sellersSearch.map(item => item.seller.id)}
                                handleCheckAll={handleCheck}
                              />
                            </th>
                            <th>Instituição</th>
                            <th>Status</th>
                            <th />
                          </tr>
                        </thead>
                        <tbody>
                          {currentTab === 'tab-02' &&
                            sellersSearch.map((provider: ISellersSearch) => (
                              <Fragment key={provider.seller.id}>
                                <TrContainer
                                  status={provider.seller.status}
                                  className="no-cursor no-hover"
                                >
                                  <td>
                                    <Checkbox
                                      name="check"
                                      handleCheck={handleCheck}
                                      checks={checks}
                                      id={provider.seller.id}
                                    />
                                  </td>
                                  {/* provider */}
                                  <td className="td-provider">
                                    <Link
                                      to={`/seller/rents-control/${provider.seller.id}`}
                                    >
                                      <section>
                                        <img
                                          src={
                                            provider.user.avatar_url
                                              ? provider.user.avatar_url
                                              : iconUser
                                          }
                                          alt=""
                                        />
                                        <p>{provider.seller.name}</p>
                                      </section>
                                    </Link>
                                    {/* pagamento to do criar logica de cores */}
                                  </td>
                                  <td className="pay">
                                    {provider.seller.status === 'active' ? (
                                      <span className="status">ATIVO</span>
                                    ) : provider.seller.status === 'pending' ? (
                                      <span className="status">PENDENTE</span>
                                    ) : (
                                      <span className="status">SUSPENSO</span>
                                    )}
                                  </td>
                                  {/* <td className="total">
                                    <p>
                                      <span>R$</span>{' '}
                                      {provider.provider.valueCount}/mês
                                    </p>
                                  </td> */}
                                  <td>
                                    <ButtonContainer>
                                      <button onClick={() => handleSetEditSeller(provider)} className="manage" type="button">
                                        Editar Instituição
                                      </button>
                                      <button onClick={() => handleOpenModalManage(provider)} className="gray-button" type="button">
                                        Gerenciar Status
                                      </button>
                                      <button onClick={() => handleSetEditLti(provider)} className="gray-button" type="button">
                                        Conexão LTI
                                      </button>
                                    </ButtonContainer>
                                  </td>
                                </TrContainer>
                              </Fragment>
                            ))}
                        </tbody>
                      </TableContainer>
                    </TabItem>
                  </>
                )}
              </TableContent>
            </Body>
          </MainContent>
        </Main>
      </Container>
    </>
  );
};

export default SellerControl;
