import React, { useState, useCallback, useEffect } from 'react';
import BeatLoader from 'react-spinners/BeatLoader';
import InputSearch from '../../components/forms/InputSearch';
import ModalAddService from '../../components/template/ModalAddService';
import { IconAddCircle, IconDelete, IconEdit } from '../../styles/icons';
import { deleteServiceProvider, getServiceProvider } from '../../services/api';

import {
  SectionService,
  Service,
  TextLink,
  ServiceContainer,
  IconContainer,
  Options,
  OptionRight,
  OptionLeft,
  AddButton,
  ServiceImage,
  Button,
  Container,
} from './styles';
import { useToast } from '../../hooks/toast';
import ModalEditService from '../../components/template/ModalEditService';
import AlertConfirm from '../../components/template/AlertConfirm';

interface IService {
  id: string;
  title: string;
  price: string;
  description: string;
  link: string;
  image: string;
}

const MyServices: React.FC = () => {
  const { addToast } = useToast();
  const [loading, setLoading] = useState(false);
  const [services, setServices] = useState<Array<IService>>();
  const [currentService, setCurrentService] = useState<IService>();

  // search
  const [, setSearch] = useState('');
  const [modalAddServiceOpen, setModalAddServiceOpen] = useState(false);
  const [modalEditServiceOpen, setModalEditServiceOpen] = useState(false);

  const toggleModalAddService = useCallback(() => {
    setModalAddServiceOpen(!modalAddServiceOpen);
  }, [modalAddServiceOpen]);

  const toggleModalEditService = useCallback(() => {
    setModalEditServiceOpen(!modalEditServiceOpen);
  }, [modalEditServiceOpen]);

  const handleDeleteService = useCallback(
    async (id: string) => {
      const result = await AlertConfirm({
        title: 'Tem certeza que deseja deletar este serviço?',
        text: 'Após deletar o serviço, não há como recupera-lo',
      });

      if (!result) {
        return;
      }
      try {
        await deleteServiceProvider(id);
        window.location.reload();
      } catch (error) {
        addToast({
          title: 'Ops...',
          type: 'error',
          description:
            'Algo inesperado aconteceu, por favor recarrege sua página.',
        });
      }
    },
    [addToast],
  );

  const handleEditService = useCallback(
    service => {
      setCurrentService(service);
      toggleModalEditService();
    },
    [toggleModalEditService],
  );

  useEffect(() => {
    const load = async (): Promise<void> => {
      try {
        setLoading(true);
        const { data: res } = await getServiceProvider();

        setServices(res);
      } catch (error) {
        addToast({
          title: 'Ops...',
          type: 'error',
          description:
            'Algo inesperado aconteceu, por favor recarrege sua página.',
        });
      } finally {
        setLoading(false);
      }
    };
    load();
  }, [addToast, modalEditServiceOpen, modalAddServiceOpen]);

  return (
    <Container>
      <ModalAddService
        isOpen={modalAddServiceOpen}
        setIsOpen={toggleModalAddService}
      />
      <ModalEditService
        isOpen={modalEditServiceOpen}
        setIsOpen={toggleModalEditService}
        service={currentService}
      />

      <Options>
        <OptionRight>
          <h1>Meus serviços</h1>
          <div style={{ width: '250px', marginTop: '20px' }}>
            <InputSearch
              name="search"
              onChange={e => setSearch(e.target.value)}
              placeholder="Pesquisar"
            />
          </div>
        </OptionRight>
        <OptionLeft style={{ flexDirection: 'row-reverse', marginTop: '20px' }}>
          <AddButton onClick={() => toggleModalAddService()}>
            <IconAddCircle /> Novo Serviço
          </AddButton>
        </OptionLeft>
      </Options>
      <SectionService>
        {loading && (
          <div className="loading" style={{ textAlign: 'center' }}>
            <BeatLoader size={16} />
          </div>
        )}
        {services?.map(service => {
          return (
            <Service key={service.id}>
              {service.image && (
                <ServiceImage
                  style={{
                    backgroundImage: `url(
                        ${service.image}
                      )`,
                  }}
                />
              )}
              <div style={{ display: 'flex' }}>
                <ServiceContainer>
                  <h3>{service.title}</h3>
                  <p>{service.description}</p>
                  <p>{service.price}</p>
                  {service.link && (
                    <TextLink>
                      Link para mais detalhes:{' '}
                      <a href={service.link} target="__blank">
                        {service.link}
                      </a>
                    </TextLink>
                  )}
                </ServiceContainer>
                <IconContainer>
                  <Button
                    onClick={() => handleEditService(service)}
                    type="button"
                  >
                    <IconEdit />
                  </Button>
                  <Button
                    onClick={() => handleDeleteService(service.id)}
                    type="button"
                  >
                    <IconDelete />
                  </Button>
                </IconContainer>
              </div>
            </Service>
          );
        })}
      </SectionService>
    </Container>
  );
};

export default MyServices;
