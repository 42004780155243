import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  div {
    width: 100%;
  }

  @media (min-width: 768px) {
    justify-content: space-between;
    flex-direction: row;

    div:first-child {
      width: 54%;
      margin-right: 12px;
    }

    div:last-child {
      flex: 1;
    }
  }
`;
