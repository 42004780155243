import { IContent, IContentSection } from '../components/template/Render';

export const buildTopics = (e: string): IContent[] => {
  const contentSplit = e.split('<h1>');
  const contentElement = contentSplit
    .filter(item => item !== '')
    .map(item => {
      const div = document.createElement('div');
      div.innerHTML = `<h1>${item}`;
      return div.children;
    });

  const topics = contentElement.map((topicItem: any) => {
    const title = topicItem[0].outerHTML || '';
    const sectionsArray = [...topicItem];
    const sections = sectionsArray
      .filter((item: any, i: number) => i !== 0 && item !== '')
      .map((sectionItem: any) => {
        const sectionObj: IContentSection = {
          content: '',
          label: '',
          type: 'txt',
          subtype: '',
        };

        if (sectionItem.hasAttribute('data-interaction')) {
          sectionObj.content = sectionItem.getAttribute('data-resource') || '';
          sectionObj.label = sectionItem.innerText || '';
          sectionObj.type = (sectionItem.getAttribute('data-type') ||
            'media') as 'media'; // Seguir interface
          sectionObj.subtype = (sectionItem.getAttribute('data-subtype') ||
            '') as '';
        } else {
          sectionObj.content = sectionItem.outerHTML;
          sectionObj.type = 'txt';
        }
        return sectionObj;
      });
    return {
      title: title.replace('<h1>', '').replace('</h1>', '') as string,
      sections,
    };
  });

  return topics;
};
