export const usersTypes = [
  {
    name: 'Admin',
    nameSlug: 'admin',
  },
  {
    name: 'Diretor',
    nameSlug: 'director',
  },
  {
    name: 'Gerente',
    nameSlug: 'manager',
  },
  {
    name: 'Curador',
    nameSlug: 'curator',
  },
  {
    name: 'Revisor',
    nameSlug: 'review',
  },
  {
    name: 'Fornecedor',
    nameSlug: 'provider',
  },
  {
    name: 'Fornecedor de serviço',
    nameSlug: 'serviceProvider',
  },
  {
    name: 'Editor',
    nameSlug: 'editor',
  },
  {
    name: 'Instituição',
    nameSlug: 'seller',
  },
  {
    name: 'Client',
    nameSlug: 'client',
  },
  {
    name: 'User',
    nameSlug: 'user',
  },
  {
    name: 'Gerente de Conteúdo',
    nameSlug: 'seller_content_manager',
  },
  {
    name: 'Assistente de Conteúdo',
    nameSlug: 'seller_assistent',
  },
];

export const permissionUsersTypes = {
  admin: usersTypes.filter((_, i) => i <= 4),
  director: usersTypes.filter((_, i) => i >= 2 && i <= 4),
  provider: usersTypes.filter((_, i) => i >= 6 && i <= 7),
  seller: usersTypes.filter((_, i) => i >= 11),
};

export const internalTeam = [
  'admin',
  'director',
  'manager',
  'curator',
  'review',
];

export enum INTERNAL_TEAM_ENUM {
  admin = 'admin',
  director = 'director',
  manager = 'manager',
  curator = 'curator',
  review = 'review',
}

export enum PROVIDER_ENUM {
  provider = 'provider',
  serviceProvider = 'serviceProvider',
  editor = 'editor',
}

export const PROVIDER_USER_TYPES = ['provider', 'editor', 'serviceProvider'];
