import styled from 'styled-components';

export const ContainerInput = styled.div`
  input {
    width: 100%;
    display: flex;
    margin-top: 16px;
    margin-bottom: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #555;
    background-color: #fff;
    background-clip: padding-box;
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    height: 34px;
    &:focus {
      border-color: #66afe9;
      outline: 0;
      box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
        0 0 8px rgba(102, 175, 233, 0.6);
    }
  }

  select {
    width: 100%;
    display: flex;
    margin-top: 16px;
    margin-bottom: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #555;
    background-color: #fff;
    background-clip: padding-box;
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    height: 34px;
    &:focus {
      border-color: #66afe9;
      outline: 0;
      box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
        0 0 8px rgba(102, 175, 233, 0.6);
    }
  }
`;
