import React, { useCallback, useMemo, useRef, useState } from 'react';

import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import { RawEditorSettings } from 'tinymce';
import Input from '../../forms/Input';
import Button from '../../forms/Buttom';

import { ContentPage, SectionGalery } from './styles';
import { IEditResource, IPages } from './index';
import { TabItem } from '../Tabs';
import Upload from '../../forms/Upload';
import { IconFileAlt } from '../../../styles/icons';
import { useToast } from '../../../hooks/toast';
import { useAuth } from '../../../hooks/auth';
import { uploadFiles } from '../../../services/api';

interface IProps {
  handlePage(page: IPages): void;
  propsEditor: RawEditorSettings;
  setIsOpen: () => void;
  editResource: IEditResource;
}

interface IMyInfographics {
  id: string;
  filename: string;
  url: string;
}

const FormInfographic: React.FC<IProps> = ({
  handlePage,
  propsEditor,
  setIsOpen,
  editResource,
}) => {
  const formRef = useRef<FormHandles>(null);
  const { addToast } = useToast();
  const { user } = useAuth();

  const [myInfographics, setMyInfographics] = useState<IMyInfographics[]>([]);
  const [selectedInfographic, setSelectedInfographic] = useState('');
  const [loadUpload, setLoadUpload] = useState(false);

  const isEdit = useMemo(() => {
    return Object.keys(editResource).length > 0;
  }, [editResource]);

  const handleUpload = useCallback(
    async (files: File[]) => {
      setLoadUpload(true);
      try {
        const formData = new FormData();
        formData.append('infographics', files[0]);
        await uploadFiles({
          formData,
          provider_id: user.provider?.id || '123',
          type: 'infographics',
        }).then((res: any) => {
          const { data } = res;
          setSelectedInfographic(data.url);
          setMyInfographics([...myInfographics, data]);
        });

        addToast({
          title: 'Sucesso!',
          description: 'Infografico enviado com sucesso',
          type: 'success',
        });
      } catch (error) {
        addToast({
          title: 'Ops ...',
          description: 'Ocorreu um erro durante seu upload.',
          type: 'error',
        });
      } finally {
        setLoadUpload(false);
      }
    },
    [addToast, myInfographics, user.provider],
  );

  const handleSubmit = useCallback(
    async data => {
      try {
        if (data.url === 'url') {
          addToast({
            title: 'Ops ...',
            description: 'Selecione arquivo zip com o infografico',
            type: 'error',
          });
          return;
        }
        // padrão de style para iframe
        data.width = '100%;';
        data.height = data.height ?? '650px';
        propsEditor.insertContent(
          `<div class="resource" data-interaction="true" data-type="iframe" data-subtype="" data-resource="${encodeURI(
            JSON.stringify(data),
          )}">
                  Iframe Externo
                </div>\n
                <p>&nbsp;</p>`,
        );

        propsEditor.focus();
        setIsOpen();
      } catch (error) {
        addToast({
          title: 'Ops ...',
          description: 'Ocorreu um erro durante seu upload.',
          type: 'error',
        });
        propsEditor.focus();
      }
    },
    [propsEditor, addToast, setIsOpen],
  );

  return (
    <Form ref={formRef} onSubmit={handleSubmit}>
      <ContentPage>
        {!isEdit && (
          <button type="button" onClick={() => handlePage('main')}>
            Voltar
          </button>
        )}
        <SectionGalery>
          <h2>Infográfico</h2>
          <Input
            hidden
            label=""
            name="url"
            defaultValue="url"
            onChange={e => setSelectedInfographic(e.target.value)}
            value={selectedInfographic}
          />
          <div className="container-pdf">
            <div className="wrap-icon">
              <IconFileAlt />
            </div>
            <h3>
              {selectedInfographic !== ''
                ? myInfographics.find(item => item.url === selectedInfographic)
                    ?.url
                : `Selecione um infografico ...`}
            </h3>
          </div>
          <TabItem id="tab-01" defaultValue="tab-01">
            <div className="container-imges">
              <h2>Enviar infografico</h2>
              <Upload
                onUpload={handleUpload}
                formts={[{ extension: '.zip', types: 'application/zip' }]}
                loading={loadUpload}
              />
            </div>
          </TabItem>
          <Input
            label="Altura *"
            name="height"
            placeholder="Exemplo: (100px, 30px, etc...)"
            defaultValue="650px"
          />
          <Input hidden label="" name="type" defaultValue="iframe" />
        </SectionGalery>
        <Button label="Inserir" type="submit" />
      </ContentPage>
    </Form>
  );
};

export default FormInfographic;
