import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import React, { useCallback, useRef, useState } from 'react';
import * as Yup from 'yup';
import { useToast } from '../../../hooks/toast';
import getValidationErrors from '../../../utils/getVaidationErrors';
import Buttom from '../../forms/Buttom';

import TextArea from '../../forms/TextArea';

import Modal from '../Modal';

import { Container, Bottom } from './styles';

interface IModalProps {
  isOpen: boolean;
  setIsOpen: () => void;
  handleInitialState(updateMessage: string): void;
}

const ModalEditItem: React.FC<IModalProps> = ({
  isOpen,
  setIsOpen,
  handleInitialState,
}) => {
  const { addToast } = useToast();
  const [loading, setLoading] = useState(false);
  const formRef = useRef<FormHandles>(null);
  const handleSubmit = useCallback(
    async data => {
      try {
        setLoading(true);
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          content: Yup.string().required('Campo Obrigatório'),
        });
        await schema.validate(data, {
          abortEarly: false,
        });
        await handleInitialState(data.content);

        setIsOpen();
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);
          formRef.current?.setErrors(errors);
          return;
        }
        addToast({
          title: 'Ops...',
          type: 'error',
          description: 'Algo inesperado aconteceu.',
        });
      } finally {
        setLoading(false);
      }
    },
    [addToast, setIsOpen, handleInitialState],
  );
  return (
    <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
      <Container>
        <h1>O que está sendo atualizado?</h1>
        <p>
          Antes de prosseguir, digite abaixo o que está sendo atualizado no
          conteúdo desta unidade.
        </p>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <TextArea
            label=""
            name="content"
            placeholder="Ex: Alteração na definição da lei…"
          />
          <button type="submit" hidden>
            submeter
          </button>
        </Form>
        <Bottom>
          <Buttom
            name="cancelar"
            type="button"
            onClick={() => setIsOpen()}
            label="Cancelar"
            outline
          />
          <Buttom
            name="salvar"
            onClick={() => formRef.current?.submitForm()}
            label="Salvar"
            isLoading={loading}
          />
        </Bottom>
      </Container>
    </Modal>
  );
};

export default ModalEditItem;
