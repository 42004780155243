import React, { useState, useEffect, useCallback } from 'react';
import { BeatLoader } from 'react-spinners';
import { Link, useParams } from 'react-router-dom';

import { useToast } from '../../hooks/toast';

import { Holder, Container, Title } from './styles';
import { useAuth } from '../../hooks/auth';

import ModalDisciplina from '../../components/template/ModalDisciplina';

import {
  Main,
  MainContent,
  Body,
} from '../../components/template/SellerTemplate/styles';
import NavTop from '../../components/template/NavTop';

import Render, { IContent } from '../../components/template/Render';

import { getItemsContent, getSellerDataById } from '../../services/api';

interface IParams {
  id: string;
  idItem: string;
}

interface IItem {
  id: string;
  authors: Array<string>;
  mediaUrl: string;
  questions: string;
  bibliographicReference: Array<string>;
  introduction: string;
  learningObjectives: Array<string>;
  synthesis: string;
  title: string;
  levelOfLearning: Array<string>;
  knowledgeUniversity: {
    id: string;
  };
  knowledges: {
    id: string;
    title: string;
  }[];
  topics: string;
  status: string;
  item: {
    id: string;
  };
  competence: string;
}

interface IRender {
  title: string;
  authors: Array<string>;
  mediaUrl: string;
  questions: string;
  knowlegdeLevel: Array<string>;
  knowlegdeArea1: string;
  introduction: string;
  learningObjectives: Array<string>;
  synthesis: string;
  bibliographicReference: Array<string>;
  content: IContent[];
  competence: string;
}

interface IItems {
  sellerId: string;
  itemId: string;
  itemContentId: string;
  title: string;
}

interface ISeller {
  loaded: boolean;
  id: string;
  name: string;
  ownerId: string;
  cnpj: string;
  planId: string;
}

const StoreItem: React.FC = () => {
  const { user } = useAuth();
  const { addToast } = useToast();

  const routeParames: IParams = useParams();

  // SELLERS ===========
  const [seller, setSeller] = useState<ISeller>({} as ISeller);

  useEffect(() => {
    const load = async (): Promise<void> => {
      if (user.seller) {
        try {
          const { data: res } = await getSellerDataById(user.seller.id);
          setSeller(res);
        } catch (error) {
          addToast({
            title: 'Ops...',
            type: 'error',
            description:
              'Algo inesperado aconteceu, por favor recarrege sua página.',
          });
        }
      }
    };

    load();
  }, [user, addToast]);

  // ITEM ===========
  const [itemInfo, setItemInfo] = useState({ itemId: '', title: '' });
  const [item, setItem] = useState<IRender>({
    title: '',
    authors: [''],
    mediaUrl: '',
    questions: '',
    knowlegdeLevel: [''],
    knowlegdeArea1: '',
    introduction: '',
    learningObjectives: [''],
    synthesis: '',
    bibliographicReference: [''],
    content: [
      {
        title: 'Tópico 01',
        sections: [{ content: '', type: 'txt' }],
      },
    ],
    competence: '',
  });

  const handleSetItem = useCallback((unitItem: IItem) => {
    setItem({
      title: unitItem.title,
      authors: unitItem.authors,
      mediaUrl: unitItem.mediaUrl,
      questions: unitItem.questions,
      knowlegdeLevel: unitItem.levelOfLearning,
      knowlegdeArea1: '',
      introduction: unitItem.introduction,
      learningObjectives: unitItem.learningObjectives,
      bibliographicReference: unitItem.bibliographicReference,
      synthesis: unitItem.synthesis,
      content: JSON.parse(unitItem.topics) as IContent[],
      competence: unitItem.competence,
    });
  }, []);

  useEffect(() => {
    const load = async (): Promise<void> => {
      try {
        const { data: res } = await getItemsContent(routeParames.id);
        handleSetItem(res.content);
        setItemInfo({
          itemId: res.content.item.id,
          title: res.content.title,
        });
      } catch (error) {
        addToast({
          title: 'Ops...',
          type: 'error',
          description:
            'Algo inesperado aconteceu, por favor recarrege sua página.',
        });
      }
    };
    load();
  }, [handleSetItem, addToast, routeParames]);

  // LOADING =====
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (item.title !== '') setLoading(true);
  }, [setLoading, item.title]);

  // DISCIPLINA =====
  const [selectedItem, setSelectedItem] = useState<IItems>({} as IItems);
  const [modalDisciplinaOpen, setModalDisciplinaOpen] = useState(false);
  const toggleModalDisciplina = useCallback(() => {
    setModalDisciplinaOpen(!modalDisciplinaOpen);
  }, [modalDisciplinaOpen]);
  // DISCIPLINA =====

  return (
    <Holder>
      <Main>
        <MainContent className="blur">
          <NavTop />
          <Body className="body">
            <ModalDisciplina
              isOpen={modalDisciplinaOpen}
              setIsOpen={toggleModalDisciplina}
              selectedItem={selectedItem}
            />
            <Container className="slideDown">
              {loading ? (
                <>
                  <Title>
                    <div className="fadeIn">
                      <div className="bread-crumb">
                        <ul>
                          <li>
                            <Link to="/seller/rented-units/">Catálogo</Link>
                          </li>
                          <li>{item.title}</li>
                        </ul>
                      </div>
                      <div className="provider">
                        <div>
                          <h2>{item.title}</h2>
                          <div>
                            <div>
                              {item.questions && (
                                <p>
                                  Banco de questões: para baixar clique{' '}
                                  <a href={item.questions}>aqui</a> .
                                </p>
                              )}
                            </div>
                            {item.knowlegdeLevel.map(knowledge => (
                              <span key={knowledge} className="tag">
                                {knowledge}
                              </span>
                            ))}
                          </div>
                        </div>

                        {user.type !== 'seller_assistent' && (
                          <button
                            type="button"
                            onClick={() => {
                              setSelectedItem({
                                itemId: itemInfo.itemId,
                                itemContentId: routeParames.id,
                                sellerId: seller.id,
                                title: itemInfo.title,
                              });
                              toggleModalDisciplina();
                            }}
                          >
                            Add
                          </button>
                        )}
                      </div>
                    </div>
                  </Title>
                  <Render className="slideUp" render={item} currentTopic={0} />
                </>
              ) : (
                <div className="global-loading">
                  <BeatLoader size={16} />
                </div>
              )}
            </Container>
          </Body>
        </MainContent>
      </Main>
    </Holder>
  );
};

export default StoreItem;
