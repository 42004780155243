/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import { RawEditorSettings } from 'tinymce';
import Input from '../../forms/Input';
import Button from '../../forms/Buttom';

import { ContentPage, SectionGalery } from './styles';
import { IEditResource, IPages } from './index';
import Tabs, { TabItem } from '../Tabs';
import Upload from '../../forms/Upload';
import { IconFileAlt } from '../../../styles/icons';
import { useToast } from '../../../hooks/toast';
import { useAuth } from '../../../hooks/auth';
import { getGallery, uploadFiles } from '../../../services/api';

interface IProps {
  handlePage(page: IPages): void;
  propsEditor: RawEditorSettings;
  setIsOpen: () => void;
  editResource: IEditResource;
}

interface IMyPDFs {
  id: string;
  filename: string;
  url: string;
}

const FormPDF: React.FC<IProps> = ({
  handlePage,
  propsEditor,
  setIsOpen,
  editResource,
}) => {
  const formRef = useRef<FormHandles>(null);
  const { addToast } = useToast();
  const { user } = useAuth();

  const [myPDFs, setMyPDFs] = useState<IMyPDFs[]>([]);
  const [selectedPdf, setSelectedPdf] = useState('');
  const [currentTab, setCurrentTab] = useState('tab-01');
  const [loadUpload, setLoadUpload] = useState(false);

  const isEdit = useMemo(() => {
    return Object.keys(editResource).length > 0;
  }, [editResource]);

  const handleTab = useCallback((id: string) => {
    setCurrentTab(id);
  }, []);

  useEffect(() => {
    if (Object.keys(editResource).length > 0) {
      setSelectedPdf(editResource.resource);
    }
  }, [editResource]);

  useEffect(() => {
    getGallery({
      provider_id: user.provider?.id || '',
      type: 'pdf',
    }).then((res: any) => {
      setMyPDFs(res);
    });
  }, [user.provider]);

  const handleUpload = useCallback(
    async (files: File[]) => {
      setLoadUpload(true);

      try {
        const formData = new FormData();
        formData.append('images', files[0]);
        let providerId = user.provider?.id || '';
        if (user.type === 'review') {
          providerId = user.id;
        }

        await uploadFiles({
          formData,
          provider_id: providerId,
          type: 'pdf',
        });
        getGallery({
          provider_id: providerId,
          type: 'pdf',
        }).then((res: any) => {
          setSelectedPdf(res[0].url);
          setMyPDFs(res);
        });
        setCurrentTab('tab-02');
      } catch (error) {
        addToast({
          title: 'Ops ...',
          description: 'Ocorreu um erro durante seu upload.',
          type: 'error',
        });
      } finally {
        setLoadUpload(false);
      }
    },
    [addToast, user.id, user.provider, user.type],
  );

  const handleSubmit = useCallback(
    async data => {
      if (data.url === '') {
        addToast({
          title: 'Ops ...',
          description: 'Selecione um pdf para adicionar este recurso',
          type: 'error',
        });
        return;
      }

      if (isEdit) {
        // editResource.element.remove();
        editResource.element.setAttribute('data-resource', data.url);
        propsEditor.insertContent(propsEditor.selection.getContent());
      } else {
        propsEditor.insertContent(
          `<div class="resource" data-interaction="true" data-type="media" data-subtype="pdf" data-resource="${data.url}">
          PDF
        </div>\n
        <p>&nbsp;</p>`,
        );
      }

      propsEditor.focus();
      setIsOpen();
    },
    [addToast, propsEditor, setIsOpen, editResource.element, isEdit],
  );

  return (
    <Form ref={formRef} onSubmit={handleSubmit}>
      <ContentPage>
        {!isEdit && (
          <button type="button" onClick={() => handlePage('main')}>
            Voltar
          </button>
        )}
        <SectionGalery>
          <h2>PDF</h2>
          <Input
            hidden
            label=""
            name="url"
            defaultValue="url"
            onChange={e => setSelectedPdf(e.target.value)}
            value={selectedPdf}
          />
          <div className="container-pdf">
            <div className="wrap-icon">
              <IconFileAlt />
            </div>
            <h3>
              {selectedPdf !== ''
                ? myPDFs.find(item => item.url === selectedPdf)?.filename
                : `Selecione um pdf ...`}
            </h3>
          </div>

          <div className="content-tabs">
            <Tabs
              className="secondary"
              itens={[
                { id: 'tab-01', label: 'Envia PDF' },
                { id: 'tab-02', label: 'Galeria de PDF' },
              ]}
              handleTab={handleTab}
              defaultValue={currentTab}
            />
          </div>
          <TabItem id="tab-01" defaultValue={currentTab}>
            <div className="container-imges">
              <h2>Enviar PDF</h2>
              <Upload
                onUpload={handleUpload}
                formts={[{ extension: '.pdf', types: 'application/pdf' }]}
                loading={loadUpload}
              />
            </div>
          </TabItem>
          <TabItem id="tab-02" defaultValue={currentTab}>
            <div className="container-imges">
              <h2>Minha Galeria</h2>
              <div className="content-show-galery">
                {myPDFs.length > 0 ? (
                  <ul>
                    {myPDFs.map(pdf => (
                      <li
                        key={pdf.id}
                        className={`${selectedPdf === pdf.url ? `active` : ``}`}
                        onClick={() => setSelectedPdf(pdf.url)}
                      >
                        {pdf.filename}
                      </li>
                    ))}
                  </ul>
                ) : (
                  <h3>Nada encontrado.</h3>
                )}
              </div>
            </div>
          </TabItem>
          <Input hidden label="" name="type" defaultValue="pdf" />
        </SectionGalery>
        <Button label="Inserir" type="submit" />
      </ContentPage>
    </Form>
  );
};

export default FormPDF;
