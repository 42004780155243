import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import { Container, Content } from './styles';
import Render, { IContent } from '../../components/template/Render';
import { useToast } from '../../hooks/toast';
import { getItemsContent } from '../../services/api';

interface IParams {
  id: string;
}

interface IItem {
  id: string;
  authors: Array<string>;
  mediaUrl: string;
  questions: string;
  bibliographicReference: Array<string>;
  introduction: string;
  learningObjectives: Array<string>;
  synthesis: string;
  title: string;
  levelOfLearning: Array<string>;
  knowledgeUniversity: number;
  topics: string;
  status: string;
}

const Unit: React.FC = () => {
  const { addToast } = useToast();
  const routeParames: IParams = useParams();

  const [item, setItem] = useState<IItem>({} as IItem);

  const render = useMemo(() => {
    if (Object.keys(item).length === 0) {
      return {
        title: '',
        authors: [''],
        mediaUrl: '',
        questions: '',
        knowlegdeLevel: [''],
        knowlegdeArea1: '',
        introduction: '',
        learningObjectives: [''],
        synthesis: '',
        bibliographicReference: [''],
        content: [],
      };
    }
    return {
      title: item.title,
      authors: item.authors,
      mediaUrl: item.mediaUrl,
      questions: item.questions,
      knowlegdeLevel: item.levelOfLearning,
      knowlegdeArea1: '',
      introduction: item.introduction,
      learningObjectives: item.learningObjectives,
      bibliographicReference: item.bibliographicReference,
      synthesis: item.synthesis,
      content: JSON.parse(item.topics) as IContent[],
    };
  }, [item]);

  useEffect(() => {
    const load = async (): Promise<void> => {
      try {
        const { data: res } = await getItemsContent(routeParames.id);
        setItem(res.content);
      } catch (error) {
        addToast({
          title: 'Ops ...',
          description: 'Algo inesperado aconteceu',
          type: 'error',
        });
      }
    };
    load();
  }, [addToast, routeParames]);

  return (
    <Container className="slideDown">
      <Content>
        <Render render={render} currentTopic={0} className="review" />
      </Content>
    </Container>
  );
};

export default Unit;
