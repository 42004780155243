import { useEffect } from 'react';
import { useHistory, withRouter } from 'react-router-dom';

const ScrollToTop: React.FC = () => {
  const history = useHistory();
  useEffect(() => {
    const unlisten = history.listen(() => {
      document.querySelector('main')?.scrollTo(0, 0);
    });
    return () => {
      unlisten();
    };
  }, [history]);

  return null;
};

export default withRouter(ScrollToTop);
