/* eslint-disable react/no-array-index-key */
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { RawEditorSettings } from 'tinymce';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import Input from '../../forms/Input';
import ButtonSubmit from '../../forms/Buttom';

import { ContentPage, SectionGalery, Button } from './styles';
import { IEditResource, IPages } from './index';
import Editor from '../../forms/Editor';
import { generateId } from '../../../utils/generateId';
import { useToast } from '../../../hooks/toast';

interface IProps {
  handlePage(page: IPages): void;
  propsEditor: RawEditorSettings;
  setIsOpen: () => void;
  editResource: IEditResource;
}

interface IList {
  id: string;
  title: string;
  description: string;
}

interface IOnChange {
  id: string;
  text: string;
  field: 'title' | 'description';
}

const INIT_STATE: IList = {
  id: generateId(),
  title: '',
  description: '',
};

const FormQuestion: React.FC<IProps> = ({
  handlePage,
  propsEditor,
  setIsOpen,
  editResource,
}) => {
  const formRef = useRef<FormHandles>(null);
  const { addToast } = useToast();
  const [list, setList] = useState<IList[]>([INIT_STATE]);

  const isEdit = useMemo(() => {
    return Object.keys(editResource).length > 0;
  }, [editResource]);

  const editList = useMemo<IList[]>(() => {
    if (editResource.resource) {
      const recoverList = JSON.parse(editResource.resource);
      return recoverList.map((item: any) => {
        return {
          id: generateId(),
          ...item,
        };
      });
    }

    return [];
  }, [editResource.resource]);

  useEffect(() => {
    if (editList && editList.length > 0) {
      setList(editList);
    }
  }, [editList]);

  const handleAddItem = useCallback(() => {
    setList([
      ...list,
      {
        id: generateId(),
        title: '',
        description: '',
      },
    ]);
  }, [list]);

  const handleRemoveItem = useCallback(
    (id: string) => {
      if (list.length <= 1) {
        return;
      }
      const newList = list.filter(item => item.id !== id);
      setList(newList);
    },
    [list],
  );

  const handleOnChange = useCallback(
    ({ id, text, field }: IOnChange) => {
      const newList = list.map(item => {
        if (item.id === id) {
          return {
            ...item,
            title: field === 'title' ? text : item.title,
            description: field === 'description' ? text : item.description,
          };
        }
        return item;
      });

      setList(newList);
    },
    [list],
  );
  const handleSubmit = useCallback(
    async data => {
      const titles = Object.values(data.title);
      const descriptions = Object.values(data.description);
      if (titles.filter(item => item === '').length > 0) {
        addToast({
          title: 'Ops ...',
          description: 'Todos os títulos devem ser preenchidos',
          type: 'error',
        });
        return;
      }

      if (descriptions.filter(item => item === '').length > 0) {
        addToast({
          title: 'Ops ...',
          description: 'Todos as descrições devem ser preenchidos',
          type: 'error',
        });
        return;
      }

      const listJSON = titles.map((item, i: number) => {
        return {
          title: item,
          description: descriptions[i],
        };
      });

      if (isEdit) {
        // editResource.element.remove();
        editResource.element.setAttribute(
          'data-resource',
          JSON.stringify(listJSON),
        );
        propsEditor.insertContent(propsEditor.selection.getContent());
      } else {
        propsEditor.insertContent(
          `<div
          class="resource"
          data-interaction="true"
          data-type="list"
          data-subtype=""
          data-resource='${JSON.stringify(listJSON).replace(/[']/g, '&#39;')}'>
            Lista
          </div>\n
          <p>&nbsp;</p>`,
        );
      }

      propsEditor.focus();
      setIsOpen();
    },
    [addToast, propsEditor, setIsOpen, isEdit, editResource.element],
  );

  return (
    <Form ref={formRef} onSubmit={handleSubmit}>
      <ContentPage>
        {!isEdit && (
          <button type="button" onClick={() => handlePage('main')}>
            Voltar
          </button>
        )}
        <SectionGalery>
          <h2>Lista</h2>
          <div className="container-list">
            <label htmlFor="list">Lista de Conteúdo*</label>
            {list.map((item, i: number) => (
              <div className="list-item" key={`list -${i}`}>
                <Input
                  label="Título do Item*"
                  name={`title[${item.id}]`}
                  placeholder="Digite o Título do item da lista"
                  value={item.title}
                  onChange={e =>
                    handleOnChange({
                      id: item.id,
                      text: e.target.value,
                      field: 'title',
                    })
                  }
                />
                <Editor
                  label="Descrição do Item*"
                  name={`description[${item.id}]`}
                  placeholder="Digite a descrição do item"
                  value={item.description}
                  onChange={e =>
                    handleOnChange({
                      id: item.id,
                      text: e.target.value,
                      field: 'description',
                    })
                  }
                />

                <Button
                  className="danger"
                  type="button"
                  onClick={() => handleRemoveItem(item.id)}
                >
                  Remover Item
                </Button>
              </div>
            ))}

            <Button type="button" onClick={() => handleAddItem()}>
              Add Item +
            </Button>
          </div>
          <Input hidden label="" name="type" defaultValue="list" />
        </SectionGalery>
        <ButtonSubmit label="Inserir" type="submit" />
      </ContentPage>
    </Form>
  );
};

export default FormQuestion;
