import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import React, { useCallback, useRef, useState } from 'react';
import * as Yup from 'yup';
import { useToast } from '../../../hooks/toast';
import { IPlanPrice } from '../../../pages/Plans';
import {
  postPlansProviderPrice,
  putPlansProviderPrice,
} from '../../../services/api';
import { saveManagePlanPriceLog } from '../../../services/logs';

import { useAuth } from '../../../hooks/auth';

import getValidationErrors from '../../../utils/getVaidationErrors';
import Buttom from '../../forms/Buttom';
import Input from '../../forms/Input';

import Modal from '../Modal';

import { Container, Bottom } from './styles';

interface IModalProps {
  isOpen: boolean;
  setIsOpen: () => void;
  handleInitialState(): void;
  planPrice: IPlanPrice;
}

const ModalEditPlanPrice: React.FC<IModalProps> = ({
  isOpen,
  setIsOpen,
  handleInitialState,
  planPrice,
}) => {
  const { addToast } = useToast();
  const { user } = useAuth();
  const [loading, setLoading] = useState(false);
  const formRef = useRef<FormHandles>(null);

  const handleSubmit = useCallback(
    async data => {
      try {
        setLoading(true);
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          price: Yup.string().required('Campo Obrigatório'),
        });
        await schema.validate(data, {
          abortEarly: false,
        });

        let action_taken = 'created';

        if (planPrice && planPrice.id) {
          action_taken = 'updated';
          await putPlansProviderPrice({
            id: planPrice.id,
            planId: planPrice.planId,
            providerId: planPrice.providerId,
            value: data.price,
          });
        } else {
          await postPlansProviderPrice({
            planId: planPrice.planId,
            providerId: planPrice.providerId,
            value: data.price,
          });
        }

        saveManagePlanPriceLog({
          userId: user.id,
          action: `${action_taken}_provider_price_plan`,
          providerId: planPrice.providerId,
          planId: planPrice.planId,
          value: data.price,
        });

        addToast({
          title: 'Sucesso!',
          type: 'success',
          description: 'Plano editado editado com sucesso!',
        });
        handleInitialState();
        setIsOpen();
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);
          formRef.current?.setErrors(errors);
          return;
        }
        addToast({
          title: 'Ops...',
          type: 'error',
          description: 'Algo inesperado aconteceu.',
        });
      } finally {
        setLoading(false);
      }
    },
    [planPrice, addToast, handleInitialState, setIsOpen],
  );
  return (
    <Modal isOpen={isOpen} setIsOpen={setIsOpen} width="38%">
      <Container>
        <h1>Editar Plano</h1>
        <Form
          ref={formRef}
          onSubmit={handleSubmit}
          initialData={{ price: planPrice?.value }}
        >
          <Input
            name="price"
            label="Valor mensal do plano:*"
            type="text"
            placeholder="Preço"
          />
          <button type="submit" hidden>
            submeter
          </button>
        </Form>
        <Bottom>
          <Buttom
            name="cancelar"
            type="button"
            onClick={() => setIsOpen()}
            label="Cancelar"
            outline
          />
          <Buttom
            name="salvar"
            onClick={() => formRef.current?.submitForm()}
            label="Salvar"
            isLoading={loading}
          />
        </Bottom>
      </Container>
    </Modal>
  );
};

export default ModalEditPlanPrice;
