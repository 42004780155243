/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { HTMLAttributes, useCallback } from 'react';
import Select from 'react-select';

interface IOptions {
  label: string;
  value: string;
}

interface IReactSelect extends HTMLAttributes<HTMLDivElement> {
  name: string;
  options: IOptions[];
  handleValue(value: string): void;
  placeholder?: string;
  defaultValue?: string;
}

const ReactSelect: React.FC<IReactSelect> = ({
  options,
  handleValue,
  placeholder,
  defaultValue,
  style,
}) => {
  const getDefaultValue = useCallback(() => {
    if (!defaultValue) return null;
    const findOption = options.find(option => defaultValue === option.value);
    return findOption;
  }, [options, defaultValue]);

  const customStyle = {
    option: (provided: any, state: any) => ({
      ...provided,
      border: '0',
      color: '#8D8585',
      background: state.isFocused ? '#F9F5F5' : 'white',
      paddingTop: 6,
      paddingBottom: 6,
    }),
    container: (provided: any, state: any) => ({
      ...provided,
      border: '0',
    }),
  };
  const themeOptions = (theme: any) => ({
    ...theme,
    colors: {
      ...theme.colors,
      primary50: '#E0DEDE',
      primary25: '#E0DEDE',
      primary: '#E0DEDE',
    },
  });

  return (
    <Select
      onChange={(e: any) => handleValue(e.value)}
      name="curador"
      options={options}
      theme={themeOptions}
      styles={customStyle}
      placeholder={placeholder}
      defaultValue={getDefaultValue()}
      style={style}
    />
  );
};

export default ReactSelect;
