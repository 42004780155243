/* eslint-disable no-continue */
/* eslint-disable react/no-danger */
import React, { useCallback, useMemo, useState } from 'react';

import TeXToSVG from 'tex-to-svg';

import {
  SectionQuestion,
  ContainerQuestion,
  ContentSectionTitle,
  ContentQuestion,
  ItemQuestion,
  ButtonAnswer,
  Menssage,
} from './styles';

import { IContentSection } from './index';
import {
  IconCheckCircle,
  IconCloseCircle,
  IconPencilAlt,
} from '../../../styles/icons';

interface IQuestion {
  section: IContentSection;
}

interface IQuestionItems {
  title: string;
  content: string;
  options: {
    id: string;
    title: string;
    description: string;
  }[];
  right: string;
}

interface IHandleOptions {
  id: string;
  description: string;
}

const alphabet = 'abcdefghijklmnopqrstuvwxyz'.split('');

const RenderList: React.FC<IQuestion> = ({ section }) => {
  const [selectedOption, setSelectedOption] = useState('');
  const [selectedDescription, setSelectedDescription] = useState('');
  const [checkAnswer, setCheckAnswer] = useState<boolean | null>(null);

  const question = useMemo<IQuestionItems>(() => {
    const questionJSON = JSON.parse(section.content) as IQuestionItems;
    if (questionJSON) {
      return questionJSON;
    }
    return {} as IQuestionItems;
  }, [section.content]);

  let newSelectedDescription = selectedDescription;

  // função para tratar formulas em latex no feedback das questões
  if (selectedDescription.indexOf(`<span class="latex">`) >= 0) {
    const arrayStrings = selectedDescription.split('<span class="latex">');
    let control = 0;

    newSelectedDescription = '';

    while (control < arrayStrings.length) {
      if (arrayStrings[control].indexOf('\\[') === -1) {
        newSelectedDescription += arrayStrings[control];
        control++;
        continue;
      }

      let latexString = arrayStrings[control].substring(
        arrayStrings[control].indexOf('\\['),
        arrayStrings[control].lastIndexOf('\\]') + 2,
      );

      if (!latexString.includes('svg')) {
        latexString = latexString.substring(2);
        latexString = latexString.substring(0, latexString.length - 2);

        newSelectedDescription += arrayStrings[control].replace(
          arrayStrings[control].substring(
            arrayStrings[control].indexOf('\\['),
            arrayStrings[control].lastIndexOf('\\]') + 2,
          ),

          TeXToSVG(latexString, {
            width: 1280,
            ex: 8,
            em: 14,
          }),
        );
      }

      control++;
    }
  }

  const handleChooseOption = useCallback(
    ({ id, description }: IHandleOptions) => {
      setSelectedOption(id);
      setSelectedDescription(description);
      setCheckAnswer(null);
    },
    [],
  );

  const handleCheckAnswer = useCallback(() => {
    if (selectedOption === '') {
      return;
    }
    if (selectedOption === question.right) {
      setCheckAnswer(true);
      return;
    }
    setCheckAnswer(false);
  }, [selectedOption, question.right]);

  return (
    <SectionQuestion>
      <ContentSectionTitle>
        <IconPencilAlt />
        <h2>Atividade</h2>
      </ContentSectionTitle>
      <ContainerQuestion>
        <div
          dangerouslySetInnerHTML={{
            __html: question.content && question.content,
          }}
        />
        <ContentQuestion>
          {question.options.map((item, i) => (
            <ItemQuestion
              key={item.id}
              className={`${selectedOption === item.id ? `active` : ``} ${
                checkAnswer === true && selectedOption === item.id
                  ? `is-right`
                  : ``
              } ${
                checkAnswer === false && selectedOption === item.id
                  ? `is-wrong`
                  : ``
              }
              ${
                checkAnswer !== null && question.right === item.id
                  ? `is-right`
                  : ``
              }
              `}
              onClick={() =>
                handleChooseOption({
                  id: item.id,
                  description: item.description,
                })
              }
            >
              <span>{alphabet[i]}.</span>
              <div
                dangerouslySetInnerHTML={{
                  __html: item.title && item.title,
                }}
              />
            </ItemQuestion>
          ))}

          <ButtonAnswer type="button" onClick={() => handleCheckAnswer()}>
            Responder
          </ButtonAnswer>
        </ContentQuestion>
      </ContainerQuestion>

      {checkAnswer !== null && (
        <Menssage className={`${checkAnswer === false && `error`}`}>
          {checkAnswer === true ? (
            <IconCheckCircle width={60} height={60} />
          ) : (
            <IconCloseCircle width={60} height={60} />
          )}
          <div>
            <strong>
              Resposta {checkAnswer === true ? `Correta` : `Incorreta`}
            </strong>
            <div
              dangerouslySetInnerHTML={{
                __html: newSelectedDescription && newSelectedDescription,
              }}
            />
          </div>
        </Menssage>
      )}
    </SectionQuestion>
  );
};

export default RenderList;
