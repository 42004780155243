import styled from 'styled-components';

export const Container = styled.div`
  height: 100vh;
  display: flex;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 0 auto;
  max-width: 700px;
`;

export const ContentForm = styled.div`
  padding: 0 14px;
  width: 100%;
  margin: 0 auto;
  h1 {
    text-align: center;
    margin-bottom: 32px;
  }

  @media (min-width: 768px) {
    max-width: 500px;
  }
`;

export const BottomForm = styled.div`
  margin-top: 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  a {
    color: var(--dark);
    text-decoration: underline;
    opacity: 1;
    transition: opacity 0.1s;
    &:hover {
      opacity: 0.6;
    }
  }

  div + div {
    margin-left: 10px;
  }
`;

export const ContentBottom = styled.div`
  margin-top: 24px;
  a {
    font-weight: 700;
    color: var(--aurea);
  }
`;
