import styled, { css } from 'styled-components';

export const Container = styled.div`
  height: calc(100vh - 100px);
  display: flex;
  max-width: 812px;
  margin: 0 auto 18px auto;

  flex-direction: column;
`;

export const Content = styled.div`
  display: flex;
`;

interface IBloco {
  Border?: boolean;
  Inverted?: boolean;
  Third?: boolean;
  ThirdDouble?: boolean;
  Left?: boolean;
  Hidden?: boolean;
}

export const Bloco = styled.div<IBloco>`
  width: 100%;
  background: #ffffff;
  padding: 20px 30px;
  border: 1px solid var(--gray-border);
  border-radius: 10px;
  margin-top: 25px;
  ${props =>
    props.Border &&
    css`
      border-bottom: 10px solid var(--aurea);
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      border-radius: 10px 10px 0 0;
      h2,
      p {
        width: 100%;
      }
      img {
        margin: 10px 0 0 0;
      }
    `}
  h2 {
    font-weight: 700;
    font-size: 1.375rem;
    color: var(--aurea);
  }
  h2,
  p {
    margin: 10px 0;
    strong {
      font-weight: bold;
    }
  }
  ${props =>
    props.Inverted &&
    css`
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: center;
      border: none;
      background: var(--aurea);
      text-align: center;
      cursor: pointer;
      transition: background 0.3s;
      &:hover {
        background: var(--hover);
        img {
          filter: brightness(0.3);
        }
        h2,
        p {
          color: #565656;
        }
      }
      img {
        width: 60px;
        filter: brightness(1.5) saturate(0);
      }
      h2,
      p {
        color: #ffffff;
      }
      h2 {
        font-size: 1.2rem;
      }
    `}
  ${props =>
    props.Third &&
    css`
      width: 33.33333%;
    `}
    ${props =>
    props.ThirdDouble &&
    css`
      width: 66.66666%;
    `}
    ${props =>
    props.Left &&
    css`
      margin-left: 25px;
    `}
    ${props =>
    props.Hidden &&
    css`
      visibility: hidden;
    `}
`;


export const DateContainer = styled.div`
  text-transform: uppercase;
  border-radius: 5px;
  width: 82px;
  height: 24px;
  text-align: center;
  font-weight: 600;
  font-size: 12px;
  padding-top: 5px;
  justify-self: center;
`;

export const TitleLeft = styled.div`
.title-published{
  margin-left: 84%;
  font-size: 110%;
  white-space: nowrap;
 }

 .title-units{
  margin-top: -3%
 }

 .title-provider{
  margin-left: 44%;
  font-size: 110%;
  margin-top: -4%;
 }
`;

export const Providers = styled.div`
`;