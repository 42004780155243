import firebase from 'firebase/app';
import { initializeApp } from 'firebase/app';
import 'firebase/database';

const firebaseConfig = {
  apiKey: 'AIzaSyCijYSKqtUWGllt-XMLiKQ_-hBM8hS-6RM',
  authDomain: 'aurea-notifications.firebaseapp.com',
  databaseURL: 'https://aurea-notifications-default-rtdb.firebaseio.com',
  projectId: 'aurea-notifications',
  storageBucket: 'aurea-notifications.appspot.com',
  messagingSenderId: '86422282567',
  appId: '1:86422282567:web:128a9ea259013b062fec62',
};

const firebaseLogsConfig = {
  apiKey: 'AIzaSyBFRmAvQj8vSeALvWt1jVmnhtxgzDifpKw',
  authDomain: 'aurea-8bcf1.firebaseapp.com',
  projectId: 'aurea-8bcf1',
  storageBucket: 'aurea-8bcf1.appspot.com',
  messagingSenderId: '742706488950',
  appId: '1:742706488950:web:0920dce487e6a5048ee157',
  measurementId: 'G-DNJ2NQ2DYY',
};

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

const firebaseLogs = initializeApp(firebaseLogsConfig, 'firebaseLogs');

export { firebase, firebaseLogs };
