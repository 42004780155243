import React, { useCallback, useMemo, useRef } from 'react';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import { RawEditorSettings } from 'tinymce';
import Input from '../../forms/Input';
import Button from '../../forms/Buttom';

import { ContentPage } from './styles';
import { IEditResource, IPages } from '.';
import { useToast } from '../../../hooks/toast';

interface IProps {
  handlePage(page: IPages): void;
  propsEditor: RawEditorSettings;
  setIsOpen: () => void;
  editResource: IEditResource;
}

const FormPodcast: React.FC<IProps> = ({
  handlePage,
  propsEditor,
  setIsOpen,
  editResource,
}) => {
  const { addToast } = useToast();
  const formRef = useRef<FormHandles>(null);

  const isEdit = useMemo(() => {
    return Object.keys(editResource).length > 0;
  }, [editResource]);

  const handleSubmit = useCallback(
    async data => {
      if (data.link === '') {
        addToast({
          title: 'Ops ...',
          description: 'Insira o link do podcast para adicionar este recurso',
          type: 'error',
        });
        return;
      }

      let linkPodcast = '';
      if (data.link.includes('<iframe')) {
        const newLink = data.link.split(' ');
        linkPodcast = newLink.find((item: string) => item.includes('src="'));
        linkPodcast = linkPodcast.replace('src="', '').replace('"', '');
      } else {
        linkPodcast = data.link;
      }

      if (linkPodcast === '') {
        addToast({
          title: 'Ops ...',
          description: 'Insira o link do incorporar de soundcloud.com',
          type: 'error',
        });
        return;
      }

      if (isEdit) {
        // editResource.element.remove();
        editResource.element.setAttribute('data-resource',linkPodcast);
        propsEditor.insertContent(propsEditor.selection.getContent());
      } else {
        propsEditor.insertContent(
          `<div class="resource" data-interaction="true" data-type="media" data-subtype="podcast" data-resource="${linkPodcast}">
              Podcast
            </div>\n
            <p>&nbsp;</p>`,
        );
      }

      propsEditor.focus();
      setIsOpen();
    },
    [propsEditor, setIsOpen, addToast, isEdit, editResource.element],
  );
  return (
    <Form
      ref={formRef}
      onSubmit={handleSubmit}
      initialData={{ link: editResource.resource }}
    >
      <ContentPage>
        {!isEdit && (
          <button type="button" onClick={() => handlePage('main')}>
            Voltar
          </button>
        )}
        <div>
          <h2>Podcast</h2>
          <Input
            label="Inserir um podcast"
            name="link"
            type="text"
            placeholder='<iframe src="https://w.soundcloud.com/player/... />'
          />
          <Input hidden label="" name="type" defaultValue="podcast" />
          <Button label="Inserir" type="submit" />
        </div>
      </ContentPage>
    </Form>
  );
};

export default FormPodcast;
