import styled from 'styled-components';

export const Container = styled.div`

a {
  text-decoration: none !important;
}

button {
    display: flex;
    align-items: center;
    height: 45px;

    border-radius: 8px;
    border: 1px solid transparent;
    padding: 0 16px;
    background: #e1e1e1;

    &:first-child {
      color: #fff;
      border-color: #7284ff;
      background: #7284ff;
    }

    &:hover,
    &:active {
      border-bottom-color: #ccc;
    }

    &[disabled] {
      opacity: 0.5;
      cursor: default;
      cursor: not-allowed;
    }
  }

  button:active {
    transform: scale(0.98);
    top: 10px;
  }

  button:focus {
  outline: none !important;
}
  svg {
    margin-right: 8px;
  }
`;

