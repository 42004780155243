import styled from 'styled-components';

export const Container = styled.div`
  max-width: 812px;
  margin: 0 auto 18px auto;
`;

export const SectionService = styled.div`
  margin-top: 40px;
  max-height: 600px;
`;

export const Service = styled.div`
  margin-top: 20px;
  border: 1px solid var(--gray-border);
  border-radius: 5px;
  display: block;

  h3 {
    font-weight: 600;
  }

  p {
    margin-top: 8px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3; /* number of lines to show */
    -webkit-box-orient: vertical;
  }
`;
export const TextLink = styled.p`
  margin-top: 10px;
`;

export const ServiceContainer = styled.div`
  width: 90%;
  padding: 20px 15px;
`;

export const ServiceImage = styled.div`
  display: block;
  width: 100%;
  height: 100px;
  display: inline-block;
  background-position: center;
  border-radius: 5px 5px 0px 0px;
`;

export const IconContainer = styled.div`
  width: 10%;
  padding-top: 9%;
  margin-left: 3%;

  svg {
    margin-right: 8px;
  }
`;

export const Options = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-between;

  margin-top: 36px;
  .hidden {
    display: none;
  }
`;

export const OptionRight = styled.div`
  /* display: flex; */
  align-items: center;
  width: 60%;
  > div {
    float: left;
  }
  &.clear {
    > div {
      float: none;
    }
  }

  .search {
    width: 220px;
    &.mT {
      margin-top: 20px;
    }
  }

  /* Controla propriedades do ReactSelect */
  .select {
    width: 190px;
    margin-left: 8px;
    > div {
      > div {
        min-height: 40px;
      }
    }

    div {
      color: var(--gray) !important;
    }

    span {
      display: none;
    }
  }

  /* div + div {
    margin-left: 10px;
  } */
`;

export const Both = styled.div`
  clear: both;
`;

export const OptionLeft = styled.div`
  width: 38%;
  display: flex;
  a {
    background: var(--aurea);
    padding: 6px 12px;

    font-weight: bold;

    border: 1px solid var(--aurea);
    border-radius: 5px;

    margin-left: 8px;
    transition: background 0.4s;
    color: var(--dark);

    display: flex;
    align-items: center;

    &:hover {
      background: var(--hover);
      border-color: var(--hover);

      text-decoration: none;
    }

    svg {
      margin-right: 6px;
    }
  }
  &.hidden {
    display: none;
  }
`;
export const AddButton = styled.button`
  background: var(--aurea);
  padding: 6px 14px;
  margin-top: 50px;

  font-weight: bold;

  border: 1px solid var(--aurea);
  border-radius: 5px;

  margin-left: 8px;
  transition: background 0.4s;
  color: var(--dark);

  display: flex;
  align-items: center;
  &:hover {
    background: var(--hover);
  }

  svg {
    margin-right: 6px;
  }
`;

export const Button = styled.button`
  background: transparent;
  border: none;
`;
