import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Title } from '../../components/template/TemplateManager/styles';

import { Container } from './styles';
import {
  ContentReview,
  ContentLeft,
  ContentRight,
  Card,
  CardTitle,
  CardBody,
  CardItem,
  Status,
  CardFooter,
  ButtonWhite,
  InputSendMensage,
} from '../Units/styles';
import IconUser from '../../assets/icons/icon-user-circle.svg';

import { IconDocument, IconSchool, IconSendMessage } from '../../styles/icons';
import {
  filterComment,
  getShowReviewer,
  postCreateComment,
} from '../../services/api';
import { useToast } from '../../hooks/toast';
import Render, { IContent } from '../../components/template/Render';
import { statusReview } from '../../data/items';
import {
  CommentBody,
  CommentBox,
  CommentContent,
  CommentFooter,
  CommentImg,
  CommentItem,
  CommentLoggedUserBox,
  CommentLoggedUserImg,
  CommentLoggedUserItem,
  CommentLoggedUserTime,
  CommentTime,
} from '../Units/comment.styles';
import { timeAgo } from '../../utils/timeSince';
import { useAuth } from '../../hooks/auth';

interface IParams {
  id: string;
}

// /item/content/find/87486e8d-30e2-4f07-b67f-f415ac53853c
// /review/find/87486e8d-30e2-4f07-b67f-f415ac53853c
// idContent

interface IItem {
  id: string;
  authors: Array<string>;
  mediaUrl: string;
  questions: string;
  bibliographicReference: Array<string>;
  introduction: string;
  learningObjectives: Array<string>;
  synthesis: string;
  title: string;
  levelOfLearning: Array<string>;
  topics: string;
  provider: {
    name: string;
  };
  status: string;
}

interface IReviwer {
  id: string;
  reviewerId: string;
  curatorId: string;
  managerStatus: string;
  reviewerStatus: string;
  curatorStatus: string;
  curator: {
    name: string;
  };
  reviewer: {
    name: string;
  };
  created_at: string;
  item: IItem;
}

interface IComments {
  id: string;
  author: {
    id: string;
    name: string;
    type: string;
    email: string;
    avatar: string;
  };
  comment: string;
  created_at: string;
}

const UpdateItem: React.FC = () => {
  const history = useHistory();
  const routeParames: IParams = useParams();
  const { addToast } = useToast();
  const { user } = useAuth();
  const screenCommentRef = useRef<HTMLDivElement>(null);

  const [comments, setComments] = useState<IComments[]>([]);
  const [comment, setComment] = useState('');

  const [review, setReview] = useState<IReviwer>({} as IReviwer);
  const itemStatus = useMemo(() => {
    if (review.item) {
      return review.item.status === 'correction';
    }
    return false;
  }, [review]);
  useEffect(() => {
    const load = async (): Promise<void> => {
      try {
        // Get Avaliação
        const { data: resReview } = await getShowReviewer({
          id: routeParames.id,
          type: 'content',
        });
        setReview({
          ...resReview.content,
          item: {
            ...resReview.content.itemContent,
          },
        });

        // Get nos comentários
        const { data: resComment } = await filterComment({
          reviewId: resReview.content.id,
        });
        setComments(resComment.content);

        screenCommentRef.current?.scrollTo(0, 1000000000);
      } catch (error) {
        addToast({
          title: 'Ops ...',
          description: 'Ocorreu um erro',
          type: 'error',
        });
      }
    };
    load();
  }, [addToast, routeParames.id]);

  // Para aumentar o tamanho da tela
  useEffect(() => {
    document.querySelector('body')?.classList.add('width-all');
    return () => {
      document.querySelector('body')?.classList.remove('width-all');
    };
  }, []);

  const render = useMemo(() => {
    if (!review.item) {
      return {
        title: '',
        authors: [''],
        mediaUrl: '',
        questions: '',
        knowlegdeLevel: [''],
        knowlegdeArea1: '',
        introduction: '',
        learningObjectives: [''],
        synthesis: '',
        bibliographicReference: [''],
        content: [],
      };
    }
    return {
      title: review.item.title,
      authors: review.item.authors,
      mediaUrl: review.item.mediaUrl,
      questions: review.item.questions,
      knowlegdeLevel: [''],
      knowlegdeArea1: '',
      introduction: review.item.introduction,
      learningObjectives: review.item.learningObjectives,
      synthesis: review.item.synthesis,
      bibliographicReference: review.item.bibliographicReference,
      content: JSON.parse(review.item.topics) as IContent[],
    };
  }, [review]);

  const statusCurratorLabel = useMemo(() => {
    return statusReview.find(item => item.value === review.curatorStatus)
      ?.label;
  }, [review.curatorStatus]);

  const statusCurratorValue = useMemo(() => {
    return statusReview.find(item => item.value === review.curatorStatus)
      ?.value;
  }, [review.curatorStatus]);

  const statusReviewerLabel = useMemo(() => {
    return statusReview.find(item => item.value === review.reviewerStatus)
      ?.label;
  }, [review.reviewerStatus]);

  const statusReviewerValue = useMemo(() => {
    return statusReview.find(item => item.value === review.reviewerStatus)
      ?.value;
  }, [review.reviewerStatus]);

  const dataReviews = useMemo(() => {
    return (
      review.created_at &&
      new Date(review.created_at).toLocaleDateString('pt-BR')
    );
  }, [review]);

  const handleSendComment = useCallback(async () => {
    if (comment === '') {
      return;
    }
    try {
      await postCreateComment({
        comment,
        reviewId: review.id,
        itemId: review.item ? review.item.id : '',
      });
      setComment('');

      const { data: resComment } = await filterComment({
        reviewId: review.id,
      });
      setComments(resComment.content);
      screenCommentRef.current?.scrollTo(0, 1000000000);
    } catch (error) {
      addToast({
        title: 'Ops ...',
        type: 'error',
        description: 'Algo de inesperado aconteceu',
      });
    }
  }, [addToast, comment, review.id, review.item]);

  const commentBody = useMemo(() => {
    return comments.map(commentItem => {
      const userPhoto = commentItem.author?.avatar || IconUser;
      if (commentItem.author.id === user.id) {
        return (
          <CommentLoggedUserItem key={`${commentItem.id}`}>
            <CommentContent>
              <CommentLoggedUserBox>{commentItem.comment}</CommentLoggedUserBox>
              <CommentLoggedUserTime>
                {timeAgo(commentItem.created_at)}
              </CommentLoggedUserTime>
            </CommentContent>
            <CommentLoggedUserImg>
              <img src={userPhoto} alt="foto" />
            </CommentLoggedUserImg>
          </CommentLoggedUserItem>
        );
      }
      return (
        <CommentItem key={`${commentItem.id}`}>
          <CommentImg>
            <img src={userPhoto} alt="foto" />
          </CommentImg>
          <CommentContent>
            <CommentBox>{commentItem.comment}</CommentBox>
            <CommentTime>{timeAgo(commentItem.created_at)}</CommentTime>
          </CommentContent>
        </CommentItem>
      );
    });
  }, [comments, user.id]);

  return (
    <Container className="slideDown">
      <Title>
        <h1>{review.item && review.item.title}</h1>
      </Title>
      <ContentReview>
        <ContentLeft>
          <Render render={render} currentTopic={0} className="review" />
        </ContentLeft>
        <ContentRight>
          <Card>
            <CardTitle>
              <div>
                <h2>Status da Avaliação</h2>
                <span>
                  <strong>Atual: </strong>{' '}
                  {!itemStatus ? `Publicada` : `Correção`}
                </span>
              </div>
            </CardTitle>
            <CardBody>
              <CardItem>
                <div className="icon">
                  <IconSchool width={18} />
                </div>
                <div className="options">
                  <div>
                    <strong>Curadoria</strong>
                    <span>
                      <strong>Início</strong> {dataReviews}
                    </span>
                  </div>
                  <div>
                    <Status className={`${statusCurratorValue}`}>
                      {statusCurratorLabel}
                    </Status>
                  </div>
                </div>
              </CardItem>
              <CardItem className="pt-0">
                <div className="icon">
                  <IconDocument width={12} />
                </div>
                <div className="options">
                  <div>
                    <strong>Revisão</strong>
                    <span>
                      <strong>Início</strong> {dataReviews}
                    </span>
                  </div>
                  <div>
                    <Status className={`${statusReviewerValue}`}>
                      {statusReviewerLabel}
                    </Status>
                  </div>
                </div>
              </CardItem>
              {!itemStatus ? (
                <CardItem>
                  <div className="infos">
                    <strong>Política de Atualização</strong>
                    <p>
                      Esteja ciente que para realizar uma atualização é
                      necessário justificar as mudanças. Após solicitar a
                      atualização, a unidade passará por todas as etapas de
                      análise novamente, o prazo para retorno da análise é de
                      até <strong>48 horas</strong>.
                    </p>
                  </div>
                </CardItem>
              ) : (
                <CardItem>
                  <div className="infos">
                    <p>
                      Verifique a caixa de <strong>COMENTÁRIOS</strong> antes de
                      corrigir.
                    </p>
                  </div>
                </CardItem>
              )}
            </CardBody>
            <CardFooter>
              {!itemStatus ? (
                <ButtonWhite
                  onClick={() => history.push(`/studio/${routeParames.id}`)}
                >
                  Realizar Atualização
                </ButtonWhite>
              ) : (
                <ButtonWhite
                  onClick={() => history.push(`/studio/${routeParames.id}`)}
                >
                  Corrigir Unidade
                </ButtonWhite>
              )}
            </CardFooter>
          </Card>
          {itemStatus && (
            <Card>
              <CardTitle className="coment-title">
                <div>
                  <h2>Comentários</h2>
                </div>
              </CardTitle>
              <CommentBody ref={screenCommentRef}>{commentBody}</CommentBody>
              <CommentFooter>
                <InputSendMensage>
                  <input
                    type="text"
                    onChange={e => setComment(e.target.value)}
                    value={comment}
                    placeholder="Digite uma mensagem"
                    onKeyDown={event =>
                      event.key === 'Enter' && handleSendComment()
                    }
                  />
                  <button type="button" onClick={() => handleSendComment()}>
                    <IconSendMessage width={23} />
                  </button>
                </InputSendMensage>
              </CommentFooter>
            </Card>
          )}
        </ContentRight>
      </ContentReview>
    </Container>
  );
};

export default UpdateItem;
