/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { BeatLoader } from 'react-spinners';
import { useHistory } from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import {
  IconAdd,
  IconBookOpenArea,
  IconFilter,
  IconMoreVertical,
  IconUniversity,
  IconUserAlt,
} from '../../styles/icons';
import {
  Container,
  Content,
  FilterButton,
  PrimaryButton,
  DelegateButton,
  Status,
  OptionView,
  TooltipOptions,
  ButtonsDelegate,
  TooltipInfo,
} from './styles';
import {
  Title,
  Options,
  OptionRight,
  TableContent,
  MoreInfo,
  MoreInfoItem,
  Both,
} from '../../components/template/TemplateManager/styles';

import InputSearch from '../../components/forms/InputSearch';
import Tabs, { TabItem } from '../../components/template/Tabs';
import Checkbox, { CheckBoxAll } from '../../components/forms/Checkbox';
import ModalDelegate from '../../components/template/ModalDelegate';

import {
  filterItemsProvider,
  filterReviewer,
  putReviewStatus,
} from '../../services/api';
import { saveManageUnitsLog } from '../../services/logs';
import { useToast } from '../../hooks/toast';

import IconUser from '../../assets/icons/icon-user-circle.svg';
import { IconMaterialEdit } from '../../styles/icons';

import useLazyEffect from '../../hooks/useLazyEffect';
import { itemsStaus } from '../../data/items';
import AlertConfirm from '../../components/template/AlertConfirm';
import { useAuth } from '../../hooks/auth';
import ModalDelegateEdit from '../../components/template/ModalDelegateEdit';
import Dropdown from '../../components/events/Dropdown';
import ReactSelect from '../../components/forms/ReactSelect';

interface IItem {
  id: string;
  authors: Array<string>;
  bibliographicReference: Array<string>;
  introduction: string;
  learningObjectives: Array<string>;
  synthesis: string;
  title: string;
  levelOfLearning: Array<string>;
  provider: {
    name: string;
  };
  provider_name: string;
  topics: string;
  status: string;
  version: number;
  knowledges: {
    title: string;
  }[];
}

export interface IReviwer {
  id: string;
  item: IItem;
  itemContent: IItem;
  curator: {
    name: string;
    id: string;
  };
  reviewer: {
    name: string;
    id: string;
  };
  provider: {
    name: string;
  };
}

interface IReviewEdit {
  reviewId: string;
  curatorId: string;
  reviewerId: string;
  itemId: string;
}

const permissionAdm = ['admin', 'director', 'manager'];

const statusReview = [
  { value: null, label: 'Análise' },
  { value: 'approved', label: 'Aprovado' },
  { value: 'reproved', label: 'Reprovado' },
  { value: 'adjust', label: 'Correção' },
];

const Units: React.FC = () => {
  const controller = new AbortController();
  const { addToast } = useToast();
  const { user } = useAuth();

  const history = useHistory();
  const [isOpen, setIsOpen] = useState('');
  const [currentPage, setCurrentPage] = useState(0);
  const [currentPageReview, setCurrentPageReview] = useState(0);

  const [search, setSearch] = useState('');
  const [status, setStatus] = useState('');
  const [filter, setFilter] = useState('');
  const [editReview, setEditReview] = useState<IReviewEdit>({} as IReviewEdit);
  const [currentItemId, setCurrentItemId] = useState<Array<string>>([]);
  const [currentTab, setCurrentTab] = useState(() => {
    if (permissionAdm.includes(user.type)) {
      return 'tab-01';
    }
    return 'tab-02';
  });
  const [checks, setChecks] = useState<Array<string>>([]);
  const [loading, setLoading] = useState(false);
  const [dropdonwFilter, setDropdonwFilter] = useState(false);

  const [items, setItems] = useState<IItem[]>([]);
  const [reviewer, setReviewer] = useState<IReviwer[]>([]);

  const [modalDelegate, setModalDelegate] = useState(false);
  const [modalDelegateEdit, setModalDelegateEdit] = useState(false);

  const arrayTabs = useMemo(() => {
    const tabs = [
      { id: 'tab-01', label: 'Novas' },
      { id: 'tab-02', label: 'Ativas' },
      { id: 'tab-03', label: 'Publicadas' },
      { id: 'tab-04', label: 'Reprovadas' },
    ];
    if (permissionAdm.includes(user.type)) {
      return tabs;
    }

    return tabs.filter(tab => tab.id !== 'tab-01');
  }, [user.type]);

  useEffect(() => {
    const login = async (): Promise<void> => {
      try {
        setLoading(true);

        if (permissionAdm.includes(user.type)) {
          const { data: res } = await filterItemsProvider({
            statusItemContent: 'send',
          });
          setItems(res.content);
          return;
        }

        const { data: resFilter } = await filterReviewer({
          active: true,
        });
        setReviewer(resFilter.content);
      } catch (error) {
        addToast({
          title: 'Ops ...',
          type: 'error',
          description:
            'Algo de inesperado aconteceu, por favor tente mais tarde',
        });
      } finally {
        setLoading(false);
      }
    };
    login();
  }, [addToast, user.type]);

  // change tab
  useLazyEffect(() => {
    const load = async (): Promise<void> => {
      try {
        setLoading(true);
        let obj = {};
        if (currentTab === 'tab-01') {
          controller.abort();
          const { data: res } = await filterItemsProvider({
            statusItemContent: 'send',
            title: search || undefined,
            orderByTitle: filter === 'a-z' ? 'ASC' : undefined,
            orderByCreatedAt: filter === 'newest' ? 'DESC' : undefined,
          });
          setItems(res.content);
          return;
        }
        if (currentTab === 'tab-02') {
          controller.abort();
          obj = {
            active: true,
            itemContentTitle: search === '' ? undefined : search,
            orderByItemTitle: filter === 'a-z' ? 'ASC' : undefined,
            orderByCreatedAt: filter === 'newest' ? 'DESC' : undefined,
            itemContentStatus: status === '' ? undefined : status,
          };
        } else if (currentTab === 'tab-03') {
          controller.abort();
          obj = {
            active: false,
            managerStatus: 'approved',
            itemContentTitle: search === '' ? undefined : search,
            orderByItemTitle: filter === 'a-z' ? 'ASC' : undefined,
            orderByCreatedAt: filter === 'newest' ? 'DESC' : undefined,
          };
        } else {
          controller.abort();
          obj = {
            managerStatus: 'reproved',
            itemContentTitle: search === '' ? undefined : search,
            orderByItemTitle: filter === 'a-z' ? 'ASC' : undefined,
            orderByCreatedAt: filter === 'newest' ? 'DESC' : undefined,
          };
        }

        const { data: resFilter } = await filterReviewer(obj);
        setReviewer(resFilter.content);
      } catch (error) {
        addToast({
          title: 'Ops ...',
          type: 'error',
          description:
            'Algo de inesperado aconteceu, por favor tente mais tarde',
        });
      } finally {
        setLoading(false);
      }
    };
    load();
  }, [currentTab, addToast, search, filter, status]);

  const handleTab = useCallback((tab: string) => {
    setCurrentTab(tab);
  }, []);

  const handleCheck = useCallback(check => {
    setChecks(check);
  }, []);

  const handleModalDelegate = useCallback(() => {
    setModalDelegate(!modalDelegate);
  }, [modalDelegate]);

  const handleModalDelegateEdit = useCallback(() => {
    setModalDelegateEdit(!modalDelegateEdit);
  }, [modalDelegateEdit]);

  const handleDelegateEdit = useCallback(
    ({ curatorId, reviewId, reviewerId, itemId }: IReviewEdit) => {
      handleModalDelegateEdit();
      setEditReview({
        curatorId,
        reviewerId,
        reviewId,
        itemId,
      });
    },
    [handleModalDelegateEdit],
  );

  const handleCurrentItemId = useCallback(
    (id: Array<string>) => {
      setCurrentItemId(id);
      handleModalDelegate();
    },
    [handleModalDelegate],
  );

  const handleDropdown = useCallback((id: string) => {
    setIsOpen(state => (state === id ? '' : id));
  }, []);

  const handleInitalState = useCallback(async () => {
    const { data: resFilter } = await filterReviewer({
      active: true,
    });

    setCurrentTab('tab-02');
    setReviewer(resFilter.content);
    setChecks([]);
  }, []);

  const handleChangeStatus = useCallback(
    async ({ ids, statusItem }: { ids: Array<string>; statusItem: string }) => {
      const statusLabel = statusReview.find(
        item => item.value === status,
      )?.label;
      const confirm = await AlertConfirm({
        title: `Tem certeza que deseja mudar o status deste item para ${
          itemsStaus.find(item => item.value === statusItem)?.label
        }`,
        text: 'Esta ação pode ser desfeita',
        type: statusItem,
      });

      if (!confirm) {
        return;
      }

      try {
        await putReviewStatus({
          ids,
          status: statusItem,
          comment: confirm || ' ',
        });

        const { data: resFilter } = await filterReviewer({
          active: true,
        });

        saveManageUnitsLog({
          userId: user.id,
          action: 'update_unit_status',
          status: statusItem,
          unityId: ids,
        });

        addToast({
          title: 'Sucesso!',
          description: `Item foi ${statusLabel} com sucesso`,
          type: 'success',
        });
        setReviewer(resFilter.content);
        setChecks([]);
      } catch (error) {
        addToast({
          title: 'Ops ...',
          type: 'error',
          description:
            'Algo de inesperado aconteceu, por favor tente mais tarde',
        });
      }
    },
    [addToast, status],
  );

  const PER_PAGE = 10;

  const offset = currentPage * PER_PAGE;

  const offsetReview = currentPageReview * PER_PAGE;

  const currentPageData = items.slice(offset, offset + PER_PAGE).map(item => {
    return (
      <React.Fragment key={item.id}>
        <tr className={`${isOpen === item.id ? `dropdown` : ``}`}>
          <td className="no-cursor">
            <Checkbox
              name="check"
              handleCheck={handleCheck}
              checks={checks}
              id={item.id}
            />
          </td>
          <td onClick={() => handleDropdown(item.id)}>
            {item.title}
            <Status className={item.status}>Aguardando responsáveis</Status>
            {item.version !== 1 && (
              <Status className="correction">Atualização</Status>
            )}
          </td>
          <td onClick={() => handleDropdown(item.id)}>
            <DelegateButton onClick={() => handleCurrentItemId([item.id])}>
              <IconAdd />
              Delegar
            </DelegateButton>
          </td>
          <td>
            <OptionView>
              <PrimaryButton
                style={{ width: '105px' }}
                onClick={() => history.push(`/dashboard/units/item/${item.id}`)}
              >
                Ver unidade
              </PrimaryButton>
              <TooltipOptions>
                <IconMoreVertical />
              </TooltipOptions>
            </OptionView>
          </td>
        </tr>
        <tr
          className={`${isOpen !== item.id ? `hidden` : `slideUp`} more-info`}
        >
          <td />
          <td colSpan={4}>
            <MoreInfo>
              <MoreInfoItem>
                <IconBookOpenArea />
                <div>
                  <span>Área de conhecimento:</span> <br />
                  {item.knowledges.map(know => know.title).join(', ')}
                </div>
              </MoreInfoItem>
              <MoreInfoItem>
                <IconUniversity />
                <div>
                  <span>Nível de aprendizagem:</span> <br />
                  {item.levelOfLearning.map((level, i) => {
                    if (i === 0) {
                      return level;
                    }
                    return `, ${level}`;
                  })}
                </div>
              </MoreInfoItem>
              <MoreInfoItem>
                <IconUserAlt />
                <div>
                  <span>Provider:</span> <br />
                  {item.provider_name}
                </div>
              </MoreInfoItem>
            </MoreInfo>
          </td>
        </tr>
      </React.Fragment>
    );
  });

  const currentPageDataReview = reviewer
    .slice(offsetReview, offsetReview + PER_PAGE)
    .map(review => {
      return (
        <React.Fragment key={review.id}>
          <tr
            className={`${isOpen === review.itemContent.id ? `dropdown` : ``}`}
          >
            <td className="no-cursor">
              <Checkbox
                name="check"
                handleCheck={handleCheck}
                checks={checks}
                id={`${review.id}`}
              />
            </td>
            <td onClick={() => handleDropdown(review.itemContent.id)}>
              {review.itemContent.title}
              <Status className={review.itemContent.status}>
                {
                  itemsStaus.find(
                    item => item.value === review.itemContent.status,
                  )?.label
                }
              </Status>
            </td>
            <td className="no-cursor">
              <ButtonsDelegate>
                <TooltipInfo>
                  <img src={IconUser} alt="img" />
                  <span>
                    <div>
                      <strong>{review.reviewer.name}</strong>
                    </div>
                    <i>Revisor</i>
                  </span>
                </TooltipInfo>
                <TooltipInfo>
                  <img src={IconUser} alt="img" />
                  <span>
                    <div>
                      <strong>{review.curator.name}</strong>
                    </div>
                    <i>Curador</i>
                  </span>
                </TooltipInfo>
                <TooltipInfo
                  hidden={
                    !permissionAdm.includes(user.type) ||
                    currentTab !== 'tab-02'
                  }
                >
                  <button
                    type="button"
                    onClick={() =>
                      handleDelegateEdit({
                        curatorId: review.curator.id,
                        reviewerId: review.reviewer.id,
                        reviewId: review.id,
                        itemId: review.itemContent.id,
                      })
                    }
                  >
                    <IconMaterialEdit />
                  </button>
                  <span>Editar Delegação</span>
                </TooltipInfo>
              </ButtonsDelegate>
            </td>
            <td>
              <OptionView>
                <PrimaryButton
                  style={{ width: '115px' }}
                  onClick={() =>
                    history.push(`/dashboard/units/review/${review.id}`)
                  }
                >
                  Ver avaliação
                </PrimaryButton>
                <TooltipOptions>
                  <IconMoreVertical />
                  <span>
                    <button
                      type="button"
                      onClick={() =>
                        handleChangeStatus({
                          ids: [review.id],
                          statusItem: 'reproved',
                        })
                      }
                    >
                      Reprovar
                    </button>
                    <button
                      type="button"
                      onClick={() =>
                        handleChangeStatus({
                          ids: [review.id],
                          statusItem: 'approved',
                        })
                      }
                    >
                      Aprovar
                    </button>
                  </span>
                </TooltipOptions>
              </OptionView>
            </td>
          </tr>
          <tr
            className={`${
              isOpen !== review.itemContent.id ? `hidden` : `slideUp`
            } more-info`}
          >
            <td />
            <td colSpan={4}>
              <MoreInfo>
                <MoreInfoItem>
                  <IconBookOpenArea />
                  <div>
                    <span>Área de conhecimento:</span> <br />
                    {review.itemContent.knowledges
                      .map(item => item.title)
                      .join(', ')}
                  </div>
                </MoreInfoItem>
                <MoreInfoItem>
                  <IconUniversity />
                  <div>
                    <span>Nível de aprendizagem:</span> <br />
                    {review.itemContent.levelOfLearning &&
                      review.itemContent.levelOfLearning.join(', ')}
                  </div>
                </MoreInfoItem>
                <MoreInfoItem>
                  <IconUserAlt />
                  <div>
                    <span>Provider:</span> <br />
                    {review.provider.name}
                  </div>
                </MoreInfoItem>
              </MoreInfo>
            </td>
          </tr>
        </React.Fragment>
      );
    });

  const pageCount = Math.ceil(items.length / PER_PAGE);
  const pageCountReviewer = Math.ceil(reviewer.length / PER_PAGE);
  function handlePageClick({ selected: selectedPage }) {
    setCurrentPage(selectedPage);
  }
  function handlePageClickReview({ selected: selectedPage }) {
    setCurrentPageReview(selectedPage);
  }

  const renderItems = useCallback(() => {
    return (
      <>
        <table>
          <thead>
            <tr className="no-cursor no-hover">
              <th>
                <CheckBoxAll
                  ids={items.map(item => item.id)}
                  handleCheckAll={handleCheck}
                />
              </th>
              <th>Unidade de ensino</th>
              <th>Responsável</th>
              <th>Visualização</th>
            </tr>
          </thead>
          {currentPageData}
          <tbody />
        </table>

        <ReactPaginate
          previousLabel="← Anterior"
          nextLabel="Próximo →"
          pageCount={pageCount}
          onPageChange={handlePageClick}
          containerClassName="pagination"
          previousLinkClassName="pagination__link"
          nextLinkClassName="pagination__link"
          disabledClassName="pagination__link--disabled"
          activeClassName="pagination__link--active"
        />
      </>
    );
  }, [handleCheck, items, pageCount, currentPageData]);

  const renderReviwer = useCallback(() => {
    return (
      <>
        <table>
          <thead>
            <tr className="no-cursor no-hover">
              <th>
                <CheckBoxAll
                  ids={reviewer.map(item => item.id)}
                  handleCheckAll={handleCheck}
                />
              </th>
              <th>Unidade de ensino</th>
              <th>Responsável</th>
              <th>Visualização</th>
            </tr>
          </thead>
          {currentPageDataReview}
          <tbody />
        </table>

        <ReactPaginate
          previousLabel="← Anterior"
          nextLabel="Próximo →"
          pageCount={pageCountReviewer}
          onPageChange={handlePageClickReview}
          containerClassName="pagination"
          previousLinkClassName="pagination__link"
          nextLinkClassName="pagination__link"
          disabledClassName="pagination__link--disabled"
          activeClassName="pagination__link--active"
        />
      </>
    );
  }, [reviewer, handleCheck, currentPageDataReview, pageCountReviewer]);

  return (
    <>
      {user.type !== 'review' && user.type !== 'curator' ? (
        <>
          <ModalDelegate
            isOpen={modalDelegate}
            setIsOpen={handleModalDelegate}
            currentItemId={currentItemId}
            handleInitalState={handleInitalState}
          />
          <ModalDelegateEdit
            isOpen={modalDelegateEdit}
            setIsOpen={handleModalDelegateEdit}
            handleInitalState={handleInitalState}
            review={editReview}
          />
        </>
      ) : undefined}
      <Container className="slideDown">
        <Title>
          <h1>Unidades</h1>
        </Title>
        <Content>
          <Options>
            <OptionRight>
              <div className="search">
                <InputSearch
                  name="search"
                  onChange={e => setSearch(e.target.value)}
                  placeholder="Pesquisar"
                />
              </div>
              {currentTab === 'tab-02' && (
                <div
                  style={{ marginTop: '0', marginLeft: '8px' }}
                  className="select"
                >
                  <ReactSelect
                    name="status"
                    options={[
                      { value: '', label: 'Status ...' },
                      { label: 'Análise', value: 'analyze' },
                      { label: 'Correção', value: 'correction' },
                    ]}
                    handleValue={setStatus}
                    defaultValue={status}
                    placeholder="Status ..."
                  />
                </div>
              )}
              <Dropdown
                setIsOpen={dropdonwFilter}
                handleDropdown={setDropdonwFilter}
                style={{ margin: '0' }}
              >
                <FilterButton
                  onClick={() => setDropdonwFilter(!dropdonwFilter)}
                >
                  <IconFilter />
                </FilterButton>
                <main hidden={!dropdonwFilter}>
                  <header className="notification">
                    <strong>Visualização</strong>
                  </header>
                  <section>
                    <button
                      type="button"
                      onClick={() => {
                        setFilter('a-z');
                        setDropdonwFilter(false);
                      }}
                    >
                      De A-Z
                    </button>
                    <button
                      type="button"
                      onClick={() => {
                        setFilter('newest');
                        setDropdonwFilter(false);
                      }}
                    >
                      Mais Recentes
                    </button>
                  </section>
                </main>
              </Dropdown>
              {checks.length > 0 && currentTab === 'tab-01' && (
                <PrimaryButton onClick={() => handleCurrentItemId(checks)}>
                  Delegar
                </PrimaryButton>
              )}
              <Both />
            </OptionRight>
          </Options>
          <TableContent>
            <Tabs
              className="secondary"
              itens={arrayTabs}
              defaultValue={currentTab}
              handleTab={handleTab}
            />
            {loading ? (
              <div className="loading">
                <BeatLoader size={16} />
              </div>
            ) : (
              <div className="slideUp">
                <TabItem defaultValue={currentTab} id="tab-01">
                  {renderItems()}
                </TabItem>
                <TabItem defaultValue={currentTab} id="tab-02">
                  {renderReviwer()}
                </TabItem>
                <TabItem defaultValue={currentTab} id="tab-03">
                  {renderReviwer()}
                </TabItem>
                <TabItem defaultValue={currentTab} id="tab-04">
                  {renderReviwer()}
                </TabItem>
              </div>
            )}
          </TableContent>
        </Content>
      </Container>
    </>
  );
};

export default Units;
