import React, { useCallback, useMemo, useRef } from 'react';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import { RawEditorSettings } from 'tinymce';
import * as Yup from 'yup';
import Input from '../../forms/Input';
import Button from '../../forms/Buttom';

import { useToast } from '../../../hooks/toast';
import getValidationErrors from '../../../utils/getVaidationErrors';

import { ContentPage } from './styles';
import { IEditResource, IPages } from '.';

interface IProps {
  handlePage(page: IPages): void;
  propsEditor: RawEditorSettings;
  setIsOpen: () => void;
  editResource: IEditResource;
}

interface IIframe {
  url: string;
  width: string;
  height: string;
}

const FormIframe: React.FC<IProps> = ({
  handlePage,
  propsEditor,
  setIsOpen,
  editResource,
}) => {
  const formRef = useRef<FormHandles>(null);
  const { addToast } = useToast();

  const isEdit = useMemo(() => {
    return Object.keys(editResource).length > 0;
  }, [editResource]);

  const editIframe = useMemo<IIframe>(() => {
    if (editResource.resource) {
      const recoverData = JSON.parse(decodeURI(editResource.resource));
      return recoverData;
    }
    return {} as IIframe;
  }, [editResource.resource]);

  const handleSubmit = useCallback(
    async data => {
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          width: Yup.string().required('Campo Obrigatório'),
          height: Yup.string().required('Campo Obrigatório'),
          url: Yup.string()
            .required('Campo Obrigatório')
            .url('Imagem deve ser uma url'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const iframe = data;
        if (!iframe) {
          return;
        }

        if (isEdit) {
          editResource.element.setAttribute(
            'data-resource',
            encodeURI(JSON.stringify(iframe)),
          );
          propsEditor.insertContent(propsEditor.selection.getContent());
        } else {
          propsEditor.insertContent(
            `<div class="resource" data-interaction="true" data-type="iframe" data-subtype="" data-resource="${encodeURI(
              JSON.stringify(iframe),
            )}">
                    Iframe Externo
                  </div>\n
                  <p>&nbsp;</p>`,
          );
        }

        propsEditor.focus();
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);
          formRef.current?.setErrors(errors);
          if (errors.url !== '' && Object.keys(errors).length === 1) {
            addToast({
              title: 'Ops ...',
              description: 'Escolha uma imagem neste recurso',
              type: 'error',
            });
          }
          return;
        }
      }
      propsEditor.focus();
      setIsOpen();
    },
    [propsEditor, setIsOpen, isEdit, editResource.element, addToast],
  );
  return (
    <Form
      ref={formRef}
      onSubmit={handleSubmit}
      initialData={{
        url: editIframe.url,
        width: editIframe.width,
        height: editIframe.height,
      }}
    >
      <ContentPage>
        {!isEdit && (
          <button type="button" onClick={() => handlePage('main')}>
            Voltar
          </button>
        )}
        <div>
          <h2>Iframe Externo</h2>
          <Input
            label="URL Externa*"
            name="url"
            placeholder="Cole aqui a URL"
          />
          <Input
            label="Largura *"
            name="width"
            placeholder="Exemplo: (100%, 30px, etc...)"
          />
          <Input
            label="Altura *"
            name="height"
            placeholder="Exemplo: (100px, 30px, etc...)"
          />
          <Input hidden label="" name="type" defaultValue="iframe" />
          <Button label="Inserir" type="submit" />
        </div>
      </ContentPage>
    </Form>
  );
};

export default FormIframe;
