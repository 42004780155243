import React from 'react';

import AppRoutes from './app.routes';
import AuthRoutes from './auth.routes';
import { useAuth } from '../hooks/auth';

const Routes: React.FC = () => {
  const { signed } = useAuth();

  return <>{signed ? <AuthRoutes /> : <AppRoutes />}</>;
};

export default Routes;
