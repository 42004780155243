import React, {
  createContext,
  useState,
  useContext,
  useCallback,
  useEffect,
} from 'react';
import { useHistory } from 'react-router-dom';
import { useToast } from './toast';

interface ICartContextState {
  addToCart(item: Partial<ICartAdd>): void;
  removeToCart(item: ICartAdd): void;
  clearCart(): void;
  cartItems: ICartAdd[];
}

export interface ICartAdd {
  id: string;
  name: string;
  email: string;
  cnpj: string;
  cpf: string;
  companyName: string;
  status:
    | 'active'
    | 'inative'
    | 'exclused'
    | 'review'
    | 'approved'
    | 'rejected'
    | 'pending'
    | 'draft';
  typePerson: 'Profissional Liberal' | 'Instituição de Ensino' | 'Fornecedor';
  condition: string;
  imgLogoUrl: string | null;
  isPrimium: boolean;
  provider_total_items: number | undefined;
  plan_value: string | number | undefined;
  priceInNumber: string | number | undefined;
  isRented?: boolean;
  isService?: boolean;
  titleService?: string;
  descriptionService?: string;
  priceService?: string;
  idService?: string;
}

const CartContext = createContext<ICartContextState>({} as ICartContextState);

export const CartProvider: React.FC = ({ children }) => {
  const { addToast } = useToast();
  const history = useHistory();

  const [cartItems, setCartItems] = useState<ICartAdd[]>([]);

  useEffect(() => {
    const items = localStorage.getItem('@aurea:cart');

    if (items !== null) {
      setCartItems(JSON.parse(items));
    }
  }, []);

  const addToCart = useCallback(
    (item: ICartAdd) => {
      const arrayCart = cartItems;
      const equals = arrayCart.find(items => items.id === item.id);

      if (equals) {
        addToast({
          type: 'error',
          title: 'Item já adicionado ao carrinho',
        });

        return;
      }

      arrayCart.push(item);
      setCartItems(arrayCart);

      history.push('/seller/cart');

      addToast({
        type: 'success',
        title: 'Item adicionado ao carrinho',
      });

      localStorage.setItem('@aurea:cart', JSON.stringify(arrayCart));
      return arrayCart;
    },
    [cartItems, history, addToast],
  );

  const removeToCart = useCallback(
    (item: ICartAdd) => {
      const arrayCart = cartItems;
      const itemsToRemove = arrayCart.filter(items => items.id !== item.id);
      localStorage.setItem('@aurea:cart', JSON.stringify(itemsToRemove));
      setCartItems(itemsToRemove);
      addToast({
        type: 'success',
        title: 'Item removido do carrinho',
      });
    },
    [cartItems, addToast],
  );

  const clearCart = useCallback(() => {
    setCartItems([]);
    localStorage.removeItem('@aurea:cart');
  }, [setCartItems]);

  const store = {
    addToCart,
    removeToCart,
    clearCart,
    cartItems,
  };

  return <CartContext.Provider value={store}>{children}</CartContext.Provider>;
};

export const useCart = (): ICartContextState => {
  const context = useContext(CartContext);

  if (!context) {
    throw new Error('useCart must be used within an AuthProvider');
  }

  return context;
};
