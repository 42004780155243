/* eslint-disable react/no-danger */
import { useField } from '@unform/core';
import React, { useCallback, useEffect, useState } from 'react';

import { Container, ItemRadio, Content } from './styles';

interface IOption {
  value: string;
  label?: string;
  icon?: React.ReactNode;
}

interface IChoiceRadioProps {
  label: string;
  name: string;
  options: IOption[];
  defaultValue?: any;
  multiple?: boolean;
}

const ChoiceRadio: React.FC<IChoiceRadioProps> = ({
  label,
  name,
  options,
  defaultValue,
  multiple,
  ...rest
}) => {
  const { fieldName, registerField, error } = useField(name);
  const [value, setValue] = useState<any>();

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  const threatAsCheckbox = !!(multiple || options.length === 1);
  const nativeField = threatAsCheckbox ? 'checkbox' : 'radio';

  function getValue(): any {
    if (multiple)
      return Array.from(value || []).length === 0 ? undefined : value;
    return value === '' ? undefined : value;
  }

  registerField({ name: fieldName, ref: getValue, getValue });

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { checked: toAdd, value: fieldVal } = e.target;
      if (multiple) {
        const newVal = toAdd
          ? [...Array.from(value || []), fieldVal]
          : Array.from(value).filter(v => v !== e.target.value);
        setValue(newVal);
      } else {
        setValue(toAdd ? fieldVal : '');
      }
    },
    [multiple, value],
  );

  const checked = useCallback(
    (val: string) => {
      if (multiple) {
        return Array.from(value || []).includes(val);
      }
      return value === val;
    },
    [multiple, value],
  );

  return (
    <Container>
      <label>{label}</label>
      <Content>
        {options.map(({ value: Value, label: Label, icon: Icon }) => {
          const checkboxId = `${fieldName}-${Value}`;

          return (
            <ItemRadio>
              <input
                type={nativeField}
                id={checkboxId}
                value={Value}
                name={name}
                checked={checked(Value)}
                onChange={handleChange}
                {...rest}
              />
              <label htmlFor={checkboxId}>
                <div>{Icon && Icon}</div>
                <span dangerouslySetInnerHTML={{ __html: Label || '' }} />
              </label>
            </ItemRadio>
          );
        })}
      </Content>
      {error && <small>{error}</small>}
    </Container>
  );
};

export default ChoiceRadio;
