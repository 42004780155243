import React from 'react';

import { Container, Content, Main, MainContent, Body } from './styles';

import Sidebar from '../Sidebar';
import NavTop from '../NavTop';

const MainTemplate: React.FC = ({ children }) => {
  return (
    <Container className="fadeIn">
      <Content>
        <Sidebar />
        <Main>
          <MainContent className="blur">
            <NavTop />
            <Body className="body" id="body">
              {children}
            </Body>
          </MainContent>
        </Main>
      </Content>
    </Container>
  );
};

export default MainTemplate;
