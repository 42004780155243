import React from "react";
import { CSVLink } from "react-csv";
import { FiDownloadCloud } from "react-icons/fi";
import { Container } from "./styles";


const ExportCsv = ({ headers, data, filename, disabled }) => {
    const separator = ";";
    const csvData = [
        headers.map(header => header.label),
        ...data.map((item) => {
            return headers.map(header => item[header.key]);
        }),
    ];

    return (
        <Container>
            <CSVLink data={csvData} filename={filename} separator={separator}>
                <button type="button" title="" disabled={disabled}>
                    <FiDownloadCloud />
                    Exportar Relatório
                </button>
            </CSVLink>
        </Container>
    );
};

export default ExportCsv;
