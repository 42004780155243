import styled from 'styled-components';

export const Container = styled.div`
  max-width: 812px;
  margin: 0 auto 18px auto;
`;

export const Card = styled.div`
  margin-top: 22px;
  background-color: white;
  padding: 12px 32px 32px 32px;

  border: 1px solid var(--gray-border);
  border-radius: 20px;
`;

export const RowGroup = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: row;
  position: relative;

  div {
    width: 100%;
    & + div {
      margin-left: 18px;
    }
  }
`;

export const CardSection = styled.div`
  margin: 32px 0 32px 0;
  display: flex;
  justify-content: center;
  flex-direction: row;

  div {
    width: 100%;
  }
`;

export const ColunmGroup = styled.div`
  & + div {
    margin-left: 18px;
  }
`;

export const UploadProfile = styled.div`
  display: flex;
  flex-direction: column;

  padding-top: 18px;
  > label {
    font-weight: bold;
    color: var(--gray);
  }
`;

export const UploadProfileContent = styled.div`
  display: flex;
  flex-direction: row;
  align-content: center;
`;

export const File = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  label {
    padding: 5px 10px;
    color: black;
    font-size: 1rem;
    font-weight: bold;
    width: 150px;
    color: var(--dark);

    border-radius: 5px;
    border: 1px solid var(--gray-border);

    cursor: pointer;
    transition: background 0.3s;
    &:hover {
      background: var(--gray-border);
    }
  }
  p {
    color: var(--gray);
    font-size: 0.75rem;
    margin-top: 10px;
  }
  input {
    display: none;
  }
`;

export const Image = styled.div`
  position: relative;
  margin-top: 20px;
  margin-right: 24px;
  flex: 1;

  svg {
    cursor: pointer;
    position: absolute;
    right: 0px;
    bottom: 0;
  }

  img {
    object-fit: cover;
    width: 134px;
    height: 134px;
    border-radius: 50%;
  }
`;

export const ProviderImage = styled.div`
  position: relative;
  margin-top: 20px;
  margin-right: 24px;
  flex: 1;

  svg {
    cursor: pointer;
    position: absolute;
    right: 0px;
    bottom: 0;
  }

  img {
    object-fit: cover;
    width: 134px;
    height: 134px;
  }
`;

export const Bottom = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  margin-top: 24px;

  div + div {
    margin-left: 18px;
  }
`;
