import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 32px;
  display: flex;
  > div {
    width: 100%;
    div {
      max-width: 100%;
    }

    &.wrap {
      display: flex;
      flex-wrap: wrap;
      div {
        max-width: 45%;
      }
    }
  }
`;

export const InputItem = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  flex: 0 0 45%;
  max-width: 45%;

  margin: 0px 0px 12px 12px;
  padding: 10px;
  border: 0;
  border-bottom: 2px solid var(--gray-border);
  input {
    width: 100%;

    font-size: 1.25rem;
    font-weight: 900;
    color: var(--gray);
    background-color: transparent;
    border: 0;
    &::placeholder {
      color: var(--gray-border);
    }
  }

  svg {
    cursor: pointer;
    align-self: flex-end;
  }
`;
