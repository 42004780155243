import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  div.arrow {
    border: 1px solid black;
    background: red;
  }
  main {
    position: absolute;
    top: calc(100% + 8px);
    right: -4px;

    width: 170px;
    height: auto;

    background: var(--dark);
    border-radius: 5px;
    color: white;
    overflow: hidden;
    z-index: 2;

    header {
      display: flex;
      align-items: center;
      padding: 10px 14px;
      img {
        width: 42px !important;
        height: 42px !important;
      }
      div {
        margin-left: 10px;
        text-align: left;

        strong {
          font-size: 0.875rem;
          font-weight: bold;
          color: var(--aurea);
        }

        p {
          font-size: 0.75rem;
        }
      }
    }

    section {
      display: flex;
      flex-direction: column;
      text-align: left;

      a,
      button {
        background: transparent;
        text-decoration: none;
        color: white;
        font-size: 0.75rem;

        padding: 12px 20px;
        border: 0;
        border-top: 1px solid rgb(224 222 225 / 0.15);
        display: flex;
        align-items: center;
        transition: background, 0.2s;
        svg {
          margin-right: 10px;
        }

        &:hover {
          background: var(--gray);
        }
      }
    }

    .alert {
      text-align: center;
      color: #ffffff;
      font-family: 'Source Sans Pro';
    }

    .notification {
      display: flex;
      justify-content: space-between;
      align-items: center;
      strong {
        font-weight: bold;
      }

      button {
        background: transparent;
        border: 0;
        color: white;
        font-size: 0.75rem;

        &:hover {
          text-decoration: underline;
        }

        .center {
          width: 100%;
          text-align: center;
        }
      }
    }
  }
`;
