import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  color: #999;

  select {
    flex: 1;

    width: 100%;
    margin-top: 14px;

    color: #555555;
    line-height: 1.2;
    background: #fff;
    border: 1px solid #e6e6e6;
    border-radius: 5px;
    padding: 10px;

    transition: 0.4s;
    overflow: hidden;
  }
`;
