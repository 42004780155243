import styled from 'styled-components';
import BackgroundSign from '../../../assets/images/bg-sign.png';

export const Container = styled.div`
  display: none;
  flex: 1;
  background: linear-gradient(#f6c866, #e59b50);
  position: relative;

  @media (min-width: 992px) {
    display: block;
  }
`;

export const Background = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: url(${BackgroundSign}) no-repeat center;
  background-size: cover;
  opacity: 0.08;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 80%;
  margin: 0 auto;
  text-align: left;
  justify-content: center;

  z-index: 100;
  position: relative;

  img {
    display: flex;
  }

  h1 {
    margin-top: 24px;
  }

  p {
    font-size: 1.2rem;
    margin-top: 24px;
  }
`;
