/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { useCallback, useEffect, useState } from 'react';
import { FiDownloadCloud, FiFilter, FiFrown } from 'react-icons/fi';

import { Circle } from './Circle';
import { generateId } from '../../../utils/generateId';
import Api from '../../../services/api';

import {
  AlertWrapper,
  Container,
  Filters,
  FiltersWrapper,
  Wrapper,
} from './styles';

import { useAuth } from '../../../hooks/auth';
import { useToast } from '../../../hooks/toast';
import { UsersTypes } from '../../../services/auth';
import Input from '../../../components/forms/Input';
import Select from '../../../components/forms/SelectFormOption';

interface ITableProps {
  seller_id: string;
  seller: string;
  student: string;
  total_access: number;
  last_access: string;
}

interface IPlanProps {
  name: string;
  maxRent: number;
}

interface ISellersProps {
  id: string;
  name: string;
  status: string;
}

interface IProvider {
  id: string;
  name: string;
  company_name: string;
}

interface IParams {
  [key: string]: string;
}

const AccessReport: React.FC = () => {
  const { user } = useAuth();
  const { addToast } = useToast();

  const [sellers, setSellers] = useState<ISellersProps[]>([]);
  const [providers, setProviders] = useState<IProvider[]>([]);
  const [planData, setPlanData] = useState<IPlanProps>();
  const [dataSource, setDataSource] = useState<ITableProps[]>([]);

  const getPlan = useCallback(async (seller_id = '') => {
    const { data: requestPlans } = await Api.get(
      `/sellers/plans-seller/${seller_id}`,
    );

    setPlanData(requestPlans);
  }, []);

  const getData = useCallback(
    async (params?: IParams) => {
      try {
        if (params?.seller_id) {
          await getPlan(params.seller_id);
        }

        const { data: allProviders } = await Api.get(
          `/sellers/providers/${params?.seller_id || user.id}/`,
        );

        const { data: AccessDataSource } = await Api.get(
          '/sellers/report-access/',
          { params },
        );

        setProviders([...allProviders]);
        setDataSource([...AccessDataSource]);
      } catch (error) {
        console.error(error);

        addToast({
          type: 'error',
          title: 'Atenção!',
          description:
            'Não foi possível realizar esta operação, tente novamente.',
        });
      }
    },
    [user, addToast, getPlan],
  );

  const initialize = useCallback(
    async (seller_id = '') => {
      if (user.type === UsersTypes.admin && !seller_id) {
        const { data: allSellers } = await Api.get('/sellers/');
        setSellers(allSellers);

        return;
      }

      getPlan();
      getData();
    },
    [user, getData, getPlan],
  );

  const TotalAccess = dataSource.reduce(
    (total, register) => total + Number(register.total_access),
    0,
  );

  // const SelectOptionsSellers = sellers.map(seller => ({
  //   label: seller.name,
  //   value: seller.id,
  //   status: seller.status === 'active' ? 'Ativo' : 'Suspenso',
  // }));

  const SelectOptionsSellers = sellers
    .filter(seller => seller.status === 'active')
    .map(seller => ({
      label: seller.name,
      value: seller.id,
      status: 'Ativo',
    }));

  const SelectOptionsProviders = providers.map(provider => ({
    label: provider.company_name,
    value: provider.id,
  }));

  const percentageOfUse = planData
    ? Number(((dataSource.length / planData.maxRent) * 100).toFixed(2))
    : undefined;

  useEffect(() => {
    initialize();
  }, [initialize]);

  return (
    <Container>
      <header>
        <h1>Relatório de Acessos</h1>
        <span>
          {user.name}
          {planData ? ` | Plano: ${planData.name}` : null}
        </span>
      </header>

      <Filters>
        <FiltersWrapper onSubmit={data => getData(data)}>
          <button type="submit">
            <FiFilter />
            Filtrar
          </button>

          <Input label="Data Inicial" required name="start_date" type="date" />
          <Input label="Data Final" required name="end_date" type="date" />

          {user.type === UsersTypes.admin && (
            <Select
              name="seller_id"
              placeholder="Instituição"
              options={SelectOptionsSellers}
            />
          )}

          {SelectOptionsProviders.length > 0 && (
            <Select
              name="provider_id"
              placeholder="Fornecedor"
              options={SelectOptionsProviders}
            />
          )}
        </FiltersWrapper>
        {/* <button type="button" disabled>
          <FiDownloadCloud />
          Exportar Relatório
        </button> */}
      </Filters>

      {typeof percentageOfUse !== 'undefined' && (
        <>
          <Wrapper>
            <Circle percent={percentageOfUse} />
            <section>
              <div>
                Uso de
                <strong>{percentageOfUse}%</strong>
              </div>
            </section>
            <section>
              <div>
                Total Acesso
                <strong>{TotalAccess}</strong>
              </div>
            </section>
            <section>
              <div>
                Acessos Únicos
                <strong>{dataSource.length}</strong>
              </div>
            </section>
          </Wrapper>
        </>
      )}

      <table>
        <thead>
          <tr>
            <th>Cliente</th>
            <th>Identificador de acesso</th>
            <th style={{ width: '100px', textAlign: 'center' }}>Acessos</th>
            <th style={{ width: '180px' }}>Último acesso</th>
          </tr>
        </thead>
        <tbody>
          {dataSource.map(register => (
            <tr key={generateId()}>
              <td>{register.seller}</td>
              <td>{register.student || <b>(Não Informado)</b>}</td>
              <td style={{ textAlign: 'center' }}>{register.total_access}</td>
              <td>{register.last_access}</td>
            </tr>
          ))}
        </tbody>
      </table>

      {dataSource.length === 0 && (
        <AlertWrapper>
          <FiFrown size={50} />
          Sem dados para apresentação
        </AlertWrapper>
      )}
    </Container>
  );
};

export { AccessReport };
