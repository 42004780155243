export const itemsStaus = [
  {
    label: 'Rascunho',
    value: 'draft',
  }, // criou item e tá atualizando
  { label: 'Enviado', value: 'send' }, // manda pra aprovação, mas sem delegação
  { label: 'Aprovado', value: 'publish' }, // aprovar
  { label: 'Análise', value: 'analyze' }, // delegado pra arrumar, aqui bloquea para edição
  { label: 'Correção', value: 'correction' }, // ?
  { label: 'Reprovado', value: 'reproved' }, // reprovado
  { label: 'Excluído', value: 'deleted' }, // deletado
];

export const statusReview = [
  { value: null, label: 'Análise' },
  { value: 'approved', label: 'Aprovado' },
  { value: 'reproved', label: 'Reprovado' },
  { value: 'correction', label: 'Correção' },
];
