/* eslint-disable react/jsx-no-bind */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import ReactPaginate from 'react-paginate';
import { Link, useHistory } from 'react-router-dom';
import { BeatLoader } from 'react-spinners';
import { Container, Content } from './styles';
import {
  DeleteButton,
  OptionLeft,
  OptionRight,
  Options,
  TableContent,
  Title,
  NameContent,
  Bagde,
  MoreInfo,
  MoreInfoItem,
  ActionsUnit,
  Actions,
  ActionsContent,
} from '../../components/template/TemplateManager/styles';
import { TooltipInfo } from '../Units/styles';
import ChoiceEllipse from '../../components/forms/ChoiceEllipse';
import InputSearch from '../../components/forms/InputSearch';
import {
  IconTrash,
  IconBook,
  IconBookOpenArea,
  IconUniversity,
  IconWallet,
  IconEdit,
  IconDelete,
  IconUpdate,
  IconRotate,
} from '../../styles/icons';
import Tabs from '../../components/template/Tabs';
import Checkbox, { CheckBoxAll } from '../../components/forms/Checkbox';
import { useToast } from '../../hooks/toast';
import { useAuth } from '../../hooks/auth';
import {
  filterItemsProvider,
  getItemsContent,
  getKnowledge,
  IGetKnowledge,
  postItemRecory,
  putItemsContentStatus,
} from '../../services/api';
import { saveManageUnitsFromEditorLog } from '../../services/logs';
import useLazyEffect from '../../hooks/useLazyEffect';
import AlertConfirm from '../../components/template/AlertConfirm';
import ReactSelect from '../../components/forms/ReactSelect';

interface IItems {
  id: string;
  idAuthor: string;
  idProvider: string;
  title: string;
  authors: Array<string>;
  levelOfLearning: string[];
  status: string;
  knowledges: {
    title: string;
  }[];
  item: {
    id: string;
  };
  version: number;
}

const statusOptions = [
  { id: '1', label: 'Todos', value: 'all' },
  { id: '2', label: 'Aprovados', value: 'publish' },
  { id: '3', label: 'Reprovados', value: 'reproved' },
  { id: '4', label: 'Rascunho', value: 'draft' },
  { id: '5', label: 'Correção', value: 'correction' },
  { id: '6', label: 'Análise', value: 'analyze' },
];

const statusItems = [
  {
    label: 'Rascunho',
    value: 'draft',
  }, // criou item e tá atualizando
  { label: 'Enviado', value: 'send' }, // manda pra aprovação, mas sem delegação
  { label: 'Aprovado', value: 'publish' }, // publish ou publish??
  { label: 'Análise', value: 'analyze' }, // delegado pra arrumar, aqui bloquea para edição
  { label: 'Correção', value: 'correction' }, // volta para novas correções
  { label: 'Reprovado', value: 'reproved' }, // reprovado
  { label: 'Excluído', value: 'deleted' }, // deleted
];

const showEdit = ['draft', 'correction', 'publish', 'reproved'];
const showDelete = ['draft'];
const showRecovery = ['deleted'];

const MyUnits: React.FC = () => {
  const firstLoad = useRef(false);

  const { addToast } = useToast();
  const history = useHistory();
  const { user } = useAuth();
  const [items, setItems] = useState<IItems[]>([]);
  const [itemsCopy, setItemsCopy] = useState<IItems[]>([]);
  const [isOpen, setIsOpen] = useState('');
  const [currentPage, setCurrentPage] = useState(0);

  const [currentTab, setCurrentTab] = useState('tab-02');
  const [checks, setChecks] = useState<Array<string>>([]);
  const [knowledges, setKnowledges] = useState<IGetKnowledge[]>([]);

  const [loading, setLoading] = useState(false);
  const [select, setSelect] = useState('all');
  const [status, setStatus] = useState('all');
  const [search, setSearch] = useState('');

  const statusOptionsParse = useMemo(() => {
    return statusOptions.map(item => {
      if (item.value === 'all') {
        return {
          ...item,
          label: `${item.label} (${itemsCopy.length})`,
        };
      }
      return {
        ...item,
        label: `${item.label} (${
          itemsCopy.filter(itemItem => itemItem.status === item.value).length
        })`,
      };
    });
  }, [itemsCopy]);

  // PRIMEIRA BUSCA
  useEffect(() => {
    const load = async (): Promise<void> => {
      try {
        setLoading(true);
        const { data: res } = await filterItemsProvider({
          orderByCreatedAt: 'DESC',
        });
        setItems(res.content);
        setItemsCopy(res.content);
        const knowledge = await getKnowledge();
        setKnowledges(knowledge);
      } catch (error) {
        addToast({
          title: 'Ops...',
          type: 'error',
          description:
            'Algo inesperado aconteceu, por favor recarrege sua página.',
        });
      } finally {
        setLoading(false);
      }
    };
    load();
  }, [addToast]);

  // Change Tabs
  useLazyEffect(() => {
    const load = async (): Promise<void> => {
      try {
        setLoading(true);
        let obj = {};
        if (currentTab === 'tab-01') {
          obj = {
            orderByTitle: 'ASC',
            notStatus: 'deleted',
          };
        } else if (currentTab === 'tab-02') {
          obj = {
            orderByCreatedAt: 'DESC',
            notStatus: 'deleted',
          };
        } else {
          obj = {
            statusItemContent: 'deleted',
          };
        }
        const { data: res } = await filterItemsProvider(obj);
        setItems(res.content);
      } catch (error) {
        addToast({
          title: 'Ops...',
          type: 'error',
          description:
            'Algo inesperado aconteceu, por favor recarrege sua página.',
        });
      } finally {
        setLoading(false);
      }
    };
    load();
  }, [currentTab]);

  // SELECT
  useLazyEffect(() => {
    const load = async (): Promise<void> => {
      try {
        setLoading(true);
        if (select === 'all') {
          const { data: resStatus } = await filterItemsProvider({
            orderByCreatedAt: 'DESC',
          });
          setItems(resStatus.content);
          return;
        }

        const { data: resFilter } = await filterItemsProvider({
          title: search === '' ? undefined : search,
          statusItemContent: status === 'all' ? undefined : status,
          knowledges: select === '' ? undefined : select,
        });
        setItems(resFilter.content);
      } catch (error) {
        addToast({
          title: 'Ops...',
          type: 'error',
          description: 'Algo de inesperado aconteceu',
        });
      } finally {
        setLoading(false);
      }
    };
    load();
  }, [select]);

  // Search && Change Staus
  useEffect(() => {
    if (!firstLoad.current) {
      firstLoad.current = true;
      return;
    }
    setLoading(true);

    const timer = setTimeout(() => {
      filterItemsProvider({
        title: search === '' ? undefined : search,
        statusItemContent: status === 'all' ? undefined : status,
      })
        .then((res: any) => {
          if (!res.data) {
            return;
          }
          setItems(res.data.content);
        })
        .finally(() => {
          setLoading(false);
        });
    }, 1000);
    return () => clearTimeout(timer);
  }, [search, addToast, status]);

  const handleCheck = useCallback(check => {
    setChecks(check);
  }, []);

  const handleTab = useCallback((id: string) => {
    setCurrentTab(id);
  }, []);

  const handleSelect = useCallback((value: string) => {
    setSelect(value);
  }, []);

  const handleStatus = useCallback((value: string) => {
    setStatus(value);
  }, []);

  const handleDropdown = useCallback((id: string) => {
    setIsOpen(state => (state === id ? '' : id));
  }, []);

  const handleCheckItemFields = useCallback(async (id: string) => {
    const { data: resItem } = await getItemsContent(id);
    const { content } = resItem;

    if (content.authors.filter((item: string) => item !== '').length === 0) {
      return false;
    }

    if (
      content.bibliographicReference.filter((item: string) => item !== '')
        .length === 0
    ) {
      return false;
    }

    if (
      content.learningObjectives.filter((item: string) => item !== '')
        .length === 0
    ) {
      return false;
    }

    if (content.knowledges.length === 0) {
      return false;
    }

    if (
      content.levelOfLearning.filter((item: string) => item !== '').length === 0
    ) {
      return false;
    }

    if (content.introduction === '') {
      return false;
    }

    if (content.synthesis === '') {
      return false;
    }

    return true;
  }, []);

  const handleRecoveryItemStatus = useCallback(
    async (id: string) => {
      const confirm = await AlertConfirm({
        title: `Tem certeza que deseja recuperar este item?`,
        text: 'Esta ação pode ser desfeita',
      });
      if (!confirm) return;
      try {
        setLoading(true);
        await postItemRecory(id);

        const { data: resFilter } = await filterItemsProvider({
          orderByCreatedAt: 'DESC',
        });
        setItems(resFilter.content);

        saveManageUnitsFromEditorLog({
          userId: user.id,
          action: 'recovered_unity',
          unityId: id,
        });

        addToast({
          title: 'Sucesso!',
          type: 'success',
          description: `A unidade foi recuperada com sucesso`,
        });
      } catch (error) {
        addToast({
          title: 'Ops...',
          type: 'error',
          description: 'Algo de inesperado aconteceu',
        });
      } finally {
        setLoading(false);
      }
    },
    [addToast],
  );

  const handleChangeStatus = useCallback(
    async ({ ids, statusItem }: { ids: Array<string>; statusItem: string }) => {
      const statusLabel = statusItems.find(
        item => item.value === statusItem,
      )?.label;
      const confirm = await AlertConfirm({
        title: `Tem certeza que deseja alterar o status para ${statusLabel}?`,
        text: 'Esta ação pode ser desfeita',
        type: statusItem,
      });

      if (!confirm) {
        return;
      }
      try {
        setLoading(true);
        // se status for enviado, verificar todos os campo
        if (statusItem === 'send') {
          const check = await handleCheckItemFields(ids[0]);
          if (!check) {
            addToast({
              title: 'Ops...',
              type: 'error',
              description:
                'Preencha todos os campos do item antes de enviar para a análise',
            });
            return;
          }
        }

        await putItemsContentStatus({
          ids,
          status: statusItem,
        });

        const { data: resFilter } = await filterItemsProvider({
          orderByCreatedAt: 'DESC',
        });
        setItems(resFilter.content);

        saveManageUnitsFromEditorLog({
          userId: user.id,
          action: 'chaged_unity_status',
          unityId: ids,
        });

        addToast({
          title: 'Sucesso!',
          type: 'success',
          description: `Status da unidade foi alterado para ${statusLabel}`,
        });
      } catch (error) {
        addToast({
          title: 'Ops...',
          type: 'error',
          description: 'Algo de inesperado aconteceu',
        });
      } finally {
        setLoading(false);
      }
    },
    [addToast, handleCheckItemFields],
  );

  const PER_PAGE = 20;
  const offset = currentPage * PER_PAGE;
  const currentPageData = items.slice(offset, offset + PER_PAGE).map(item => {
    return (
      <React.Fragment key={item.id}>
        <tr className={`${isOpen === item.id ? `dropdown` : ``}`}>
          <td className="no-cursor">
            <Checkbox
              name="check"
              handleCheck={handleCheck}
              checks={checks}
              id={item.id}
            />
          </td>
          <td onClick={() => handleDropdown(item.id)}>
            <NameContent>
              <span>{item.title}</span>
              {item.version !== 1 &&
                ['draft', 'send', 'analyze'].includes(item.status) && (
                  <TooltipInfo style={{ marginLeft: '10px' }}>
                    <IconUpdate />
                    <span>Em atualização</span>
                  </TooltipInfo>
                )}
            </NameContent>
          </td>
          <td onClick={() => handleDropdown(item.id)}>
            <ActionsUnit>
              <Bagde className={`${item.status}`}>
                {
                  statusItems.find(
                    statusItem => statusItem.value === item.status,
                  )?.label
                }
              </Bagde>
              {item.status === 'draft' && (
                <button
                  onClick={() =>
                    handleChangeStatus({
                      ids: [item.id],
                      statusItem: 'send',
                    })
                  }
                  type="button"
                >
                  {' '}
                  Enviar para aprovação
                </button>
              )}

              {item.status === 'correction' && (
                <button
                  // style={{ fontSize: '0.75rem' }}
                  type="button"
                  onClick={() =>
                    history.push(`/dashboard/my-units/update/${item.id}`)
                  }
                >
                  Ver Unidade
                </button>
              )}

              <Actions>
                <ActionsContent className="show">
                  <IconEdit
                    className={`${!showEdit.includes(item.status) && `hidden`}`}
                    onClick={() =>
                      item.status === 'publish'
                        ? history.push(`/dashboard/my-units/update/${item.id}`)
                        : history.push(`/studio/${item.id}`)
                    }
                  />
                  <IconDelete
                    className={`${
                      !showDelete.includes(item.status) && `hidden`
                    }`}
                    onClick={() =>
                      handleChangeStatus({
                        ids: [item.id],
                        statusItem: 'deleted',
                      })
                    }
                  />
                  <IconRotate
                    className={`${
                      !showRecovery.includes(item.status) && `hidden`
                    }`}
                    onClick={() => handleRecoveryItemStatus(item.id)}
                  />
                </ActionsContent>
              </Actions>
            </ActionsUnit>
          </td>
        </tr>
        <tr className={`${isOpen !== item.id ? `hidden` : `fadeIn`} more-info`}>
          <td />
          <td colSpan={4}>
            <MoreInfo>
              <MoreInfoItem>
                <IconBookOpenArea />
                <div>
                  <span>Área de conhecimento:</span> <br />
                  {item.knowledges.map(know => know.title).join(', ')}
                </div>
              </MoreInfoItem>
              <MoreInfoItem>
                <IconUniversity />
                <div>
                  <span>Nível de aprendizagem:</span> <br />
                  {item.levelOfLearning.join(', ')}
                </div>
              </MoreInfoItem>
              <MoreInfoItem>
                <IconWallet />
                <div>
                  <span>Vendas:</span> <br />
                  --
                </div>
              </MoreInfoItem>
            </MoreInfo>
          </td>
        </tr>
      </React.Fragment>
    );
  });

  const pageCount = Math.ceil(items.length / PER_PAGE);

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  function handlePageClick({ selected: selectedPage }) {
    setCurrentPage(selectedPage);
  }

  const renderItems = useCallback(() => {
    return (
      <>
        <table>
          <thead>
            <tr className="no-cursor no-hover">
              <th>
                <CheckBoxAll
                  ids={items.map(item => item.id)}
                  handleCheckAll={handleCheck}
                />
              </th>
              <th>Unidade de Ensino</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {items.length <= 0 && (
              <tr>
                <td colSpan={3}>
                  <p style={{ textAlign: 'center' }}>
                    Nenhum item encontrado{' '}
                    <span role="img" aria-label="wow">
                      😮
                    </span>
                  </p>
                </td>
              </tr>
            )}
            {currentPageData}
          </tbody>
        </table>

        <ReactPaginate
          previousLabel="← Anterior"
          nextLabel="Próximo →"
          pageCount={pageCount}
          onPageChange={handlePageClick}
          containerClassName="pagination"
          previousLinkClassName="pagination__link"
          nextLinkClassName="pagination__link"
          disabledClassName="pagination__link--disabled"
          activeClassName="pagination__link--active"
        />
      </>
    );
  }, [handleCheck, items, pageCount, currentPageData]);

  return (
    <Container className="slideDown">
      <Title>
        <h1>Minhas Unidades</h1>
        <ChoiceEllipse
          name="status"
          itens={statusOptionsParse}
          defaultValue={status}
          handleStatus={handleStatus}
        />
      </Title>
      <Content>
        <Options>
          <OptionRight>
            <div className="search">
              <InputSearch
                name="search"
                onChange={e => setSearch(e.target.value)}
                placeholder="Pesquisar"
              />
            </div>

            <div
              style={{ marginTop: '0', marginLeft: '8px', width: '210px' }}
              className="select"
            >
              <ReactSelect
                name="select_usertype"
                options={[
                  { label: 'Áreas de conhecimento', value: '' },
                  ...knowledges,
                ]}
                defaultValue={select}
                handleValue={handleSelect}
                placeholder="Áreas de conhecimento"
              />
            </div>
            {checks.length > 0 && (
              <DeleteButton
                onClick={() =>
                  handleChangeStatus({ ids: checks, statusItem: 'deleted' })
                }
              >
                <IconTrash />
              </DeleteButton>
            )}
          </OptionRight>
          <OptionLeft style={{ flexDirection: 'row-reverse' }}>
            <Link to="/studio">
              <IconBook /> Nova Unidade
            </Link>
          </OptionLeft>
        </Options>
        <TableContent>
          <Tabs
            itens={[
              { id: 'tab-01', label: 'A-Z' },
              { id: 'tab-02', label: 'Mais Recentes' },
              { id: 'tab-03', label: 'Lixeira' },
            ]}
            defaultValue={currentTab}
            handleTab={handleTab}
          />
          {loading ? (
            <div className="loading">
              <BeatLoader size={16} />
            </div>
          ) : (
            <div className={`${loading ? `hidden` : ``} slideUp`}>
              {renderItems()}
            </div>
          )}
        </TableContent>
      </Content>
    </Container>
  );
};

export default MyUnits;
