import styled, { css } from 'styled-components';

export const Holder = styled.div`
  display: flex;
  width: 100%;
  & > main {
    width: 100%;
  }
`;

export const Container = styled.div``;

interface IContentProps {
  readonly Tiny?: boolean;
}

export const Content = styled.div<IContentProps>`
  ${props =>
    props.Tiny &&
    css`
      height: 300px;
      overflow: hidden;
      position: relative;
      margin-top: -10px;
    `}
  h2 {
    color: var(--tertiary);
    font-size: 1.5rem;
    font-weight: bold;
    margin-top: 10px;
    margin-bottom: 20px;
  }
  & > section {
    width: 100%;
    padding: 0 20px;
    & > div {
      & > div:first-child {
        & > div:first-child {
          border-radius: 5px 5px 0 0;
        }
      }
    }
  }
  .grow {
    position: absolute;
    bottom: 0px;
    display: none;
    width: 100%;
    height: 100px;
    background: var(--white);
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#fcfafa+0,fcfafa+100&0+0,1+56,1+100 */
    background: -moz-linear-gradient(
      top,
      rgba(252, 250, 250, 0) 0%,
      rgba(252, 250, 250, 1) 56%,
      rgba(252, 250, 250, 1) 100%
    ); /* FF3.6-15 */
    background: -webkit-linear-gradient(
      top,
      rgba(252, 250, 250, 0) 0%,
      rgba(252, 250, 250, 1) 56%,
      rgba(252, 250, 250, 1) 100%
    ); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(
      to bottom,
      rgba(252, 250, 250, 0) 0%,
      rgba(252, 250, 250, 1) 56%,
      rgba(252, 250, 250, 1) 100%
    ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00fcfafa', endColorstr='#fcfafa',GradientType=0 ); /* IE6-9 */

    ${props =>
      props.Tiny &&
      css`
        display: flex;
        justify-content: center;
        align-items: center;
      `}

    button {
      background: var(--aurea);
      border: none;
      font-weight: bold;
      padding: 15px 20px;
      border-radius: 5px;
      font-size: 1rem;
      color: var(--dark);
      display: flex;
      justify-content: center;
      align-items: center;
      svg {
        margin-right: 10px;
      }
    }
  }
  .loading {
    width: 100%;
    padding: 20px;
    text-align: center;
    & > div {
      display: inline-block;
      opacity: 0.5;
    }
  }
`;

export const Title = styled.div`
  h1 {
    margin-right: 24px;
  }
  div.bread-crumb {
    ul {
      display: flex;
      list-style: none;
      li {
        font-weight: bold;
        color: var(--aurea);
        a {
          font-weight: normal;
          color: var(--tertiary);
          text-decoration: none;
          &:hover {
            text-decoration: underline;
          }
          &:after {
            content: '>';
            display: inline-block;
            margin: 0 10px;
            font-size: 0.75rem;
          }
        }
        &:last-child {
          text-decoration: underline;
        }
      }
    }
  }
  div.provider {
    background: #ffffff;
    border: 1px solid var(--quaternary);
    padding: 30px;
    margin: 20px 0;
    border-radius: 10px;
    display: flex;
    align-items: center;
    img {
      width: 115px;
      height: 115px;
      object-fit: cover;
      margin-right: 30px;
      border-radius: 50%;
    }
    & > div {
      width: 100%;
      h1 {
        font-size: 1.875rem;
        margin-bottom: 10px;
      }
      & > div {
        display: flex;
        align-items: flex-end;
        & > div {
          width: 50%;
        }
        & > div:first-child {
          p.units {
            margin-top: 20px;
            font-size: 0.75rem;
            color: var(--aurea);
            strong {
              font-weight: bold;
            }
          }
        }
        & > div:last-child {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          p {
            color: var(--gray);
            strong {
              font-weight: bold;
            }
            span {
              font-size: 0.625rem;
            }
          }
          a {
            font-size: 0.9rem;
            display: block;
            width: 140px;
            padding: 15px;
            border: none;
            color: var(--dark);
            background: var(--gray-opacity);
            border-radius: 5px;
            font-weight: bold;
            transition: background 400ms;
            margin-left: 20px;
            &:hover {
              text-decoration: none;
              background: var(--gray-border);
            }
          }
          button {
            display: block;
            width: 140px;
            padding: 15px;
            border: none;
            color: var(--dark);
            background: var(--aurea);
            border-radius: 5px;
            font-weight: bold;
            transition: background 400ms;
            margin-left: 20px;
            &:hover {
              text-decoration: none;
              background: var(--hover);
            }
          }
          a,
          button {
            &.rented {
              opacity: 0.5;
              background: var(--white);
              border: 1px solid var(--gray-border);
              transition: opacity 0.3s;
              text-align: center;
              &:hover {
                opacity: 1;
              }
            }
          }
        }
      }
    }
  }
`;
