/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React from 'react';

import { Container, RowGroup, File, Check } from './styles';

interface IModalProps {
  isOpen: boolean;
}

const ModalTerms: React.FC<IModalProps> = ({ isOpen }) => {
  return (
    <Container>
      <div>
        <br />
        Prezados(as),
        <br />
        <p>
          O Termo de Uso da Plataforma Educacional Áurea e Termo de Política de
          Privacidade tem por finalidade explicitar as regras e condições que
          irão orientar a utilização doa PLATAFORMA ÁUREA disponibilizada pela
          ÁUREA SOLUÇÃO EDUCACIONAL LTDA, pessoa jurídica de direito privado,
          inscrita no Cadastro Nacional de Pessoas Jurídicas do Ministério da
          Fazenda sob o nº 40.066.585/0001-67, estabelecida no Brasil, com sede
          na rua Piratininga, 808, Edifício Torre de Oregon, sala 2, Zona 01,
          CEP 87013-100, Maringá-Pr, em sua página de internet (“Website”)
          localizada no endereço eletrônico www.aurea.app. A utilização do site
          ou de conteúdos nele expostos sujeita o usuário à observância e
          respeito às condições abaixo expostas.
        </p>
        <p>Clique nos botões abaixo para acessar os documentos.</p>
        <br />
        <RowGroup>
          <File>
            <a
              href="https://aurea-files.s3.amazonaws.com/provider/gallery/0127898b-becd-4e89-9e53-181dd5b5ee10/1670260522529%20-%20AUREA%20-%20Termo%20de%20Pol%C3%ADtica%20de%20Privacidade.pdf"
              target="_blank"
              rel="noreferrer"
            >
              Termo de Política de Privacidade
            </a>
          </File>

          <File>
            <a
              href="https://aurea-files.s3.amazonaws.com/provider/gallery/0127898b-becd-4e89-9e53-181dd5b5ee10/1670261412034%20-%20AUREA%20-%20Termo%20de%20Uso%20da%20Plataforma%20Educacional%20Aurea.pdf"
              target="_blank"
              rel="noreferrer"
            >
              Termo de Uso da Plataforma Educacional Aurea
            </a>
          </File>
        </RowGroup>
        <br />
        <Check>
          <label>
            <input type="checkbox" id="acceptCheckbox" />

            <span>
              {' '}
              Confirmo leitura e ciência do Termo de Uso da Plataforma
              Educacional Áurea e Termo de Política de Privacidade da Plataforma
              Educacional Áurea.
            </span>
          </label>
        </Check>
        {/* <img src={LogoYellow} alt="Logo" width={164} /> */}
      </div>
      {/* <Bottom>
        <Buttom label="Cancelar" outline onClick={() => setIsOpen()} />
        <Buttom label="Salvar" />
      </Bottom> */}
    </Container>
  );
};

export default ModalTerms;
