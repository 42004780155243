/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import CreatableSelect from 'react-select/creatable';
import { useField } from '@unform/core';
import { Container, Create } from './styles';

import { useAuth } from '../../../hooks/auth';

import {
  postCreateSellerSubject,
  getListSellerSubjectSimple,
  getListProviderSubjects,
} from '../../../services/api';

interface IOptions {
  label: string;
  value: string;
}

interface IReactSelect {
  name: string;
  placeholder?: string;
}

const ReactSelectCreateForm: React.FC<IReactSelect> = ({
  name,
  placeholder,
}) => {
  const { user } = useAuth();
  const { fieldName, error, registerField, defaultValue } = useField(name);
  const [options, setOptions] = useState<IOptions[]>([]);

  useEffect(() => {
    async function load() {
      if (user?.seller?.id) {
        const { data } = await getListSellerSubjectSimple(user?.seller?.id);
        const SubjectList = data.map(val => {
          return {
            value: val.subject_id,
            label: val.name,
          };
        });
        setOptions(SubjectList);
      } else if (user.type === 'provider' && user.provider) {
        const { data: res } = await getListProviderSubjects(user.provider.id);

        if (res) {
          const SubjectList = res.map(item => {
            return {
              value: item.subject_id,
              label: item.name,
            };
          });

          setOptions(SubjectList);
        }
      }
    }
    if (user?.seller?.id || user.provider) load();
  }, [user]);

  const pattem = useMemo<IOptions | undefined>(() => {
    const findDefault = options.find(item => defaultValue === item.value);
    return findDefault
      ? { label: findDefault.label, value: findDefault.value }
      : undefined;
  }, [options, defaultValue]);

  const [currnetValue, setCurrentValue] = useState<IOptions | undefined>(
    pattem,
  );

  const handleSetCurrentValue = useCallback(
    (value: string) => {
      options.forEach(item => {
        if (item.value === value) {
          setCurrentValue(item);
        }
      });
    },
    [options],
  );

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: currnetValue,
      path: 'value',
    });
  }, [fieldName, registerField, currnetValue]);

  const getDefaultValue = useCallback(() => {
    if (!defaultValue) return null;
    const findOption = options.find(
      option => currnetValue?.value === option.value,
    );
    return findOption;
  }, [options, defaultValue, currnetValue]);

  useEffect(() => {
    if (defaultValue) {
      options.forEach(item => {
        if (item.value === defaultValue) {
          setCurrentValue(item);
        }
      });
    }
  }, [defaultValue, options, setCurrentValue]);

  const customStyle = {
    option: (provided: any, state: any) => ({
      ...provided,
      border: '0',
      color: '#8D8585',
      background: state.isFocused ? '#F9F5F5' : 'white',
      paddingTop: 6,
      paddingBottom: 6,
    }),
    container: (provided: any, state: any) => ({
      ...provided,
      border: '0',
    }),
  };
  const themeOptions = (theme: any) => ({
    ...theme,
    colors: {
      ...theme.colors,
      primary50: '#E0DEDE',
      primary25: '#E0DEDE',
      primary: '#E0DEDE',
    },
  });

  // CREATE =====
  const [isLoading, setIsLoading] = useState(false);
  const handleCreate = useCallback(
    async (inputValue: any) => {
      setIsLoading(true);
      const {
        data: { subject_id },
      } = await postCreateSellerSubject({
        name: inputValue,
        sellerId: user?.seller?.id,
      });
      setTimeout(() => {
        const choosed = {
          value: subject_id,
          label: inputValue,
        };
        setOptions([choosed, ...options]);
        setCurrentValue(choosed);
        setIsLoading(false);
      }, 1000);
    },
    [options, setOptions, setIsLoading, setCurrentValue, user],
  );

  return (
    <Container isError={!!error}>
      <CreatableSelect
        name={name}
        onChange={(e: any) => handleSetCurrentValue(e.value)}
        options={options}
        getOptionValue={(option: IOptions) => option.value}
        theme={themeOptions}
        styles={customStyle}
        placeholder={placeholder}
        defaultValue={getDefaultValue()}
        onCreateOption={handleCreate}
        isDisabled={isLoading}
        isLoading={isLoading}
        value={currnetValue}
        formatCreateLabel={inputValue => (
          <>
            <Create>Criar nova</Create> {inputValue}
          </>
        )}
      />
      {error && <small>{error}</small>}
    </Container>
  );
};

export default ReactSelectCreateForm;
