import styled from 'styled-components';

export const Container = styled.div`
  padding: 20px;
  p {
    color: var(--gray);
    font-size: 0.75rem;
    margin-top: 10px;
  }
`;

export const ContentForm = styled.div`
  margin-top: 30px;
  label {
    font-weight: 700;
    color: var(--gray);
    margin-bottom: 14px;
  }
  .select {
    margin-top: 14px;
  }
`;

export const ContentField = styled.div`
  margin: 20px 0;
`;

export const Bottom = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  margin-top: 24px;

  div + div {
    margin-left: 18px;
  }
`;

export const SelectContent = styled.div`
  margin-top: 14px;
`;
