import styled, { css, keyframes } from 'styled-components';
import { animated } from 'react-spring';

interface ToastProps {
  type?: 'info' | 'success' | 'error';
  hasDescription: boolean;
}

const toastType = {
  info: css`
    background: #ebf8ff;
    color: #3172b7;
  `,
  success: css`
    background: var(--success);
    color: #fff;
  `,
  error: css`
    background: var(--danger);
    color: #fff;
  `,
};

const loadBar = keyframes`
  0% { width: 100%; }
  100% { width: 0%; }
`;

export const Container = styled(animated.div)<ToastProps>`
  width: 360px;

  position: relative;
  padding: 16px 30px 16px 24px;
  border-radius: 5px;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.2);
  & + div {
    margin-top: 10px;
  }
  display: flex;
  ${props => toastType[props.type || 'info']};
  > svg {
    margin: 0px 12px 0 0;
  }
  div {
    flex: 1;
    p {
      margin-top: 4px;
      font-size: 16px;
      line-height: 20px;
      font-weight: 500;
    }
  }

  strong {
    font-weight: 700;
  }

  button {
    position: absolute;
    right: 12px;
    top: 8px;
    border: 0;
    background: transparent;
    /* Pega a cor do container */
    color: inherit;
  }

  &::after {
    display: flex;
    align-items: center;
    justify-content: center;

    position: absolute;
    bottom: 0px;
    left: 0px;

    border-bottom-left-radius: 5px;
    transition: background 0.5s;

    color: var(--gray);
    background: #fff;
    opacity: 0.35;
    content: '';
    width: 0%;
    height: 9px;

    animation-name: ${loadBar};
    animation-duration: 5s;
  }

  ${props =>
    !props.hasDescription &&
    css`
      align-items: center;
      > svg {
        margin-top: 0;
      }
    `}
`;

export const Content = styled.div``;
