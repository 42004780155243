import styled from 'styled-components';
import { shade } from 'polished';

interface ICancel {
  cancel: boolean;
}

export const Container = styled.div`
  display: flex;
  width: 100%;
`;

export const Main = styled.main`
  display: flex;
  flex: 1;
  height: 100vh;
  overflow-y: auto;
`;

export const MainContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const Body = styled.div`
  width: 100%;
  max-width: 812px;
  margin: 35px auto 0 auto;
  section.search {
    margin-top: 30px;
    margin-bottom: 41px;
    width: 252px;
  }
`;

export const Content = styled.div``;

export const Title = styled.header`
  display: flex;
  align-items: center;
  h1 {
    font-size: 2.25rem;
    margin-right: 30px;
  }
`;

export const ModalContent = styled.div<ICancel>`
  h1 {
    margin: 34px 0 30px 37px;
    font-size: 30px;
  }
  section {
    display: flex;
    align-items: center;
    justify-content: center;
    button {
      border: 1px solid #f6c866;
      color: #3d3939;
      width: 150px;
      margin-right: 19px;
      font-family: 'Source Sans Pro';
      font-weight: 700;
      font-size: 16;
      padding: 14px;
      border-radius: 5px;
      background: #f6c866;
      transition: all 0.2s;
      &:hover {
        background: ${shade(0.2, '#F6C866')};
      }
    }
    button.cancel {
      background: #ffffff;
      &:hover {
        background: ${shade(0.1, '#FFFFFF')};
      }
    }
  }
`;

export const ModalContentToSuspend = styled.div<ICancel>`
  h1 {
    margin: 34px 0 30px 37px;
    font-size: 30px;
  }
  section {
    display: flex;
    align-items: center;
    justify-content: center;
    button {
      border: 1px solid #f6c866;
      color: #ffff;
      width: 150px;
      margin-right: 19px;
      font-family: 'Source Sans Pro';
      font-weight: 700;
      font-size: 16;
      padding: 14px;
      border-radius: 5px;
      background: #6bc196;
      transition: all 0.2s;
      &:hover {
        background: ${shade(0.1, '#6BC196')};
      }
    }
    button.cancel {
      background: #cc593e;
      &:hover {
        background: ${shade(0.1, '#CC593E')};
      }
    }
  }
`;

export const SelectContainer = styled.div`
  display: flex;
  flex-direction: column;
  label.selectTitle {
    color: #8d8585;
    font-family: 'Source Sans Pro';
    font-weight: 700;
    font-size: 16px;
    margin-left: 38px;
    margin-bottom: 15px;
  }
  select {
    border: 1px solid #e0dede;
    background: #ffffff;
    color: #8d8585;
    margin: 0 58px 55px 38px;
    height: 44px;
  }
`;

export const ButtonContainer = styled.section`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  button {
    margin-bottom: 5px;
    padding: 5px 10px;
    font-family: 'Source Sans Pro';
    font-size: 14px;
    color: var(--dark);
    background: var(--aurea);
    border: none;
    cursor: pointer;
    &:hover {
      background-color: #0056b3;
    }
  }

    .gray-button {
    background-color: #cccccc;
    &:hover {
      background-color: #999999;
    }
  }
`;