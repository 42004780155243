import { shade } from 'polished';
import styled from 'styled-components';

export const Container = styled.div`
  max-width: 812px;
  margin: 0 auto 18px auto;
`;
export const Holder = styled.div`
  display: flex;
  width: 100%;
  & > main {
    width: 100%;
  }
  & > aside {
    width: 300px;
  }
`;

export const Content = styled.div`
  section {
    display: flex;
    flex-wrap: wrap;
    padding-top: 20px;
    article {
      width: calc(33.3333333% - 15px);
      background: #ffffff;
      border: 1px solid var(--quaternary);
      border-radius: 5px;
      margin: 7.5px;
      & > * {
        padding: 15px;
      }
      header {
        text-align: center;
        padding-top: 20px;
        padding-bottom: 20px;
        position: relative;
        min-height: 130px;
        h1 {
          margin-top: 10px;
          font-size: 1rem;
          margin-bottom: 15px;
        }
        p {
          font-size: 0.875rem;
          color: var(--gray);
          &.units {
            font-size: 0.75rem;
            color: var(--aurea);
            strong {
              font-weight: bold;
            }
          }
        }

        button {
          position: absolute;
          right: 0px;
          top: 0px;
          background: none;
          border: none;
          padding: 5px 10px;
          opacity: 1;
          transition: opacity 0.3s;
          &:hover {
            opacity: 0.5;
          }
        }
      }
      main {
        border-top: 1px solid var(--gray-border);
        display: flex;
        justify-content: space-between;
        padding: 0px;
        p {
          color: var(--gray);
          strong {
            font-weight: bold;
          }
          span {
            font-size: 0.625rem;
          }
        }
        a {
          border: 1px solid var(--gray-border);
          padding: 5px 15px;
          border-radius: 5px;
          font-size: 0.75rem;
          font-weight: bold;
          color: var(--dark);
          transition: background 400ms;
          &:hover {
            text-decoration: none;
            background: var(--gray-border);
          }
        }
      }
      footer {
        padding-top: 20px;
        padding-bottom: 20px;
        a {
          text-align: center;
          display: block;
          width: 100%;
          padding: 5px 10px;
          border: none;
          color: var(--dark);
          background: var(--aurea);
          border-radius: 5px;
          font-weight: bold;
          transition: background 400ms;
          &:hover {
            text-decoration: none;
            background: var(--hover);
          }
        }
        button {
          display: block;
          width: 100%;
          padding: 5px 10px;
          border: none;
          color: var(--dark);
          background: var(--aurea);
          border-radius: 5px;
          font-weight: bold;
          transition: background 400ms;
          &:hover {
            text-decoration: none;
            background: var(--hover);
          }
        }
        a,
        button {
          &.rented {
            opacity: 0.5;
            background: var(--white);
            border: 1px solid var(--gray-border);
            transition: opacity 0.3s;
            text-align: center;
            &:hover {
              opacity: 1;
            }
          }
        }
      }
    }
    .loading {
      width: 100%;
      padding: 20px;
      text-align: center;
      & > div {
        display: inline-block;
        opacity: 0.5;
      }
    }
  }
`;

interface IPropsCancel {
  cancel: boolean;
}

export const ModalContent = styled.div<IPropsCancel>`
  h1.modalTitle {
    margin-top: 33px;
    text-align: center;
    color: #3d3939;
    font-size: 22px;
    font-family: 'Source Sans Pro';
    font-weight: 700;
    margin-bottom: 40px;
  }
  section {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 25px;
    button {
      width: 90px;
      height: 50px;
      margin-left: 20px;
      color: #ffff;
      font-family: 'Source Sans Pro';
      font-weight: 700;
      background: #6bc196;
      border: 1px solid transparent;
      border-radius: 5px;
      transition: all 0.2s;
      &:hover {
        background: ${shade(0.1, '#6BC196')};
      }
    }
    button.cancel {
      background: #cc593e;
      &:hover {
        background: ${shade(0.1, '#CC593E')};
      }
    }
  }
`;
