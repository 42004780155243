import styled, { css } from 'styled-components';
import { shade } from 'polished';
import qrcode from '../../../assets/images/qrcode.png';

interface ICancel {
  cancel: boolean;
}

export const Container = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  padding: 18px;
  gap: 8px;

  span {
    position: relative;

    display: flex;
    align-items: center;
    user-select: none;

    color: #252733;
    font-size: 14px;
    line-height: 20px;
    padding: 5px 10px;

    cursor: pointer;

    small {
      font-weight: 600;
      color: #777;
    }

    img {
      width: 44px;
      height: 44px;
      padding: 2px;
      border: 1.5px solid #dfe0eb;
      border-radius: 50%;
      margin-left: 16px;
    }

    svg {
      color: #c5c7cd;
    }

    button {
      display: flex;
      align-items: center;

      height: 100%;
      border: 0;
      background: none;

      svg {
        margin-right: 8px;
      }
    }

    & + span {
      border-left: 1.5px solid #dfe0eb;
      margin-left: 20px;
      padding-left: 20px;
    }
  }

  .notification > label {
    position: absolute;
    top: 40%;
    right: 25%;
    transform: translateY(-50%);

    width: 12px;
    height: 12px;
    border-radius: 50%;

    background: var(--aurea);
  }
`;

export const NotificationsBox = styled.div`
  position: absolute;
  top: 90%;
  right: 0;

  width: 100vw;
  max-width: 540px;
  padding: 24px;
  background: #fff;
  border-radius: 4px;

  z-index: 10;
  user-select: none;
  cursor: default;

  max-height: 500px;
  overflow-y: auto;

  display: none;

  &.open {
    display: flex;
    flex-direction: column;
  }

  header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 18px;

    h2 {
      font-weight: 600;
      font-size: 24px;
    }

    button {
      color: #777;
      font-size: 12px;
    }

    button:hover {
      opacity: 0.8;
      text-decoration: underline;
    }
  }
`;

export const NotificationsContent = styled.div`
  margin: 0 -8px;
`;

interface INotification {
  read: boolean;
}

export const Notification = styled.article<INotification>`
  display: flex;
  flex-direction: column;

  padding: 8px;

  &:hover {
    background: #f6c866;
  }

  label {
    display: flex;
    justify-content: space-between;
  }

  span {
    width: 30px;

    &::after {
      content: '';

      width: 8px;
      height: 8px;

      background: var(--aurea);
      border-radius: 50%;
    }
  }

  ${props =>
    !props.read &&
    css`
      label span::after {
        background: transparent;
      }
    `}

  p {
    display: inline;
    font-size: 14px;
  }

  > strong {
    color: #999;
  }

  strong {
    font-weight: 600;
  }
`;

export const ModalContent = styled.div<ICancel>`
  h1 {
    margin: 34px 0 30px 37px;
    font-size: 30px;
    display: flex;
    justify-content: center;
    position: relative;
    right: 30px;
  }
  section {
    display: flex;
    align-items: center;
    justify-content: center;
    button {
      border: 1px solid #f6c866;
      color: #3d3939;
      width: 150px;
      margin-right: 19px;
      font-family: 'Source Sans Pro';
      font-weight: 700;
      font-size: 16;
      padding: 14px;
      border-radius: 5px;
      background: #f6c866;
      transition: all 0.2s;
      &:hover {
        background: ${shade(0.2, '#F6C866')};
      }
    }
    button.cancel {
      background: #f6c866;
      &:hover {
        background: ${shade(0.1, '#F6C866')};
      }
    }
  }
`;

export const RowGroup = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: row;
  position: relative;

  div {
    width: 100%;
    & + div {
      margin-left: 18px;
    }
  }
`;

export const File = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  a {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    left: 21px;
    text-align: center;
    padding: 5px 10px;
    color: black;
    font-size: 1rem;
    font-weight: bold;
    width: 250px;
    color: var(--dark);

    border-radius: 5px;
    border: 1px solid #f6c866;

    cursor: pointer;
    transition: background 0.3s;
    &:hover {
      background: #f6c866;
    }
  }
`;

export const ContactDropDown = styled.div`
  h1 {
    position: relative;
    margin: 34px 0 30px 37px;
    font-size: 30px;
    width: 50px;
    height: 55px;
    left: 180px;
  }

  section {
    display: flex;
    align-items: center;
    justify-content: center;
    button {
      border: 1px solid #f6c866;
      color: #3d3939;
      width: 150px;
      margin-right: 19px;
      font-family: 'Source Sans Pro';
      font-weight: 700;
      font-size: 16;
      padding: 14px;
      border-radius: 5px;
      background: #f6c866;
      transition: all 0.2s;
      &:hover {
        background: ${shade(0.2, '#F6C866')};
      }
    }
    button.cancel {
      background: #f6c866;
      &:hover {
        background: ${shade(0.1, '#F6C866')};
      }
    }
  }
  .imagem {
    position: relative;
    left: 210px;
    background-image: url(${qrcode});
    background-repeat: no-repeat;
    width: 175px;
    height: 175px;
  }
  p {
    position: relative;
    left: 11px;
  }
`;
