import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  /* Desfoque em alerts */
  .swal2-shown .blur {
      filter: blur(2px);
  }
  .swal2-shown .tox{
    filter: blur(2px);
  }

  .swal2-container {
    z-index: 99999;
  }

  .swal2-container.swal2-backdrop-show, .swal2-container.swal2-noanimation {
    background: none;
    > div {
      box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.2);
      border-radius: 20px;
      padding: 2.5rem;
      width: 38em;
      .swal2-header {
        h2 {
          font-family: 'Source Sans Pro', sans-serif;
          font-size: 1.25rem;
          font-weight: 900;
          color: var(--dark);
        }
      }
      .swal2-content {
        font-family: 'Source Sans Pro', sans-serif;
        font-size: 1.125rem;
        color: var(--gray);
      }

      .swal2-actions {
        button {
          font-weight: 900;
        }
      }
    }
  }


`;
