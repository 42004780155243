import styled, { css } from 'styled-components';
import { IconCheckCircleRed as IconCloseCircleStyle } from '../../../styles/icons';

interface IContentProps {
  isErrored?: boolean;
  disabled?: boolean;
}

export const Container = styled.div`
  padding-top: 18px;

  label {
    font-weight: 700;
    color: var(--gray);
  }

  small {
    color: var(--error);
  }
`;

export const Content = styled.div<IContentProps>`
  background: #fff;
  border: 1px solid var(--quaternary);
  border-radius: 5px;
  padding: 10px 14px;
  margin-top: 14px;

  position: relative;

  width: 100%;
  color: var(--dark);
  display: flex;
  align-items: center;
  & + div {
    margin-top: 8px;
  }

  input {
    background: transparent;
    flex: 1;
    width: calc(100% - 20px);
    border: 0;
    color: var(--dark);

    &::placeholder {
      color: var(--placeholder);
    }

    &:disabled {
      background-color: var(--hover-menu);
    }
  }

  > svg {
    margin-left: 4px;
    fill: var(--gray);
    &.eye {
      cursor: pointer;
      opacity: 0.5;
      transition: opacity 0.1s;
      &.open {
        opacity: 1;
      }
    }
  }

  ${props =>
    props.isErrored &&
    css`
      border: 1px solid var(--error);
    `}

  ${props =>
    props.disabled &&
    css`
      background-color: var(--hover-menu);
    `}
`;

export const IconCloseCircle = styled(IconCloseCircleStyle)`
  width: 18px;
  height: 18px;
`;
