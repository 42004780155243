/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable func-names */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { BeatLoader } from 'react-spinners';
import { useDebouncedCallback } from 'use-debounce';

import InputSearch from '../../components/forms/InputSearch';
import AlertConfirm from '../../components/template/AlertConfirm';
import ModalDisciplina from '../../components/template/ModalDisciplina';
import NavTop from '../../components/template/NavTop';

import {
  Body,
  Main,
  MainContent,
} from '../../components/template/SellerTemplate/styles';

import { useAuth } from '../../hooks/auth';
import { useToast } from '../../hooks/toast';

import {
  filterItemsProvider,
  filterStoreItemsAvailableItems,
  getKnowledge,
  getLevelOfLearning,
  getSellerDataById,
  getSellerUnitysToUpdate,
  IGetKnowledge,
  updateSellerUnity,
  getSellerIdByContentManagerUserId,
  downloadUnity,
} from '../../services/api';

import {
  IconBookmarkBlack,
  // IconCrownTiny,
  IconDownload,
  IconShare,
} from '../../styles/icons';
import { Container, Content, Holder, RightSide, Title } from './styles';

import {
  IItems,
  IItemsCreate,
  // IProviders,
  ISeller,
  IUnityToUpdate,
} from './interfaces';

const StoreProvider: React.FC = () => {
  const { user } = useAuth();

  const { addToast } = useToast();
  const [loading, setLoading] = useState(true);

  // SEARCH ===========
  const [search, setSearch] = useState('');

  // CHECKBOX ===========
  const inputParentLevel = useRef(document.createElement('div'));
  const [levelOfLearning, setLevelOfLearning] = useState([]);
  const [selectedLevel, setSelectedLevel] = useState<string[]>([]);

  const inputParentKnowledge = useRef(document.createElement('div'));
  const [knowledges, setKnowledges] = useState<IGetKnowledge[]>([]);
  const [selectedKnowledge, setSelectedKnowledge] = useState<any[]>([]);

  const inputParentProvider = useRef(document.createElement('div'));
  // const [selectedProvider, setSelectedProvider] = useState<string[]>([]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  // const [providers, setProviders] = useState<IProviders[]>([]);

  const [formattedItems, setFormattedItems] = useState({});
  const [itemQuantity, setItemQuantity] = useState(0);

  const [filterType, setFilterType] = useState('unit');

  // SELLERS ===========
  const [seller, setSeller] = useState<ISeller>({} as ISeller);
  const [unitsToUpdate, setUnitsToUpdate] = useState<Array<IUnityToUpdate>>();

  const [isButtonEnabled, setIsButtonEnabled] = useState(true);
  // MORE ITEMS ===========
  const [more, setMore] = useState({});

  const handleFilterType = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setFilterType(e.target.id);
    },
    [],
  );

  const handleMore = useCallback(
    key => {
      setMore({
        ...more,
        [key]: !more[key],
      });
    },
    [more, setMore],
  );

  const handleLevel = useCallback(
    id => {
      let shadow: string[] = [...selectedLevel];
      if (shadow.includes(id)) {
        shadow = shadow.filter(level => level !== id);
      } else {
        shadow.push(id);
      }
      setSelectedLevel(shadow);
    },
    [selectedLevel, setSelectedLevel],
  );

  const handleKnowledge = useCallback(
    value => {
      let shadow = [...selectedKnowledge];

      if (shadow.includes(value)) {
        shadow = shadow.filter(level => level !== value);
      } else {
        shadow.push(value);
      }

      setSelectedKnowledge(shadow);
    },
    [selectedKnowledge, setSelectedKnowledge],
  );

  // const handleProvider = useCallback(
  //   id => {
  //     let shadow: string[] = [...selectedProvider];
  //     if (shadow.includes(id)) {
  //       shadow = shadow.filter(
  //         selectedProviderItem => selectedProviderItem !== id,
  //       );
  //     } else {
  //       shadow.push(id);
  //     }
  //     setSelectedProvider(shadow);
  //   },
  //   [selectedProvider, setSelectedProvider],
  // );

  // CLEAR FILTER ===========
  const clearFilter = useCallback(() => {
    setSelectedKnowledge([]);
    const checkedKnowledge = inputParentKnowledge.current.querySelectorAll(
      'input[type=checkbox]',
    );

    Array.prototype.forEach.call(checkedKnowledge, function (el) {
      if (el.checked) el.checked = false;
    });

    setSelectedLevel([]);
    const checkedLevel = inputParentLevel.current.querySelectorAll(
      'input[type=checkbox]',
    );

    Array.prototype.forEach.call(checkedLevel, function (el) {
      if (el.checked) el.checked = false;
    });

    // setSelectedProvider([]);
    // const checkedProvider = inputParentProvider.current.querySelectorAll(
    //   'input[type=checkbox]',
    // );

    // Array.prototype.forEach.call(checkedProvider, function (el) {
    //   if (el.checked) el.checked = false;
    // });

    setSearch('');
  }, [inputParentKnowledge, inputParentLevel, inputParentProvider]);

  const handleCoursesData = useCallback(async content => {
    const formattedItemsObj = {};
    const moreObj = {};

    if (itemQuantity === 0) {
      setItemQuantity(content.length);
    }

    content.forEach((element: IItems) => {
      element.knowledges.forEach(know => {
        if (
          selectedKnowledge.length === 0 ||
          (selectedKnowledge.length !== 0 &&
            selectedKnowledge.includes(know.id))
        ) {
          if (!Array.isArray(formattedItemsObj[know.title])) {
            formattedItemsObj[know.title] = [];
          }
          formattedItemsObj[know.title].push(element);
        }
      });
    });

    Object.keys(formattedItemsObj).forEach(key => {
      moreObj[key] = formattedItemsObj[key].length > 4;
    });

    setMore(moreObj);
    setFormattedItems(formattedItemsObj);
    setLoading(false);
  }, []);

  const handleSellerSearch = useCallback(async (): Promise<void> => {
    setLoading(true);

    const params = {
      statusItemContent: 'publish',
      title: search || undefined,
      // providerId: selectedProvider.join(',') || undefined,
      knowledges: selectedKnowledge.join(',') || undefined,
      levelOfLearning: selectedLevel.join(',') || undefined,
      quantity: 700 || undefined,
      filterType: filterType || undefined,
    };

    if (
      // selectedProvider.length === 0 &&
      selectedKnowledge.length === 0 &&
      selectedLevel.length === 0
    ) {
      params.quantity = 700;
    }

    const { data } = await filterStoreItemsAvailableItems(params);
    const { content } = data;
    await handleCoursesData(content);
  }, [
    search,
    selectedLevel,
    selectedKnowledge,
    // selectedProvider,
    itemQuantity,
    filterType,
  ]);

  const handleProviderSearch = useCallback(async (): Promise<void> => {
    setLoading(true);

    const { data } = await filterItemsProvider({
      title: search || undefined,
      statusItemContent: 'publish',
      size: 1000,
    });

    const { content } = data;
    await handleCoursesData(content);
  }, [
    search,
    selectedLevel,
    selectedKnowledge,
    // selectedProvider,
    itemQuantity,
  ]);

  // DISCIPLINA =====
  const [selectedItem, setSelectedItem] = useState<IItemsCreate>(
    {} as IItemsCreate,
  );

  const [modalDisciplinaOpen, setModalDisciplinaOpen] = useState(false);
  const toggleModalDisciplina = useCallback(() => {
    setModalDisciplinaOpen(!modalDisciplinaOpen);
  }, [modalDisciplinaOpen]);

  const generateKey = useCallback(
    () => (Math.random() + 1).toString(36).substring(7),
    [],
  );

  const getLmsKey = async (): Promise<string | null> => {
    let lmsKey = localStorage.getItem('@aurea:seller:lms_key');

    if (!lmsKey) {
      const userDataByLocalStorage = localStorage.getItem('@aurea:user');
      let userId = null;

      if (userDataByLocalStorage) {
        const userDataInJson = JSON.parse(userDataByLocalStorage);
        userId = userDataInJson.id;
      }
      if (userId) {
        const { data } = await getSellerIdByContentManagerUserId(userId);

        lmsKey = data.lms_key;
      }
    }

    return lmsKey;
  };

  const handleShare = useCallback(async (itemId: string) => {
    const lmsKey = await getLmsKey();

    if (lmsKey) {
      lmsKey.replace(/['"]+/g, '');
      await AlertConfirm({
        title: 'Link Temporario',
        type: 'shareTemporaryLink',
        subjectId: itemId,
        lmsKey,
      });
    }
  }, []);

  const [countdown, setCountdown] = useState(30);
  const [startCountdown, setStartCountdown] = useState(false);
  const [showDownloadIcon, setShowDownloadIcon] = useState(false);

  useEffect(() => {
    let timer;

    if (!isButtonEnabled && startCountdown && countdown > 0) {
      timer = setTimeout(() => {
        setCountdown(prevCountdown => prevCountdown - 5);
      }, 5000);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [!isButtonEnabled, startCountdown, countdown]);

  const getOfflineItems = useCallback(
    async (itemId, itemName) => {
      try {
        const lmsKey = await getLmsKey();
        if (lmsKey) {
          // loading
          setLoading(true);
          setIsButtonEnabled(false);

          const lmsKeyValue = lmsKey.replace(/['"]+/g, '');
          const { data } = await downloadUnity(lmsKeyValue, itemId);

          const parsedData = `data:application/zip;base64,${data}`;
          const downloadLink = document.createElement('a');
          downloadLink.href = parsedData;
          downloadLink.download = `${itemName}.zip`;
          setLoading(false);
          downloadLink.click();

          setStartCountdown(true); // Alterado

          setTimeout(() => {
            setIsButtonEnabled(true);
            setCountdown(30); // Alterado
          }, 30000);
        }
        addToast({
          type: 'success',
          title: 'Arquivo baixado com sucesso',
        });
      } catch (error) {
        setLoading(false);

        addToast({
          type: 'error',
          title: 'Erro ao baixar o arquivo',
          description: 'Tente novamente mais tarde',
        });
        setShowDownloadIcon(true);
      }
    },
    [setIsButtonEnabled],
  );

  const allowedIdsForDownload = [
    '1f5c651a-d2cd-4825-b1a0-699364102a00', // aurea seller of content
    'c8ab0423-2722-4d8f-b2b7-cb2fbc8f265c', // ceuma seller
    'de246747-72ba-4779-9546-2c6094ea5d11', // degustação seller staging
  ];
  // CATEGORIA =====
  const renderItems = useCallback(() => {
    const itemCategories = Object.keys(formattedItems);

    if (itemCategories.length !== 0)
      return itemCategories.map((category: string) => (
        <Content key={generateKey()} Tiny={more[category]} className="slideUp">
          <h2>{category}</h2>
          <section>
            {formattedItems[category].map((item: IItems) => (
              <article key={generateKey()}>
                <main>
                  <h1>{item.title}</h1>
                  <p>{item.company_name}</p>
                </main>
                <footer>
                  <Link
                    to={`/seller/rented-unit/item/${
                      item.item_content_id || item.id
                    }`}
                  >
                    Ver
                  </Link>
                  {user.type !== 'seller_assistent' && (
                    <>
                      {user.seller ? (
                        <>
                          <button
                            type="button"
                            onClick={() => {
                              setSelectedItem({
                                title: item.title,
                                sellerId: seller.id,
                                itemId: item.item_id,
                                itemContentId: item.item_content_id,
                              });

                              toggleModalDisciplina();
                            }}
                          >
                            Add
                          </button>
                          {allowedIdsForDownload.includes(user.id) && (
                            <>
                              {user.id !==
                                'c8ab0423-2722-4d8f-b2b7-cb2fbc8f265c' && (
                                <button
                                  className="shareButton"
                                  type="button"
                                  onClick={() => handleShare(item.item_id)}
                                >
                                  <IconShare
                                    style={{ width: '60%', height: '60%' }}
                                  />
                                </button>
                              )}
                              {isButtonEnabled ? (
                                <button
                                  className="downloadButton"
                                  type="button"
                                  onClick={() =>
                                    getOfflineItems(item.item_id, item.title)
                                  }
                                >
                                  <IconDownload
                                    style={{ width: '100%', height: '100%' }}
                                  />
                                </button>
                              ) : (
                                <button
                                  className="downloadButton"
                                  type="button"
                                >
                                  {!showDownloadIcon ? (
                                    <div style={{ padding: '1.9px 1.9px' }}>
                                      {countdown}
                                    </div>
                                  ) : (
                                    <div>
                                      <IconDownload
                                        style={{
                                          width: '100%',
                                          height: '100%',
                                        }}
                                      />
                                    </div>
                                  )}
                                </button>
                              )}
                            </>
                          )}
                        </>
                      ) : (
                        <button
                          type="button"
                          onClick={() => {
                            setSelectedItem({
                              title: item.title,
                              itemId: item.item.id,
                              itemContentId: item.id,
                            });

                            toggleModalDisciplina();
                          }}
                        >
                          Add
                        </button>
                      )}
                    </>
                  )}
                </footer>
              </article>
            ))}
            <div className="grow">
              <button type="button" onClick={() => handleMore(category)}>
                <IconBookmarkBlack /> Mostrar mais
              </button>
            </div>
          </section>
        </Content>
      ));
  }, [
    isButtonEnabled,
    formattedItems,
    user.seller,
    more,
    handleMore,
    toggleModalDisciplina,
    seller,
    countdown,
    showDownloadIcon,
  ]);

  const handleUpdateUnityVersion = useCallback(
    async (itemId: string, title: string) => {
      const confirm = await AlertConfirm({
        title: `Tem certeza que deseja atualizar a unidade ${title} para a nova versão?`,
      });
      if (!confirm) {
        return;
      }

      try {
        await updateSellerUnity(itemId);
        clearFilter();
        addToast({
          title: 'Unidade atualiza com sucesso...',
          type: 'success',
          description: `Unidade ${title} foi atualiza para versão mais recente`,
        });
      } catch (error) {
        addToast({
          title: 'Ops...',
          type: 'error',
          description:
            'Algo inesperado aconteceu, por favor recarrege sua página.',
        });
      }
    },
    [addToast, clearFilter],
  );

  const debounced = useDebouncedCallback(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    value => {
      const textForSearch = value.trim();
      if (textForSearch.length <= 3) {
        return;
      }
      if (user.type === 'provider') {
        handleProviderSearch();
      } else {
        handleSellerSearch();
      }
    },
    500,
    { maxWait: 1000 },
  );

  useEffect(() => {
    const load = async (): Promise<void> => {
      setLoading(true);

      if (user.seller) {
        try {
          const { data } = await getSellerDataById(user.seller.id);
          setSeller(data);
        } catch (error) {
          addToast({
            title: 'Ops...',
            type: 'error',
            description:
              'Algo inesperado aconteceu, por favor recarrege sua página.',
          });
        }
      }

      if (user.type.includes('seller')) {
        const level = await getLevelOfLearning();
        setLevelOfLearning(level as []);

        const know = await getKnowledge();
        setKnowledges(know);

        // get data
        if (user.type === 'seller') {
          const { data: units } = await getSellerUnitysToUpdate();
          setUnitsToUpdate(units);
        }

        handleSellerSearch();
      }
    };

    load();
  }, [user, addToast]);

  useEffect(() => {
    if (!knowledges) return;

    user.seller ? handleSellerSearch() : handleProviderSearch();
  }, [selectedKnowledge]);

  return (
    <Holder>
      <Main>
        <MainContent className="blur">
          <NavTop />
          <Body className="body">
            <ModalDisciplina
              isOpen={modalDisciplinaOpen}
              setIsOpen={toggleModalDisciplina}
              selectedItem={selectedItem}
            />
            <Container className="slideDown">
              <Title>
                <h1>Meu Catálogo</h1>
                {user.seller && <h2>Unidades adquiridas</h2>}
              </Title>

              {loading && (
                <div className="global-loading">
                  <BeatLoader size={16} />
                </div>
              )}

              {unitsToUpdate && unitsToUpdate.length !== 0 && (
                <Content className="slideUp">
                  <h2>Unidades que foram atualizadas:</h2>
                  <section>
                    {unitsToUpdate.map(item => (
                      <article key={generateKey()}>
                        <main>
                          <h1>{item.title}</h1>
                          <p>{item.companyName}</p>
                          <p>Versão atual: {item.version}</p>
                        </main>
                        <footer style={{ textAlign: 'center' }}>
                          <Link to={`/seller/rented-unit/item/${item.id}`}>
                            Ver
                          </Link>
                          <button
                            type="button"
                            onClick={() =>
                              handleUpdateUnityVersion(item.itemId, item.title)
                            }
                          >
                            Atualizar
                          </button>
                        </footer>
                      </article>
                    ))}
                  </section>
                </Content>
              )}

              {!loading && renderItems()}

              {Object.keys(formattedItems).length <= 0 && !loading && (
                <div className="global-loading slideUp">
                  <p>Nenhum item encontrado.</p>
                </div>
              )}
            </Container>
          </Body>
        </MainContent>
      </Main>

      {user.type.includes('seller') || user.type === 'provider' ? (
        <RightSide className="slideLeft">
          <div>
            <h2>Filtrar unidades</h2>
            <h3>Pesquisar por nome de:</h3>
            <div className="radioContainer">
              <label htmlFor="unit">
                <input
                  type="radio"
                  id="units"
                  name="searchBy"
                  defaultChecked
                  onChange={e => {
                    handleFilterType(e);
                    debounced(search);
                  }}
                />
                Unidades
              </label>
              <label htmlFor="tag">
                <input
                  type="radio"
                  id="tags"
                  name="searchBy"
                  onChange={e => {
                    handleFilterType(e);
                    debounced(search);
                  }}
                />
                Tags
              </label>
            </div>
            <InputSearch
              name="search"
              onChange={e => {
                setSearch(e.target.value);
                debounced(e.target.value);
              }}
              placeholder="Pesquisar"
              value={search}
            />
          </div>
          {user.type !== 'provider' && (
            <>
              <div>
                <h3>Áreas de Conhecimento</h3>
                <div className="checkboxContainer" ref={inputParentKnowledge}>
                  {knowledges.map(
                    (
                      know: { label: string; value: string; type: string },
                      index: number,
                    ) => (
                      <label
                        className="checkboxContent"
                        htmlFor={`know-${index}`}
                        key={know.value}
                      >
                        {know.label}
                        <input
                          name=""
                          type="checkbox"
                          id={`know-${index}`}
                          onChange={() => handleKnowledge(know.value)}
                        />
                        <span className="checkmark" />
                      </label>
                    ),
                  )}
                </div>
              </div>
              <div>
                <h3>Nível de Aprendizagem</h3>
                <div className="checkboxContainer" ref={inputParentLevel}>
                  {levelOfLearning.map(
                    (level: { label: string; id: string }, index: number) => (
                      <label
                        className="checkboxContent"
                        htmlFor={`level-${index}`}
                        key={level.id}
                      >
                        {level.label}
                        <input
                          name=""
                          type="checkbox"
                          id={`level-${index}`}
                          onChange={() => handleLevel(level.id)}
                        />
                        <span className="checkmark" />
                      </label>
                    ),
                  )}
                </div>
              </div>
              {/* <div>
                <h3>Fornecedor</h3>
                <div className="checkboxContainer" ref={inputParentProvider}>
                  {providers.length === 0 && <p>Nenhum item encontrado.</p>}
                  {providers.map(
                    (
                      providerSeller: {
                        companyName: string;
                        id: string;
                        condition: string;
                      },
                      index: number,
                    ) => (
                      <label
                        className="checkboxContent"
                        htmlFor={`provider-${index}`}
                        key={providerSeller.companyName}
                      >
                        {providerSeller.condition === 'premium' && (
                          <IconCrownTiny />
                        )}{' '}
                        {providerSeller.companyName}
                        <input
                          name=""
                          type="checkbox"
                          id={`provider-${index}`}
                          onChange={() => handleProvider(providerSeller.id)}
                        />
                        <span className="checkmark" />
                      </label>
                    ),
                  )}
                </div>
              </div> */}
            </>
          )}
          <button type="button" onClick={clearFilter}>
            Limpar Filtros
          </button>
        </RightSide>
      ) : undefined}
    </Holder>
  );
};

export default StoreProvider;
