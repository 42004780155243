import styled from 'styled-components';

import {fadeIn} from '../../../styles/global';

export const Container = styled.div`
  padding: 18px 24px;
  h1 {
    margin-bottom: 18px;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

export const Box = styled.div`
  padding: 12px 18px;
  border: 1px dashed var(--gray-border);
  flex: 48%;
  margin: 0 16px 16px 0;
`;

export const ContentBox = styled.div`
  margin-top: 16px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  button {
    flex: 48%;
    background: var(--aurea);
    border: 1px solid var(--aurea);
    color: var(--dark);
    font-weight: bold;
    padding: 14px 24px;
    border-radius: 5px;
    width: 150px;
    margin: 0 8px 8px 0;
    transition: background 0.2s;
    &:hover {
      background: var(--hover);
      border-color: var(--hover);
    }
  }
`;

export const ContentPage = styled.div`
  animation: ${fadeIn} var(--animate) linear 1;
  > button {
    background: transparent;
    border: 0;
    padding: 12px 12px 12px 0;
    &:hover {
      text-decoration: underline;
    }
    &:before {
      content: '< ';
      font-size: 0.8rem;
      margin-right: 5px;
    }
  }

  > div {
    display: flex;
    flex-direction: column;
    width: 80%;
    margin: 0 auto;

    label {
      font-size: 1rem;
    }

    button {
      margin-top: 14px;
      align-self: flex-end;
    }
  }
`;

export const Input = styled.input`
  background: #fff;
  border: 1px solid var(--quaternary);
  border-radius: 5px;
  padding: 10px 14px;
  margin-top: 14px;
  width: 100%;
`;

export const Button = styled.button`
  background: var(--aurea);
  border: 1px solid var(--aurea);
  color: var(--dark);
  font-weight: bold;
  padding: 14px 24px;
  border-radius: 5px;
  width: 150px;
  margin-top: 16px;
  transition: background 0.2s;
  margin-top: 8px;
  align-self: flex-end;
  &:hover {
    background: var(--hover);
    border-color: var(--hover);
  }

  &.danger {
    background: var(--danger);
    border: 1px solid var(--danger);

    color: white;
  }
`;

// Content Modal
export const SectionGalery = styled.div`
  width: 100% !important;
  max-height: 500px;
  overflow-y: auto;
  padding: 8px;

  label {
    font-weight: 700;
    color: var(--gray);
  }

  .container-img {
    padding-top: 18px;
    .title {
      font-weight: 700;
      color: var(--gray);
    }

    .thumb-img {
      margin-top: 14px;
      width: 100%;
      height: 200px;
      border: 1px solid var(--gray-border);
      border-radius: 5px;

      background-color: var(--theme-border);
      background-position: center;
      background-size: cover;
      object-fit: cover;
    }

    button {
      background: var(--aurea);
      border: 1px solid var(--aurea);
      font-weight: 700;
      padding: 16px 32px;
      border-radius: 5px;
      transition: background 0.5s;
      align-self: flex-end;
      float: right;

      &:hover {
        background: var(--hover);
      }

      &:disabled {
        background: var(--aurea);
      }

      & + button {
        margin-left: 8px;
      }
    }
  }

  .content-tabs {
    margin-bottom: 16px;
    padding-bottom: 0px;
    border-bottom: 1px solid var(--gray-border);
  }

  .container-imges {
    margin-top: 8px;
    h2 {
      font-weight: 700;
      margin-bottom: 16px;
    }

    .content-show-galery {
      width: 100%;

      margin-top: 16px;
      padding: 8px;
      background: var(--theme-bg-gray);
      border-radius: 5px;
      border: 1px solid var(--gray-border);

      display: flex;
      flex-wrap: wrap;

      h3 {
        font-weight: 700;
        text-align: center;
        margin: 8px 0;
        width: 100%;
      }

      .galery-item {
        height: 100px;
        border: 3px solid var(--gray-border);
        border-radius: 5px;
        background: var(--theme-border);
        background-size: cover;
        flex: 0 0 31%;
        max-width: 30%;

        margin: 0 12px 12px 0;

        cursor: pointer;
      }

      .galery-paginate {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        button {
          background: var(--aurea);
          border: 1px solid var(--aurea);
          font-weight: 700;
          padding: 16px 32px;
          border-radius: 5px;
          transition: background 0.5s;

          &:hover {
            background: var(--hover);
          }

          &:disabled {
            background: var(--aurea);
          }

          & + button {
            margin-left: 8px;
          }
        }
      }

      ul {
        list-style: none;
        li {
          margin-bottom: 12px;
          cursor: pointer;
          &.active {
            text-decoration: underline;
          }
        }
      }
    }
  }

  .container-pdf {
    margin: 8px 0 8px 0;
    display: flex;
    align-items: center;

    .wrap-icon {
      padding: 12px 14px;
      background: var(--aurea);
      border-radius: 5px;
      margin-right: 12px;
    }
  }

  .container-list {
    margin-top: 20px;
    border: 1px solid var(--gray-border);
    border-radius: 5px;
    padding: 8px;

    .list-item {
      background: #f3f3f3;
      padding: 8px 8px 18px 8px;
      border-radius: 5px;
      margin: 8px 0 16px 0;
    }
  }

  .radio-marked {
    label {
      margin-left: 8px;
    }
    input[type='radio'] {
      position: relative;
      cursor: pointer;

      &:checked:after {
        content: '';
        display: block;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background-color: white;
        position: absolute;
        top: 1px;
        left: 3px;
      }

      &:checked:before {
        border: 2px solid var(--aurea);
        background-color: var(--aurea);
      }

      &::before {
        content: '';
        display: block;
        position: absolute;
        width: 16px;
        height: 16px;
        top: -3.5px;
        left: -1px;
        border: 2px solid rgba(141, 133, 133, 0.45);
        border-radius: 50%;
        background-color: white;
      }
    }
  }
`;
