import React, { useState, useEffect, useCallback } from 'react';
import { BeatLoader } from 'react-spinners';
import { Link, useParams } from 'react-router-dom';

import { useToast } from '../../hooks/toast';

import { Holder, Container, Content, Title } from './styles';
import { useAuth } from '../../hooks/auth';

import {
  Main,
  MainContent,
  Body,
} from '../../components/template/SellerTemplate/styles';
import NavTop from '../../components/template/NavTop';

import { IconBookmarkYellow } from '../../styles/icons';
import IconUser from '../../assets/icons/icon-user-circle-lg.svg';

import Render, { IContent } from '../../components/template/Render';

import {
  getProviderStoreDataById,
  getItemsContent,
  getSellerDataById,
} from '../../services/api';
import { useCart } from '../../hooks/cart';

interface IParams {
  id: string;
  idItem: string;
}

interface IProvider {
  loaded: boolean;
  id: string;
  name: string;
  description: string;
  email: string;
  cnpj: string;
  cpf: string;
  companyName: string;
  status:
    | 'active'
    | 'inative'
    | 'exclused'
    | 'review'
    | 'approved'
    | 'rejected'
    | 'pending'
    | 'draft';
  typePerson: 'Profissional Liberal' | 'Instituição de Ensino' | 'Fornecedor';
  condition: string;
  imgLogoUrl: string | null;
  isPrimium: boolean;
  unitCount: number | undefined;
  plan_value: string | number | undefined;
  priceInNumber: string | number | undefined;
  provider_total_items: number | undefined;
  isRented?: boolean;
}

interface ISeller {
  loaded: boolean;
  id: string;
  name: string;
  ownerId: string;
  cnpj: string;
  planId: string;
}

interface IItem {
  id: string;
  authors: Array<string>;
  mediaUrl: string;
  questions: string;
  bibliographicReference: Array<string>;
  introduction: string;
  learningObjectives: Array<string>;
  synthesis: string;
  title: string;
  levelOfLearning: Array<string>;
  knowledgeUniversity: {
    id: string;
  };
  knowledges: {
    id: string;
    title: string;
  }[];
  topics: string;
  status: string;
  item: {
    id: string;
  };
}

interface IRender {
  title: string;
  authors: Array<string>;
  mediaUrl: string;
  questions: string;
  knowlegdeLevel: Array<string>;
  knowlegdeArea1: string;
  introduction: string;
  learningObjectives: Array<string>;
  synthesis: string;
  bibliographicReference: Array<string>;
  content: IContent[];
}

const StoreItem: React.FC = () => {
  const { user } = useAuth();
  const { addToCart, cartItems } = useCart();

  const routeParames: IParams = useParams();
  const { addToast } = useToast();

  // SELLERS ===========
  const [seller, setSeller] = useState<ISeller>({} as ISeller);
  useEffect(() => {
    const load = async (): Promise<void> => {
      if (user.type === 'seller' && user.seller) {
        try {
          const { data: res } = await getSellerDataById(user.seller.id);
          setSeller(res);
        } catch (error) {
          addToast({
            title: 'Ops...',
            type: 'error',
            description:
              'Algo inesperado aconteceu, por favor recarrege sua página.',
          });
        }
      }
    };
    load();
  }, [user, addToast]);

  // PROVIDER ===========
  const [provider, setProvider] = useState<IProvider | { loaded: false }>({
    loaded: false,
  });
  const handleSetProvider = useCallback((providerItem: IProvider) => {
    const finalPrice = Number(providerItem.plan_value) || 10000;
    setProvider({
      ...providerItem,
      loaded: true,
      isPrimium: providerItem.condition === 'premium',
      unitCount: providerItem.unitCount || 104,
      priceInNumber: finalPrice,
      plan_value: finalPrice.toLocaleString('pt-br', {
        style: 'currency',
        currency: 'BRL',
      }),
    });
  }, []);

  useEffect(() => {
    const load = async (): Promise<void> => {
      try {
        const { data: res } = await getProviderStoreDataById(
          routeParames.id,
          seller.planId,
          seller.id,
        );
        handleSetProvider(res);
      } catch (error) {
        addToast({
          title: 'Ops...',
          type: 'error',
          description:
            'Algo inesperado aconteceu, por favor recarrege sua página.',
        });
      }
    };
    if (seller.planId) load();
  }, [handleSetProvider, addToast, routeParames, seller]);

  // ITEM ===========
  const [item, setItem] = useState<IRender>({
    title: '',
    authors: [''],
    mediaUrl: '',
    questions: '',
    knowlegdeLevel: [''],
    knowlegdeArea1: '',
    introduction: '',
    learningObjectives: [''],
    synthesis: '',
    bibliographicReference: [''],
    content: [
      {
        title: 'Tópico 01',
        sections: [{ content: '', type: 'txt' }],
      },
    ],
  });
  const handleSetItem = useCallback((unitItem: IItem) => {
    setItem({
      title: unitItem.title,
      authors: unitItem.authors,
      mediaUrl: unitItem.mediaUrl,
      questions: unitItem.questions,
      knowlegdeLevel: unitItem.levelOfLearning,
      knowlegdeArea1: '',
      introduction: unitItem.introduction,
      learningObjectives: unitItem.learningObjectives,
      bibliographicReference: unitItem.bibliographicReference,
      synthesis: unitItem.synthesis,
      content: JSON.parse(unitItem.topics) as IContent[],
    });
  }, []);
  useEffect(() => {
    const load = async (): Promise<void> => {
      try {
        const { data: res } = await getItemsContent(routeParames.idItem);
        handleSetItem(res.content);
      } catch (error) {
        addToast({
          title: 'Ops...',
          type: 'error',
          description:
            'Algo inesperado aconteceu, por favor recarrege sua página.',
        });
      }
    };
    load();
  }, [handleSetItem, addToast, routeParames]);

  // LOADING =====
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (provider.loaded === true && item.title !== '') setLoading(true);
  }, [setLoading, provider.loaded, item.title]);
  return (
    <Holder>
      <Main>
        <MainContent className="blur">
          <NavTop />
          <Body className="body">
            <Container className="slideDown">
              {loading ? (
                <>
                  <Title>
                    {provider.loaded === true && (
                      <div className="fadeIn">
                        <div className="bread-crumb">
                          <ul>
                            <li>
                              <Link to="/seller/store/">Loja</Link>
                            </li>
                            <li>
                              <Link
                                to={`/seller/store/provider/${provider.id}`}
                              >
                                {provider.companyName}
                              </Link>
                            </li>
                            <li>{item.title}</li>
                          </ul>
                        </div>
                        <div className="provider">
                          <img src={provider.imgLogoUrl || IconUser} alt="" />
                          <div>
                            <h1>{provider.companyName}</h1>
                            <div>
                              <div>
                                <p>{provider.description}</p>
                                <p className="units">
                                  <IconBookmarkYellow />{' '}
                                  <strong>
                                    {provider.provider_total_items}
                                  </strong>{' '}
                                  unidades
                                </p>
                              </div>
                              <div>
                                <p>
                                  <strong>{provider.plan_value}</strong>
                                  <span> /mês</span>
                                </p>
                                {provider.isRented ? (
                                  <Link
                                    className="rented"
                                    to="/seller/rented-units"
                                  >
                                    Alugado
                                  </Link>
                                ) : (
                                  <>
                                    {cartItems.find(
                                      cartItem => cartItem.id === provider.id,
                                    ) ? (
                                      <Link to="/seller/cart">
                                        Item no Carrinho
                                      </Link>
                                    ) : (
                                      <button
                                        onClick={() => addToCart(provider)}
                                        type="button"
                                      >
                                        Alugar
                                      </button>
                                    )}
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </Title>
                  <Content className="slideUp">
                    <h2>Unidade {item.title}</h2>
                    <section>
                      <Render render={item} currentTopic={0} />
                    </section>
                  </Content>
                </>
              ) : (
                <div className="global-loading">
                  <BeatLoader size={16} />
                </div>
              )}
            </Container>
          </Body>
        </MainContent>
      </Main>
    </Holder>
  );
};

export default StoreItem;
