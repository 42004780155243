/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
/* eslint-disable react/style-prop-object */
/* eslint-disable react/no-array-index-key */
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { RawEditorSettings } from 'tinymce';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import Input from '../../forms/Input';
import ButtonSubmit from '../../forms/Buttom';

import { ContentPage, SectionGalery, Button } from './styles';
import { IEditResource, IPages } from './index';
import Editor from '../../forms/Editor';
import { generateId } from '../../../utils/generateId';
import { useToast } from '../../../hooks/toast';

interface IProps {
  handlePage(page: IPages): void;
  propsEditor: RawEditorSettings;
  setIsOpen: () => void;
  editResource: IEditResource;
}

interface IList {
  id: string;
  title: string;
  description: string;
}

interface IOnChange {
  id: string;
  text: string;
  field: 'title' | 'description';
}

interface IQuestion {
  content: string;
  right: string;
  options: IList[];
}

const INIT_STATE: IList = {
  id: generateId(),
  title: '',
  description: '',
};

const FormList: React.FC<IProps> = ({
  handlePage,
  propsEditor,
  setIsOpen,
  editResource,
}) => {
  const [list, setList] = useState<IList[]>([INIT_STATE]);
  const [right, setRight] = useState('');

  const { addToast } = useToast();
  const formRef = useRef<FormHandles>(null);

  const isEdit = useMemo(() => {
    return Object.keys(editResource).length > 0;
  }, [editResource]);

  const editQuestion = useMemo<IQuestion>(() => {
    if (editResource.resource) {
      const recoverQuestion = JSON.parse(editResource.resource);

      return recoverQuestion;
    }
    return {} as IQuestion;
  }, [editResource.resource]);

  useEffect(() => {
    if (Object.keys(editQuestion).length > 0) {
      setList(editQuestion.options);
      setRight(editQuestion.right);
    }
  }, [editQuestion]);

  const handleSubmit = useCallback(
    async data => {
      const items = Object.values(data.item);
      const description = Object.values(data.description);
      const ids = Object.values(data.id);
      if (!data.content) {
        addToast({
          title: 'Ops ...',
          description: 'O enunciado deve ser preenchido',
          type: 'error',
        });
        return;
      }

      if (!data.right) {
        addToast({
          title: 'Ops ...',
          description: 'Alguma questão deve ser marcada como correta',
          type: 'error',
        });
        return;
      }

      if (items.filter(item => item === '').length > 0) {
        addToast({
          title: 'Ops ...',
          description: 'Todos os títulos devem ser preenchidos',
          type: 'error',
        });
        return;
      }

      if (description.filter(item => item === '').length > 0) {
        addToast({
          title: 'Ops ...',
          description: 'Todos as descrições devem ser preenchidos',
          type: 'error',
        });
        return;
      }

      const questionJSON = {
        title: data.title,
        content: data.content,
        options: items.map((item, i: number) => {
          return {
            id: ids[i],
            title: item,
            description: description[i],
          };
        }),
        right: data.right,
      };

      if (isEdit) {
        // editResource.element.remove();
        editResource.element.setAttribute(
          'data-resource',
          JSON.stringify(questionJSON),
        );
        propsEditor.insertContent(propsEditor.selection.getContent());
      } else {
        propsEditor.insertContent(
          `<div
          class="resource"
          data-interaction="true"
          data-type="question"
          data-subtype=""
          data-resource='${JSON.stringify(questionJSON).replace(
            /[']/g,
            '&#39;',
          )}'>
            Questão
          </div>\n
          <p>&nbsp;</p>`,
        );
      }

      propsEditor.focus();
      setIsOpen();
    },
    [addToast, propsEditor, setIsOpen, isEdit, editResource.element],
  );

  const handleAddItem = useCallback(() => {
    setList([
      ...list,
      {
        id: generateId(),
        title: '',
        description: '',
      },
    ]);
  }, [list]);

  const handleRemoveItem = useCallback(
    (id: string) => {
      if (list.length <= 1) {
        return;
      }
      const newList = list.filter(item => item.id !== id);
      setList(newList);
    },
    [list],
  );

  const handleOnChange = useCallback(
    ({ id, text, field }: IOnChange) => {
      const newList = list.map(item => {
        if (item.id === id) {
          return {
            ...item,
            title: field === 'title' ? text : item.title,
            description: field === 'description' ? text : item.description,
          };
        }
        return item;
      });

      setList(newList);
    },
    [list],
  );

  return (
    <Form
      ref={formRef}
      onSubmit={handleSubmit}
      initialData={{
        content: editQuestion.content,
        right: editQuestion.right,
      }}
    >
      <ContentPage>
        {!isEdit && (
          <button type="button" onClick={() => handlePage('main')}>
            Voltar
          </button>
        )}
        <SectionGalery>
          <h2>Questão</h2>

          <Editor
            label="Enunciado*"
            name="content"
            placeholder="Insira um enunciado (Obrigatório)"
          />
          <div className="container-list">
            <label htmlFor="list">Questões*</label>
            {list.map(item => {
              return (
                <div className="list-item" key={item.id}>
                  <div className="radio-marked">
                    {/* <label htmlFor={`right-${item.id}`}>Resposta Correta*</label> */}
                    <br />
                    <input
                      type="radio"
                      name="is_right"
                      id={`right-${item.id}`}
                      value={item.id}
                      checked={item.id === right}
                      onChange={e => setRight(e.target.value)}
                    />{' '}
                    <label htmlFor={`right-${item.id}`}>
                      É a respota correta?*
                    </label>
                  </div>
                  <Input
                    name={`id[${item.id}]`}
                    label=""
                    onChange={() => item.id}
                    value={item.id}
                    hidden
                  />
                  <Editor
                    id={item.id}
                    label="Alternativa*"
                    name={`item[${item.id}]`}
                    placeholder="Insira o texto da alternativa"
                    value={item.title}
                    onChange={e =>
                      handleOnChange({
                        id: item.id,
                        text: e.target.value,
                        field: 'title',
                      })
                    }
                  />
                  <Editor
                    label="Feedback*"
                    name={`description[${item.id}]`}
                    placeholder="Ex.: Resposta correta/Incorreta"
                    value={item.description}
                    onChange={e =>
                      handleOnChange({
                        id: item.id,
                        text: e.target.value,
                        field: 'description',
                      })
                    }
                  />

                  <Button
                    className="danger"
                    type="button"
                    onClick={() => handleRemoveItem(item.id)}
                  >
                    Remover Item
                  </Button>
                </div>
              );
            })}

            <Button type="button" onClick={() => handleAddItem()}>
              Add Item +
            </Button>
          </div>
          <Input
            hidden
            label=""
            value={right}
            onChange={() => right}
            name="right"
            defaultValue=""
          />
          <Input hidden label="" name="type" defaultValue="question" />
        </SectionGalery>
        <ButtonSubmit label="Inserir" type="submit" />
      </ContentPage>
    </Form>
  );
};

export default FormList;
