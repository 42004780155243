import React, { useState, useEffect, useCallback } from 'react';
import { BeatLoader } from 'react-spinners';
import { Link, useParams, useHistory } from 'react-router-dom';

import { useToast } from '../../hooks/toast';
import { Holder, Container, Title, ModalContent } from './styles';
import Modal from '../../components/template/Modal';

import {
  Main,
  MainContent,
  Body,
} from '../../components/template/SellerTemplate/styles';

import NavTop from '../../components/template/NavTop';
import Render, { IContent } from '../../components/template/Render';
import { getItemsContent, deleteSellerSubjectItem } from '../../services/api';
import { useAuth } from '../../hooks/auth';

interface IParams {
  id: string;
  idSubject: string;
  idSubjectItem: string;
}

interface IItem {
  id: string;
  authors: Array<string>;
  mediaUrl: string;
  questions: string;
  bibliographicReference: Array<string>;
  introduction: string;
  learningObjectives: Array<string>;
  synthesis: string;
  title: string;
  levelOfLearning: Array<string>;
  knowledgeUniversity: {
    id: string;
  };
  knowledges: {
    id: string;
    title: string;
  }[];
  topics: string;
  status: string;
  item: {
    id: string;
  };
}

interface IRender {
  title: string;
  authors: Array<string>;
  mediaUrl: string;
  questions: string;
  knowlegdeLevel: Array<string>;
  knowlegdeArea1: string;
  introduction: string;
  learningObjectives: Array<string>;
  synthesis: string;
  bibliographicReference: Array<string>;
  content: IContent[];
}

interface IItems {
  sellerId: string;
  itemId: string;
  itemContentId: string;
  title: string;
}

const StoreItem: React.FC = () => {
  const { user } = useAuth();
  const routeParames: IParams = useParams();
  const { addToast } = useToast();
  const history = useHistory();

  // ITEM ===========
  const [item, setItem] = useState<IRender>({
    title: '',
    authors: [''],
    mediaUrl: '',
    questions: '',
    knowlegdeLevel: [''],
    knowlegdeArea1: '',
    introduction: '',
    learningObjectives: [''],
    synthesis: '',
    bibliographicReference: [''],
    content: [
      {
        title: 'Tópico 01',
        sections: [{ content: '', type: 'txt' }],
      },
    ],
  });
  const handleSetItem = useCallback((unitItem: IItem) => {
    setItem({
      title: unitItem.title,
      authors: unitItem.authors,
      mediaUrl: unitItem.mediaUrl,
      questions: unitItem.questions,
      knowlegdeLevel: unitItem.levelOfLearning,
      knowlegdeArea1: '',
      introduction: unitItem.introduction,
      learningObjectives: unitItem.learningObjectives,
      bibliographicReference: unitItem.bibliographicReference,
      synthesis: unitItem.synthesis,
      content: JSON.parse(unitItem.topics) as IContent[],
    });
  }, []);
  useEffect(() => {
    const load = async (): Promise<void> => {
      try {
        const { data: res } = await getItemsContent(routeParames.id);
        handleSetItem(res.content);
      } catch (error) {
        addToast({
          title: 'Ops...',
          type: 'error',
          description:
            'Algo inesperado aconteceu, por favor recarrege sua página.',
        });
      }
    };
    load();
  }, [handleSetItem, addToast, routeParames]);

  // LOADING =====
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (item.title !== '') setLoading(true);
  }, [setLoading, item.title]);

  // REMOVE SUBJECT ======
  const [isOpen, setIsOpen] = useState(false);
  const handleRemoveSubject = useCallback(async () => {
    try {
      await deleteSellerSubjectItem(routeParames.idSubjectItem);
      history.push(`/seller/subject/${routeParames.idSubject}`);
    } catch (error) {
      addToast({
        title: 'Ops...',
        type: 'error',
        description:
          'Algo inesperado aconteceu, por favor recarrege sua página.',
      });
    }
    setIsOpen(!isOpen);
  }, [isOpen, addToast, routeParames, history]);
  const handleOpenModal = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  return (
    <Holder>
      <Main>
        <MainContent className="blur">
          <NavTop />
          <Body className="body">
            <Modal isOpen={isOpen} setIsOpen={() => handleOpenModal()}>
              <ModalContent cancel>
                <h1 className="modalTitle">
                  Tem certeza que deseja remover a unidade?
                </h1>
                <section>
                  <button type="button" onClick={() => handleRemoveSubject()}>
                    SIM
                  </button>
                  <button
                    type="button"
                    onClick={() => handleOpenModal()}
                    className="cancel"
                  >
                    NÃO
                  </button>
                </section>
              </ModalContent>
            </Modal>
            <Container className="slideDown">
              {loading ? (
                <>
                  <Title>
                    <div className="fadeIn">
                      <div className="bread-crumb">
                        <ul>
                          <li>
                            <Link to="/seller/manage-subjects">
                              Disciplinas
                            </Link>
                          </li>
                          <li>
                            <Link
                              to={`/seller/subject/${routeParames.idSubject}`}
                            >
                              Disciplina
                            </Link>
                          </li>
                          <li>{item.title}</li>
                        </ul>
                      </div>
                      <div className="provider">
                        <div>
                          <h2>
                            {item.title}
                            {item.questions && (
                              <p>
                                Banco de questões: para baixar clique{' '}
                                <a href={item.questions}>aqui</a> .
                              </p>
                            )}
                          </h2>
                          <div>
                            {item.knowlegdeLevel.map(knowledge => (
                              <span key={knowledge} className="tag">
                                {knowledge}
                              </span>
                            ))}
                          </div>
                        </div>

                        {user.type !== 'seller_assistent' && (
                          <button
                            type="button"
                            onClick={() => handleOpenModal()}
                          >
                            Remover
                          </button>
                        )}
                      </div>
                    </div>
                  </Title>
                  <Render className="slideUp" render={item} currentTopic={0} />
                </>
              ) : (
                <div className="global-loading">
                  <BeatLoader size={16} />
                </div>
              )}
            </Container>
          </Body>
        </MainContent>
      </Main>
    </Holder>
  );
};

export default StoreItem;
