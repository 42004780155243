import styled from 'styled-components';

export const Container = styled.div`
  width: 100vw;
  height: 100vh;
  overflow: hidden;
`;

export const StepPanel = styled.div`
  display: flex;
  flex-direction: row;

  padding: 28px 40px;

  height: 120px;
  width: 100%;
  background-color: var(--dark);
  color: var(--dark-soft);
  opacity: 0.95;

  /* button {
    background-color: transparent;
    border: 0;
    font-size: 1.125rem;
    font-weight: 900;
    color: white;
    text-transform: uppercase;
  } */
`;

export const ContentStep = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 80%;
  margin: 0 auto;
`;

export const ContentButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StepInfo = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
  /* cursor: pointer; */
  &.active {
    color: white;
    h1 {
      color: var(--aurea);
    }
    .step-number {
      border-color: var(--aurea);
    }
  }

  &.active::after {
    background-color: white;
  }
  &::after {
    content: '';
    display: block;
    position: absolute;
    right: -100px;
    width: 50%;
    height: 2px;
    background-color: var(--gray);
    opacity: 0.95;
  }
  &:last-child::after {
    display: none;
  }
`;

export const StepNumber = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 60px;
  height: 60px;

  border: 2px solid var(--dark-soft);
  border-radius: 50%;

  font-size: 1.875rem;
  font-weight: bold;
`;

export const ContentStepInfo = styled.div`
  margin-left: 16px;
  h1 {
    font-size: 1.125rem;
    text-transform: uppercase;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Card = styled.div`
  margin: 18px auto 12px auto;
  width: 95%;
  background-color: white;
  padding: 12px 32px 32px 32px;

  border-radius: 20px;
  flex: 1;
  h1 {
    font-size: 1.125rem;
    margin-bottom: 24px;
  }

  h1 ~ div {
    max-width: 100%;
  }

  /* textarea {
    width: 100%;
    border: 0;
    resize: none;
    height: 180px;
    &::placeholder {
      color: var(--gray-border);
    }
  } */

  &.tinymce {
    height: 300px;
    h1 {
      margin-bottom: 4px;
    }
    h2 {
      font-weight: bold;
      padding: 18px 36px;
      background: white;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
    }
    > div {
      height: 450px !important;
      border-width: 1px 0 0 0;
    }
  }
`;

export const ContentLeft = styled.div`
  width: 50%;
  height: calc(100vh - 120px);

  /* padding: 18px 0 0 0; */

  border-right: 1px solid var(--gray);

  display: flex;
  flex-direction: column;
  overflow-y: hidden;
  .tox.tox-tinymce {
    height: 100% !important;
  }
`;
export const ContentRight = styled.div`
  width: 50%;
  height: calc(100vh - 120px);

  background-color: white;
  border-left: 1px solid var(--gray);
`;

export const InputTitle = styled.div`
  display: flex;
  align-items: center;

  input {
    margin-left: 18px;
    padding: 14px;
    border: 0;
    border-bottom: 4px solid #fbe4b3;
    position: relative;
    font-weight: 900;
    font-size: 2.25rem;
    width: 100%;
    &::placeholder {
      font-weight: 900;
      color: var(--gray-border);
    }
  }
`;

export const WarpForm = styled.div`
  width: 100%;
  height: calc(100vh - 120px - 62px);
  overflow-y: auto;
`;

export const Bottom = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const ButtonNext = styled.button`
  padding: 18px;
  background-color: var(--aurea);
  border: 2px solid var(--aurea);
  text-transform: uppercase;
  width: 100%;
  font-weight: bold;
  font-size: 1.125rem;
`;

export const ButtonPrev = styled.button`
  padding: 18px;
  background-color: white;
  border: 2px solid var(--aurea);
  text-transform: uppercase;
  width: 100%;
  font-weight: bold;
  font-size: 1.125rem;
`;

export const InputAuthor = styled.div`
  margin-top: 32px;
  display: flex;
  > div {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }
`;

export const InputAuthorItem = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  flex: 0 0 45%;
  max-width: 45%;

  margin: 0px 0px 12px 12px;
  padding: 10px;
  border: 0;
  border-bottom: 2px solid var(--gray-border);
  input {
    width: 100%;

    font-size: 1.25rem;
    font-weight: 900;
    color: var(--gray);
    background-color: transparent;
    border: 0;
    &::placeholder {
      color: var(--gray-border);
    }
  }

  svg {
    cursor: pointer;
    align-self: flex-end;
  }
`;

export const SelectArea = styled.div`
  margin-top: 32px;
  display: flex;
  align-items: center;
  > div {
    margin-left: 8px;
    /* display: flex; */
    width: 50%;
    text-transform: uppercase;
    font-weight: bold;
    color: var(--gray);
    .select {
      width: 50%;
      height: auto;
      padding: 4px 16px;
      color: var(--gray-opacity);
      div {
        width: 100%;
      }

      ul {
        bottom: 46px;
        top: unset;
        color: var(--gray);
      }
    }
  }
  div {
    color: var(--gray);
    /* padding: 1px 2px 1px 5px; */
  }
`;

export const Second = styled.div``;

export const Third = styled.div`
  height: 100%;
`;

export const Fourth = styled.div``;

export const Section = styled.div`
  .thumb-img {
    margin: 14px 0px 40px 0px;
    width: 100%;
    height: 200px;
    border: 1px solid var(--gray-border);
    border-radius: 5px;
    background-color: var(--theme-border);
    background-position: center;
    background-size: cover;
    object-fit: cover;
  }
  label {
    display: block;
    color: var(--gray);
    width: 100%;
    text-align: center;
    margin: 10px 0;
    font-size: 22px;
    font-weight: 900;
  }
  .iconUrl {
    margin-top: 30px;
    color: var(--aurea);
    font-weight: 500;
  }
  input {
    margin-left: 12px;
    padding: 14px;
    border: 0;
    border-bottom: 2px solid var(--gray-border);
    position: relative;
    width: 85%;
    font-size: 1.05rem;
    font-weight: 900;
    color: var(--gray);
    &::placeholder {
      font-weight: 900;
      color: var(--gray-border);
    }
  }
`;
