/* eslint-disable react/require-default-props */
import React, { useState, useEffect, HTMLAttributes } from 'react';
import { useField } from '@unform/core';
import { Container, Content } from './styles';

interface IOption {
  id: string;
  label?: string;
}

interface IChoiceProps extends HTMLAttributes<HTMLInputElement> {
  label: string;
  name: string;
  options: IOption[];
  defaultValue?: any;
  multiple?: boolean;
}

export default function Choice(props: IChoiceProps): any {
  const { name, options, multiple, label, className, ...rest } = props;
  const { fieldName, registerField, error } = useField(name);
  const [value, setValue] = useState<any>();

  useEffect(() => {
    setValue(props.defaultValue);
  }, [props, setValue]);

  const threatAsCheckbox = !!(multiple || options.length === 1);
  const nativeField = threatAsCheckbox ? 'checkbox' : 'radio';

  function getValue(): any {
    if (multiple)
      return Array.from(value || []).length === 0 ? undefined : value;
    return value === '' ? undefined : value;
  }

  registerField({ name: fieldName, ref: getValue, getValue });

  function handleChange(e: React.ChangeEvent<HTMLInputElement>): any {
    const { checked: toAdd, value: fieldVal } = e.target;
    if (multiple) {
      const newVal = toAdd
        ? [...Array.from(value || []), fieldVal]
        : Array.from(value).filter(v => v !== e.target.value);
      setValue(newVal);
    } else {
      setValue(toAdd ? fieldVal : '');
    }
  }

  function checked(val: string): any {
    if (multiple) {
      return Array.from(value || []).includes(val);
    }
    return value === val;
  }

  return (
    <Container>
      <label>{label}</label>
      <Content className={className}>
        {options.map(option => {
          const checkboxId = `${fieldName}-${option.id}`;
          return (
            <div key={checkboxId}>
              <input
                {...rest}
                type={nativeField}
                id={checkboxId}
                name={fieldName}
                aria-label={checkboxId}
                onChange={handleChange}
                value={option.id}
                checked={checked(option.id)}
              />
              {option.label && (
                <label htmlFor={checkboxId}>{option.label}</label>
              )}
            </div>
          );
        })}
      </Content>

      {error && <small>{error}</small>}
    </Container>
  );
}
