/* eslint-disable no-nested-ternary */
import React, { Fragment, useEffect, useState } from 'react';
import { BeatLoader } from 'react-spinners';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import ReactPaginate from 'react-paginate';
import InputSearch from '../../components/forms/InputSearch';
import { TableContent } from '../../components/template/TemplateManager/styles';
import { useToast } from '../../hooks/toast';
import { getProviderRents } from '../../services/api';
import IconUser from '../../assets/icons/icon-user-circle-lg.svg';
import * as S from './styles';
import formatValue from '../../utils/formatValue';

interface IProviderRents {
  id: string;
  cancelResquested: boolean;
  status: string;
  value: string;
  createdAt: string;
  seller: {
    id: string;
    logoUrl: string | null;
    name: string;
  };
}

const ProvidersRents: React.FC = () => {
  const { addToast } = useToast();
  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState(false);
  const [providerRents, setProviderRents] = useState<IProviderRents[]>();

  useEffect(() => {
    const load = async (): Promise<void> => {
      try {
        setLoading(true);
        const { data: res } = await getProviderRents();
        setProviderRents(res);
      } catch (error) {
        addToast({
          title: 'Ops...',
          type: 'error',
          description:
            'Algo inesperado aconteceu, por favor recarrege sua página.',
        });
      } finally {
        setLoading(false);
      }
    };
    load();
  }, [addToast]);

  const [currentPage, setCurrentPage] = useState(0);

  const PER_PAGE = 5;

  const offset = currentPage * PER_PAGE;

  const pageCount = providerRents
    ? Math.ceil(providerRents.length / PER_PAGE)
    : 0;

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  function handlePageClick({ selected: selectedPage }) {
    setCurrentPage(selectedPage);
  }

  return (
    <S.Main>
      <S.MainContent>
        <S.Body>
          <S.Container>
            <S.Content>
              <S.Title>
                <h1>Meus alugueis</h1>
              </S.Title>
              <section className="search">
                <InputSearch
                  name="search"
                  onChange={e => setSearch(e.target.value)}
                  placeholder="Pesquisar"
                  value={search}
                />
              </section>
              <TableContent>
                {loading ? (
                  <div className="loading">
                    <BeatLoader size={16} />
                  </div>
                ) : (
                  <S.TableContainer>
                    <thead>
                      <tr>
                        <th>Instituições</th>
                        <th
                          style={{ textAlign: 'center', paddingRight: '20px' }}
                        >
                          Status
                        </th>
                        <th
                          style={{ textAlign: 'center', paddingRight: '20px' }}
                        >
                          Valor
                        </th>
                        <th style={{ textAlign: 'center' }}>
                          Data de aquisição
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {providerRents &&
                        providerRents
                          .slice(offset, offset + PER_PAGE)
                          .filter(item =>
                            item.seller.name.toLowerCase().includes(search),
                          )
                          .map(sellerRent => (
                            <Fragment key={sellerRent.seller.name}>
                              <S.TrContainer status={sellerRent.status}>
                                <td className="td-container">
                                  <div className="seller-container">
                                    <img
                                      src={
                                        sellerRent.seller.logoUrl
                                          ? sellerRent.seller.logoUrl
                                          : IconUser
                                      }
                                      alt=""
                                    />
                                    <p>{sellerRent.seller.name}</p>
                                  </div>
                                </td>
                                <td>
                                  {sellerRent.status === 'active' ? (
                                    <span className="status">ATIVO</span>
                                  ) : sellerRent.status === 'requested' ? (
                                    <span className="status">SOLICITADO</span>
                                  ) : (
                                    <span className="status">AGUARDANDO</span>
                                  )}
                                </td>
                                <td>
                                  <span className="price">
                                    <span>R$</span>
                                    {formatValue(sellerRent.value)}
                                  </span>
                                </td>
                                <td>
                                  <p style={{ fontSize: '0.75em' }}>
                                    {format(
                                      new Date(sellerRent.createdAt),
                                      "dd 'de' MMMM 'de' yyyy",
                                      {
                                        locale: ptBR,
                                      },
                                    )}
                                  </p>
                                </td>
                              </S.TrContainer>
                            </Fragment>
                          ))}
                    </tbody>
                  </S.TableContainer>
                )}
                <ReactPaginate
                  previousLabel="← Anterior"
                  nextLabel="Próximo →"
                  pageCount={pageCount}
                  onPageChange={handlePageClick}
                  containerClassName="pagination"
                  previousLinkClassName="pagination__link"
                  nextLinkClassName="pagination__link"
                  disabledClassName="pagination__link--disabled"
                  activeClassName="pagination__link--active"
                />
              </TableContent>
            </S.Content>
          </S.Container>
        </S.Body>
      </S.MainContent>
    </S.Main>
  );
};

export default ProvidersRents;
