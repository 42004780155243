import styled from 'styled-components';

export const Container = styled.div`
  max-width: 812px;
  margin: 0 auto 18px auto;
`;
export const Content = styled.div``;

export const FilterButton = styled.button`
  background: white;
  border: 1px solid var(--gray-border);
  border-radius: 5px;
  margin-left: 10px;
  margin-right: 20px;

  height: 40px;
  padding: 0 8px;
  display: flex;
  align-items: center;
  transition: border-color 0.2s;
  /* &:hover {
    border-color: var(--dark);
  } */
`;

export const PrimaryButton = styled.button`
  background: var(--aurea);
  padding: 7px 20px;

  font-weight: bold;
  font-size: 0.75rem;
  border: 0;
  border-radius: 5px;

  transition: background 0.4s;

  &:hover {
    background: var(--hover);
  }
`;

export const DelegateButton = styled.button`
  background: transparent;
  padding: 7px 4px;
  width: 94px;
  font-weight: bold;

  border: dashed var(--gray-border);
  border-width: 0 0 2px 0;

  text-transform: uppercase;
  color: var(--gray);

  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Status = styled.div`
  font-size: 0.75rem;
  color: var(--gray);
  font-weight: bold;
  text-transform: uppercase;

  margin-top: 8px;

  position: relative;
  padding: 4px 12px 4px 18px;
  background: var(--gray-border);
  border-radius: 5px;
  display: table-cell;
  bottom: -4px;

  & + div {
    left: 8px;
  }

  &.publish {
    background: var(--success-opcaity);
    color: var(--success);
  }

  &.approved {
    background: var(--success-opcaity);
    color: var(--success);
  }

  &.reproved {
    background: var(--danger-opacity);
    color: var(--danger);
  }

  &.correction {
    background: var(--warning-opacity);
    color: var(--warning);
  }
`;

export const OptionView = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const TooltipOptions = styled.div`
  position: relative;
  cursor: pointer;
  padding: 5px 0 5px 24px;
  span {
    width: 120px;
    background: var(--dark);
    padding: 8px 0px;
    border-radius: 4px;
    font-size: 14px;
    text-align: center;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.4s;
    position: absolute;
    top: 100%;
    z-index: 1;

    left: -22px;
    transform: translateX(-50%);
    color: #fff;

    &::before {
      content: '';
      border-style: solid;
      border-color: var(--dark) transparent;
      border-width: 6px 6px 0 6px;
      position: absolute;
      bottom: 100%;
      right: 0;
      transform: translateX(-50%) rotate(180deg);
    }

    button {
      background: transparent;
      color: white;
      border: 0;
      padding: 5px 0;
      width: 100%;
      &:hover {
        background: var(--gray);
      }
    }
  }

  &:hover span {
    opacity: 1;
    visibility: visible;
  }
`;

export const ButtonsDelegate = styled.div`
  display: flex;
  align-items: center;

  img {
    width: 32px;
    height: 32px;
    border: 1px solid white;
    border-radius: 50%;
  }

  button {
    width: 32px;
    height: 32px;
    background: white;
    border: 1px solid var(--gray-border);
    border-radius: 50%;
  }

  div:nth-child(2) {
    position: relative;
    left: -12px;
  }

  div:last-child {
    position: relative;
    left: -24px;
  }

  > div {
    height: 30px;
  }
`;

export const TooltipInfo = styled.div`
  position: relative;
  cursor: pointer;
  /* padding: 5px 0 5px 0; */

  span {
    width: 100px;
    background: var(--dark);
    padding: 8px 10px;
    border-radius: 4px;
    font-size: 14px !important;
    text-align: center;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.4s;
    position: absolute;
    top: calc(100% + 10px);

    left: 50px;
    transform: translateX(-50%);
    color: #fff !important;
    z-index: 1;

    &::before {
      content: '';
      border-style: solid;
      border-color: var(--dark) transparent;
      border-width: 6px 6px 0 6px;
      position: absolute;
      bottom: 100%;
      right: 70px;
      transform: translateX(-50%) rotate(180deg);
    }
  }

  &:hover span {
    opacity: 1;
    visibility: visible;
  }
`;

export const ContentReview = styled.div`
  display: flex;
  margin-top: 28px;
`;

export const ContentLeft = styled.div`
  width: calc(100% - 290px);
  padding-right: 20px;
`;

export const ContentRight = styled.div`
  width: 290px;
  height: 100%;
`;

export const ContentLargeButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 23px;
`;

export const LargeButton = styled.button`
  height: 50px;
  width: 140px;

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 5px;
  border: 0;
  font-weight: bold;

  background: var(--aurea);
  color: var(--dark);
  transition: background 0.2s;
  &:hover {
    background: var(--hover);
  }

  &.disapproved {
    background: var(--danger);
    color: white;

    &:hover {
      background: var(--danger);
    }
  }

  &:disabled {
    opacity: 0.7;
  }
`;

export const Card = styled.div`
  margin-top: 10px;
  padding-bottom: 19px;

  border-radius: 5px;
  background: white;
  border: 1px solid var(--gray-border);
`;

export const CardTitle = styled.div`
  display: flex;
  padding: 18px 16px 0 16px;

  justify-content: space-between;
  div {
    h2 {
      font-size: 1rem;
      font-weight: bold;
      text-transform: uppercase;
      display: inline-block;
      vertical-align: middle;
      margin-right: 30px;
    }

    span {
      font-size: 0.75rem;
      color: var(--gray);
      strong {
        font-weight: bold;
      }
    }

    button {
      background: transparent;
      text-decoration: underline;
      font-size: 0.75rem;
      color: var(--gray);
      border: 0;
    }
    .buttonEdit {
      border: 1px solid #f6c866;
      color: #3d3939;
      width: 60px;
      font-weight: 700;
      font-size: 16;
      padding: 10px;
      border-radius: 5px;
      background: #f6c866;
      display: inline-block;
      vertical-align: middle;
    }
  }

  &.coment-title {
    padding-bottom: 18px;
    border-bottom: 1px solid var(--gray-border);
  }
`;

export const CardBody = styled.div`
  padding-top: 20px;
`;

export const CardItem = styled.div`
  display: flex;
  align-items: center;
  padding: 5px 16px 8px 16px;
  /* padding-bottom: 12px; */
  & + div {
    border-top: 1px solid var(--gray-border);
    padding-top: 12px;
  }

  .icon {
    width: 20px;
    margin-right: 8px;
    display: flex;
    justify-content: center;
  }

  .options {
    display: flex;
    justify-content: space-between;
    flex: 1;
    div {
      display: flex;
      flex-direction: column;
      strong {
        font-weight: bold;
      }
      > span {
        color: var(--gray);
        font-size: 0.75rem;

        &.large-font {
          font-size: 1rem;
        }
      }
    }
  }
  &.pt-0 {
    padding-top: 0px;
  }

  &.pb-0 {
    padding-bottom: 0px;
  }

  .responsibles {
    margin-top: 8px;
    display: flex;
    img {
      border-radius: 50%;
      border: 1px solid white;
    }

    div:last-child {
      position: relative;
      left: -12px;
    }
  }

  .infos {
    strong {
      color: var(--gray);
      font-weight: bold;
      text-align: left;

      font-size: 0.75rem;
    }

    p {
      color: var(--gray);
      text-align: left;
      font-size: 0.75rem;

      margin-bottom: 10px;
    }
  }
`;

export const CardFooter = styled.div`
  padding: 0px 16px 0px 16px;
  width: 100%;
  text-align: center;
  /* margin-bottom: 6px; */

  &.b-0 {
    border: 0;
  }
`;

export const ButtonWhite = styled.button`
  padding: 6px 0;
  margin: 0 auto;
  width: 238px;

  background: white;

  border: 1px solid var(--gray-border);
  border-radius: 5px;

  font-size: 0.75rem;
  font-weight: bold;

  color: var(--dark);
  text-align: center;

  &:disabled {
    color: rgb(61 57 57 / 0.4);
  }
`;

export const InputSendMensage = styled.div`
  width: 100%;
  margin-top: 12px;
  padding: 6px 10px 6px 16px;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  background: var(--hover-menu);
  input {
    flex: 1;
    background: transparent;
    border: 0;
    font-size: 0.75rem;
    padding: 0 6px 0 0;
  }

  button {
    background: transparent;
    border: 0;
    padding: 0;
    margin: 0;
  }
`;

export const ContentButtonsMode = styled.div`
  width: 100%;
  margin-bottom: 23px;
  display: flex;
`;

export const ButtonMode = styled.button`
  background: transparent;
  border: 1px solid var(--gray-border);
  height: 50px;
  text-align: center;
  color: var(--dark);
  font-weight: bold;
  border-radius: 5px;
  transition: border 0.2s;
  flex: 0.5;

  &:hover {
    border: 1px solid var(--dark);
  }

  &.active {
    background: var(--aurea);
    border: 1px solid var(--aurea);
    transition: background 0.2s;

    &:hover {
      background: var(--hover);
    }
  }

  &.preview {
    border-bottom-left-radius: 5px;
    border-top-left-radius: 5px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  &.edit {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }
`;

export const ContentPreview = styled.div``;
export const ContentEditor = styled.div``;

export const ContentTabHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  > button {
    background: var(--aurea);
    color: white;
    font-weight: bold;
    border: 0;
    border-radius: 5px;
    height: 36px;

    padding: 0 11px;
    transition: background 0.2s;
    &:hover {
      background: var(--hover);
    }

    &:disabled {
      opacity: 0.8;
    }
  }
`;

export const ContentTab = styled.div`
  margin-bottom: 16px;
  padding-bottom: 0px;
  border-bottom: 1px solid var(--gray-border);
  padding-right: 20px;
`;

export const ContentTabItem = styled.div`
  h2 {
    font-weight: bold;
    padding: 18px 36px;
    background: white;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
  > div {
    border: 0;
  }

  .bg-white {
    > div {
      margin-top: 0;
      max-width: 95%;
    }
    background: white;
    padding: 0px 0px 10px 16px;
    width: 100%;
  }

  .inputSingle {
    width: 95%;

    font-size: 1.25rem;
    font-weight: 900;
    color: var(--gray);
    margin: 0px 0px 12px 12px;
    padding: 10px;
    background-color: transparent;
    border: 0;
    border-bottom: 2px solid var(--gray-border);
    &::placeholder {
      color: var(--gray-border);
    }
  }
`;

export const InputMany = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  flex: 0 0 45%;
  max-width: 90%;

  margin: 0px 0px 12px 12px;
  padding: 10px;
  border-bottom: 2px solid var(--gray-border);

  input {
    width: 100%;

    font-size: 1.25rem;
    font-weight: 900;
    color: var(--gray);
    background-color: transparent;
    border: 0;
    &::placeholder {
      color: var(--gray-border);
    }
  }
`;
