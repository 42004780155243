import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

export const Container = styled.div`
  display: flex;
  width: 100%;
  max-width: 280px;
  min-height: 100vh;
  background: #fff;
  flex-direction: column;
  border-right: 1px solid var(--gray-border);
  z-index: 10;
  overflow-y: auto;
  min-width: 200px;
  max-height: calc(100vh - 100px);
  overflow-y: auto;
`;

export const Brand = styled.div`
  padding: 25px 25px 25px;
`;

export const MenuLink = styled(NavLink)`
  text-decoration: none !important;
`;

export const MenuItem = styled.li`
  list-style: none;

  border-left: 5px solid transparent;
  transition: 0.4s;

  &:hover {
    border-color: var(--aurea);
    background: var(--hover-menu);
  }

  a {
    display: flex;
    align-items: center;

    color: var(--dark);
    font-size: 16px; 
    line-height: 18px; 
    letter-spacing: 0.2px;
    padding: 12px 18px; 
    padding-left: 20px;
    text-decoration: none !important;
  }

  svg {
      width: 25px;
      height: 25px;
      color: var(--aurea);
      margin-right: 12px; 
    }

    span {
      flex: 1;
    }
`;
