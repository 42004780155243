/* eslint-disable no-useless-escape */
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { Link, useHistory } from 'react-router-dom';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import {
  Container,
  Content,
  ContentForm,
  ContentBottom,
  SelectProfile,
  SelectProfileContent,
  Card,
  IconBookAddress,
  SelectForm,
  SelectFormCircle,
  AltText,
  IconAlertAlt,
} from './styles';
import { Image, File } from '../Profile/styles';
import BackgroundSign from '../../components/template/BackgroundSign';
import RowGroup from '../../components/forms/RowGroup';
import Input from '../../components/forms/Input';
import InputMask from '../../components/forms/InputMask';
import { UploadProfile, UploadProfileContent } from '../Profile/styles';
import {
  IconErrorCircle,
  IconUsersMult,
  IconUsers,
  IconUser,
} from '../../styles/icons';
import IconUserCircleLg from '../../assets/icons/icon-user-circle-lg.svg';
import { BottomForm } from '../SignIn/styles';
import Buttom from '../../components/forms/Buttom';
import ChoiceRadio from '../../components/forms/ChoiceRadioForm';
import { useToast } from '../../hooks/toast';
import getValidationErrors from '../../utils/getVaidationErrors';
import {
  signupSeller,
  getPlansProvider,
  putSendPicture,
} from '../../services/api';

export interface IPlans {
  id: string;
  name: string;
  description: string;
  minRent: number;
  maxRent: number;
  createdAt: string;
}

const SignUp: React.FC = () => {
  const history = useHistory();
  const formRef = useRef<FormHandles>(null);
  const img = useRef<HTMLImageElement>(null);
  const { addToast } = useToast();
  const [currentPage, setCurrentPage] = useState(0);
  const [file, setFile] = useState<FileList | null>(null);

  useEffect(() => {
    if (file) {
      const reader = new FileReader();
      reader.onload = e => {
        img.current?.setAttribute('src', e.target?.result as string);
      };
      reader.readAsDataURL(file[0]);
    }
  }, [file]);

  const [plans, setPlans] = useState<IPlans[]>([]);
  useEffect(() => {
    async function Action() {
      const { data: res } = await getPlansProvider({});
      setPlans(res);
    }
    Action();
  }, []);

  const uploadName = useMemo(() => {
    if (file) {
      const ext = file[0].name.split(/\.(?=[^\.]+$)/);
      return `${file[0].name.substr(0, 6)}..._.${ext[1]}`;
    }
    return 'Escolher Imagem';
  }, [file]);

  const handleCleanImg = useCallback(() => {
    setFile(null);
    img.current?.setAttribute('src', IconUserCircleLg);
  }, []);

  const handleSubmit = useCallback(
    async (data: any) => {
      if (currentPage === 0) {
        try {
          const schemaFirst = Yup.object().shape({
            email: Yup.string()
              .required('Campo Obrigatório')
              .email('Email inválido'),
            name: Yup.string().required('Campo Obrigatório'),
            seller: Yup.object().shape({
              cnpj: Yup.string().required('Campo Obrigatório'),
              companyName: Yup.string().required('Campo Obrigatório'),
            }),
          });
          await schemaFirst.validate(data, {
            abortEarly: false,
          });

          setCurrentPage(1);
          return;
        } catch (error) {
          const errors = getValidationErrors(error);
          formRef.current?.setErrors(errors);
          addToast({
            title: 'Ops ...',
            type: 'error',
            description: 'Verifique todos os campos!',
          });
          return;
        }
      }

      try {
        const schemaFirst = Yup.object().shape({
          seller: Yup.object().shape({
            plan: Yup.string().required('Campo Obrigatório'),
          }),
        });
        await schemaFirst.validate(data, {
          abortEarly: false,
        });

        console.log(data);

        const { data: sellerData } = await signupSeller({
          nameUser: data.name,
          email: data.email,
          password: data.password,
          sellerName: data.seller.companyName,
          cnpj: data.seller.cnpj,
          // logoUrl: '',
        });

        console.log('sellerData', sellerData);

        if (file) {
          const formData = new FormData();
          formData.append('images', file[0]);
          const config = {
            headers: {
              'content-type': 'multipart/form-data',
            },
          };
          await putSendPicture(sellerData.user.id, formData, config);
        }

        history.push('/');
        addToast({
          title: 'Sucesso',
          type: 'success',
          description: 'Usuário cadastrado com sucesso',
        });
      } catch (error) {
        if (error.errors) {
          const errors = getValidationErrors(error);
          formRef.current?.setErrors(errors);
        }
        addToast({
          title: 'Ops ...',
          type: 'error',
          description: 'Verifique todos os campos!',
        });
        return;
      }
    },
    [currentPage, addToast, history, file],
  );

  const renderForm = useCallback(() => {
    return (
      <>
        <div style={{ display: currentPage === 0 ? 'block' : 'none' }}>
          <RowGroup>
            <Input name="name" label="Seu nome:*" placeholder="Jhon Doe" />
            <InputMask
              name="seller.cnpj"
              label="CNPJ da Instituição:*"
              type="text"
              placeholder="00.000.000/0001-00"
              mask="99.999.999/9999-99"
            />
          </RowGroup>

          <Input
            name="seller.companyName"
            label="Nome da instituição:*"
            type="text"
            placeholder="Realize Tecnologia em Educação"
          />
          <Input
            name="email"
            label="Seu Email:*"
            type="text"
            placeholder="email@dominio.com.br"
          />
          <AltText>
            <IconAlertAlt />
            <label>
              Você receberá no e-mail cadastrado um link de confirmação e após
              confirmar, você receberá sua senha para acesso ao sistema.
            </label>
          </AltText>

          <UploadProfile>
            <label>Logotipo da Instituição:</label>
            <UploadProfileContent>
              <Image>
                <img src={IconUserCircleLg} ref={img} width="132" alt="" />
                <IconErrorCircle onClick={() => handleCleanImg()} />
              </Image>
              <File style={{ width: '64%' }}>
                <label htmlFor="file">{uploadName}</label>
                <input
                  type="file"
                  name="file"
                  id="file"
                  onChange={e => {
                    setFile(e.target.files);
                  }}
                />
                <p>Imagens de no máximo 10MB</p>
              </File>
            </UploadProfileContent>
          </UploadProfile>

          <BottomForm>
            <Buttom label="Avançar" block />
          </BottomForm>
        </div>
        <div style={{ display: currentPage === 1 ? `block` : `none` }}>
          <ChoiceRadio
            name="seller.plan"
            label="Quantidade de alunos na instituição:*"
            options={plans.map((plan, index) => {
              return {
                value: plan.id,
                label: `De <strong>${plan.minRent}</strong> a <strong>${plan.maxRent}</strong> alunos`,
                icon:
                  index === 0 ? (
                    <IconUser height="16.03" width="14.25" />
                  ) : index === 1 ? (
                    <IconUsers height="16.03" width="19.59" />
                  ) : (
                    <IconUsersMult height="16.03" width="25.23" />
                  ),
              };
            })}
          />

          <BottomForm>
            <Buttom
              label="Voltar"
              type="button"
              onClick={() => setCurrentPage(0)}
              block
              outline
            />
            <Buttom label="Concluir" block />
          </BottomForm>
        </div>
        <SelectForm>
          <SelectFormCircle
            onClick={() => setCurrentPage(0)}
            className="selected"
          >
            1
          </SelectFormCircle>
          <SelectFormCircle
            onClick={() => formRef.current?.submitForm()}
            className={`${currentPage ? `selected` : ``}`}
          >
            2
          </SelectFormCircle>
        </SelectForm>
      </>
    );
  }, [currentPage, handleCleanImg, uploadName, plans]);

  return (
    <Container>
      <BackgroundSign />
      <Content className="fadeIn">
        <SelectProfile>
          <SelectProfileContent>
            <Link to="/signup">
              <Card>Fornecedor</Card>
            </Link>
            <Link to="/signup/seller">
              <Card isSelected>
                <IconBookAddress />
                Instituição
              </Card>
            </Link>
          </SelectProfileContent>
        </SelectProfile>

        <ContentForm>
          <h1>Cadastre-se</h1>
          <Form ref={formRef} onSubmit={handleSubmit}>
            {renderForm()}
          </Form>
        </ContentForm>
        <ContentBottom>
          Já tem cadastro? <Link to="/">Faça seu login</Link>
        </ContentBottom>
      </Content>
    </Container>
  );
};

export default SignUp;
