import styled from 'styled-components';

export const Holder = styled.div`
    margin: 20px 5vw;
`;

export const CardGrid = styled.div`
    margin-top: 20px;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 10px;
`;

export const Card = styled.div`
    padding: 20px;
    background: #fff;
    border: 1px solid var(--quaternary);
    border-radius: 5px;
    margin-bottom: 10px;

    h2{
        margin-bottom: 10px;
        font-weight: bold;
    }
  
    .video-responsive {
        overflow: hidden;
        padding-bottom: 56.25%;
        position: relative;
        height: 0;
    }

    .video-responsive iframe {
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        position: absolute;
    }
`;