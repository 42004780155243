/* eslint-disable import/no-extraneous-dependencies */
import React, { createContext, useState, useCallback, useContext } from 'react';
import { v4 as uuid } from 'uuid';

import ToastContainer from '../components/template/ToastContainer';

export interface IToastMessage {
  id: string;
  title: string;
  type?: 'success' | 'error' | 'info';
  description?: string;
}

interface IToastContextData {
  addToast(obj: Omit<IToastMessage, 'id'>): void;
  removeToast(id: string): void;
}

const ToastContext = createContext({} as IToastContextData);

export const ToastProvider: React.FC = ({ children }) => {
  const [message, setMessage] = useState<IToastMessage[]>([]);

  const addToast = useCallback(
    ({
      type = 'info',
      title,
      description,
    }: Omit<IToastMessage, 'id'>): void => {
      const toast = {
        id: uuid(),
        type,
        title,
        description,
      };

      setMessage(state => [...state, toast]);
    },
    [],
  );

  const removeToast = useCallback((id: string) => {
    setMessage(state => state.filter(item => item.id !== id));
  }, []);

  return (
    <ToastContext.Provider value={{ addToast, removeToast }}>
      {children}
      <ToastContainer messages={message} />
    </ToastContext.Provider>
  );
};

export const useToast = (): IToastContextData => {
  const context = useContext(ToastContext);

  if (!context) {
    throw Error('useToast must be used whithin a ToastProvider');
  }

  return context;
};
