/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import ReactPaginate from 'react-paginate';
import moment from 'moment';
import {
  Container,
  Content,
  Bloco,
  DateContainer,
  TitleLeft,
  Providers,
} from './styles';
import { useAuth } from '../../hooks/auth';
import IProviderDTO from '../../interfaces/ProviderDTO';

import { findUnitsPublished, getProviderDataById } from '../../services/api';

import logoYellow from '../../assets/images/logo-new-yellow.svg';

import imgPages from '../../assets/images/pages.svg';
import imgStudents from '../../assets/images/students.svg';
import imgInstitution from '../../assets/images/institution.svg';

import iconUserAlt from '../../assets/icons/icon-user-alt.svg';

import iconDepartmentStore from '../../assets/icons/icon-department-store.svg';
import iconThList from '../../assets/icons/icon-th-list.svg';
import iconOpenBook from '../../assets/icons/icon-open-book.svg';
import iconSettings from '../../assets/icons/icon-settings.svg';
import DashboardProvider from '../../components/template/DashboardProvider';
import {
  ApprovedItem,
  ApprovedList,
  ItemTitle,
  KnowledgeItem,
  RentsList,
} from '../../components/template/DashboardProvider/styles';

export interface IUnits {
  active: boolean;
  content_id: string;
  created_at: string;
  curator_id: string;
  curator_status: string;
  curator_avatar_url: string;
  curator_name: string;
  id: string;
  item_content_id: string;
  item_content_knowledges: string;
  item_content_level_of_learning: string[];
  item_content_status: string;
  item_content_title: string;
  manager_id: string;
  manager_status: string;
  reviewer_id: string;
  reviewer_status: string;
  stage: string;
  updated_at: string;
  provider_name: string;
  providers: string;
}

const Dashboard: React.FC = () => {
  const history = useHistory();
  const { user } = useAuth();
  const [provider, setProvider] = useState<IProviderDTO>();
  const [unitPublished, setUnitPublished] = useState<IUnits[]>();
  console.log('unitPublished', unitPublished);

  const [currentPage, setCurrentPage] = useState(0);

  const PER_PAGE = 10;

  const offset = currentPage * PER_PAGE;
  const pageCount = unitPublished
    ? Math.ceil(unitPublished.length / PER_PAGE)
    : 0;

  useEffect(() => {
    async function getProvider(): Promise<void> {
      const providerFromDB: IProviderDTO = (
        await getProviderDataById(user?.provider?.id as string)
      ).data;
      setProvider(providerFromDB);
    }
    if (user && user.type === 'provider') getProvider();
  }, [user]);

  useEffect(() => {
    async function getUnitPublished(): Promise<void> {
      console.log('entrou');
      const units: IUnits[] = (await findUnitsPublished()).data;
      setUnitPublished(units);
    }
    if (
      user.type !== 'provider' &&
      user.type !== 'seller_assistent' &&
      user.type !== 'editor' &&
      user.type !== 'admin' &&
      user.type !== 'manager' &&
      user.type !== 'curator'
    ) {
      getUnitPublished();
    }
  }, [user]);

  function handlePageClick({ selected: selectedPage }): void {
    setCurrentPage(selectedPage);
  }
  return (
    <>
      {user.type === 'provider' && provider?.status !== 'review' && (
        <Content style={{ maxWidth: '100%', display: 'inline' }}>
          <DashboardProvider />
        </Content>
      )}
      <Container className="slideDown">
        <Content>
          {user.type === 'provider' && provider?.status === 'review' ? (
            <Bloco Border>
              <h2>Olá, {user.name}!</h2>
              <p>Seu perfil está em análise no momento.</p>
              <p>
                Você será notificado por e-mail assim que a análise for
                concluída.
                <br />
                Nosso prazo é de no máximo 48h.{' '}
              </p>
              <img src={logoYellow} alt="" />
            </Bloco>
          ) : user.type !== 'provider' ? (
            <Bloco Border>
              <h2>Olá, {user.name}!</h2>
              <p>
                Seja bem-vind@ ao <strong>Sistema Aurea</strong>.
              </p>
              <p>
                Estamos trabalhando para melhorar ainda mais a sua experiência!
              </p>
              <img src={logoYellow} alt="" />
            </Bloco>
          ) : undefined}
        </Content>

        {(user.type === 'seller' || user.type === 'seller_assistent') && (
          <>
            <Content>
              <Bloco
                Border
                Inverted
                Third
                onClick={() => history.push(`/dashboard/profile`)}
              >
                <img src={iconUserAlt} alt="" />
                <h2>Meu Perfil</h2>
              </Bloco>
              <Bloco
                Border
                Inverted
                Third
                Left
                onClick={() => history.push(`/seller/store`)}
              >
                <img src={iconDepartmentStore} alt="" />
                <h2>Loja de Fornecedores</h2>
              </Bloco>
              <Bloco
                Border
                Inverted
                Third
                Left
                onClick={() => history.push(`/seller/rented-units`)}
              >
                <img src={iconThList} alt="" />
                <h2>Meu Catálogo</h2>
              </Bloco>
            </Content>
            <Content>
              <Bloco
                Border
                Inverted
                Third
                onClick={() => history.push(`/seller/manage-subjects`)}
              >
                <img src={iconOpenBook} alt="" />
                <h2>Gerenciar Disciplinas</h2>
              </Bloco>

              <Bloco
                Border
                Inverted
                Third
                Left
                onClick={() => history.push(`/seller/rents`)}
              >
                <img src={iconSettings} alt="" />
                <h2>Meus Aluguéis</h2>
              </Bloco>
              <Bloco Border Inverted Third Left Hidden />
            </Content>
          </>
        )}

        {user.type !== 'provider' &&
          user.type !== 'seller' &&
          user.type !== 'seller_assistent' && (
            <Content>
              <Bloco Border Inverted Third>
                <img src={imgPages} alt="" />
                <h2>Compartilhar</h2>
              </Bloco>
              <Bloco Border Inverted Third Left>
                <img src={imgInstitution} alt="" />
                <h2>Conhecer</h2>
              </Bloco>
              <Bloco Border Inverted Third Left>
                <img src={imgStudents} alt="" />
                <h2>Aprender</h2>
              </Bloco>
            </Content>
          )}

        {user.type !== 'provider' &&
          user.type !== 'seller_assistent' &&
          user.type !== 'editor' &&
          user.type !== 'admin' &&
          user.type !== 'manager' &&
          user.type !== 'curator' &&
          unitPublished &&
          unitPublished.length !== 0 && (
            <Content>
              <Bloco>
                <ApprovedList>
                  <TitleLeft>
                    <h3 className="title-published">Datad da postagem</h3>
                    <h3 className="title-units">Unidade - Área</h3>
                    <h3 className="title-provider">Fornecedores</h3>
                  </TitleLeft>
                  <br />
                  {unitPublished && (
                    <RentsList>
                      {unitPublished
                        .slice(offset, offset + PER_PAGE)
                        .map(units => (
                          <ApprovedItem>
                            <ItemTitle>{units.item_content_title}</ItemTitle>
                            <KnowledgeItem>
                              <Providers>{units.providers}</Providers>
                            </KnowledgeItem>
                            <DateContainer>
                              {moment(new Date(units.updated_at))
                                .locale('pt')
                                .format('DD/MM/YYYY')}
                            </DateContainer>
                          </ApprovedItem>
                        ))}
                      <ReactPaginate
                        previousLabel="←"
                        nextLabel="→"
                        pageCount={pageCount}
                        onPageChange={handlePageClick}
                        containerClassName="pagination__dashboard"
                        pageClassName="pagination__page"
                        breakClassName="pagination__break"
                        previousLinkClassName="pagination__link"
                        nextLinkClassName="pagination__link"
                        disabledClassName="pagination__link--disabled"
                        activeClassName="pagination__link--active"
                      />
                    </RentsList>
                  )}
                </ApprovedList>
              </Bloco>
            </Content>
          )}
        <br />
      </Container>
    </>
  );
};

export default Dashboard;
