/* eslint-disable no-param-reassign */
/**
 * If you pass an where object with undefined parans to typeorm
 * He will try to parse the undefined as a param.
 * This function will make the undefined props be deleted from obj
 *
 * @param obj
 */
export default function clearObject(obj: any): any {
  if (obj)
    Object.keys(obj).forEach(key => {
      if (obj[key] === null || obj[key] === '' || obj[key] === undefined) {
        delete obj[key];
      }
    });

  return obj;
}
