import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  vertical-align: center;
  video {
    max-width: 100%;
  }
`;
export const Title = styled.h1`
  display: flex;
  justify-content: center;
  margin: 25px 0px;
`;
