import styled, { css } from 'styled-components';

interface IContainerProps {
  isError?: boolean;
}

export const Container = styled.div<IContainerProps>`
  small {
    color: var(--danger);
  }

  ${props =>
    props.isError &&
    css`
      > div {
        > div {
          border-color: var(--danger);
        }
      }
    `}
`;

export const Create = styled.span`
  &:before {
    font-size: 1.1rem;
    content: '+';
    margin-right: 5px;
  }
  text-transform: uppercase;
  font-size: 0.9rem;
  font-weight: bold;
  display: inline-block;
  background: #f6c86636;
  color: #F6C866;
  padding: 1.5px 7px;
  border-radius: 5px;
  margin-right: 10px;
`;
