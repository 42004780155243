import React from 'react';

import { Container } from './styles';

interface IChoiceEllipseProps {
  name: string;
  itens: {
    id: string;
    value: string;
    label: string;
  }[];
  defaultValue: string;
  handleStatus(value: string): void;
}

const ChoiceEllipse: React.FC<IChoiceEllipseProps> = ({
  itens,
  name,
  defaultValue,
  handleStatus,
}) => {
  return (
    <Container>
      {itens.map(item => (
        <div key={`choice-ellipse-${item.id}`}>
          <input
            type="radio"
            value={item.value}
            defaultChecked={defaultValue === item.value}
            name={name}
            id={`choice-ellipse-${item.id}`}
            onChange={e => handleStatus(e.target.value)}
          />
          <label htmlFor={`choice-ellipse-${item.id}`}>{item.label}</label>
        </div>
      ))}
    </Container>
  );
};

export default ChoiceEllipse;
