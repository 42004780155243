/* eslint-disable react/require-default-props */
/* eslint-disable no-nested-ternary */

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import React, {
  useCallback,
  useRef,
  useState,
  useEffect,
  useMemo,
} from 'react';
import * as Yup from 'yup';
import { useToast } from '../../../hooks/toast';
import { ISellersSearch } from '../../../pages/sellerControl';
import { IPlans } from '../../../pages/Plans';
import {
  signupSeller,
  putSendPicture,
  getPlansProvider,
  generateLmsKey,
  updateSeller,
  putUpdateUser,
} from '../../../services/api';

import getValidationErrors from '../../../utils/getVaidationErrors';
import RowGroup from '../../forms/RowGroup';
import Buttom from '../../forms/Buttom';
import Input from '../../forms/Input';
import ChoiceRadio from '../../forms/ChoiceRadioForm';
import Modal from '../Modal';
import InputMask from '../../forms/InputMask';
import AlertConfirm from '../AlertConfirm';

import {
  UploadProfile,
  UploadProfileContent,
  Image,
  File,
} from '../../../pages/Profile/styles';
import { Container, Bottom } from './styles';
import {
  IconErrorCircle,
  IconUsersMult,
  IconUsers,
  IconUser,
} from '../../../styles/icons';
import IconUserCircleLg from '../../../assets/icons/icon-user-circle-lg.svg';

interface IModalProps {
  isOpen: boolean;
  setIsOpen: () => void;
  seller?: ISellersSearch;
}

const ModalEditSeller: React.FC<IModalProps> = ({
  isOpen,
  setIsOpen,
  seller,
}) => {
  const { addToast } = useToast();
  const img = useRef<HTMLImageElement>(null);
  const [file, setFile] = useState<FileList | null>(null);

  const [loading, setLoading] = useState(false);
  const formRef = useRef<FormHandles>(null);

  useEffect(() => {
    if (file) {
      const reader = new FileReader();
      reader.onload = e => {
        img.current?.setAttribute('src', e.target?.result as string);
      };
      reader.readAsDataURL(file[0]);
    }
  }, [file]);

  const [plans, setPlans] = useState<IPlans[]>([]);
  useEffect(() => {
    async function Action(): Promise<void> {
      const { data: res } = await getPlansProvider({});
      setPlans(res);
    }
    Action();
  }, []);

  const uploadName = useMemo(() => {
    if (file) {
      const ext = file[0].name.split(/\.(?=[^.]+$)/);
      return `${file[0].name.substring(0, 6)}..._.${ext[1]}`;
    }
    return 'Escolher Imagem';
  }, [file]);

  const handleCleanImg = useCallback(() => {
    setFile(null);
    img.current?.setAttribute('src', IconUserCircleLg);
  }, []);

  const handleSubmit = useCallback(
    async (data: ISellersSearch) => {
      try {
        const schemaFirst = Yup.object().shape({
          // email: Yup.string()
          //   .required('Campo Obrigatório')
          //   .email('Email inválido'),
          // name: Yup.string().required('Campo Obrigatório'),
          seller: Yup.object().shape({
            cnpj: Yup.string().required('Campo Obrigatório'),
            name: Yup.string().required('Campo Obrigatório'),
          }),
        });
        await schemaFirst.validate(data, {
          abortEarly: false,
        });
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);
          formRef.current?.setErrors(errors);
          addToast({
            title: 'Ops ...',
            type: 'error',
            description: 'Verifique todos os campos!',
          });
          return;
        }
      }

      try {
        setLoading(true);
        formRef.current?.setErrors({});
        // const schemaFirst = Yup.object().shape({
        //   seller: Yup.object().shape({
        //     plan: Yup.string().required('Campo Obrigatório'),
        //   }),
        // });
        // await schemaFirst.validate(data, {
        //   abortEarly: false,
        // });

        if (seller && Object.keys(seller).length > 0) {
          try {
            await updateSeller({
              id: seller?.seller.id,
              email: data.user.email,
              name: data.seller.name,
              cnpj: data.seller.cnpj,
            });
            await putUpdateUser(
              {
                id: seller.user.id,
                name: data.user.name,
                avatar: data.user.avatar_url,
                email: data.user.email,

                // export interface IUser {
                //   id: string;
                //   name: string;
                //   avatar: string;
                //   email: string;
                //   type: UsersTypes;
                //   status?: string;
                //   provider?: IProvider;
                //   seller?: ISeller;
                //   isServiceProvider: boolean;
                // }
              },
              seller.user.id,
            );
            if (file) {
              const formData = new FormData();
              formData.append('images', file[0]);
              const config = {
                headers: {
                  'content-type': 'multipart/form-data',
                },
              };
              await putSendPicture(seller.user.id, formData, config);
            }

            addToast({
              title: 'Sucesso',
              type: 'success',
              description: 'Usuário cadastrado com sucesso',
            });

            setTimeout(() => {
              window.location.reload();
            }, 1000);
          } catch (error) {
            addToast({
              title: 'Ops ...',
              type: 'error',
              description: 'Verifique todos os campos!!!',
            });
          }
        } else {
          const { data: sellerData } = await signupSeller({
            nameUser: data.user.name,
            email: data.user.email,
            password: '',
            sellerName: data.seller.name,
            cnpj: data.seller.cnpj,
          });
          if (file) {
            const formData = new FormData();
            formData.append('images', file[0]);
            const config = {
              headers: {
                'content-type': 'multipart/form-data',
              },
            };
            await putSendPicture(sellerData.user.id, formData, config);
          }
          const { data: response } = await generateLmsKey(sellerData.sellerId);
          addToast({
            title: 'Sucesso',
            type: 'success',
            description: 'Usuário cadastrado com sucesso',
          });
          const confirmPress = await AlertConfirm({
            title: 'Chave LMS foi gerada!',
            text: response.lms_key,
            type: 'lms',
          });
          if (confirmPress) {
            window.location.reload();
          }
        }
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);
          formRef.current?.setErrors(errors);
        }else if (error.response && error.response.status === 400 && 
          error.response.data.status === 'error' && 
          error.response.data.message === 'This email is already taken') {
            
            addToast({
              title: 'Erro',
              type: 'error',
              description: 'Este e-mail já está cadastrado em outra instituição.',
            });

            setLoading(false);
            return;
        }else{
          addToast({
            title: 'Ops ...',
            type: 'error',
            description: 'Verifique todos os campos !!!',
          });
          return;
        }
      }
    },
    [addToast, setIsOpen, seller, file],
  );

  return (
    <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
      <Container>
        <h1>
          {seller && Object.keys(seller).length > 0
            ? `Editar Acesso da Intituição`
            : `Criar Acesso para Instiuição`}
        </h1>
        <Form ref={formRef} onSubmit={handleSubmit} initialData={seller}>
          <div className="slideUp">
            <div>
              <RowGroup>
                <Input
                  name="user.name"
                  label="Seu nome:*"
                  type="text"
                  placeholder="Jhon Doe"
                />
                {seller && Object.keys(seller).length > 0 ? (
                  <Input
                    name="seller.cnpj"
                    label="CNPJ da Instituição:*"
                    type="text"
                    placeholder="00.000.000/0001-00"
                  />
                ) : (
                  <InputMask
                    name="seller.cnpj"
                    label="CNPJ da Instituição:*"
                    type="text"
                    placeholder="00.000.000/0001-00"
                    mask="99.999.999/9999-99"
                  />
                )}
              </RowGroup>

              <Input
                name="seller.name"
                label="Nome da instituição:*"
                type="text"
                placeholder="VG Consultoria"
              />
            </div>

            <Input
              name="user.email"
              label="Seu Email:*"
              type="text"
              placeholder="email@dominio.com.br"
            />

            <UploadProfile>
              <label>Logotipo da Instituição:</label>
              <UploadProfileContent>
                <Image>
                  <img src={IconUserCircleLg} ref={img} width="132" alt="" />
                  <IconErrorCircle onClick={() => handleCleanImg()} />
                </Image>
                <File style={{ width: '64%' }}>
                  <label htmlFor="file">{uploadName}</label>
                  <input
                    type="file"
                    name="user.avatar_url"
                    id="file"
                    onChange={e => {
                      setFile(e.target.files);
                    }}
                  />
                  <p>Imagens de no máximo 10MB</p>
                </File>
              </UploadProfileContent>
            </UploadProfile>
            {/* {!(seller && Object.keys(seller).length > 0) && (
              <div style={{ marginTop: '20px' }}>
                <ChoiceRadio
                  name="seller.plans_seller.planId"
                  label="Quantidade de alunos na instituição:*"
                  options={plans.map((plan, index) => {
                    return {
                      value: plan.id,
                      label: `De <strong>${plan.minRent}</strong> a <strong>${plan.maxRent}</strong> alunos`,
                      icon:
                        index === 0 ? (
                          <IconUser height="16.03" width="14.25" />
                        ) : index === 1 ? (
                          <IconUsers height="16.03" width="19.59" />
                        ) : (
                          <IconUsersMult height="16.03" width="25.23" />
                        ),
                    };
                  })}
                />
              </div>
            )} */}
          </div>
        </Form>
        <Bottom>
          <Buttom
            name="cancelar"
            type="button"
            onClick={() => setIsOpen()}
            label="Cancelar"
            outline
          />
          <Buttom
            name="salvar"
            onClick={() => formRef.current?.submitForm()}
            label="Salvar"
            isLoading={loading}
          />
        </Bottom>
      </Container>
    </Modal>
  );
};

export default ModalEditSeller;
