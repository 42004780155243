import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { BeatLoader } from 'react-spinners';
import Checkbox, { CheckBoxAll } from '../../components/forms/Checkbox';
import InputSearch from '../../components/forms/InputSearch';
import ModalEditPlan from '../../components/template/ModalEditPlan';
import ModalEditPlanPrice from '../../components/template/ModalEditPlanPrice';
import Tabs from '../../components/template/Tabs';
import {
  AddButton,
  OptionLeft,
  OptionRight,
  Options,
  Title,
  TableContent,
  PrimaryButton,
  ActionsUnit,
} from '../../components/template/TemplateManager/styles';
import { useToast } from '../../hooks/toast';
import useLazyEffect from '../../hooks/useLazyEffect';
import {
  getPlansProvider,
  getPlansProviderPrice,
  getProviderById,
} from '../../services/api';
import { IconAddCircle } from '../../styles/icons';
import formatValue from '../../utils/formatValue';
import { useAuth } from '../../hooks/auth';
import { Status } from '../Units/styles';

import { Container, Content } from './styles';

export interface IPlans {
  id: string;
  name: string;
  description: string;
  minRent: number;
  maxRent: number;
  createdAt: string;
}

interface IParams {
  provider_id: string;
}

interface IProvider {
  id: string;
  companyName: string;
}

export interface IPlanPrice {
  id: string;
  planId: string;
  providerId: string;
  value: string;
}

const Plans: React.FC = () => {
  const routeParames: IParams = useParams();

  const { addToast } = useToast();
  const { user } = useAuth();

  const [checks, setChecks] = useState<Array<string>>([]);
  const [currentTab, setCurrentTab] = useState('tab-02');
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState('');

  const [provider, setProvider] = useState({} as IProvider);

  const [modalEditPlan, setModalEditPlan] = useState(false);
  const [modalEditPlanPrice, setModalEditPlanPrice] = useState(false);

  const [plans, setPlans] = useState<IPlans[]>([]);
  const [currentPlanEdit, setCurrentPlanEdit] = useState<IPlans>({
    id: '',
    name: '',
    description: '',
    minRent: 0,
    maxRent: 0,
    createdAt: '',
  });

  const [plansPrice, setPlansPrice] = useState<IPlanPrice[]>([]);
  const [currentPlanEditPrice, setCurrentPlanEditPrice] = useState<IPlanPrice>({
    id: '',
    planId: '',
    providerId: '',
    value: '',
  });

  const load = useCallback(async () => {
    try {
      setLoading(true);
      const { data: res } = await getPlansProvider({});
      setPlans(res);

      if (routeParames.provider_id) {
        const { data: resProvider } = await getProviderById(
          routeParames.provider_id,
        );
        setProvider(resProvider);

        const { data: resProviderPrice } = await getPlansProviderPrice({
          providerId: routeParames.provider_id,
        });
        setPlansPrice(resProviderPrice);
      }
    } catch (error) {
      addToast({
        title: 'Ops...',
        description: 'Algo inesperado aconteceu!',
        type: 'error',
      });
    } finally {
      setLoading(false);
    }
  }, [addToast, routeParames.provider_id]);
  useEffect(() => {
    load();
  }, [addToast, load, routeParames.provider_id]);

  useLazyEffect(() => {
    const loadSearch = async (): Promise<void> => {
      try {
        const { data: res } = await getPlansProvider({
          name: search === '' ? undefined : search,
        });

        setPlans(res);
      } catch (error) {
        addToast({
          title: 'Ops...',
          description: 'Algo inesperado aconteceu!',
          type: 'error',
        });
      }
    };
    loadSearch();
  }, [search]);

  const handleTab = useCallback((id: string) => {
    setCurrentTab(id);
  }, []);

  const handleCheck = useCallback(check => {
    setChecks(check);
  }, []);

  const handleEditPlan = useCallback(() => {
    setModalEditPlan(!modalEditPlan);
  }, [modalEditPlan]);

  const handleEditPlanPrice = useCallback(() => {
    setModalEditPlanPrice(!modalEditPlanPrice);
  }, [modalEditPlanPrice]);

  const handleSetEditPlan = useCallback(
    (plan: IPlans) => {
      setCurrentPlanEdit(plan);
      handleEditPlan();
    },
    [handleEditPlan],
  );

  const handleSetEditPlanPrice = useCallback(
    (plan: IPlanPrice) => {
      setCurrentPlanEditPrice(plan);
      handleEditPlanPrice();
    },
    [handleEditPlanPrice],
  );

  const handleSetCreatePlan = useCallback(() => {
    setCurrentPlanEdit({} as IPlans);
    handleEditPlan();
  }, [handleEditPlan]);

  const handleInitialState = useCallback(async () => {
    await load();
    setCurrentTab('tab-02');
    setSearch('');
  }, [load]);

  const [userCheck, setUserCheck] = useState(false);

  useEffect(() => {
    if (user.type === 'director') {
      setUserCheck(true);
    } else {
      setUserCheck(false);
    }
  }, [user.type]);

  const renderPlans = useCallback(() => {
    return (
      <div className="slideUp">
        <table>
          <thead>
            <tr>
              <th>
                <CheckBoxAll
                  ids={plans.map(item => item.id)}
                  handleCheckAll={handleCheck}
                />
              </th>
              <th>Planos</th>
              <th>Mínimo</th>
              <th>Máximo</th>
              <th>{routeParames.provider_id ? `Valor mensal` : <meta />}</th>
            </tr>
          </thead>
          <tbody>
            {plans.map(plan => (
              <tr className="no-cursor" key={plan.id}>
                <td>
                  <Checkbox
                    name="check"
                    handleCheck={handleCheck}
                    checks={checks}
                    id={plan.id}
                  />
                </td>
                <td>
                  {plan.name} <br />
                  <span className="text-gray">
                    Criado em:{' '}
                    {new Date(plan.createdAt).toLocaleDateString('pt-BR')}
                  </span>
                </td>
                <td>
                  <Status>{plan.minRent} Alunos</Status>
                </td>
                <td>
                  <Status>{plan.maxRent} Alunos</Status>
                </td>
                <td>
                  <ActionsUnit style={{ justifyContent: 'space-between' }}>
                    {routeParames.provider_id && (
                      <span className="price">
                        <span>R$</span>
                        {'  '}
                        {formatValue(
                          plansPrice.find(item => item.planId === plan.id)
                            ?.value,
                        ) || '0,00'}
                      </span>
                    )}
                    {!userCheck ? (
                      <PrimaryButton
                        onClick={() => {
                          routeParames.provider_id
                            ? handleSetEditPlanPrice(
                                plansPrice.find(
                                  item => item.planId === plan.id,
                                ) ||
                                  ({
                                    planId: plan.id,
                                    providerId: routeParames.provider_id,
                                  } as IPlanPrice),
                              )
                            : handleSetEditPlan(plan);
                        }}
                      >
                        Editar
                      </PrimaryButton>
                    ) : undefined}
                  </ActionsUnit>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }, [
    plans,
    userCheck,
    handleCheck,
    routeParames.provider_id,
    checks,
    handleSetEditPlanPrice,
    plansPrice,
    handleSetEditPlan,
  ]);

  return (
    <>
      <ModalEditPlan
        handleInitialState={handleInitialState}
        isOpen={modalEditPlan}
        setIsOpen={handleEditPlan}
        plan={currentPlanEdit}
      />
      <ModalEditPlanPrice
        handleInitialState={handleInitialState}
        isOpen={modalEditPlanPrice}
        setIsOpen={handleEditPlanPrice}
        planPrice={currentPlanEditPrice}
      />
      <Container className="slideDown">
        <Title style={{ flexDirection: 'column', alignItems: 'flex-start' }}>
          <h1>Planos de Aluguel</h1>
          <p>{provider && provider.companyName}</p>
        </Title>
        <Content>
          <Options>
            <OptionRight>
              <div className="search">
                <InputSearch
                  name="search"
                  onChange={e => setSearch(e.target.value)}
                  placeholder="Pesquisar"
                />
              </div>
            </OptionRight>
            <OptionLeft style={{ flexDirection: 'row-reverse' }}>
              <AddButton onClick={handleSetCreatePlan}>
                <IconAddCircle /> Novo Plano
              </AddButton>
            </OptionLeft>
          </Options>
          <TableContent>
            <Tabs itens={[]} defaultValue={currentTab} handleTab={handleTab} />
            {loading ? (
              <div className="loading">
                <BeatLoader size={16} />
              </div>
            ) : (
              renderPlans()
            )}
          </TableContent>
        </Content>
      </Container>
    </>
  );
};

export default Plans;
