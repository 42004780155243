import styled from 'styled-components';
import { shade } from 'polished';

interface ICancel {
  cancel: boolean;
}

export const Container = styled.div`
  text-align: justify;
  padding: 20px;
  p {
    color: var(--gray);

    margin-top: 10px;
  }
`;

export const Radio = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 28px;

  > label {
    margin-top: 32px;
    font-weight: bold;
    color: var(--gray);
  }

  input {
    margin-right: 12px;
  }

  input[type='radio'] {
    position: relative;
    cursor: pointer;

    &:checked:after {
      content: '';
      display: block;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background-color: white;
      position: absolute;
      top: 0px;
      left: 3px;
    }

    &:checked:before {
      border: 2px solid var(--aurea);
      background-color: var(--aurea);
    }

    &::before {
      content: '';
      display: block;
      position: absolute;
      width: 16px;
      height: 16px;
      top: -3.5px;
      left: -1px;
      border: 2px solid rgba(141, 133, 133, 0.45);
      border-radius: 50%;
      background-color: white;
    }
  }
`;
export const Check = styled.div`
  text-align: justify;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 28px;

  > label {
    margin-top: 32px;
    font-weight: bold;
    color: var(--gray);
  }

  input {
    margin-right: 12px;
  }

  input[type='checkbox'] {
    position: relative;
    cursor: pointer;

    &:checked:after {
      content: '';
      display: block;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background-color: white;
      position: absolute;
      top: 0px;
      left: 3px;
    }

    &:checked:before {
      border: 2px solid var(--aurea);
      background-color: var(--aurea);
    }

    &::before {
      content: '';
      display: block;
      position: absolute;
      width: 16px;
      height: 16px;
      top: -3.5px;
      left: -1px;
      border: 2px solid rgba(141, 133, 133, 0.45);
      border-radius: 50%;
      background-color: white;
    }
  }
`;

export const RadioContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  margin-top: 16px;
`;

export const Bottom = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  margin-top: 24px;

  div + div {
    margin-left: 18px;
  }
`;

export const ModalContent = styled.div<ICancel>`
  h1 {
    margin: 34px 0 30px 37px;
    font-size: 30px;
  }
  section {
    display: flex;
    align-items: center;
    justify-content: center;
    button {
      border: 1px solid #f6c866;
      color: #3d3939;
      width: 150px;
      margin-right: 19px;
      font-family: 'Source Sans Pro';
      font-weight: 700;
      font-size: 16;
      padding: 14px;
      border-radius: 5px;
      background: #f6c866;
      transition: all 0.2s;
      &:hover {
        background: ${shade(0.2, '#F6C866')};
      }
    }
    button.cancel {
      background: #ffffff;
      &:hover {
        background: ${shade(0.1, '#FFFFFF')};
      }
    }
  }
`;

export const RowGroup = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: row;
  position: relative;

  div {
    width: 100%;
    & + div {
      margin-left: 18px;
    }
  }
`;

export const File = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  a {
    text-align: center;
    padding: 5px 10px;
    color: black;
    font-size: 1rem;
    font-weight: bold;
    width: 250px;
    color: var(--dark);

    border-radius: 5px;
    border: 1px solid var(--gray-border);

    cursor: pointer;
    transition: background 0.3s;
    &:hover {
      background: var(--gray-border);
    }
  }
`;
