import React from 'react';
import { Holder, CardGrid, Card } from './styles';

interface IVideosContentProps {
  title: string;
  video: string;
}

const videosContent = [
  {
    title: 'Como acessar a plataforma',
    video: '651201548?h=fb0f1208d1',
  },
  {
    title: 'Alterando credenciais (nome, e-mail, senha e imagem de perfil)',
    video: '651201603?h=a4ba48fb51',
  },
  {
    title: 'Adicionando novos usuários',
    video: '651201643?h=5805e9f524',
  },
  //   {
  //     title: 'Loja de serviços (parceiros)',
  //     video: '651201750?h=97678e0d98',
  //   },
  {
    title: 'Meu catálogo',
    video: '651201798?h=63d1466e3a',
  },
  {
    title: 'Disciplinas',
    video: '651201856?h=4d2db424a4',
  },
  {
    title: 'Cursos',
    video: '651201946?h=a2fb0a1278',
  },
  {
    title: 'Vinculando unidades de ensino ao AVA (LMS Canvas)',
    video: '651202052?h=25c645913e',
  },
  {
    title: 'Loja de fornecedores',
    video: '651201691?h=f1f42ccc8b',
  },
];

const Tutorials: React.FC = () => {
  return (
    <Holder>
      <h1>Vídeo tutoriais</h1>
      <h2>Instruções de como utilizar a plataforma Áurea</h2>
      <CardGrid>
        {videosContent.map((item: IVideosContentProps) => (
          <Card key={item.video}>
            <h2>{item.title}</h2>
            <div className="video-responsive">
              <iframe
                width="853"
                height="480"
                src={`https://player.vimeo.com/video/${item.video}&title=0&byline=0&portrait=0`}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                title="Embedded Vimeo"
              />
            </div>
          </Card>
        ))}
      </CardGrid>
    </Holder>
  );
};

export default Tutorials;
