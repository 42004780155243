import React, { ReactNode, useMemo } from 'react';

import Dropzone from 'react-dropzone';
import { DropContainer, UploadMessage } from './styles';
import { IconAttachFile } from '../../../styles/icons';

interface IUploadProps {
  onUpload: Function;
  formts: {
    extension: string;
    types: string;
  }[];
  loading?: boolean;
  name?: string;
  defaultValue?: string;
  onChange?: Function;
  style?: object;
}

const Upload: React.FC<IUploadProps> = ({
  onUpload,
  formts,
  loading = false,
}) => {
  const strFormats = useMemo(() => {
    return formts.reduce((arr, curr) => {
      return `${arr},${curr.extension},${curr.types}`;
    }, '');
  }, [formts]);

  function renderDragMessage(
    isDragActive: boolean,
    isDragRejest: boolean,
    fileRejections,
  ): ReactNode {
    const maxSize = 30000000;
    const isFileTooLarge =
      fileRejections.length > 0 && fileRejections[0].file.size > maxSize;
    if (isFileTooLarge)
      return (
        <UploadMessage type="error">Arquivo maior do que 30 MB.</UploadMessage>
      );

    if (!isDragActive) {
      if (loading) {
        return <UploadMessage>Realizando upload ...</UploadMessage>;
      }
      return (
        <>
          <UploadMessage>
            <IconAttachFile />
            <strong>
              Adicione um arquivo {formts.map(item => `${item.extension}, `)}
            </strong>
            ou arraste aqui (até 30MB).
          </UploadMessage>
        </>
      );
    }

    if (isDragRejest) {
      return <UploadMessage type="error">Arquivo não suportado</UploadMessage>;
    }

    return <UploadMessage type="success">Solte o arquivo aqui</UploadMessage>;
  }

  return (
    <>
      <Dropzone
        minSize={0}
        maxSize={30000000} // 5242880
        accept={strFormats}
        onDropAccepted={files => onUpload(files)}
      >
        {({
          getRootProps,
          getInputProps,
          isDragActive,
          isDragReject,
          fileRejections,
        }): any => (
          <DropContainer
            {...getRootProps()}
            isDragActive={isDragActive}
            isDragReject={isDragReject}
          >
            <input {...getInputProps()} data-testid="upload" />
            {renderDragMessage(isDragActive, isDragReject, fileRejections)}
          </DropContainer>
        )}
      </Dropzone>
    </>
  );
};

export default Upload;
