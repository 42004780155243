/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { useEffect, useState } from 'react';
import { FiFilter, FiFrown } from 'react-icons/fi';

import { generateId } from '../../../utils/generateId';
import Api, { getAccessUnits, getKnowledge, IGetKnowledge } from '../../../services/api';

import {
  AlertWrapper,
  Container,
  Filters,
  FiltersWrapper,
} from './styles';

import { useAuth } from '../../../hooks/auth';
import { useToast } from '../../../hooks/toast';
import Input from '../../../components/forms/Input';
import Select from '../../../components/forms/SelectForm';
import ExportCsv from '../../../components/ExportCsv';
import ReactPaginate from 'react-paginate';

interface AccessUnits {
  title: string;
  knowledges: string;
  access_counter: number;
  TitleKnowledges: string;
}

const UnitAccessReport: React.FC = () => {
  const { user } = useAuth();
  const { addToast } = useToast();
  const [accessUnits, setAccessUnits] = useState<AccessUnits[]>([]);
  const [knowledges, setKnowledges] = useState<IGetKnowledge[]>([]);

  useEffect(() => {
    const load = async (): Promise<void> => {
      try {

        const know = await getKnowledge();
        setKnowledges(know);

      } catch (error) {
        addToast({
          title: 'Ops ...',
          description: 'Aconteceu algo inesperado, por favor tente mais tarde',
          type: 'error',
        });
      }
    };
    load();
  }, []);

  const Selectknowledge = knowledges ? knowledges.map(knowledge => ({
    label: knowledge.label,
    value: knowledge.value,
  })) : [];


  const handleSubmit = async (data) => {
    try {
      const accessUnitsData = await getAccessUnits(data.knowledgeid, data.start_date, data.end_date);
      if (accessUnitsData.data.length === 0) {
        addToast({
          type: 'info',
          title: 'Atenção!',
          description: 'Não foram encontradas unidades de acesso para o período informado.',
        });

      } else {
        setAccessUnits(accessUnitsData.data);
      }

    } catch (error) {
      console.error(error);

      addToast({
        type: 'error',
        title: 'Atenção!',
        description:
          'Não foi possível realizar esta operação, tente novamente.',
      });
    }
  };

  const [currentPage, setCurrentPage] = useState(0);
  const PER_PAGE = 25;
  const pageCount = accessUnits
    ? Math.ceil(accessUnits.length / PER_PAGE)
    : 0;

  const offset = currentPage * PER_PAGE;

  function handlePageClick({ selected: selectedPage }) {
    setCurrentPage(selectedPage);
  }
  // information report
  const headers = [
    { label: "Unidades", key: "title" },
    { label: "Área de conhecimento", key: "TitleKnowledges" },
    { label: "Acessos", key: "access_counter" },
  ];
  const filename = "relatorio.csv";
  const data = accessUnits;

  return (
    <Container>
      <header>
        <h1>Relatório de Acessos por Unidades</h1>
        <span>
          {user.name}
        </span>
      </header>

      <Filters>
        <FiltersWrapper onSubmit={handleSubmit}>
          <button type="submit">
            <FiFilter />
            Filtrar
          </button>

          <Input label="Data Inicial" required name="start_date" type="date" />
          <Input label="Data Final" required name="end_date" type="date" />

          <Select
            name="knowledgeid"
            placeholder="Área de conhecimento"
            options={Selectknowledge}
          />

        </FiltersWrapper>
          <ExportCsv headers={headers} data={data} filename={filename} disabled={accessUnits.length === 0} />
      </Filters>

      <table>
        <thead>
          <tr>
            <th>Unidades</th>
            <th>Área de conhecimento</th>
            <th style={{ width: '100px', textAlign: 'center' }}>Acessos</th>
          </tr>
        </thead>
        <tbody>
          {accessUnits.slice(offset, offset + PER_PAGE).map(access => (
            <tr key={generateId()}>
              <td>{access.title}</td>
              <td>{access.TitleKnowledges || <b>(Não Informado)</b>}</td>
              <td style={{ textAlign: 'center' }}>{access.access_counter}</td>
            </tr>
          ))}
        </tbody>
      </table>

      {accessUnits.length === 0 && (
        <AlertWrapper>
          <FiFrown size={50} />
          Sem dados para apresentação
        </AlertWrapper>
      )}
      {accessUnits.length > 25 && (
        <ReactPaginate
          previousLabel="←"
          nextLabel="→"
          pageCount={pageCount}
          onPageChange={handlePageClick}
          containerClassName="pagination"
          previousLinkClassName="pagination__link"
          nextLinkClassName="pagination__link"
          disabledClassName="pagination__link--disabled"
          activeClassName="pagination__link--active"
        />
      )}
    </Container>
  );
};

export { UnitAccessReport };
