/* eslint-disable react/button-has-type */
/* eslint-disable react/require-default-props */
import React, { useEffect, useState } from 'react';
import BeatLoader from 'react-spinners/BeatLoader';
import { Holder, Container, Content, Title, RightSide } from './styles';
import {
  Main,
  MainContent,
  Body,
} from '../../components/template/SellerTemplate/styles';
import NavTop from '../../components/template/NavTop';
import IconUser from '../../assets/icons/icon-user-circle-lg.svg';
import { IconBookmarkYellow } from '../../styles/icons';
import { useToast } from '../../hooks/toast';
import { getAllProviders } from '../../services/api';
import ModalFormPartners from '../../components/template/ModalFormsPartners';
import InputSearch from '../../components/forms/InputSearch';

interface IProvider {
  id: string;
  companyName: string;
  condition: string;
  description: string;
  imgLogoUrl: string;
  providerTotalServices: string;
}
// interface sempre com letra I maiusculo e segunda deve  maiúscula tamb
interface IStoreServiceProps {
  externalLink?: string;
}
const StoreService: React.FC<IStoreServiceProps> = ({ externalLink }) => {
  const { addToast } = useToast();
  const [providers, setProviders] = useState<IProvider[]>([]);
  const [loading, setLoading] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [search, setSearch] = useState('');

  useEffect(() => {
    const load = async (): Promise<void> => {
      setLoading(true);
      try {
        const { data: res } = await getAllProviders();
        res.push({
          id: '1',
          companyName: 'Tiago - teste provider',
          condition: 'Condition Test',
          description: 'A SoluTech Solutions é uma empresa...',
          imgLogoUrl:
            'https://www.euax.com.br/wp-content/uploads/2019/10/Teste.png',
          providerTotalServices: '5',
        });

        res.push({
          id: '2',
          companyName: 'Tiago - teste provider',
          condition: 'Condition Test',
          description: 'Tiago - teste provider',
          imgLogoUrl:
            'https://www.euax.com.br/wp-content/uploads/2019/10/Teste.png',
          providerTotalServices: '10',
        });

        setProviders(res);
      } catch (error) {
        addToast({
          title: 'Ops...',
          type: 'error',
          description:
            'Algo inesperado aconteceu, por favor recarrege sua página.',
        });
      } finally {
        setLoading(false);
      }
    };
    load();
  }, [addToast]);

  const openModal = (): void => {
    setModalIsOpen(true);
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setSearch(e.target.value);
  };

  return (
    <Holder>
      <Main>
        <MainContent className="blur">
          <NavTop />
          <Body className="body">
            <Container className="slideDown">
              <Title style={{ marginLeft: '30px' }}>
                <h1>Parceiros</h1>
              </Title>
              <Content className="slideUp">
                {loading && (
                  <div className="loading">
                    <BeatLoader size={16} />
                  </div>
                )}
                <section>
                  {providers
                    .filter(
                      provider =>
                        provider.companyName &&
                        provider.companyName
                          .toLowerCase()
                          .includes(search.toLowerCase()),
                    )
                    .map(provider => (
                      <article className="slideUp" key={provider.id}>
                        <header>
                          <img src={provider.imgLogoUrl || IconUser} alt="" />
                          <h1>
                            {provider.companyName
                              ? `${provider.companyName.substring(0, 20)}...`
                              : provider.companyName}
                          </h1>
                          <p>{provider.condition}</p>
                          <p className="units">
                            <IconBookmarkYellow />{' '}
                            <strong>{provider.providerTotalServices}</strong>{' '}
                            Serviços
                          </p>
                          <p
                            style={{
                              textAlign: 'left',
                              margin: '10px 0',
                              minHeight: '55px',
                            }}
                          >
                            {provider.description}
                          </p>
                          {externalLink ? (
                            <a
                              href={externalLink}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Clique aqui e conheça mais!
                            </a>
                          ) : (
                            <button
                              onClick={openModal}
                              style={{
                                fontWeight: 'bold',
                                textDecoration: 'none',
                                border: 'none',
                                background: 'none',
                                fontSize: '14px',
                              }}
                            >
                              Clique aqui e conheça mais!
                            </button>
                          )}
                        </header>

                        <div className="yellowBelt"> </div>
                      </article>
                    ))}
                </section>
              </Content>
            </Container>
          </Body>
        </MainContent>
      </Main>
      <RightSide className="slideLeft">
        <div>
          <h2>Filtrar parceiros</h2>
          <InputSearch
            name="search"
            onChange={handleSearch}
            placeholder="Pesquisar"
            value={search}
          />
        </div>
      </RightSide>
      <ModalFormPartners isOpen={modalIsOpen} setIsOpen={setModalIsOpen} />
    </Holder>
  );
};

export default StoreService;
