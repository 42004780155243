import React, { useRef, useEffect, TextareaHTMLAttributes } from 'react';
import { useField } from '@unform/core';

import { Container } from './styles';

interface ITextAreaProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  name: string;
  label: string;
}

const TextArea: React.FC<ITextAreaProps> = ({ label, name, ...rest }) => {
  const inputRef = useRef<HTMLTextAreaElement>(null);
  const { fieldName, defaultValue, error, registerField } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  return (
    <Container isErrored={!!error}>
      <label>{label}</label>
      <textarea defaultValue={defaultValue} ref={inputRef} {...rest} />
      {error && <small>{error}</small>}
    </Container>
  );
};

export default TextArea;
