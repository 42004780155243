/* eslint-disable no-constant-condition */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { BeatLoader } from 'react-spinners';
import * as Yup from 'yup';

import Input from '../../components/forms/Input';
import TextArea from '../../components/forms/TextArea';
import AlertConfirm from '../../components/template/AlertConfirm';

import { IconErrorCircle } from '../../styles/icons';
import IconUserCircleLg from '../../assets/icons/icon-user-circle-lg.svg';
import IconUploadImage from '../../assets/icons/icon-upload-image.svg';
import {
  Container,
  Card,
  RowGroup,
  CardSection,
  ColunmGroup,
  UploadProfile,
  UploadProfileContent,
  File,
  Image,
  ProviderImage,
  Bottom,
} from './styles';
import Buttom from '../../components/forms/Buttom';
import { useAuth } from '../../hooks/auth';
import { useToast } from '../../hooks/toast';
import getValidationErrors from '../../utils/getVaidationErrors';
import {
  deleteAvatar,
  putSendPicture,
  getProviderDataById,
  editProviderById,
  putUpdateMyUser,
  uploadFiles,
  sendEmailLmsKey,
} from '../../services/api';
import Choice from '../../components/forms/Choice';
import { knowledgeLevel } from '../../data/knowledgeLevel';
import IProviderDTO from '../../interfaces/ProviderDTO';
import { PROVIDER_ENUM } from '../../data/users';
import { PROVIDER_TYPES_ENUM } from '../../data/subTypeProvider';
import { UsersTypes } from '../../services/auth';
import { saveGenericLog } from '../../services/logs';

const maxSize = 10000000;

const Profile: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const { user, updateUser } = useAuth();
  const { addToast } = useToast();

  const [provider, setProvider] = useState<IProviderDTO>();
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState<FileList | null>(null);
  const [providerImageLogo, setProviderImageLogo] = useState<
    FileList | any[] | null
  >([]);
  const [providerImageCapa, setProviderImageCapa] = useState<
    FileList | any[] | null
  >([]);

  const [providerImgCapa, setProviderImgCapa] = useState<string | any>(
    IconUploadImage,
  );
  const [providerImgLogo, setProviderImgLogo] = useState<string | any>(
    IconUploadImage,
  );

  const isProvider = useMemo<boolean>(() => {
    return (
      [PROVIDER_ENUM.provider].includes(user.type as any) ||
      [PROVIDER_ENUM.serviceProvider].includes(user.type as any)
    );
  }, [user.type]);
  const [isProviderLiberal, setIsProviderLiberal] = useState<boolean>(false);

  const getProviderData = useCallback(async (): Promise<void> => {
    async function getProvider() {
      const providerFromDB: IProviderDTO = (
        await getProviderDataById(user.provider?.id as string)
      ).data;

      setProvider(providerFromDB);
      setProviderImgCapa(providerFromDB.imgCoverUrl || IconUploadImage);
      setProviderImgLogo(providerFromDB.imgLogoUrl || IconUploadImage);

      setIsProviderLiberal(
        providerFromDB?.typePerson === PROVIDER_TYPES_ENUM.liberal,
      );
    }
    if ((user && user.type === 'provider') || 'serviceProvider') getProvider();
  }, [user]);

  useEffect(() => {
    if (isProvider) getProviderData();
  }, [getProviderData, isProvider]);

  const fileUpload = useCallback(
    async (files: FileList) => {
      const formData = new FormData();
      formData.append('images', files[0]);
      const config = {
        headers: {
          'content-type': 'multipart/form-data',
        },
      };
      return putSendPicture(user.id, formData, config);
    },
    [user.id],
  );

  const handleSubmit = useCallback(
    async data => {
      try {
        setLoading(true);
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          // Usuario
          name: Yup.string().required('Campo Obrigatório'),
          email: Yup.string()
            .required('Campo Obrigatório')
            .email('Email inválido'),
          new_password: Yup.string(),
          confirm_password: Yup.string().oneOf(
            [Yup.ref('new_password'), null],
            'As senhas não são iguais',
          ),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const providerType = UsersTypes.provider;

        const userBody = {
          id: user.id,
          name: data.name,
          email: data.email,
          type:
            user.type === UsersTypes.serviceProvider ? providerType : user.type,
          password: data.confirm_password ? data.confirm_password : undefined,
        };
        await putUpdateMyUser(userBody);
        const updateUserObj = {
          id: data.id,
          email: data.email,
          name: data.name,
          avatar: undefined,
        };

        if (file) {
          const { data: resUser } = await fileUpload(file);
          updateUserObj.avatar = resUser.avatar_url;
        }

        updateUser(updateUserObj);
        if (isProvider) {
          let providerBody: any = {
            id: provider?.id as string,
            description: data.provider_description,
            companyName: data.companyName,
          };

          let formProvider: any = {
            companyName: Yup.string().required('Campo Obrigatório'),
            provider_description: Yup.string().required('Campo Obrigatório'),
          };

          if (isProviderLiberal) {
            formProvider = {
              ...formProvider,
              link_linkedin: Yup.string().required('Campo Obrigatório'),
              link_lattes: Yup.string().required('Campo Obrigatório'),
              knowledge_level: Yup.number().required('Campo Obrigatório'),
              professional_experience:
                Yup.string().required('Campo Obrigatório'),
            };
            providerBody = {
              ...providerBody,
              lattes: data.link_lattes,
              linkedin: data.link_linkedin,
              professional_experience: data.professional_experience,
              knowledgeLevel: data.knowledge_level.map((id: any) => Number(id)),
              imgLogoUrl: '',
              imgCoverUrl: '',
            };
          }

          if (providerImageCapa && providerImageCapa.length !== 0) {
            const formData = new FormData();
            formData.append('images', providerImageCapa[0]);

            const uploadResponseCover = await uploadFiles({
              formData,
              provider_id: provider?.id as string,
              type: 'images',
            });
            if (
              uploadResponseCover &&
              Array.isArray(uploadResponseCover.data)
            ) {
              providerBody.imgCoverUrl = uploadResponseCover.data[0].url;
            }
          }

          if (providerImageLogo && providerImageLogo.length !== 0) {
            const formData = new FormData();
            formData.append('images', providerImageLogo[0]);

            const uploadResponseLogo = await uploadFiles({
              formData,
              provider_id: provider?.id as string,
              type: 'images',
            });
            if (uploadResponseLogo && Array.isArray(uploadResponseLogo.data)) {
              providerBody.imgLogoUrl = uploadResponseLogo.data[0].url;
            }
          }

          await Yup.object().shape(formProvider).validate(data, {
            abortEarly: false,
          });

          await editProviderById(providerBody);
          await getProviderData();
        }

        // await saveGenericLog({ userId: user.id, action: 'updated_profile' });
        if (user.seller?.id) {
          saveGenericLog({
            userId: user.id,
            sellerId: user.seller.id,
            action: 'updated_profile',
          });
        } else {
          saveGenericLog({ userId: user.id, action: 'updated_profile' });
        }

        addToast({
          title: 'Sucesso!',
          type: 'success',
          description: 'Alteração feita com sucesso!',
        });
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);
          formRef.current?.setErrors(errors);
          return;
        }
        addToast({
          title: 'Opss ..',
          type: 'error',
          description: 'Ocorreu um erro ao editar o seu perfil.',
        });
      } finally {
        setLoading(false);
      }
    },
    [
      addToast,
      updateUser,
      file,
      fileUpload,
      user.type,
      provider,
      isProviderLiberal,
      getProviderData,
      isProvider,
      providerImageCapa,
      providerImageLogo,
    ],
  );

  const handleClearAvatar = useCallback(async () => {
    if (file && file?.length !== 0) {
      setFile(null);
      return;
    }
    if (user.avatar === null) {
      addToast({
        title: 'Opss ..',
        type: 'error',
        description: 'Nenhuma foto encontrada para exclusão, envie uma foto.',
      });
      return;
    }
    try {
      await deleteAvatar(user.id);

      updateUser({
        avatar: null,
      });
      setFile(null);
      addToast({
        title: 'Sucesso!',
        type: 'success',
        description: 'Avatar deletado com sucesso!',
      });
    } catch (error) {
      addToast({
        title: 'Opss ..',
        type: 'error',
        description: 'Ocorreu um erro ao editar o seu perfil.',
      });
    }
  }, [addToast, user.id, updateUser, user.avatar, file]);

  const handleClearProviderImageLogo = useCallback(async () => {
    try {
      const providerBody: any = {
        id: provider?.id as string,
        imgLogoUrl: null,
      };
      await editProviderById(providerBody);

      setProviderImageLogo([]);
      addToast({
        title: 'Sucesso!',
        type: 'success',
        description: 'Logo deletado com sucesso!',
      });
    } catch (error) {
      addToast({
        title: 'Opss ..',
        type: 'error',
        description: 'Ocorreu um erro ao editar o seu perfil.',
      });
    }
  }, [addToast, provider]);

  const handleClearProviderImageCapa = useCallback(async () => {
    try {
      const providerBody: any = {
        id: provider?.id as string,
        imgCoverUrl: null,
      };
      await editProviderById(providerBody);

      setProviderImageCapa([]);
      addToast({
        title: 'Sucesso!',
        type: 'success',
        description: 'Capa deletada com sucesso!',
      });
    } catch (error) {
      addToast({
        title: 'Opss ..',
        type: 'error',
        description: 'Ocorreu um erro ao editar o seu perfil.',
      });
    }
  }, [addToast, provider]);

  const userImgCallback = useCallback(() => {
    return file
      ? URL.createObjectURL(file[0])
      : user.avatar || IconUserCircleLg;
  }, [file, user.avatar]);

  useEffect(() => {
    const url =
      providerImageLogo && providerImageLogo?.length !== 0
        ? URL.createObjectURL(providerImageLogo[0])
        : IconUploadImage;
    setProviderImgLogo(url);
  }, [providerImageLogo]);

  useEffect(() => {
    const url =
      providerImageCapa && providerImageCapa?.length !== 0
        ? URL.createObjectURL(providerImageCapa[0])
        : IconUploadImage;
    setProviderImgCapa(url);
  }, [providerImageCapa]);

  const handleLmsKey = useCallback(async () => {
    try {
      await sendEmailLmsKey();

      await AlertConfirm({
        title:
          'Foi enviado uma mensagem para seu email contendo todas as instruções de como gerar a chave de LMS',
        text: 'Se não estiver achando na sua caixa de mensagem, verifique sua caixa de spam',
        type: 'lms',
      });
    } catch (error) {
      addToast({
        title: 'Opss ..',
        type: 'error',
        description: 'Ocorreu um erro ao editar o seu perfil.',
      });
    }
  }, [addToast]);

  return (
    <Container className="slideDown">
      <h1>Meu Perfil</h1>
      <Form ref={formRef} onSubmit={handleSubmit}>
        <Card>
          <RowGroup>
            <Input
              name="name"
              label="Nome:"
              type="text"
              defaultValue={user.name}
              placeholder="John Doe"
            />
          </RowGroup>
          <RowGroup>
            <Input
              name="email"
              label="Email:"
              type="text"
              defaultValue={user.email}
              placeholder="johndoe@dominio.com.br"
            />
            <Input
              name="current_password"
              type="password"
              label="Senha Atual:"
              placeholder="************"
              disabled
            />
          </RowGroup>
          <CardSection>
            <ColunmGroup>
              <Input
                name="new_password"
                type="password"
                label="Nova Senha:"
                placeholder="************"
              />
              <Input
                name="confirm_password"
                type="password"
                label="Confirme sua Senha:"
                placeholder="************"
              />
            </ColunmGroup>
            <ColunmGroup>
              <UploadProfile>
                <label>Imagem de Perfil:</label>
                <UploadProfileContent>
                  <Image>
                    <img src={userImgCallback()} width="132" alt="" />
                    <IconErrorCircle onClick={() => handleClearAvatar()} />
                  </Image>
                  <File>
                    <label htmlFor="file">Escolher Imagem</label>
                    <input
                      type="file"
                      name="file"
                      id="file"
                      accept="image/*"
                      onChange={e => {
                        let fileError = false;
                        if (e.target.files) {
                          if (e.target.files[0]?.size > maxSize) {
                            fileError = true;
                            addToast({
                              title: 'Imagem pesada!',
                              type: 'error',
                              description: 'Imagem é maior do que 10MB.',
                            });
                          }
                        }
                        if (!fileError) setFile(e.target.files);
                      }}
                    />
                    <p>Imagens de no máximo 10MB</p>
                  </File>
                </UploadProfileContent>
              </UploadProfile>
            </ColunmGroup>
          </CardSection>

          {isProvider && (
            <>
              {provider ? (
                <div className="slideUp">
                  <RowGroup>
                    <Input
                      name="companyName"
                      label="Nome da empresa:"
                      type="text"
                      defaultValue={provider.companyName}
                      placeholder="Instituição áurea"
                    />
                  </RowGroup>
                  <RowGroup>
                    <TextArea
                      name="provider_description"
                      label="Descrição (publicidade):*"
                      placeholder="A nossa instituição possui X anos no mercado e vem trabalhando com educação a Y anos ..."
                      defaultValue={provider?.description}
                    />
                  </RowGroup>
                  <RowGroup>
                    <UploadProfile>
                      <label>Imagem do Logo:</label>
                      <UploadProfileContent>
                        <ProviderImage>
                          <img src={providerImgLogo} width="132" alt="" />
                          <IconErrorCircle
                            onClick={() => handleClearProviderImageLogo()}
                          />
                        </ProviderImage>
                        <File>
                          <label htmlFor="provider_logo_file">
                            Escolher Imagem
                          </label>
                          <input
                            type="file"
                            name="provider_logo_file"
                            id="provider_logo_file"
                            accept="image/*"
                            onChange={e => {
                              let fileError = false;
                              if (e.target.files) {
                                if (e.target.files[0]?.size > maxSize) {
                                  fileError = true;
                                  addToast({
                                    title: 'Imagem pesada!',
                                    type: 'error',
                                    description: 'Imagem é maior do que 10MB.',
                                  });
                                }
                              }
                              if (!fileError)
                                setProviderImageLogo(e.target.files);
                            }}
                          />
                          <p>Imagens de no máximo 10MB</p>
                        </File>
                      </UploadProfileContent>
                    </UploadProfile>
                    <UploadProfile>
                      <label>Imagem de Capa:</label>
                      <UploadProfileContent>
                        <ProviderImage>
                          <img src={providerImgCapa} width="132" alt="" />
                          <IconErrorCircle
                            onClick={() => {
                              handleClearProviderImageCapa();
                            }}
                          />
                        </ProviderImage>
                        <File>
                          <label htmlFor="provider_capa_file">
                            Escolher Imagem
                          </label>
                          <input
                            type="file"
                            name="provider_capa_file"
                            id="provider_capa_file"
                            accept="image/*"
                            onChange={e => {
                              let fileError = false;
                              if (e.target.files) {
                                if (e.target.files[0]?.size > maxSize) {
                                  fileError = true;
                                  addToast({
                                    title: 'Imagem pesada!',
                                    type: 'error',
                                    description: 'Imagem é maior do que 10MB.',
                                  });
                                }
                              }
                              if (!fileError)
                                setProviderImageCapa(e.target.files);
                            }}
                          />
                          <p>Imagens de no máximo 10MB</p>
                        </File>
                      </UploadProfileContent>
                    </UploadProfile>
                  </RowGroup>
                  {isProviderLiberal && (
                    <>
                      <RowGroup>
                        <Input
                          name="link_linkedin"
                          type="text"
                          label="Link do Linkedin:*"
                          placeholder="https://linkedin.com/"
                          defaultValue={provider?.linkedin}
                        />
                        <Input
                          name="link_lattes"
                          type="text"
                          label="Link do Lattes:*"
                          placeholder="http://lattes.cnpq.br/"
                          defaultValue={provider?.lattes}
                        />
                      </RowGroup>
                      <RowGroup>
                        <Choice
                          name="knowledge_level"
                          label="Nível de Conhecimento:*"
                          multiple
                          options={knowledgeLevel}
                          defaultValue={provider?.knowledgeLevel?.map(
                            id => `${id}`,
                          )}
                          className="col-2"
                        />
                        <TextArea
                          name="professional_experience"
                          label="Experiencia profissional:*"
                          placeholder="Trabalhei durante 5 anos na instituição ..."
                          defaultValue={provider?.professional_experience}
                        />
                      </RowGroup>
                    </>
                  )}
                </div>
              ) : (
                <>
                  <div className="global-loading">
                    <BeatLoader size={16} />
                  </div>
                </>
              )}
            </>
          )}
        </Card>
        <Bottom>
          {/* <Buttom label="Cancelar" outline /> */}
          {/* {user.seller ? (
            <Buttom
              onClick={() => {
                handleLmsKey();
                saveGenericLog({
                  userId: user.id,
                  action: 'generated_lms_key',
                });
              }}
              type="button"
              label="Gerar chave LMS"
            />
          ) : undefined} */}
          <Buttom isLoading={loading} label="Atualizar Perfil" />
        </Bottom>
      </Form>
    </Container>
  );
};

export default Profile;
