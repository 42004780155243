/* eslint-disable react/no-danger */
import React from 'react';

import {
  SectionPicutre,
  SectionPictureTitle,
  SectionPictureImage,
} from './styles';

import { IContentSection } from './index';

interface IPicture {
  section: IContentSection;
  count: number;
}

interface IObjectPicture {
  name: string;
  url: string;
  resource: string;
}

const RenderList: React.FC<IPicture> = ({ section, count }) => {
  const pictureObj = JSON.parse(section.content) as IObjectPicture;
  const pictureTitle = pictureObj.name.replace('<p>', '').replace('</p>', '');

  return (
    <SectionPicutre>
      <SectionPictureTitle>
        <strong>FIGURA {count} </strong>&nbsp;-&nbsp;
        <span
          dangerouslySetInnerHTML={{
            __html: pictureTitle,
          }}
        />
      </SectionPictureTitle>
      <SectionPictureImage>
        <img src={pictureObj.url} alt="imagem" />
        <div>Fonte: {pictureObj.resource}</div>
      </SectionPictureImage>
    </SectionPicutre>
  );
};

export default RenderList;
