import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  height: 40px;
  background: #fff;
  border: 1px solid var(--quaternary);
  border-radius: 5px;
  padding: 8px 14px;

  position: relative;

  width: 100%;
  color: var(--dark);
  display: flex;
  align-items: center;
  & + div {
    margin-top: 8px;
  }

  input {
    background: transparent;
    flex: 1;
    width: calc(100% - 20px);
    border: 0;
    color: var(--dark);
    padding-left: 8px;
    &::placeholder {
      color: var(--gray);
      font-style: italic;
      font-family: 'Source Sans Pro', sans-serif;
      font-size: 16px;
    }
  }

  > svg {
    margin-left: 4px;
    fill: var(--gray);
  }
`;
