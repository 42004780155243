/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React, {
  useState,
  useCallback,
  useEffect,
  useMemo,
  Fragment,
} from 'react';
import { format } from 'date-fns';
import { IoMdCloseCircle, IoMdCreate } from 'react-icons/io';
import { useRouteMatch } from 'react-router-dom';
import { AxiosResponse } from 'axios';
import { BeatLoader } from 'react-spinners';
import { ptBR } from 'date-fns/locale';
import { Container, Main, MainContent, Body, Content, Title } from './styles';
import NavTop from '../../components/template/NavTop';
import InputSearch from '../../components/forms/InputSearch';
import {
  AddButton,
  TableContent,
  Options,
  OptionLeft,
} from '../../components/template/TemplateManager/styles';
import Tabs, { TabItem } from '../../components/template/Tabs';
import { TableContainer, TrContainer } from '../myRents/styles';
import Checkbox, { CheckBoxAll } from '../../components/forms/Checkbox';
import IconUser from '../../assets/icons/icon-user-circle-lg.svg';
import { IconAddCircle } from '../../styles/icons';
import {
  deleteSellersRent,
  getSellers,
  putSellers,
  getAllRentsBySeller,
  getPlansProvider,
  changeSellersRents,
  getPlansProviderPrice,
} from '../../services/api';
import { useToast } from '../../hooks/toast';
import Timer from '../../utils/timer';
import { useAuth } from '../../hooks/auth';
import ChoiceEllipse from '../../components/forms/ChoiceEllipse';
import Modal from '../../components/template/Modal';
import ModalCreateRentSeller from '../../components/template/ModalCreateRentSeller';
import {
  ModalContent,
  SelectContainer,
  ModalContentToSuspend,
} from '../sellerControl/styles';

interface ISellerParams {
  id: string;
}

interface ISellerItem {
  rent: {
    id: string;
    status: string;
    createdAt: string;
  };
  provider: {
    imgLogoUrl: string | null;
    companyName: string;
    condition: string;
    itemsCount: string;
    plans_provider_value: {
      value: string;
      planId: string;
    };
  };
  seller: {
    id: string;
    planId: string;
  };
}

interface IPlans {
  id: string;
  name: string;
  description: string;
  maxRent: number;
  minRent: number;
  createdAt: string;
  updatedAt: string;
  provider: {
    imgLogoUrl: string | null;
    companyName: string;
    condition: string;
    itemsCount: string;
    plans_provider_value: {
      value: string;
      planId: string;
    };
  };
}

interface IPlanPrice {
  id: string;
  providerId: string;
  value: string;
  maxRent?: string;
}

interface IChangePlan {
  planId: string;
  rentData: IPlanPrice;
}

interface IFilterPlans {
  providerId?: string;
  planId?: string;
  id?: string;
  name?: string;
  description?: string;
  minRent?: string;
  maxRent?: string;
}

export interface ISellersSearch {
  user: {
    id: string;
    name: string;
    email: string;
    avatar_url: string;
  };
  seller: {
    id: string;
    cnpj: string;
    status: string;
    name: string;
    plans_seller: {
      planId: string;
    };
  };
  provider: {
    company_name: string;
    valueCount: string;
  };
}

const queryTimer = new Timer();

const statusOptions = [
  { id: '1', value: 'active', label: 'Ativos' },
  { id: '2', value: 'requested', label: 'Solicitados' },
  { id: '3', value: 'waiting', label: 'Aguardando' },
];

const RentsControl: React.FC = () => {
  const { params } = useRouteMatch<ISellerParams>();
  const { user } = useAuth();
  const { addToast } = useToast();
  const [search, setSearch] = useState('');
  const [currentTab, setCurrentTab] = useState('tab-02');
  const [checks, setChecks] = useState<Array<string>>([]);
  const [status, setStatus] = useState('all');
  const [loading, setLoading] = useState(false);
  const [ordernation, setOrdernation] = useState('DESC');
  const [isOpenManage, setIsOpenManage] = useState(false);
  const [valueSelect, setValueSelect] = useState('');
  const [plans, setPlans] = useState<IPlans[]>();
  const [itemToRemove, setItemToRemove] = useState<IPlans>();
  const [itemToChangePlan, setItemToChangePlan] = useState<any>();
  const [sellerItems, setSellerItems] = useState<ISellerItem[]>([]);
  const [sellerRentItems, setSellerRentItems] = useState<ISellerItem[]>([]);
  const [changeStatus, setChangeStatus] = useState('');
  const [isOpenToSuspend, setIsOpenToSuspend] = useState(false);
  const [isOpenOrSuspendModal, setIsOpenOrSuspendModal] = useState(false);
  const [providersInitalCopy, setProvidersInitalCopy] = useState<ISellerItem[]>(
    [],
  );
  const [modalCreateNewRent, setModalCreateNewRent] = useState(false);
  const [buttonOpacity, setButtonOpacity] = useState(false);
  const handleTab = useCallback(
    (id: string) => {
      if (currentTab !== id) setLoading(true);
      if (currentTab === 'tab-02') {
        setOrdernation('ASC');
      } else {
        setOrdernation('DESC');
      }
      setCurrentTab(id);
    },
    [currentTab],
  );

  const handleOpenModalManage = useCallback(
    (item: IPlans) => {
      setItemToRemove(item);
      setIsOpenManage(!isOpenManage);
    },
    [isOpenManage],
  );

  const handleOpenModalToSuspend = useCallback(
    (item: IPlans) => {
      setIsOpenToSuspend(!isOpenToSuspend);
      setItemToRemove(item);
    },
    [isOpenToSuspend],
  );

  const handleOpenModalToChangePlan = (item: IPlans) => {
    setIsOpenOrSuspendModal(!isOpenOrSuspendModal);
    setItemToChangePlan(item);
  };

  const urlUserType = useMemo(() => {
    const type = [
      { id: '1', name: 'adm' },
      { id: '2', name: 'diretor' },
    ];
    let res = type.find(item => item.id === user.type)?.name;
    if (!res) {
      res = 'provider';
    }
    return res;
  }, [user.type]);

  const statusOptionsParse = useMemo(() => {
    return statusOptions.map(item => {
      if (item.value === 'all') {
        return {
          ...item,
          label: `${item.label} (${providersInitalCopy.length})`,
        };
      }
      return {
        ...item,
        label: `${item.label} (${
          providersInitalCopy.filter(
            provider => provider.rent.status === item.value,
          ).length
        })`,
      };
    });
  }, [providersInitalCopy]);

  const handleSetProvider = useCallback((sellers: ISellerItem[]) => {
    setSellerItems(sellers);
  }, []);

  const handleSetRent = useCallback((sellers: ISellerItem[]) => {
    setSellerRentItems(
      sellers.map(item => {
        return {
          ...item,
        };
      }),
    );
  }, []);

  useEffect(() => {
    const load = async (): Promise<void> => {
      try {
        // list sellers
        setLoading(true);
        let data: AxiosResponse;
        let newData: AxiosResponse;
        const planProvider = await getPlansProvider({});

        if (status === 'all') {
          data = await getSellers({
            id: params.id,
            order: ordernation,
            companyName: search,
          });

          newData = await getAllRentsBySeller(params.id);
          setProvidersInitalCopy(data.data);
        } else {
          data = await getSellers({
            id: params.id,
            order: ordernation,
            status,
            companyName: search,
          });
          newData = await getAllRentsBySeller(params.id);
          const loadInitial = await getSellers({
            id: params.id,
            // order: ordernation,
            // companyName: search
          });

          setProvidersInitalCopy(loadInitial.data);
        }

        setPlans(planProvider.data);

        // handleSetAllPlans(planProvider.data);
        handleSetProvider(data.data);
        handleSetRent(newData.data);
        setChecks([]);
      } catch (error) {
        addToast({
          title: 'Ops...',
          type: 'error',
          description:
            'Algo inesperado aconteceu, por favor recarrege sua página.',
        });
      } finally {
        setLoading(false);
      }
    };
    queryTimer.register(() => load());
  }, [
    search,
    changeStatus,
    ordernation,
    params.id,
    currentTab,
    urlUserType,
    status,
    addToast,
    handleSetProvider,
    handleSetRent,
  ]);

  const handleStatus = useCallback((value: string) => {
    setStatus(value);
  }, []);

  const handleCheck = useCallback(check => {
    setChecks(check);
  }, []);

  const handleEditSeller = useCallback(() => {
    setModalCreateNewRent(!modalCreateNewRent);
  }, [modalCreateNewRent]);

  const handleSetCreateSeller = useCallback(() => {
    handleEditSeller();
  }, [handleEditSeller]);

  const handleChangeStatus = useCallback(
    (item: ISellerParams) => {
      const load = async (): Promise<void> => {
        if (valueSelect !== '') {
          try {
            const response = await putSellers(item.id, {
              status: valueSelect,
            });
            if (response.data) {
              setChangeStatus(valueSelect);
            }
          } catch (error) {
            addToast({
              title: 'Ops...',
              type: 'error',
              description:
                'Algo inesperado aconteceu, por favor recarrege sua página.',
            });
          }
        }
      };
      load();
      setValueSelect('');
      setIsOpenManage(!isOpenManage);
    },
    [valueSelect, isOpenManage, addToast],
  );

  const handleCancel = useCallback(
    (item: ISellerParams) => {
      const load = async (): Promise<void> => {
        try {
          // aqui vai rota
          await deleteSellersRent(item.id);
          setButtonOpacity(true);
          addToast({
            type: 'success',
            title: 'Soliticitação de cancelamento',
            description: 'A solicitação será enviado ao fornecedor',
          });
        } catch (error) {
          addToast({
            title: 'Ops...',
            type: 'error',
            description:
              'Algo inesperado aconteceu, por favor recarrege sua página.',
          });
        }
      };
      setIsOpenToSuspend(!isOpenToSuspend);
      load();
    },
    [isOpenToSuspend, addToast],
  );

  const handleChangePlan = useCallback(
    (data: IChangePlan) => {
      const load = async (): Promise<void> => {
        try {
          const sendRentData: IFilterPlans = {
            providerId: data.rentData.providerId,
            planId: data.planId,
          };
          const planPrice = await getPlansProviderPrice(sendRentData);

          const dataToChangePlanPrice = {
            id: data.rentData.id,
            planId: data.planId,
            providerId: data.rentData.providerId,
            price: planPrice.data[0].value,
          };

          await changeSellersRents(dataToChangePlanPrice);
          setButtonOpacity(true);

          const rent = await getAllRentsBySeller(params.id);
          setSellerRentItems(rent.data);

          addToast({
            type: 'success',
            title: 'Sucesso!',
            description: 'O Plano foi Alterado com sucesso',
          });
        } catch (error) {
          addToast({
            title: 'Ops...',
            type: 'error',
            description:
              'Algo inesperado aconteceu, por favor recarrege sua página.',
          });
        }
      };
      load();
      setValueSelect('');
      setIsOpenOrSuspendModal(!isOpenOrSuspendModal);
    },
    [isOpenOrSuspendModal, addToast],
  );

  const renderStatus = (statusRent: string) => {
    switch (statusRent) {
      case 'active':
        return 'Ativo';
      case 'migrated':
        return 'Migrado';
      case 'waiting':
        return 'Aguardando';
      case 'requested':
        return 'Solicitado';
      case 'canceled':
        return 'Cancelado';
      default:
        return '';
    }
  };
  return (
    <>
      <ModalCreateRentSeller
        isOpen={modalCreateNewRent}
        setIsOpen={handleEditSeller}
        seller={sellerItems[0]}
      />
      <Container>
        {itemToRemove && (
          <Modal
            isOpen={isOpenManage}
            setIsOpen={() => handleOpenModalManage(itemToRemove)}
          >
            <ModalContent cancel>
              <h1 className="modalTitle">
                Gerenciar status de {itemToRemove.provider.companyName}
              </h1>
              <SelectContainer>
                <label htmlFor="select-status" className="selectTitle">
                  Alterar status para
                </label>
                <select
                  value={valueSelect}
                  onChange={e => setValueSelect(e.target.value)}
                  name="select-status"
                  id="select-status"
                >
                  <option value="">Selecionar</option>
                  <option value="active">Ativo</option>
                  <option value="requested">Solicitado</option>
                  <option value="waiting">Aguardando</option>
                </select>
              </SelectContainer>
              <section>
                <button
                  onClick={() => handleOpenModalManage(itemToRemove)}
                  className="cancel"
                  type="button"
                >
                  Cancelar
                </button>
                <button
                  onClick={() => handleChangeStatus(itemToRemove)}
                  type="button"
                >
                  Salvar
                </button>
              </section>
            </ModalContent>
          </Modal>
        )}

        {itemToRemove && (
          <Modal
            isOpen={isOpenToSuspend}
            setIsOpen={() => handleOpenModalToSuspend(itemToRemove)}
          >
            <ModalContentToSuspend cancel>
              <h1 className="modalTitle">
                Tem certeza que deseja cancelar assinatura de{' '}
                {itemToRemove.provider.companyName}?
              </h1>
              <section>
                <button
                  onClick={() => handleCancel(itemToRemove)}
                  type="button"
                >
                  SIM
                </button>
                <button
                  className="cancel"
                  onClick={() => handleOpenModalToSuspend(itemToRemove)}
                  type="button"
                >
                  NÂO
                </button>
              </section>
            </ModalContentToSuspend>
          </Modal>
        )}

        {itemToChangePlan && plans && (
          <Modal
            isOpen={isOpenOrSuspendModal}
            setIsOpen={() => handleOpenModalToChangePlan(itemToChangePlan)}
          >
            <ModalContent cancel>
              <h1 className="modalTitle">
                Gerenciar aluguel de {itemToChangePlan.provider.companyName}
              </h1>
              <SelectContainer>
                <label htmlFor="select-status" className="selectTitle">
                  Plano
                </label>
                <select
                  value={valueSelect}
                  onChange={e => setValueSelect(e.target.value)}
                  name="select-status"
                  id="select-status"
                >
                  {plans.map((plan: IPlans) => (
                    <option value={plan.id}>{plan.name}</option>
                  ))}
                  <option value="">Selecionar</option>
                </select>
              </SelectContainer>
              <section>
                <button
                  onClick={() => handleOpenModalToChangePlan(itemToChangePlan)}
                  className="cancel"
                  type="button"
                >
                  Cancelar
                </button>
                <button
                  onClick={() =>
                    handleChangePlan({
                      planId: valueSelect,
                      rentData: itemToChangePlan,
                    })
                  }
                  type="button"
                >
                  Salvar
                </button>
              </section>
            </ModalContent>
          </Modal>
        )}

        <Main>
          <MainContent>
            <NavTop />
            <Body className="body slideDown">
              <Content>
                <Title>
                  <h1>Alugueis</h1>
                  <ChoiceEllipse
                    name="status"
                    itens={statusOptionsParse}
                    defaultValue={status}
                    handleStatus={handleStatus}
                  />
                </Title>
                <Options>
                  <section className="search">
                    <InputSearch
                      name="search"
                      onChange={e => setSearch(e.target.value)}
                      placeholder="Pesquisar"
                      value={search}
                    />
                  </section>
                  <OptionLeft style={{ flexDirection: 'row-reverse' }}>
                    <AddButton onClick={handleSetCreateSeller}>
                      <IconAddCircle /> Novo Aluguel
                    </AddButton>
                  </OptionLeft>
                </Options>
              </Content>
              <TableContent>
                <Tabs
                  itens={[
                    { id: 'tab-01', label: 'A-Z' },
                    { id: 'tab-02', label: 'Mais Recentes' },
                  ]}
                  defaultValue={currentTab}
                  handleTab={handleTab}
                />
                {loading ? (
                  <div className="loading">
                    <BeatLoader size={16} />
                  </div>
                ) : (
                  <>
                    <TabItem id="tab-01" defaultValue={currentTab}>
                      <TableContainer>
                        <thead>
                          <tr>
                            <th>
                              <CheckBoxAll
                                ids={sellerItems.map(item => item.rent.id)}
                                handleCheckAll={handleCheck}
                              />
                            </th>
                            <th>Fornecedor</th>
                            <th>Valor</th>
                            <th>Status</th>
                            <th>Data de aquisição</th>
                            <th />
                          </tr>
                        </thead>
                        <tbody>
                          {sellerItems.length === 0 && (
                            <tr>
                              <td colSpan={5}>
                                <p style={{ textAlign: 'center' }}>
                                  Nenhum item encontrado{' '}
                                  <span role="img" aria-label="wow">
                                    😮
                                  </span>
                                </p>
                              </td>
                            </tr>
                          )}
                          {currentTab === 'tab-01' &&
                            sellerItems.map((seller: any) => (
                              <Fragment key={seller.rent.id}>
                                <TrContainer
                                  buttonOpacity={
                                    itemToRemove?.id === seller.rent.id
                                      ? buttonOpacity
                                      : false
                                  }
                                >
                                  <td>
                                    <Checkbox
                                      name="check"
                                      handleCheck={handleCheck}
                                      checks={checks}
                                      id={seller.rent.id}
                                    />
                                  </td>
                                  <td className="provider-rents-control">
                                    <section>
                                      <img
                                        src={
                                          seller.provider.imgLogoUrl
                                            ? seller.provider.imgLogoUrl
                                            : IconUser
                                        }
                                        alt=""
                                      />
                                      <p>{seller.provider.companyName}</p>
                                    </section>
                                  </td>
                                  <td className="value-rents-control">
                                    <p>
                                      R${' '}
                                      <span>
                                        {
                                          seller.provider.plans_provider_value
                                            .value
                                        }
                                        /mês
                                      </span>
                                    </p>
                                  </td>
                                  <td className="send-rents-control">
                                    <button
                                      onClick={() =>
                                        handleOpenModalManage(seller)
                                      }
                                      type="button"
                                    >
                                      ENVIAR PARA
                                    </button>
                                  </td>
                                  <td>
                                    <p style={{ fontSize: '0.75em' }}>
                                      {format(
                                        new Date(seller.rent.createdAt),
                                        "dd 'de' MMMM 'de' yyyy",
                                        {
                                          locale: ptBR,
                                        },
                                      )}
                                    </p>
                                  </td>
                                  <td className="cancel-rents-control">
                                    {itemToRemove?.id === seller.id ? (
                                      <button
                                        type="button"
                                        style={{ padding: '7px 10px' }}
                                      >
                                        <IoMdCloseCircle
                                          color="#3D3939"
                                          size={11}
                                        />
                                        Cancelar assinatura
                                      </button>
                                    ) : (
                                      <button
                                        type="button"
                                        style={{ padding: '7px 10px' }}
                                        onClick={() =>
                                          handleOpenModalToSuspend(seller)
                                        }
                                      >
                                        <IoMdCloseCircle
                                          color="#3D3939"
                                          size={11}
                                        />
                                        Cancelar assinatura
                                      </button>
                                    )}
                                  </td>
                                </TrContainer>
                              </Fragment>
                            ))}
                        </tbody>
                      </TableContainer>
                    </TabItem>
                    <TabItem id="tab-02" defaultValue={currentTab}>
                      <TableContainer>
                        <thead>
                          <tr>
                            <th>
                              <CheckBoxAll
                                ids={sellerItems.map(item => item.rent.id)}
                                handleCheckAll={handleCheck}
                              />
                            </th>
                            <th>Fornecedor</th>
                            <th>Valor</th>
                            <th>Status</th>
                            <th>Plano</th>
                            <th>Data de aquisição</th>
                            <th />
                          </tr>
                        </thead>
                        <tbody>
                          {sellerRentItems.length === 0 && (
                            <tr>
                              <td colSpan={5}>
                                <p style={{ textAlign: 'center' }}>
                                  Nenhum item encontrado{' '}
                                  <span role="img" aria-label="wow">
                                    😮
                                  </span>
                                </p>
                              </td>
                            </tr>
                          )}
                          {currentTab === 'tab-02' &&
                            sellerRentItems
                              .filter(
                                (seller: any) => seller.status !== 'canceled',
                              )
                              .map((seller: any) => (
                                <Fragment key={seller.id}>
                                  <TrContainer>
                                    <td>
                                      <Checkbox
                                        name="check"
                                        handleCheck={handleCheck}
                                        checks={checks}
                                        id={seller.id}
                                      />
                                    </td>
                                    <td className="provider-rents-control">
                                      <section>
                                        <img
                                          src={
                                            seller.provider.imgLogoUrl
                                              ? seller.provider.imgLogoUrl
                                              : IconUser
                                          }
                                          alt=""
                                        />
                                        <p>{seller.provider.companyName}</p>
                                      </section>
                                    </td>
                                    <td className="value-rents-control">
                                      <p>
                                        R${' '}
                                        <span>
                                          {seller.planValue}
                                          /mês
                                        </span>
                                      </p>
                                    </td>

                                    <td className="send-rents-control">
                                      <button
                                        type="button"
                                        onClick={() =>
                                          handleOpenModalManage(seller)
                                        }
                                      >
                                        {renderStatus(seller.status)}
                                      </button>
                                    </td>
                                    <td className="send-rents-control">
                                      <p
                                        style={{
                                          fontSize: '13px',
                                          fontWeight: 500,
                                          color: '#8d8585',
                                        }}
                                      >
                                        <span>{seller.planName}</span>
                                      </p>
                                    </td>
                                    <td>
                                      <p style={{ fontSize: '0.75em' }}>
                                        {format(
                                          new Date(seller.createdAt),
                                          "dd 'de' MMMM 'de' yyyy",
                                          {
                                            locale: ptBR,
                                          },
                                        )}
                                      </p>
                                    </td>
                                    <td className="cancel-rents-control">
                                      {itemToRemove?.id === seller.id ? (
                                        <button
                                          type="button"
                                          style={{ padding: '7px 10px' }}
                                        >
                                          <IoMdCloseCircle
                                            color="#3D3939"
                                            size={11}
                                          />
                                          Cancelar assinatura
                                        </button>
                                      ) : (
                                        <button
                                          type="button"
                                          onClick={() =>
                                            handleOpenModalToSuspend(seller)
                                          }
                                          style={{ padding: '7px 10px' }}
                                        >
                                          <IoMdCloseCircle
                                            color="#3D3939"
                                            size={11}
                                          />
                                          Cancelar assinatura
                                        </button>
                                      )}
                                    </td>
                                    <td className="cancel-rents-control">
                                      <button
                                        type="button"
                                        onClick={() =>
                                          handleOpenModalToChangePlan(seller)
                                        }
                                        style={{ padding: '7px 10px' }}
                                        title="Editar assinatura"
                                      >
                                        <IoMdCreate color="#3D3939" size={20} />
                                      </button>
                                    </td>
                                  </TrContainer>
                                </Fragment>
                              ))}
                        </tbody>
                        <br />
                      </TableContainer>
                    </TabItem>
                  </>
                )}
              </TableContent>
            </Body>
          </MainContent>
        </Main>
      </Container>
    </>
  );
};

export default RentsControl;
