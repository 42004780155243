import React from 'react';

import logoYellow from '../../../assets/images/logo-new-yellow.svg';
import { menuPermission } from '../../../data/menuPermisson';
import { useAuth } from '../../../hooks/auth';
import { generateId } from '../../../utils/generateId';

import { Container, Brand, MenuLink, MenuItem } from './styles';

const Sidebar: React.FC = () => {
  const { user } = useAuth();

  return (
    <Container>
      <Brand>
        <MenuLink to="/dashboard">
          <img src={logoYellow} alt="Áurea" width="100%" />
        </MenuLink>
      </Brand>
      <div>
        {menuPermission[user.type].map(({ url, label, icon: ICon }) => (
          <MenuItem key={generateId()}>
            <MenuLink to={url}>
              <ICon />
              <span>{label}</span>
            </MenuLink>
          </MenuItem>
        ))}
      </div>
    </Container>
  );
};

export default Sidebar;
