import { firebaseLogs } from './firebaseConnection';
import 'firebase/firestore';

interface ITemporaryLink {
  lms_key: string;
  item_id?: string;
  expiration_date: string;
  link: string;
}

const dataBaseLogs = firebaseLogs.firestore();

export const insertTemporaryLink = async (
  data: ITemporaryLink,
): Promise<void> => {
  await dataBaseLogs
    .collection('temporary-links')
    .add(data)
    .catch(err => {
      console.error(`Ocorreu um erro ${err}`);
    });
};
