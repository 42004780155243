import React, { useCallback, useEffect, useState, useRef } from 'react';
import { IconAdd, IconCloseX } from '../../../styles/icons';

import { Container, InputItem } from './styles';
import { generateId } from '../../../utils/generateId';

interface IInput {
  id: string;
  value: string;
}

interface IInputMany {
  icon?: React.ComponentType;
  placeholder?: string;
  wrap?: boolean;
  defaultValue?: IInput[];
  handleInputs(authorsArray: Array<string>): void;
}

const InputMany: React.FC<IInputMany> = ({
  icon: Icon,
  placeholder,
  wrap,
  handleInputs,
  defaultValue,
}) => {
  const firstLoad = useRef(false);

  const [inputs, setInputs] = useState<IInput[]>(() => {
    if (defaultValue) {
      if (
        defaultValue.filter(input => input.value !== '').length >= 1 &&
        defaultValue.filter(input => input.value === '').length < 1
      ) {
        defaultValue.push({ id: generateId(), value: '' });
      } else if (defaultValue.length === 0) {
        defaultValue.push({ id: generateId(), value: '' });
      }
      return defaultValue;
    }
    return [{ id: generateId(), value: '' }];
  });

  useEffect(() => {
    if (
      defaultValue &&
      defaultValue.filter(item => item.value !== '').length > 0 &&
      firstLoad.current === false
    ) {
      // return;
      firstLoad.current = true;
      // alert(JSON.stringify(defaultValue));
      if (
        defaultValue.filter(input => input.value !== '').length >= 1 &&
        defaultValue.filter(input => input.value === '').length < 1
      ) {
        defaultValue.push({ id: generateId(), value: '' });
      }

      setInputs(defaultValue); // Quebrando a aplicação?
    }
  }, [defaultValue]);

  useEffect(() => {
    handleInputs(
      inputs.filter(input => input.value !== '').map(input => input.value),
    );
  }, [handleInputs, inputs]);

  const addInputs = useCallback(() => {
    setInputs([...inputs, { id: generateId(), value: '' }]);
  }, [inputs]);

  const removeInputs = useCallback(
    id => {
      if (inputs.length <= 1) {
        return;
      }
      const newInputs = inputs.filter(input => id !== input.id);

      setInputs(newInputs);
    },
    [inputs],
  );

  const handleText = useCallback(
    (id: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
      if (
        inputs.filter(input => input.value !== '').length >= 1 &&
        inputs.filter(input => input.value === '').length < 1
      ) {
        setInputs([...inputs, { id: generateId(), value: '' }]);
        return;
      }
      const newInputs = inputs.map(input => {
        if (input.id === id) {
          return { ...input, value: e.target.value };
        }
        return { ...input };
      });

      setInputs(newInputs);
    },
    [inputs],
  );

  return (
    <Container>
      {Icon && <Icon />}
      <div className={`${wrap && `wrap`}`}>
        {inputs.map(input => (
          <InputItem key={`${input.id}-input-many`}>
            <input
              type="text"
              placeholder={placeholder}
              defaultValue={input.value
                .replace(/<p>/g, ' ')
                .replace(/<\/p>/g, ' ')}
              onChange={handleText(input.id)}
              onKeyDown={event =>
                event.key === 'Enter' && event.preventDefault()
              }
            />
            {!input.value ? (
              <IconAdd onClick={() => addInputs()} />
            ) : (
              <IconCloseX onClick={() => removeInputs(input.id)} />
            )}
          </InputItem>
        ))}
      </div>
    </Container>
  );
};

export default InputMany;
