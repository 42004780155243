/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { useCallback, useEffect, useState } from 'react';
import Select from '../../forms/ReactSelect';

import Buttom from '../../forms/Buttom';
import Modal from '../Modal';
import {
  Container,
  Bottom,
  ContentForm,
  ContentField,
  SelectContent,
} from './styles';
import { useToast } from '../../../hooks/toast';
import { useAuth } from '../../../hooks/auth';
import { filterUsers, putEditReview } from '../../../services/api';
import { saveManageDelegationUnitsLog } from '../../../services/logs';

interface IReviewEdit {
  reviewId: string;
  curatorId: string;
  reviewerId: string;
  itemId: string;
}

interface IModalProps {
  isOpen: boolean;
  setIsOpen: () => void;
  handleInitalState(): void;
  review: IReviewEdit;
}

const ModalDelegateEdit: React.FC<IModalProps> = ({
  isOpen,
  setIsOpen,
  handleInitalState,
  review,
}) => {
  const { addToast } = useToast();
  const { user } = useAuth();

  const [curatorSelected, setCuratorSelected] = useState(() => {
    return review.curatorId;
  });
  const [reviewSelected, setReviewSelected] = useState(() => {
    return review.curatorId;
  });

  const [curatorList, setCuratorList] = useState([]);
  const [reviewList, setReviewList] = useState([]);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const load = async (): Promise<void> => {
      try {
        const [resCurator, resReview] = await Promise.all([
          filterUsers({
            type: 'curator',
          }),
          filterUsers({
            type: 'review',
          }),
        ]);
        setCuratorList(
          resCurator.data.map((item: any) => {
            return { label: item.name, value: item.id };
          }),
        );
        setReviewList(
          resReview.data.map((item: any) => {
            return { label: item.name, value: item.id };
          }),
        );
      } catch (error) {
        addToast({
          title: 'Ops ...',
          description: 'Aconteceu algo inesperado, por favor tente mais tarde',
          type: 'error',
        });
      }
    };
    load();
  }, [addToast]);

  useEffect(() => {
    setCuratorSelected(review.curatorId);
    setReviewSelected(review.reviewerId);
  }, [review.curatorId, review.reviewerId]);

  const handleSubmit = useCallback(async () => {
    setLoading(true);
    if (curatorSelected === '' || reviewSelected === '') {
      addToast({
        title: 'Ops ...',
        type: 'error',
        description: 'Preencha todos os campos',
      });
      setLoading(false);

      return;
    }

    try {
      await putEditReview({
        curatorId: curatorSelected,
        reviewerId: reviewSelected,
        reviewIds: [review.reviewId],
      });

      saveManageDelegationUnitsLog({
        userId: user.id,
        action: 'updated_delegate_unity',
        unityId: review.itemId,
        curatorId: curatorSelected,
        reviewerId: reviewSelected,
      });

      addToast({
        title: 'Sucesso!',
        type: 'success',
        description: 'Delegação editada com sucesso!',
      });
      handleInitalState();
      setIsOpen();
    } catch (error) {
      addToast({
        title: 'Ops ...',
        type: 'error',
        description: 'Algo inesperado aconteceu, por favor tente mais tarde',
      });
    } finally {
      setLoading(false);
    }
  }, [
    addToast,
    curatorSelected,
    reviewSelected,
    setIsOpen,
    handleInitalState,
    review.reviewId,
  ]);

  const handleCurator = useCallback((value: string) => {
    setCuratorSelected(value);
  }, []);

  const handleReview = useCallback((value: string) => {
    setReviewSelected(value);
  }, []);
  return (
    <Modal isOpen={isOpen} setIsOpen={setIsOpen} width="35%">
      <Container>
        <h1>Editar Delegação</h1>
        <ContentForm>
          <ContentField>
            <label htmlFor="curador">Delegar Curador</label>
            <SelectContent>
              <Select
                name="curador"
                options={curatorList}
                placeholder="Selecionar ..."
                handleValue={handleCurator}
                defaultValue={review.curatorId}
              />
            </SelectContent>
          </ContentField>

          <ContentField>
            <label htmlFor="revisor">Delegar Revisor</label>
            <SelectContent>
              <Select
                name="revisor"
                options={reviewList}
                placeholder="Selecionar ..."
                handleValue={handleReview}
                defaultValue={review.reviewerId}
              />
            </SelectContent>
          </ContentField>
          <Bottom>
            <Buttom label="Cancelar" outline onClick={() => setIsOpen()} />
            <Buttom
              label="Salvar"
              isLoading={loading}
              onClick={() => handleSubmit()}
            />
          </Bottom>
        </ContentForm>
      </Container>
    </Modal>
  );
};

export default ModalDelegateEdit;
