import styled from 'styled-components';

export const Container = styled.div`
  padding: 20px 18px;
`;

export const RowGroup = styled.div`
  width: 100%;
  > div:first-child {
    width: 54%;
    margin-right: 12px;
    float: left;
  }

  > div:last-child {
    width: 42%;
    float: right;
    margin-top: 52px;
    > div {
      > div {
        min-height: 42px; // React Selec Hight
      }
    }
  }
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;

  div {
    width: 100%;
  }

  div:first-child {
    width: 54%;
    margin-right: 12px;
  }
`;

export const Bottom = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  margin-top: 24px;

  div + div {
    margin-left: 18px;
  }
`;

export const Both = styled.div`
  clear: both;
`;
