import React, { useEffect, useState } from 'react';
import ReactModal from 'react-modal';

interface IModalProps {
  children: any;
  isOpen: boolean;
  setIsOpen: () => void;
  width?: string;
  padding?: string;
  overflow?: string;
}

const Modal: React.FC<IModalProps> = ({
  children,
  isOpen,
  setIsOpen,
  width,
  padding,
  overflow,
}) => {
  const [modalStatus, setModalStatus] = useState(isOpen);
  useEffect(() => {
    setModalStatus(isOpen);
  }, [isOpen]);

  return (
    <ReactModal
      shouldCloseOnOverlayClick={!false}
      onRequestClose={setIsOpen}
      isOpen={modalStatus}
      ariaHideApp={false}
      style={{
        content: {
          borderTop: '5px solid #f6c866',
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
          background: '#fff',
          color: '#000000',
          borderRadius: '20px',
          width: width || '652px',
          padding: padding || '20px',
          border: 'none',
          boxShadow: '2px 2px 8px rgba(0, 0, 0, 0.2)',
          maxWidth: '80%',
          minWidth: '35%',
          overflow: overflow || 'auto',
        },
        overlay: {
          backgroundColor: 'rgba(177, 177, 177, 0.0)',
        },
      }}
    >
      {children}
    </ReactModal>
  );
};

export default Modal;
