import React, { InputHTMLAttributes, useRef, useEffect, useState } from 'react';
import { useField } from '@unform/core';

import { Container, Content, IconCloseCircle } from './styles';
import { IconMetroEye } from '../../../styles/icons';

interface IInputProps extends InputHTMLAttributes<HTMLInputElement> {
  label: string;
  name: string;
  disabled?: boolean;
}

const Input: React.FC<IInputProps> = ({
  name,
  label,
  type,
  disabled,
  hidden,
  value,
  ...rest
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const { fieldName, defaultValue, error, registerField } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);
  const [inputType, setInputType] = useState(type);

  return (
    <Container hidden={hidden}>
      <label>{label}</label>
      <Content disabled={disabled} isErrored={!!error}>
        {value ? (
          <input
            defaultValue={defaultValue}
            type={inputType}
            ref={inputRef}
            disabled={disabled}
            value={value}
            {...rest}
          />
        ) : (
          <input
            defaultValue={defaultValue}
            type={inputType}
            ref={inputRef}
            disabled={disabled}
            {...rest}
          />
        )}
        {type === 'password' && (
          <IconMetroEye
            className={`eye${inputType === type ? '' : ` open`}`}
            onClick={() => {
              setInputType(inputType === type ? 'text' : type);
            }}
          />
        )}
        {error && <IconCloseCircle />}
      </Content>
      {error && <small>{error}</small>}
    </Container>
  );
};

export default Input;
