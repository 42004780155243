import styled, { css } from 'styled-components';
import { IconAddressBook, IconAlert } from '../../styles/icons';

interface IPropsCard {
  isSelected?: boolean;
}

export const Container = styled.div`
  display: flex;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100vh;
  margin: 0 auto;
  max-width: 700px;
  padding: 24px 0;
  overflow-y: auto;
`;

export const ContentForm = styled.div`
  padding: 0 14px;
  width: 100%;
  margin: 0 auto;
  h1 {
    text-align: center;
    margin-bottom: 24px;
  }

  @media (min-width: 768px) {
    max-width: 450px;
  }
`;

export const BottomForm = styled.div`
  margin-top: 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  a {
    color: var(--dark);
    text-decoration: underline;
  }
`;

export const ContentBottom = styled.div`
  margin-top: 32px;
  a {
    font-weight: 700;
    color: var(--aurea);
  }
`;

export const SelectProfile = styled.div`
  width: 100%;
  margin-bottom: 32px;
`;

export const SelectProfileContent = styled.div`
  width: 320px;
  margin: 0 auto;

  display: flex;
  flex-direction: row;
  align-items: center;

  a:hover {
    text-decoration: none;
  }
`;

export const Card = styled.div<IPropsCard>`
  width: 170px;

  display: flex;
  align-items: center;
  justify-content: center;

  padding: 14px 18px;
  cursor: pointer;

  font-weight: 700;
  font-size: 18px;
  text-transform: uppercase;
  color: var(--gray);

  border-top-left-radius: 5px;
  border-top-right-radius: 5px;

  background: transparent;
  border-bottom: 4px solid var(--gray);
  opacity: 0.45;
  ${props =>
    props.isSelected &&
    css`
      background: #fff;
      border-bottom: 4px solid var(--aurea);
      opacity: 1;
    `}
`;

export const IconBookAddress = styled(IconAddressBook)`
  width: 20px;
  height: 20px;
  margin-right: 8px;
  fill: var(--aurea);
`;

export const SelectForm = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  margin-top: 25px;

  div + div {
    margin-left: 18px;
  }
`;

export const SelectFormCircle = styled.div`
  width: 26px;
  height: 26px;
  border-radius: 50%;

  background: var(--aurea);
  opacity: 0.4;

  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  position: relative;
  &::after {
    content: '';
    position: absolute;
    display: block;
    width: 18px;
    height: 1px;
    background: var(--aurea);
    right: -18px;
  }
  &:last-child::after {
    display: none;
  }

  &.selected {
    opacity: 1;
  }
`;

export const AltText = styled.div`
  font-weight: 500;
  font-size: 16px;
  margin-top: 20px;
  border-radius: 10px;
  padding: 15px;
  background: #f8ebd2;
  color: #3d3939;
  display: flex;
  label {
    margin-left: 10px;
    margin-right: 25px;
  }
  svg {
    margin-top: -15px;
  }
`;

export const IconAlertAlt = styled(IconAlert)`
  margin-left: 15px;
  width: 72px;
  height: 72px;
  margin-right: 8px;
  color: #f6c866;
`;
