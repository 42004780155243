import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
`;

export const Main = styled.main`
  display: flex;
  flex: 1;
  height: 100vh;
  overflow-y: auto;
`;
export const MainContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100vh;
`;

export const Body = styled.div`
  width: 100%;
  max-width: 812px;
  height: 100vh;

  margin: 0 auto 18px auto;
  h1 {
    font-size: 2.25rem;
  }

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Sidebar = styled.div`
  height: 100vh;
  background: #fff;

  border-right: 1px solid var(--gray-border);
  width: 293px;

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const TxtSidebar = styled.div`
  margin-top: 24px;
  a {
    font-weight: 700;
    color: var(--aurea);
  }

  svg {
    margin-right: 4px;
  }
`;

export const Card = styled.div`
  padding: 46px 0;
  background: white;
  border-radius: 20px;
  width: 90%;
  text-align: center;

  h2 {
    font-weight: bold;
    font-size: 2rem;
  }

  p {
    font-size: 1.25rem;
  }

  button {
    margin-top: 36px;
    padding: 12px 32px !important;
    font-size: 1rem;
  }
`;

export const Loading = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;
