import React, { InputHTMLAttributes, useRef, useEffect, useState } from 'react';
import { useField } from '@unform/core';

import InputMask from 'react-input-mask';
import { Container, Content, IconCloseCircle } from './styles';
import { IconMetroEye } from '../../../styles/icons';

interface IInputProps extends InputHTMLAttributes<HTMLInputElement> {
  label: string;
  name: string;
  mask: string;
  disabled?: boolean;
}

const Input: React.FC<IInputProps> = ({
  name,
  label,
  type,
  disabled,
  mask,
  ...rest
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const { fieldName, defaultValue, error, registerField } = useField(name);
  const [value, setValue] = useState('');
  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  return (
    <Container>
      <label>{label}</label>
      <Content disabled={disabled} isErrored={!!error}>
        <InputMask
          defaultValue={defaultValue}
          type={type}
          mask={mask}
          onChange={e => setValue(e.target.value)}
          {...rest}
        />
        <input type="text" ref={inputRef} value={value} hidden />
        {type === 'password' && <IconMetroEye />}
        {error && <IconCloseCircle />}
      </Content>
      {error && <small>{error}</small>}
    </Container>
  );
};

export default Input;
