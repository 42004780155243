/* eslint-disable array-callback-return */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-pascal-case */
import React, { useState, useCallback, HTMLAttributes } from 'react';

import { Container, Content, Card } from './styles';

interface ICardProps extends HTMLAttributes<HTMLDivElement> {
  itens: {
    id: string;
    label: string;
    icon?: React.ComponentType;
  }[];
  multiple?: boolean;
  defaultValue?: string;
  handleSelectUserType(id: string): void;
}

const ChooseCard: React.FC<ICardProps> = ({
  itens,
  defaultValue,
  handleSelectUserType,
  ...rest
}) => {
  const [checked, setChecked] = useState(() => {
    return itens.map(item => {
      return { id: item.id, checked: defaultValue === item.id };
    });
  });

  const handleClick = useCallback(
    id => {
      let findId = '';
      const newChecked = checked.map((check, i) => {
        if (check.id === id) {
          findId = id;
          return {
            ...check,
            checked: check.checked === true ? true : !check.checked,
          };
        }

        return { ...check, checked: false };
      });

      handleSelectUserType(findId);
      setChecked(newChecked);
    },
    [checked, handleSelectUserType],
  );

  return (
    <Container {...rest}>
      <label htmlFor="choose">Tipo de Perfil:*</label>
      <Content>
        <div className="row1">
          {itens.map((Item, i) => {
            if (i < 2) {
              return (
                <Card
                  style={{ marginBottom: '15px' }}
                  key={`card-choose-${i}`}
                  onClick={() => handleClick(Item.id)}
                  isChecked={
                    checked.find(value => value.id === Item.id)?.checked
                  }
                >
                  {Item.icon && <Item.icon />}
                  {Item.label}
                </Card>
              );
            }
          })}
        </div>
        <div className="row2">
          {itens.map((Item, i) => {
            if (i >= 2) {
              return (
                <Card
                  style={{ marginBottom: '15px' }}
                  key={`card-choose-${i}`}
                  onClick={() => handleClick(Item.id)}
                  isChecked={
                    checked.find(value => value.id === Item.id)?.checked
                  }
                >
                  {Item.icon && <Item.icon />}
                  {Item.label}
                </Card>
              );
            }
          })}
        </div>
      </Content>
    </Container>
  );
};

export default ChooseCard;
