import React, { useState } from 'react';
import Buttom from '../../forms/Buttom';
import Modal from '../Modal';
import Upload from '../../forms/Upload';
import { Container, Radio, RadioContent, Bottom } from './styles';

interface IModalProps {
  isOpen: boolean;
  setIsOpen: () => void;
}

interface IFileProps {
  file: File;
  name: string;
  readableSize: string;
}

const ModalCsv: React.FC<IModalProps> = ({ isOpen, setIsOpen }) => {
  const [uploadedFiles, setUploadedFiles] = useState<IFileProps[]>([]);

  function submitFile(files: File[]): void {
    // TODO
    const getFiles: IFileProps[] = [];

    files.forEach(file => {
      getFiles.push({
        name: file.name,
        readableSize: `${String(Math.fround(file.size / 1024).toFixed(2))}KB`,
        file,
      });
    });

    setUploadedFiles([...uploadedFiles, ...getFiles]);
  }
  return (
    <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
      <Container>
        <h1>Importar CSV</h1>
        <Radio>
          <label>Tipo de usuário:*</label>
          <RadioContent>
            <div>
              <input type="radio" name="type" id="type-01" />
              <label htmlFor="type-01">Administrador</label>
            </div>
            <div>
              <input type="radio" name="type" id="type-02" />
              <label htmlFor="type-02">Diretor</label>
            </div>
            <div>
              <input type="radio" name="type" id="type-03" />
              <label htmlFor="type-03">Gerente</label>
            </div>
            <div>
              <input type="radio" name="type" id="type-04" />
              <label htmlFor="type-04">Curador</label>
            </div>
            <div>
              <input type="radio" name="type" id="type-05" />
              <label htmlFor="type-05">Revisor</label>
            </div>
          </RadioContent>
        </Radio>
        <Upload
          onUpload={submitFile}
          formts={[
            { extension: '.csv', types: 'application/vnd.ms-excel, text/csv' },
          ]}
        />
        <p>
          Todos os usuários do arquivo serão cadastrados no tipo de usuário
          selecionado acima.
        </p>
        <Bottom>
          <Buttom label="Cancelar" outline onClick={() => setIsOpen()} />
          <Buttom label="Salvar" />
        </Bottom>
      </Container>
    </Modal>
  );
};

export default ModalCsv;
