import styled from 'styled-components';

export const Container = styled.div`
  display: inline;
  margin: 20px 120px 0px;
  font-family: Source Sans Pro;
  .userTitle {
    margin-left: 70px;
  }
`;

export const Content = styled.div`
  display: flex;
  justify-content: center;
`;
export const LeftSideContainer = styled.div`
  display: inline-block;
  width: 50%;
  margin: 20px 20px 0px;
`;
export const RightSideContainer = styled.div`
  display: inline-block;
  width: 20%;
  margin: 20px 20px 0px;
`;

export const ApprovedContainer = styled.div`
  width: 100%;
  margin: 20px;
  height: 220px;
  background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 9px #00000008;
  border: 1px solid #f8f5f5;
  border-radius: 20px;
  opacity: 1;
  h3 {
    font-size: 22px;
    font-weight: 600;
    padding: 30px 30px 0px;
  }
`;

export const Navigation = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ApprovedList = styled.ul`
  list-style: none;
  width: 90%;
  margin-left: 35px;
  li:last-child {
    border: none;
  }
`;

export const ApprovedItem = styled.li`
  display: flex;
  justify-content: space-between;
  padding: 7px 0px;
  border-bottom: 1px solid #e0dede;
`;

export const ItemTitle = styled.div`
  width: 45%;
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-left: -5%;
`;

export const KnowledgeItem = styled.div`
  display: flex;
  color: #8d8585;
  width: 40%;
  font-size: 14px;
  svg {
    margin-right: 5px;
    margin-top: -5px;
  }
`;

export const StatusContainer = styled.div`
  text-transform: uppercase;
  background: #6bc19645 0% 0% no-repeat padding-box;
  border-radius: 5px;
  width: 82px;
  height: 24px;
  text-align: center;
  font-weight: 600;
  color: #6bc196;
  font-size: 12px;
  padding-top: 5px;
  justify-self: center;
`;

export const LeftSide = styled.div``;

export const RightSide = styled.div`
  padding: 30px;
  display: inherit;
  select {
    width: 200px;
    height: 40px;
    color: #8d8585;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #e0dede;
    border-radius: 5px;
    padding-left: 5px;
    padding-right: 5px;
    font-family: 'Source Sans Pro';
    margin-right: 15px;
  }
`;

export const ButtonFilterRents = styled.button`
  width: 40px;
  height: 40px;
  background: white;
  border: 1px solid #e0dede;
  border-radius: 5px;
  svg {
    display: flex;
    margin-top: 5px;
    margin-left: 5px;
  }
  .letter {
    color: #8d8585;
    font-size: 12px;
    margin-left: 15px;
    margin-top: -25px;
  }
`;

export const ButtonSeeMore = styled.button`
  width: 93px;
  height: 30px;
  border: 1.5px solid #e0dede;
  background: white;
  border-radius: 5px;
  font-size: 12px;
  font-weight: 600;
  margin-right: 17px;
`;

export const RentsContainer = styled.div`
  width: 100%;
  margin: 20px;
  height: 330px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 9px #00000008;
  border: 1px solid #f8f5f5;
  border-radius: 20px;
  opacity: 1;
  h3 {
    font-size: 22px;
    font-weight: 600;
    padding: 30px 30px 0px;
  }
`;

export const RentsList = styled.ul`
  list-style: none;
  width: 90%;
  margin-left: 35px;
  li:last-child {
    border: none;
  }
`;
export const RentItem = styled.li`
  display: flex;
  justify-content: space-between;
  padding: 7px 0px;
  border-bottom: 1px solid #e0dede;
`;
export const TitleRent = styled.h4`
  font-size: 14px;
  color: #3d3939;
  width: 45%;
`;
export const DateRent = styled.p`
  font-size: 12px;
  color: #8d8585;
`;
export const TypeRent = styled.div`
  display: flex;
  margin-top: 9px;
  color: #8d8585;
  font-size: 14px;
  width: 20%;
  svg {
    margin-right: 10px;
    margin-top: -3 px;
  }
`;
export const OptionsRent = styled.div`
  margin-top: 7px;
`;

export const ReportContainer = styled.div`
  width: 100%;
  margin: 20px;
  height: 370px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 9px #00000008;
  border: 1px solid #f8f5f5;
  border-radius: 20px;
`;

export const ReportTitle = styled.p`
  font-size: 12px;
  color: #8d8585;
  padding-left: 7px;
`;

export const ReportContent = styled.div`
  text-align: center;

  input {
    display: inline-flex;
    width: 80%;
  }

  h3 {
    font-size: 16px;
    color: #8d8585;
  }
  h1 {
    font-size: 30px;
  }
  input[type='range'] {
    cursor: pointer;
    padding: 15px 10px;
  }

  input[type='range'],
  input[type='range']::-webkit-slider-runnable-track,
  input[type='range']::-webkit-slider-thumb {
    -webkit-appearance: none;
  }

  input[type='range']::-webkit-slider-runnable-track {
    width: 200px;
    height: 2px;
    background: #e0dede;
  }

  input[type='range']::-webkit-slider-thumb {
    width: 40px;
    height: 6px;

    margin-top: -2.5px;
    background-color: #f6c866;
    border-radius: 10px;
  }
`;

export const MonthLabel = styled.div`
  margin-top: 10px;
  label {
    padding: 10px 10px 0px;
    color: #8d8585;
    font-size: 12px;
  }
`;

export const HighlightContainer = styled.div`
  width: 100%;
  margin: 20px;
  height: 180px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 9px #00000008;
  border: 1px solid #f8f5f5;
  border-radius: 20px;
  .highlightNav {
    h2 {
      font-size: 20px;
      font-weight: 600;
      padding: 25px;
    }
  }
`;

export const HightlightList = styled.ul`
  padding-left: 30px;
  div {
    width: 100%;
    display: flex;
    padding-bottom: 5px;
    svg {
      margin-right: 20px;
      min-width: 13px;
    }
  }
  li {
    list-style: decimal;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
`;
