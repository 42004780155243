import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import React, { useCallback, useRef, useState } from 'react';
import * as Yup from 'yup';
import { useToast } from '../../../hooks/toast';
import getValidationErrors from '../../../utils/getVaidationErrors';
import Buttom from '../../forms/Buttom';
import Input from '../../forms/Input';

import Modal from '../Modal';

import {
  postCreateProvidersCourse,
  postCreateSellersCourse,
} from '../../../services/api';

import { saveCreateNewCouseLog } from '../../../services/logs';

import { Container, Bottom, Row, Both } from './styles';
import { useAuth } from '../../../hooks/auth';

interface IModalProps {
  isOpen: boolean;
  setIsOpen: () => void;
  clearFilter: () => void;
}

const ModalAddCourse: React.FC<IModalProps> = ({
  isOpen,
  setIsOpen,
  clearFilter,
}) => {
  const { user } = useAuth();
  const { addToast } = useToast();
  const [loading, setLoading] = useState(false);
  const formRef = useRef<FormHandles>(null);
  const handleSubmit = useCallback(
    async data => {
      try {
        setLoading(true);
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          name: Yup.string().required('Campo Obrigatório'),
        });
        await schema.validate(data, {
          abortEarly: false,
        });

        if (user.type === 'seller') {
          await postCreateSellersCourse(data);

          clearFilter();
        } else if (user.type === 'seller_content_manager') {
          await postCreateSellersCourse(data);

          clearFilter();
        } else if (user.type === 'provider') {
          await postCreateProvidersCourse(data);

          clearFilter();
        }

        saveCreateNewCouseLog({
          userId: user.id,
          sellerId: user.seller?.id || '',
          newCourseName: data.name,
        });

        addToast({
          title: 'Curso cadastrado com sucesso!',
          type: 'success',
        });
        setIsOpen();
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);
          formRef.current?.setErrors(errors);
          return;
        }
        addToast({
          title: 'Ops...',
          type: 'error',
          description: 'Algo inesperado aconteceu.',
        });
      } finally {
        setLoading(false);
      }
    },
    [user.type, addToast, setIsOpen, clearFilter],
  );
  return (
    <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
      <Container>
        <h1>Novo Curso</h1>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <Row>
            <Input
              name="name"
              label="Nome:"
              type="text"
              placeholder="Ex.: Ciências da Computação"
            />
          </Row>
          <Both />
          <button type="submit" hidden>
            submeter
          </button>
        </Form>
        <Bottom>
          <Buttom
            name="cancelar"
            type="button"
            onClick={() => setIsOpen()}
            label="Cancelar"
            outline
          />
          <Buttom
            name="salvar"
            onClick={() => formRef.current?.submitForm()}
            label="Salvar"
            isLoading={loading}
          />
        </Bottom>
      </Container>
    </Modal>
  );
};

export default ModalAddCourse;
