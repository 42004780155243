/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import React, {
  useCallback,
  useState,
  useEffect,
  useRef,
  useMemo,
} from 'react';
import { BeatLoader } from 'react-spinners';
import { Link } from 'react-router-dom';
import InputRange from 'react-input-range';

import 'react-input-range/lib/css/index.css';

import { Holder, Container, Content, Title, RightSide } from './styles';
import { useAuth } from '../../hooks/auth';

import {
  Main,
  MainContent,
  Body,
} from '../../components/template/SellerTemplate/styles';

import NavTop from '../../components/template/NavTop';

import InputSearch from '../../components/forms/InputSearch';

import { IconBookmarkYellow, IconCrownTiny } from '../../styles/icons';
import IconUser from '../../assets/icons/icon-user-circle-lg.svg';

import { useToast } from '../../hooks/toast';
import {
  storeFilterProvider,
  getLevelOfLearning,
  IGetKnowledge,
  getKnowledge,
  getSellerDataById,
} from '../../services/api';
import { saveGenericLog } from '../../services/logs';

import { useCart } from '../../hooks/cart';

import Timer from '../../utils/timer';

export interface IProviders {
  id: string;
  name: string;
  email: string;
  cnpj: string;
  cpf: string;
  companyName: string;
  status:
    | 'active'
    | 'inative'
    | 'exclused'
    | 'review'
    | 'approved'
    | 'rejected'
    | 'pending'
    | 'draft';
  typePerson: 'Profissional Liberal' | 'Instituição de Ensino' | 'Fornecedor';
  condition: string;
  imgLogoUrl: string | null;
  isPrimium: boolean;
  provider_total_items: number | undefined;
  plan_value: string | number | undefined;
  priceInNumber: string | number | undefined;
  isRented?: boolean;
}

interface IFilter {
  condition: undefined | string[];
  knowledges: undefined | string[];
  levelOfLearning: undefined | string[];
}

interface ISeller {
  loaded: boolean;
  id: string;
  name: string;
  ownerId: string;
  cnpj: string;
  planId: string;
}

const queryTimer = new Timer();

const Store: React.FC = () => {
  const { user } = useAuth();
  const { addToast } = useToast();
  const { addToCart, cartItems } = useCart();
  const [loading, setLoading] = useState(true);

  // SELLERS ===========
  const [seller, setSeller] = useState<ISeller>({} as ISeller);
  useEffect(() => {
    const load = async (): Promise<void> => {
      if (user.type === 'seller' && user.seller) {
        try {
          const { data: res } = await getSellerDataById(user.seller.id);
          setSeller(res);
        } catch (error) {
          addToast({
            title: 'Ops...',
            type: 'error',
            description:
              'Algo inesperado aconteceu, por favor recarrege sua página.',
          });
        }
      }
    };

    load();
  }, [user, addToast]);

  // SEARCH ===========
  const [search, setSearch] = useState('');

  // RANGE ===========
  const [range, setRange] = useState({ min: 550, max: 20000 });

  // CHECKBOX ===========
  const inputParentCondition = useRef(document.createElement('div'));
  const conditions = useMemo(
    () => [
      {
        label: 'Fornecedores Premium',
        value: 'premium',
      },
      {
        label: 'Fornecedores Basic',
        value: 'basic',
      },
    ],
    [],
  );
  const [selectedCondition, setSelectedCondition] = useState({});

  const inputParentLevel = useRef(document.createElement('div'));
  const [levelOfLearning, setLevelOfLearning] = useState([]);
  const [selectedLevel, setSelectedLevel] = useState({});

  const inputParentKnowledge = useRef(document.createElement('div'));
  const [knowledges, setKnowledges] = useState<IGetKnowledge[]>([]);
  const [selectedKnowledge, setSelectedKnowledge] = useState({});

  useEffect(() => {
    const load = async (): Promise<void> => {
      const level = await getLevelOfLearning();
      setLevelOfLearning(level as []);

      const know = await getKnowledge();
      setKnowledges(know);
    };

    load();
  }, [setLevelOfLearning, setKnowledges]);

  const handleLevel = useCallback(
    id => {
      const shadow = { ...selectedLevel };
      if (shadow[id]) {
        delete shadow[id];
      } else {
        shadow[id] = true;
      }
      setSelectedLevel(shadow);
    },
    [selectedLevel, setSelectedLevel],
  );

  const handleKnowledge = useCallback(
    value => {
      const shadow = { ...selectedKnowledge };
      if (shadow[value]) {
        delete shadow[value];
      } else {
        shadow[value] = true;
      }
      setSelectedKnowledge(shadow);
    },
    [selectedKnowledge, setSelectedKnowledge],
  );

  const handleCondition = useCallback(
    value => {
      const shadow = { ...selectedCondition };
      if (shadow[value]) {
        delete shadow[value];
      } else {
        shadow[value] = true;
      }
      setSelectedCondition(shadow);
    },
    [selectedCondition, setSelectedCondition],
  );

  // CLEAR FILTER ===========
  const clearFilter = useCallback(() => {
    setSelectedCondition({});
    const checkedCondition = inputParentCondition.current.querySelectorAll(
      'input[type=checkbox]',
    );
    Array.prototype.forEach.call(checkedCondition, function (el, i) {
      if (el.checked) el.checked = false;
    });

    setSelectedKnowledge({});
    const checkedKnowledge = inputParentKnowledge.current.querySelectorAll(
      'input[type=checkbox]',
    );

    Array.prototype.forEach.call(checkedKnowledge, function (el, i) {
      if (el.checked) el.checked = false;
    });

    setSelectedLevel({});
    const checkedLevel = inputParentLevel.current.querySelectorAll(
      'input[type=checkbox]',
    );
    Array.prototype.forEach.call(checkedLevel, function (el, i) {
      if (el.checked) el.checked = false;
    });

    setSearch('');

    setRange({ min: 550, max: 20000 });
  }, [inputParentLevel]);

  // PROVIDERS ===========
  const [providers, setProviders] = useState<IProviders[]>([]);
  const handleSetProvider = useCallback((provider: IProviders[]) => {
    setProviders(
      provider.map(item => {
        const finalPrice = Number(item.plan_value) || 20000;
        return {
          ...item,
          isPrimium: item.condition === 'premium',
          provider_total_items: item.provider_total_items || 104,
          priceInNumber: finalPrice,
          plan_value: finalPrice.toLocaleString('pt-br', {
            style: 'currency',
            currency: 'BRL',
          }),
        };
      }),
    );
  }, []);

  useEffect(() => {
    const load = async (): Promise<void> => {
      try {
        setLoading(true);
        handleSetProvider([]);
        const filters: IFilter = {
          condition: undefined,
          knowledges: undefined,
          levelOfLearning: undefined,
        };

        for (const key in selectedKnowledge) {
          if (!filters.knowledges) filters.knowledges = [];
          filters.knowledges.push(key);
        }

        for (const key in selectedLevel) {
          if (!filters.levelOfLearning) filters.levelOfLearning = [];
          filters.levelOfLearning.push(key);
        }

        for (const key in selectedCondition) {
          if (!filters.condition) filters.condition = [];
          filters.condition.push(`'${key}'`);
        }

        const { data: res } = await storeFilterProvider({
          companyName: search,
          maximumValuePlan: range.max,
          minimumValuePlan: range.min,
          planId: seller.planId,
          sellerId: seller.id,
          knowledges: !filters.knowledges
            ? filters.knowledges
            : filters.knowledges.join(','),
          levelOfLearning: !filters.levelOfLearning
            ? filters.levelOfLearning
            : filters.levelOfLearning.join(','),
          condition: !filters.condition
            ? filters.condition
            : filters.condition.join(','),
        });

        handleSetProvider(res);
      } catch (error) {
        addToast({
          title: 'Ops...',
          type: 'error',
          description:
            'Algo inesperado aconteceu, por favor recarrege sua página.',
        });
      } finally {
        setLoading(false);
      }
    };
    if (seller.planId) queryTimer.register(() => load());
  }, [
    handleSetProvider,
    addToast,
    search,
    selectedLevel,
    selectedKnowledge,
    selectedCondition,
    range,
    seller,
  ]);
  const renderProviders = useCallback(() => {
    return (
      <>
        {providers.map((provider: IProviders) => (
          <article key={`${provider.id}-tab-01`} className="slideUp">
            <header>
              <img src={provider.imgLogoUrl || IconUser} alt="" />
              <h1>{provider.companyName}</h1>
              <p>{provider.isPrimium ? 'Premium' : 'Basic'}</p>
              <p className="units">
                <IconBookmarkYellow />{' '}
                <strong>{provider.provider_total_items}</strong> unidades
              </p>
            </header>
            {/* <main>
              <p>
                <strong>{provider.plan_value}</strong>
                <span> /mês</span>
              </p>
              <Link to={`/seller/store/provider/${provider.id}`}>
                Ver unidades
              </Link>
            </main> */}
            <footer>
              {provider.isRented ? (
                <Link className="rented" to="/seller/rented-units">
                  Alugado
                </Link>
              ) : (
                <>
                  {cartItems.find(cartItem => cartItem.id === provider.id) ? (
                    <Link to="/seller/cart">Item no Carrinho</Link>
                  ) : (
                    <button
                      onClick={() => {
                        addToCart({ isService: false, ...provider });
                        // saveGenericLog({
                        //   userId: user.id,
                        //   action: 'added_item_to_cart',
                        // });
                        if (user.seller?.id) {
                          saveGenericLog({
                            userId: user.id,
                            sellerId: user.seller.id,
                            action: 'added_item_to_cart',
                          });
                        } else {
                          saveGenericLog({
                            userId: user.id,
                            action: 'added_item_to_cart',
                          });
                        }
                      }}
                      type="button"
                    >
                      Alugar
                    </button>
                  )}
                </>
              )}
            </footer>
          </article>
        ))}
      </>
    );
  }, [providers, addToCart, cartItems]);
  return (
    <Holder>
      <Main>
        <MainContent className="blur">
          <NavTop />
          <Body className="body">
            <Container className="slideDown">
              <Title>
                <h1>Loja de Fornecedores</h1>
              </Title>
              <Content>
                <section>
                  {!loading && renderProviders()}
                  {providers.length <= 0 && !loading && (
                    <div className="loading">
                      <p>Nenhum item encontrado.</p>
                    </div>
                  )}
                  {loading && (
                    <div className="loading">
                      <BeatLoader size={16} />
                    </div>
                  )}
                </section>
              </Content>
            </Container>
          </Body>
        </MainContent>
      </Main>
      <RightSide className="slideLeft">
        <div>
          <h2>Filtros</h2>
          <InputSearch
            name="search"
            onChange={e => setSearch(e.target.value)}
            placeholder="Pesquisar"
            value={search}
          />
          <div className="checkboxContainer" ref={inputParentCondition}>
            {conditions.map(
              (condition: { label: string; value: string }, index: number) => (
                <label
                  className="checkboxContent"
                  htmlFor={`condition-${index}`}
                  key={condition.value}
                >
                  {condition.value === 'premium' && <IconCrownTiny />}{' '}
                  {condition.label === 'Fornecedores Premium' && (
                    <text>
                      Fornecedores
                      <text style={{ fontStyle: 'italic' }}> Premium</text>
                    </text>
                  )}{' '}
                  {condition.label === 'Fornecedores Basic' && (
                    <text>
                      Fornecedores
                      <text style={{ fontStyle: 'italic' }}> Basic</text>
                    </text>
                  )}
                  <input
                    name=""
                    type="checkbox"
                    id={`condition-${index}`}
                    onChange={() => handleCondition(condition.value)}
                  />
                  <span className="checkmark" />
                </label>
              ),
            )}
          </div>
        </div>
        <div>
          <h3>Faixa de preços</h3>
          <InputRange
            minValue={550}
            maxValue={20000}
            value={range}
            formatLabel={value =>
              value.toLocaleString('pt-br', {
                style: 'currency',
                currency: 'BRL',
              })
            }
            onChange={value => typeof value === 'object' && setRange(value)}
          />
        </div>
        <div>
          <h3>Áreas de Conhecimento</h3>
          <div className="checkboxContainer" ref={inputParentKnowledge}>
            {knowledges.map(
              (
                know: { label: string; value: string; type: string },
                index: number,
              ) => (
                <label
                  className="checkboxContent"
                  htmlFor={`know-${index}`}
                  key={know.value}
                >
                  {know.label}
                  <input
                    name=""
                    type="checkbox"
                    id={`know-${index}`}
                    onChange={() => handleKnowledge(know.value)}
                  />
                  <span className="checkmark" />
                </label>
              ),
            )}
          </div>
        </div>
        <div>
          <h3>Nível de Aprendizagem</h3>
          <div className="checkboxContainer" ref={inputParentLevel}>
            {levelOfLearning.map(
              (level: { label: string; id: string }, index: number) => (
                <label
                  className="checkboxContent"
                  htmlFor={`level-${index}`}
                  key={level.id}
                >
                  {level.label}
                  <input
                    name=""
                    type="checkbox"
                    id={`level-${index}`}
                    onChange={() => handleLevel(level.id)}
                  />
                  <span className="checkmark" />
                </label>
              ),
            )}
          </div>
        </div>
        <button type="button" onClick={clearFilter}>
          Limpar Filtros
        </button>
      </RightSide>
    </Holder>
  );
};

export default Store;
