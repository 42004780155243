import styled from 'styled-components';

export const Container = styled.div`
  padding: 20px;
  p {
    color: var(--gray);
    font-size: 0.75rem;
    margin-top: 10px;
  }
`;

export const Radio = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 28px;

  > label {
    margin-top: 32px;
    font-weight: bold;
    color: var(--gray);
  }

  input {
    margin-right: 12px;
  }

  input[type='radio'] {
    position: relative;
    cursor: pointer;

    &:checked:after {
      content: '';
      display: block;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background-color: white;
      position: absolute;
      top: 0px;
      left: 3px;
    }

    &:checked:before {
      border: 2px solid var(--aurea);
      background-color: var(--aurea);
    }

    &::before {
      content: '';
      display: block;
      position: absolute;
      width: 16px;
      height: 16px;
      top: -3.5px;
      left: -1px;
      border: 2px solid rgba(141, 133, 133, 0.45);
      border-radius: 50%;
      background-color: white;
    }
  }
`;

export const RadioContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  margin-top: 16px;
`;

export const Bottom = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  margin-top: 24px;

  div + div {
    margin-left: 18px;
  }
`;
