import styled from 'styled-components';

export const Container = styled.div`
  display: flex;

  div {
    position: relative;
    height: 38px;

    display: flex;
    align-items: center;
    justify-content: center;

    label {
      padding: 8px 18px;
      color: var(--gray);

      transition: background 0.4s;
      background: white;
      border: 1px solid var(--gray-border);
      border-radius: 24px;
      user-select: none;
      cursor: pointer;
      & + label {
        margin-left: 16px;
      }
    }

    & + div {
      margin-left: 16px;
    }

    input[type='radio'] {
      position: absolute;
      visibility: hidden;

      &:checked {
        & ~ label {
          background: var(--gray-border);
        }
      }
    }
  }
`;
