import React, { useCallback, useEffect, useState } from 'react';
import { arrayMove, List } from 'react-movable';
import { Link, useParams } from 'react-router-dom';
import { BeatLoader } from 'react-spinners';
import Modal from '../../components/template/Modal';
import ModalAddSubjectToCourse from '../../components/template/ModalAddSubjectToCourse';
import NavTop from '../../components/template/NavTop';
import {
  Body,
  Main,
  MainContent,
} from '../../components/template/SellerTemplate/styles';
import {
  OptionLeft,
  OptionRight,
  Options,
} from '../../components/template/TemplateManager/styles';
import { useAuth } from '../../hooks/auth';
import { useToast } from '../../hooks/toast';
import {
  deleteProvidersCourseItem,
  deleteSellersCourseItem,
  getProvidersCourse,
  getSellersCourse,
  patchOrdenateProvidersCourses,
  patchOrdenateSellersCourses,
} from '../../services/api';
import { IconAddCircle, IconDragger } from '../../styles/icons';
import { ModalContent } from '../Courses/styles';
import { Article, Container, Content, Holder, Title } from './styles';

interface IParams {
  id: string;
}

interface ICourse {
  name: string;
  id: string;
}

interface ICourseItems {
  courseId: string;
  id: string;
  subjectId: string;
  order: number;
  name: 'teste';
}

const Course: React.FC = () => {
  const { user } = useAuth();
  const { addToast } = useToast();

  const { id: courseId } = useParams<IParams>();

  const [loading, setLoading] = useState(true);
  const [course, setCourse] = useState<ICourse>();
  const [courseItems, setCourseItems] = useState<ICourseItems[]>();
  const [itemToRemove, setItemToRemove] = useState('');
  const [isOpenDelete, setIsOpenDelete] = useState(false);

  // REFRESH ===========
  const [refresh, setRefresh] = useState(false);

  // CLEAR FILTER ===========
  const clearFilter = useCallback(() => {
    setRefresh(!refresh);
  }, [refresh]);

  useEffect(() => {
    const load = async (): Promise<void> => {
      setLoading(true);

      try {
        if (user.type.includes('seller')) {
          const { data } = await getSellersCourse(courseId);
          setCourse(data);
          setCourseItems(data.courseItems);
        } else if (user.type === 'provider') {
          const { data } = await getProvidersCourse(courseId);
          setCourse(data);
          setCourseItems(data.courseItems);
        }
      } catch (error) {
        addToast({
          title: 'Ops...',
          type: 'error',
          description:
            'Algo inesperado aconteceu, por favor recarrege sua página.',
        });
      }

      setLoading(false);
    };
    load();
  }, [addToast, courseId, refresh, user.type]);

  // HANDLE DROP ======
  const handleDrop = useCallback(
    async ({ oldIndex, newIndex }) => {
      if (user.type === 'seller_assistent') {
        return false;
      }

      if (courseItems && course) {
        const newOrder = arrayMove(courseItems, oldIndex, newIndex);
        setCourseItems(newOrder);

        try {
          // eslint-disable-next-line no-shadow
          const orderedItems = newOrder.map(item => item.id);
          if (user.type.includes('seller')) {
            await patchOrdenateSellersCourses({ orderedItems }, course.id);
          } else if (user.type === 'provider') {
            await patchOrdenateProvidersCourses({ orderedItems }, course.id);
          }
        } catch (error) {
          addToast({
            title: 'Ops...',
            type: 'error',
            description:
              'Algo inesperado aconteceu, por favor recarrege sua página.',
          });
        }
      }
    },
    [courseItems, course, addToast, user.type],
  );

  const [modalAddSubjectToCourseOpen, setModalAddSubjectToCourseOpen] =
    useState(false);
  const toggleModalAddSubjectToCourse = useCallback(() => {
    setModalAddSubjectToCourseOpen(!modalAddSubjectToCourseOpen);
  }, [modalAddSubjectToCourseOpen]);

  const toggleModalDeleteCourseItem = useCallback(
    (id?: string) => {
      if (id) setItemToRemove(id);
      setIsOpenDelete(!isOpenDelete);
    },
    [isOpenDelete],
  );

  const handleRemoveCourseItem = useCallback(async () => {
    if (itemToRemove && course) {
      try {
        if (user.type === 'provider') {
          await deleteProvidersCourseItem(itemToRemove, course.id);
        } else if (user.type.includes('seller')) {
          await deleteSellersCourseItem(itemToRemove, course.id);
        }

        addToast({
          title: 'Sucesso!',
          description: 'Curso removido com sucesso!',
          type: 'success',
        });
        setIsOpenDelete(!isOpenDelete);
        clearFilter();
      } catch (error) {
        addToast({
          title: 'Ops...',
          type: 'error',
          description:
            'Algo inesperado aconteceu, por favor recarrege sua página.',
        });
      }
    }

    setIsOpenDelete(!isOpenDelete);
  }, [itemToRemove, course, isOpenDelete, clearFilter, addToast, user.type]);

  return (
    <Holder>
      <Main>
        {course && (
          <ModalAddSubjectToCourse
            isOpen={modalAddSubjectToCourseOpen}
            setIsOpen={toggleModalAddSubjectToCourse}
            courseId={course.id}
            clearFilter={clearFilter}
          />
        )}
        <Modal
          isOpen={isOpenDelete}
          setIsOpen={() => toggleModalDeleteCourseItem()}
        >
          <ModalContent cancel>
            <h1 className="modalTitle">
              Tem certeza que deseja remover a disciplina?
            </h1>
            <section>
              <button onClick={() => handleRemoveCourseItem()} type="button">
                SIM
              </button>
              <button
                onClick={() => toggleModalDeleteCourseItem()}
                type="button"
                className="cancel"
              >
                NÃO
              </button>
            </section>
          </ModalContent>
        </Modal>
        <MainContent className="blur">
          <NavTop />
          <Body className="body">
            {course && courseItems && (
              <Container className="slideDown">
                <Content>
                  <Options>
                    <OptionRight className="clear">
                      <Title>
                        <div className="bread-crumb">
                          <ul>
                            <li>
                              <Link to="/seller/courses">Cursos</Link>
                            </li>
                            <li>{course.name}</li>
                          </ul>
                        </div>
                        <h1>Curso</h1>
                        <h2>{course.name}</h2>
                      </Title>
                    </OptionRight>
                    {user.type !== 'seller_assistent' && (
                      <OptionLeft style={{ flexDirection: 'row-reverse' }}>
                        <button
                          type="button"
                          onClick={toggleModalAddSubjectToCourse}
                        >
                          <IconAddCircle /> Nova Disciplina
                        </button>
                      </OptionLeft>
                    )}
                  </Options>
                  <section>
                    {loading && (
                      <div className="loading">
                        <BeatLoader size={16} />
                      </div>
                    )}
                    {!loading && (
                      <List
                        values={courseItems}
                        onChange={handleDrop}
                        renderList={renderList => (
                          <div
                            className="slideUp dropper"
                            {...renderList.props}
                          >
                            {renderList.children}
                          </div>
                        )}
                        renderItem={renderItem => (
                          <Article
                            key={`${renderItem.value.courseId}-tab-01`}
                            className="availableItem"
                            {...renderItem.props}
                          >
                            <header>
                              <strong>{Number(renderItem.index) + 1}</strong>
                            </header>
                            <main>
                              <div>
                                <h1>{renderItem.value.name}</h1>
                              </div>
                            </main>
                            <footer>
                              <Link
                                to={`/seller/subject/${renderItem.value.subjectId}`}
                              >
                                Ver Disciplina
                              </Link>
                              {user.type !== 'seller_assistent' && (
                                <button
                                  type="button"
                                  onClick={() =>
                                    toggleModalDeleteCourseItem(
                                      renderItem.value.id,
                                    )
                                  }
                                >
                                  Remover Disciplina
                                </button>
                              )}
                            </footer>
                            <div className="dragger">
                              <span>
                                <IconDragger />
                              </span>
                            </div>
                          </Article>
                        )}
                      />
                    )}
                    {courseItems.length <= 0 && !loading && (
                      <div className="loading">
                        <p>Nenhum item encontrado.</p>
                      </div>
                    )}
                  </section>
                </Content>
              </Container>
            )}
          </Body>
        </MainContent>
      </Main>
    </Holder>
  );
};

export default Course;
