/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { HTMLAttributes } from 'react';

import { Container, TabItemContainer } from './styles';

interface ITabItensProps extends HTMLAttributes<HTMLDivElement> {
  itens: {
    id: string;
    label: string;
    active?: boolean;
  }[];
  defaultValue: string;
  handleTab(id: string): void;
}

interface ITabItensContainerProps {
  id: string;
  defaultValue: string;
}

export const TabItem: React.FC<ITabItensContainerProps> = ({
  children,
  defaultValue,
  id,
}) => {
  return (
    <TabItemContainer
      className={`show-tab ${defaultValue === id ? `on` : `off`}`}
    >
      {children}
    </TabItemContainer>
  );
};

const Tabs: React.FC<ITabItensProps> = ({
  itens,
  defaultValue,
  handleTab,
  ...rest
}) => {
  return (
    <Container {...rest}>
      <ul>
        {itens.map(tab => (
          <li
            key={tab.id}
            onClick={() => handleTab(tab.id)}
            className={`${defaultValue === tab.id ? `active` : ``}`}
          >
            {tab.label}
          </li>
        ))}
      </ul>
    </Container>
  );
};

export default Tabs;
