/* eslint-disable react/no-array-index-key */
/* eslint-disable react/require-default-props */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useField } from '@unform/core';
import { Container } from './styles';

interface IOptions {
  label: string;
  value: string;
}

interface IReactSelect {
  name: string;
  options: IOptions[];
  placeholder?: string;
}

const ReactSelectForm: React.FC<IReactSelect> = ({
  name,
  options,
  placeholder,
}) => {
  const selectRef = useRef<HTMLSelectElement>(null);

  const { fieldName, error, registerField, defaultValue } = useField(name);
  const [selectValue, setSelectValue] = useState(defaultValue);

  const setNewValue = useCallback(value => {
    setSelectValue(value);
  }, []);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: selectRef.current,
      getValue: (ref: HTMLInputElement) => {
        const { value } = ref;

        if (!value) {
          return null;
        }

        return !Number.isNaN(Number(value)) ? Number(value) : String(value);
      },
      clearValue: () => {
        setSelectValue('');
      },
    });
  }, [fieldName, registerField]);

  return (
    <Container isError={!!error}>
      <span>{placeholder}</span>
      <select
        ref={selectRef}
        disabled={options.length === 0}
        value={selectValue || defaultValue || ''}
        onChange={e => setNewValue(e.target.value)}
        name={name}
      >
        {options.map((option, index) => (
          <option key={`${index}${option.value}`} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
      {error && <small>{error}</small>}
    </Container>
  );
};

export default ReactSelectForm;
