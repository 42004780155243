import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  cursor: pointer;
  span {
    width: auto;
    background: var(--dark);
    padding: 8px 14px;
    border-radius: 4px;
    font-size: 14px;
    text-align: center;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.4s;
    position: absolute;
    top: calc(100% + 12px);
    /* Para centralizar position absolute */
    left: 270%;
    transform: translateX(-50%);
    color: #312e38;
    &::before {
      content: '';
      border-style: solid;
      border-color: var(--dark) transparent;
      border-width: 6px 6px 0 6px;
      position: absolute;
      bottom: 100%;
      left: 17%;
      transform: translateX(-50%) rotate(180deg);
    }
  }

  &:hover span {
    opacity: 1;
    visibility: visible;
  }
`;
