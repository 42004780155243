import styled, { css } from 'styled-components';

interface IContainerProps {
  block?: boolean;
  outline?: boolean;
}

export const Container = styled.div<IContainerProps>`
  button {
    background: var(--aurea);
    border: 1px solid var(--aurea);
    font-weight: 700;
    padding: 16px 32px;
    border-radius: 5px;
    transition: background 0.5s;
    color: var(--dark);
    &:hover {
      background: var(--hover);
    }

    &:disabled {
      background: var(--aurea);
    }
  }

  ${props =>
    props.block &&
    css`
      width: 100%;
      button {
        width: 100%;
      }
    `}

  ${props =>
    props.outline &&
    css`
      button {
        background: var(--outline);
        border: 1px solid var(--aurea);
      }
    `}
`;
