import React, { useRef, useCallback, useState, useEffect } from 'react';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { Link, useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import {
  Container,
  Content,
  ContentForm,
  BottomForm,
  ContentBottom,
  SelectProfile,
  SelectProfileContent,
  Card,
  IconBookAddress,
  SelectForm,
  SelectFormCircle,
  AltText,
  IconAlertAlt,
} from './styles';

import BackgroundSign from '../../components/template/BackgroundSign';

import Input from '../../components/forms/Input';
import InputMask from '../../components/forms/InputMask';
import Buttom from '../../components/forms/Buttom';
import RowGroup from '../../components/forms/RowGroup';
import ChooseCard from '../../components/forms/ChooseCard';
import TextArea from '../../components/forms/TextArea';
import Choice from '../../components/forms/Choice';

import getValidationErrors from '../../utils/getVaidationErrors';
import { useToast } from '../../hooks/toast';
import { postSignUp } from '../../services/api';
import { knowledgeLevel } from '../../data/knowledgeLevel';
import { subTypeProvider } from '../../data/subTypeProvider';

interface ISubmitProps {
  email: string;
  name: string;
  password: string;
  type: string;
  subtype: string;
  provider: {
    cnpj: string;
    cpf: string;
    companyName: string;
    lattes: string;
    linkdin: string;
    knowledgeLevel: Array<string>;
    professionalExperience: string;
  };
}

const defaultSubtype = 'Profissional Liberal';

const SignUp: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const [selectedSubtype, setSelectedSubtype] = useState(defaultSubtype);
  const [lastPageForm, setLastPageForm] = useState(false);
  const [loading, setLoading] = useState(false);
  const { addToast } = useToast();
  const history = useHistory();

  useEffect(() => {
    formRef.current?.setErrors({});
  }, [selectedSubtype]);

  const handleSubmit = useCallback(
    async (data: ISubmitProps) => {
      // Validar primeira etapa de Profissional Liberal
      if (selectedSubtype === defaultSubtype && lastPageForm === false) {
        try {
          formRef.current?.setErrors({});
          const schemaProfissional = Yup.object().shape({
            email: Yup.string()
              .required('Campo Obrigatório')
              .email('Email inválido'),
            name: Yup.string().required('Campo Obrigatório'),
            password: Yup.string().required('Campo Obrigatório'),
            provider: Yup.object().shape({
              cpf: Yup.string().required('Campo Obrigatório'),
            }),
          });
          await schemaProfissional.validate(data, {
            abortEarly: false,
          });
          setLastPageForm(true);
          return;
        } catch (error) {
          const errors = getValidationErrors(error);
          formRef.current?.setErrors(errors);
          addToast({
            title: 'Ops ...',
            type: 'error',
            description: 'Verifique todos os campos!',
          });
          return;
        }
      }

      try {
        formRef.current?.setErrors({});
        setLoading(true);

        const schema = Yup.object().shape({
          email: Yup.string()
            .required('Campo Obrigatório')
            .email('Email inválido'),
          name: Yup.string().required('Campo Obrigatório'),
          provider: Yup.object().shape({
            cpf:
              selectedSubtype === defaultSubtype
                ? Yup.string().required('Campo Obrigatório')
                : Yup.string(),
            cnpj:
              selectedSubtype !== defaultSubtype
                ? Yup.string().required('Campo Obrigatório')
                : Yup.string(),
            companyName:
              selectedSubtype !== defaultSubtype
                ? Yup.string().required('Campo Obrigatório')
                : Yup.string(),
            lattes:
              selectedSubtype === defaultSubtype
                ? Yup.string().url('O campo deve ser preenchido com um link')
                : Yup.string(),
            linkdin:
              selectedSubtype === defaultSubtype
                ? Yup.string().url('O campo deve ser preenchido com um link')
                : Yup.string(),
            knowledgeLevel:
              selectedSubtype === defaultSubtype
                ? Yup.array().required('Campo obrigatório')
                : Yup.string(),
            professionalExperience:
              selectedSubtype === defaultSubtype
                ? Yup.string().required('Campo Obrigatório')
                : Yup.string(),
          }),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const sendData = {
          nameUser: data.name,
          email: data.email,
          password: data.password,
          type: 'provider',
          nameProvider: data.name,
          typePerson: selectedSubtype,
          ...data.provider,
          companyName:
            selectedSubtype === defaultSubtype
              ? data.name
              : data.provider.companyName,
          isServiceProvider: selectedSubtype === 'Fornecedor de Servico',
        };

        const { data: signUpData } = await postSignUp(sendData);
        if (signUpData.code === 404) {
          let description = 'Ocorreu um erro inesperado!';
          if ((signUpData.message as string).includes('use')) {
            description = 'O email já está cadastrado!';
          }
          addToast({
            title: 'Ops ...',
            type: 'error',
            description,
          });
        } else {
          addToast({
            title: 'Sucesso!',
            type: 'success',
            description:
              'Cadastro realizado com sucesso! Aguarde a aprovação, os dados de acesso serão enviados por email',
          });
          history.push('/');
        }
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);
          formRef.current?.setErrors(errors);
          addToast({
            title: 'Ops ...',
            type: 'error',
            description: 'Verifique todos os campos!',
          });
          return;
        }

        addToast({
          title: 'Ops ...',
          type: 'error',
          description: 'Ocorreu um erro inesperado!',
        });
      } finally {
        setLoading(false);
      }
    },
    [lastPageForm, selectedSubtype, addToast, history],
  );

  const handleSelectUserType = useCallback(id => {
    setSelectedSubtype(id);
  }, []);

  const renderFormInstitution = useCallback(() => {
    return (
      <div className="slideUp">
        <RowGroup>
          <Input
            name="name"
            label="Seu nome:*"
            type="text"
            placeholder="John Doe"
          />

          <InputMask
            name="provider.cnpj"
            label="CNPJ da Instituição:*"
            type="text"
            placeholder="00.000.000/0001-00"
            mask="99.999.999/9999-99"
          />
        </RowGroup>

        <Input
          name="provider.companyName"
          label="Nome da instituição:*"
          type="text"
          placeholder="Realize Tecnologia em Educação"
        />
        <Input
          name="email"
          label="Seu Email:*"
          type="text"
          placeholder="email@dominio.com.br"
        />
        <AltText>
          <IconAlertAlt />
          Você receberá no e-mail cadastrado um link de confirmação e após
          confirmar, você receberá sua senha para acesso ao sistema.
        </AltText>
        <BottomForm>
          <Buttom isLoading={loading} label="Finalizar Cadastro" block />
        </BottomForm>
      </div>
    );
  }, [loading]);

  const renderFormProfissional = useCallback(() => {
    return (
      <>
        <div
          className="slideUp"
          style={{ display: lastPageForm ? 'none' : 'block' }}
        >
          <RowGroup>
            <Input
              name="name"
              label="Seu nome:*"
              type="text"
              placeholder="John Doe"
            />
            <InputMask
              name="provider.cpf"
              label="CPF:*"
              type="text"
              placeholder="000.000.000-00"
              mask="999.999.999-99"
            />
          </RowGroup>
          <InputMask
            name="provider.cnpj"
            label="CNPJ da Instituição:"
            type="text"
            placeholder="00.000.000/0001-00"
            mask="99.999.999/9999-99"
          />
          <Input
            name="email"
            label="Seu Email:*"
            type="text"
            placeholder="email@dominio.com.br"
          />
          <AltText>
            <IconAlertAlt />
            <label>
              Você receberá no e-mail cadastrado um link de confirmação e após
              confirmar, você receberá sua senha para acesso ao sistema.
            </label>
          </AltText>
          <BottomForm>
            <Buttom label="Avançar" block />
          </BottomForm>
        </div>
        <div style={{ display: !lastPageForm ? 'none' : 'block' }}>
          <Input
            name="provider.lattes"
            label="Link do Lattes:"
            type="text"
            placeholder="http://lattes.cnpq.br/"
          />

          <Input
            name="provider.linkdin"
            label="Link do Linkedin:"
            type="text"
            placeholder="https://www.linkedin.com/"
          />

          <Choice
            name="provider.knowledgeLevel"
            label="Nível de Conhecimento:*"
            multiple
            options={knowledgeLevel}
          />

          <TextArea
            name="provider.professionalExperience"
            label="Experiência Profissional:*"
            placeholder="Trabalhei durante 5 anos na instituição…"
          />

          <BottomForm>
            <Buttom isLoading={loading} label="Finalizar Cadastro" block />
          </BottomForm>
        </div>
        <SelectForm>
          <SelectFormCircle
            onClick={() =>
              setLastPageForm(lastPageForm === true ? !lastPageForm : false)
            }
            className="selected"
          >
            1
          </SelectFormCircle>
          <SelectFormCircle
            onClick={() => formRef.current?.submitForm()}
            className={`${lastPageForm ? `selected` : ``}`}
          >
            2
          </SelectFormCircle>
        </SelectForm>
      </>
    );
  }, [lastPageForm, loading]);

  return (
    <Container>
      <BackgroundSign />
      <Content className="fadeIn">
        <SelectProfile>
          <SelectProfileContent>
            <Link to="/signup">
              <Card isSelected>
                <IconBookAddress />
                Fornecedor
              </Card>
            </Link>
            <Link to="/signup/seller">
              <Card>Instituição</Card>
            </Link>
          </SelectProfileContent>
        </SelectProfile>

        <ContentForm>
          <h1>Cadastre-se</h1>
          <Form ref={formRef} onSubmit={handleSubmit}>
            <ChooseCard
              style={{ display: lastPageForm ? 'none' : 'block' }}
              itens={subTypeProvider}
              defaultValue={selectedSubtype}
              handleSelectUserType={handleSelectUserType}
            />

            {selectedSubtype === 'Fornecedor' && renderFormInstitution()}
            {selectedSubtype === 'Fornecedor de Servico' &&
              renderFormInstitution()}
            {selectedSubtype === 'Instituição de Ensino' &&
              renderFormInstitution()}
            {selectedSubtype === defaultSubtype && renderFormProfissional()}
          </Form>
        </ContentForm>
        <ContentBottom>
          Já tem cadastro? <Link to="/">Faça seu login</Link>
        </ContentBottom>
      </Content>
    </Container>
  );
};

export default SignUp;
