import styled, { css } from 'styled-components';

interface ICardProps {
  isChecked?: boolean;
}

export const Container = styled.div`
  padding-top: 18px;

  label {
    font-weight: 700;
    color: var(--gray);
    margin-bottom: 16px;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 14px;
  align-items: center;

  @media (min-width: 425px) {
    flex-direction: row;
    justify-content: center;
  }
  .row1 {
    margin: 10px;
  }
  .row2 {
    margin: 10px;
  }
`;

export const Card = styled.div<ICardProps>`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  padding: 16px 20px 16px 20px;
  background: #fff;
  border-radius: 10px;
  border: 1px solid var(--gray-border);
  width: 100%;

  margin-bottom: 16px;

  text-align: center;
  font-size: 15px;

  cursor: pointer;
  user-select: none;

  position: relative;
  transition: border 0.5s;

  &::after {
    display: flex;
    align-items: center;
    justify-content: center;

    position: absolute;
    top: 10px;
    right: 13px;
    border-radius: 50%;

    transition: background 0.5s;

    color: var(--gray);
    background: var(--gray-border);

    content: '';
    width: 14px;
    height: 14px;
  }

  ${props =>
    props.isChecked &&
    css`
      border: 1px solid var(--aurea);
      &::after {
        background: var(--aurea);
      }
    `}

  svg {
    color: var(--aurea);
    width: 32px;
    height: 32px;
    margin-right: 8px;
  }

  @media (min-width: 425px) {
    flex-direction: column;
    width: 132px;
    padding: 24px 20px 16px 20px;
    margin-bottom: 0;

    svg {
      margin-right: 8px;
    }
  }
`;
