import styled, { css } from 'styled-components';
import { shade } from 'polished';

export const Holder = styled.div`
  display: flex;
  width: 100%;
  & > main {
    width: 100%;
  }
`;

export const Container = styled.div``;

interface IContentProps {
  Tiny?: boolean;
}

export const Content = styled.div<IContentProps>`
  ${props =>
    props.Tiny &&
    css`
      height: 300px;
      overflow: hidden;
      position: relative;
      margin-top: -10px;
    `}
  h2 {
    color: var(--tertiary);
    font-size: 1.5rem;
    font-weight: bold;
    margin-top: 10px;
    margin-bottom: 20px;
  }
  & > section {
    width: 100%;
    padding: 0 20px;
    & > div {
      & > div:first-child {
        & > div:first-child {
          border-radius: 5px 5px 0 0;
        }
      }
    }
  }
  .grow {
    position: absolute;
    bottom: 0px;
    display: none;
    width: 100%;
    height: 100px;
    background: var(--white);
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#fcfafa+0,fcfafa+100&0+0,1+56,1+100 */
    background: -moz-linear-gradient(
      top,
      rgba(252, 250, 250, 0) 0%,
      rgba(252, 250, 250, 1) 56%,
      rgba(252, 250, 250, 1) 100%
    ); /* FF3.6-15 */
    background: -webkit-linear-gradient(
      top,
      rgba(252, 250, 250, 0) 0%,
      rgba(252, 250, 250, 1) 56%,
      rgba(252, 250, 250, 1) 100%
    ); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(
      to bottom,
      rgba(252, 250, 250, 0) 0%,
      rgba(252, 250, 250, 1) 56%,
      rgba(252, 250, 250, 1) 100%
    ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00fcfafa', endColorstr='#fcfafa',GradientType=0 ); /* IE6-9 */

    ${props =>
      props.Tiny &&
      css`
        display: flex;
        justify-content: center;
        align-items: center;
      `}

    button {
      background: var(--aurea);
      border: none;
      font-weight: bold;
      padding: 15px 20px;
      border-radius: 5px;
      font-size: 1rem;
      color: var(--dark);
      display: flex;
      justify-content: center;
      align-items: center;
      svg {
        margin-right: 10px;
      }
    }
  }
  .loading {
    width: 100%;
    padding: 20px;
    text-align: center;
    & > div {
      display: inline-block;
      opacity: 0.5;
    }
  }
`;

export const Title = styled.div`
  h1 {
    margin-bottom: 24px;
  }
  div.bread-crumb {
    ul {
      display: flex;
      list-style: none;
      li {
        font-weight: bold;
        color: var(--aurea);
        a {
          font-weight: normal;
          color: var(--tertiary);
          text-decoration: none;
          &:hover {
            text-decoration: underline;
          }
          &:after {
            content: '>';
            display: inline-block;
            margin: 0 10px;
            font-size: 0.75rem;
          }
        }
        &:last-child {
          text-decoration: underline;
        }
      }
    }
  }
  div.provider {
    background: #ffffff;
    border: 1px solid var(--quaternary);
    padding: 30px;
    margin: 20px 0;
    border-radius: 4px;
    display: flex;
    align-items: center;

    img {
      width: 115px;
      height: 115px;
      object-fit: cover;
      margin-right: 30px;
      border-radius: 50%;
    }

    & > div {
      width: 100%;

      h1 {
        font-size: 1.875rem;
        margin-bottom: 10px;
      }

      .tag {
        background: var(--placeholder);
        padding: 5px 10px;
        border-radius: 1000px;
        text-transform: lowercase;
        font-size: 0.9rem;
      }

      p {
        margin: 2px 0 8px;
        font-size: 1rem;
      }
    }

    button {
      display: block;
      width: 140px;
      padding: 15px;
      border: none;
      color: var(--white);
      background: var(--danger);
      border-radius: 5px;
      font-weight: bold;
      transition: all 400ms;
      margin-left: 20px;
      &:hover {
        text-decoration: none;
        color: var(--dark);
      }
    }
  }
`;

interface IPropsCancel {
  cancel: boolean;
}

export const ModalContent = styled.div<IPropsCancel>`
  h1.modalTitle {
    margin-top: 33px;
    text-align: center;
    color: #3d3939;
    font-size: 22px;
    font-family: 'Source Sans Pro';
    font-weight: 700;
    margin-bottom: 40px;
  }
  section {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 25px;
    button {
      width: 90px;
      height: 50px;
      margin-left: 20px;
      color: #ffff;
      font-family: 'Source Sans Pro';
      font-weight: 700;
      background: #6bc196;
      border: 1px solid transparent;
      border-radius: 5px;
      transition: all 0.2s;
      &:hover {
        background: ${shade(0.1, '#6BC196')};
      }
    }
    button.cancel {
      background: #cc593e;
      &:hover {
        background: ${shade(0.1, '#CC593E')};
      }
    }
  }
`;
