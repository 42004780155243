import React, { useRef, useCallback, useState } from 'react';

import { Form } from '@unform/web';
import { Link, useLocation, useHistory } from 'react-router-dom';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import {
  Container,
  Content,
  ContentForm,
  BottomForm,
  ContentBottom,
} from './styles';
import BackgroundSign from '../../components/template/BackgroundSign';
import Input from '../../components/forms/Input';
import Buttom from '../../components/forms/Buttom';
import getValidationErrors from '../../utils/getVaidationErrors';
import { useToast } from '../../hooks/toast';
import { postResetPassword } from '../../services/api';

interface ISubmitProps {
  password: string;
  password_confirmation: string;
}

const ResetPassword: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const formRef = useRef<FormHandles>(null);
  const location = useLocation();
  const history = useHistory();

  const { addToast } = useToast();

  const handleSubmit = useCallback(
    async (data: ISubmitProps) => {
      try {
        setLoading(true);
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          password: Yup.string()
            .required('Campo Obrigatório')
            .min(6, 'A senha deve ter no mínimo 6 caracteres'),
          password_confirmation: Yup.string()
            .required('Campo Obrigatório')
            .min(6, 'A senha deve ter no mínimo 6 caracteres')
            .oneOf([Yup.ref('password'), null], 'As senha não correspondem'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const token = location.search.replace('?token=', '');
        if (!token) {
          throw new Error('Token não encontrado');
        }
        await postResetPassword({
          password: data.password,
          token,
        });

        addToast({
          title: 'Sucesso!',
          description: 'Senha alterada com sucesso, realize seu login!',
          type: 'success',
        });

        history.push('/');
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);
          formRef.current?.setErrors(errors);
          return;
        }
        addToast({
          title: 'Erro!',
          description: 'Não foi possível alterar sua senha',
          type: 'error',
        });
      } finally {
        setLoading(false);
      }
    },
    [addToast, location.search, history],
  );
  return (
    <Container>
      <BackgroundSign />
      <Content className="slideDown">
        <ContentForm>
          <h1>Reset de Senha</h1>
          <Form ref={formRef} onSubmit={handleSubmit}>
            <Input
              name="password"
              placeholder="Digite sua nova senha"
              label="Nova senha:*"
              type="password"
            />

            <Input
              name="password_confirmation"
              placeholder="Confirme sua nova senha"
              label="Confirmar senha:*"
              type="password"
            />

            <BottomForm>
              <Buttom isLoading={loading} label="Salvar" />
            </BottomForm>
          </Form>
        </ContentForm>
        <ContentBottom>
          Já tem cadastro? <Link to="/">Faça seu login</Link>
        </ContentBottom>
      </Content>
    </Container>
  );
};

export default ResetPassword;
