/* eslint-disable default-case */
import { firebaseLogs } from './firebaseConnection';
import 'firebase/firestore';

interface ILtiClientConnectionConfig {
  sellerId: string;
  name: string;
  url: string;
  clientId: string;
  plataform: string;
}

interface ILtiConnection {
  sellerId: string;
  name: string;
  url: string;
  clientId: string;
  authenticationEndpoint: string;
  accesstokenEndpoint: string;
  authConfig: {
    method: string;
    key: string;
  };
}

interface IDataDeleteConnection {
  url: string;
  clientId: string;
}

const dataBaseLogs = firebaseLogs.firestore();

export const getAllLtiConnections = async (
  firebaseDoc: string,
): Promise<ILtiConnection[] | false> => {
  const dataClientConnections = await dataBaseLogs
    .collection('lti-client-connections')
    .doc(firebaseDoc)
    .get()
    .then(doc => {
      return doc.data();
    })
    .catch(err => {
      console.error(`Ocorreu um erro ${err}`);
    });
  if (dataClientConnections) {
    const ltiAllClientConnections: ILtiConnection[] = JSON.parse(
      dataClientConnections.data,
    );
    return ltiAllClientConnections;
  }

  return false;
};

export const createNewLtiConnection = async (
  dataClient: ILtiClientConnectionConfig,
): Promise<void> => {
  const { sellerId, name, url, clientId, plataform } = dataClient;

  let NewConnection: ILtiConnection | null = null;
  switch (plataform) {
    case 'canvas':
      NewConnection = {
        sellerId,
        url: 'https://canvas.instructure.com',
        name,
        clientId,
        authenticationEndpoint: `${url}/api/lti/authorize_redirect`,
        accesstokenEndpoint: `${url}/login/oauth2/token`,
        authConfig: {
          method: 'JWK_SET',
          key: `${url}/api/lti/security/jwks`,
        },
      };
      break;

    case 'd2l':
      NewConnection = {
        sellerId,
        url,
        name,
        clientId,
        authenticationEndpoint: `${url}/d2l/lti/authenticate`,
        accesstokenEndpoint: `https://auth.brightspace.com/core/connect/token`,
        authConfig: {
          method: 'JWK_SET',
          key: `${url}/d2l/.well-known/jwks`,
        },
      };
      break;

    case 'moodle':
      NewConnection = {
        sellerId,
        url,
        name,
        clientId,
        authenticationEndpoint: `${url}/mod/lti/auth.php`,
        accesstokenEndpoint: `${url}/mod/lti/token.php`,
        authConfig: {
          method: 'JWK_SET',
          key: `${url}/mod/lti/certs.php`,
        },
      };
      break;
  }

  const firebaseDoc = 'oUxJYbS5Js6xursZMzvM';

  const ltiAllConnections: ILtiConnection[] | false =
    await getAllLtiConnections(firebaseDoc);
  if (ltiAllConnections) {
    if (NewConnection && ltiAllConnections) {
      ltiAllConnections.push(NewConnection);
      const ltiDataConnections = JSON.stringify(ltiAllConnections);
      try {
        await dataBaseLogs
          .collection('lti-client-connections')
          .doc(firebaseDoc)
          .update({ data: ltiDataConnections });
      } catch (error) {
        console.error(`Ocorreu um erro ${error}`);
      }
    }
  }
};

export const deleteLtiConnection = async (
  dataDeleteConnection: IDataDeleteConnection,
): Promise<void> => {
  const firebaseDoc = 'oUxJYbS5Js6xursZMzvM';
  const firebaseDeleteDoc = 'connectionsToDelete';

  const { url, clientId } = dataDeleteConnection;
  const ltiAllConnections: ILtiConnection[] | false =
    await getAllLtiConnections(firebaseDoc);
  if (ltiAllConnections) {
    const connectionToDelete = ltiAllConnections.find(element => {
      return element.clientId === clientId;
    });
    if (connectionToDelete && connectionToDelete.url === url) {
      try {
        const connectionsToDeleteSalved = await getAllLtiConnections(
          firebaseDeleteDoc,
        );
        if (connectionsToDeleteSalved && connectionsToDeleteSalved.length > 0) {
          connectionsToDeleteSalved.push(connectionToDelete);
          await dataBaseLogs
            .collection('lti-client-connections')
            .doc(firebaseDeleteDoc)
            .update({ data: JSON.stringify(connectionsToDeleteSalved) });
        } else {
          await dataBaseLogs
            .collection('lti-client-connections')
            .doc(firebaseDeleteDoc)
            .set({ data: JSON.stringify([connectionToDelete]) });
        }
      } catch (error) {
        console.error(`Ocorreu um erro ${error}`);
      }

      const index = ltiAllConnections.indexOf(connectionToDelete);
      try {
        ltiAllConnections.splice(index, 1);
        await dataBaseLogs
          .collection('lti-client-connections')
          .doc(firebaseDoc)
          .update({ data: JSON.stringify(ltiAllConnections) });
      } catch (error) {
        console.error(`Ocorreu um erro ${error}`);
      }
    }
  }
};
