import React, { useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Status, TooltipInfo } from '../../../pages/Units/styles';
import { IconSchool, IconUsersAlt } from '../../../styles/icons';
import Buttom from '../../forms/Buttom';
import Modal from '../Modal';
import {
  Container,
  Bottom,
  ContentItemReview,
  ItemReview,
  ItemHeader,
  ContentItem,
  BoxItem,
} from './styles';
import IconUser from '../../../assets/icons/icon-user-circle.svg';
import { statusReview } from '../../../data/items';

interface IParams {
  id: string;
}

interface IModalProps {
  isOpen: boolean;
  setIsOpen: () => void;
  reviews: IReviwer[];
}

interface IReviwer {
  id: string;
  itemContent: {
    id: string;
  };
  curator: {
    name: string;
    id: string;
    avatar: string;
  };
  reviewer: {
    name: string;
    id: string;
    avatar: string;
  };
  provider: {
    name: string;
  };
  managerStatus: string;
  reviewerStatus: string;
  curatorStatus: string;
  active: boolean;
  created_at: string;
}

const ModalReview: React.FC<IModalProps> = ({ isOpen, setIsOpen, reviews }) => {
  const routeParames: IParams = useParams();

  const history = useHistory();

  const handleChangeReview = useCallback(
    (id: string) => {
      history.push(`/dashboard/units/review/${id}`);
      setIsOpen();
    },
    [history, setIsOpen],
  );

  return (
    <Modal isOpen={isOpen} setIsOpen={setIsOpen} padding="0" width="50%">
      <Container>
        <h1>Versões de avaliação</h1>
      </Container>
      <div style={{ maxHeight: '400px', overflowY: 'auto' }}>
        {reviews.map((review, i) => (
          <ContentItemReview
            className={`${i + 1 === reviews.length && `border-bottom`}`}
            key={review.id}
          >
            <ItemReview>
              <ItemHeader>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <h2>Avaliação {i + 1}</h2>
                  {review.id === routeParames.id && (
                    <Status
                      style={{ marginTop: '0', marginLeft: '14px', top: 0 }}
                      className="correction"
                    >
                      Atual
                    </Status>
                  )}
                </div>
                <div>
                  <strong>Data Início:</strong>{' '}
                  {review.created_at &&
                    new Date(review.created_at).toLocaleDateString('pt-BR')}
                </div>
              </ItemHeader>
            </ItemReview>
            <ContentItem onClick={() => handleChangeReview(review.id)}>
              <BoxItem>
                <IconUsersAlt />
                <div>
                  <strong>Responsáveis:</strong>
                  <span className="responsibles">
                    <TooltipInfo>
                      <img
                        src={
                          review.curator.avatar
                            ? review.curator.avatar
                            : IconUser
                        }
                        alt="foto"
                      />
                      <span>
                        <div>
                          <strong>{review.curator.name}</strong>
                        </div>
                        <i>Curador</i>
                      </span>
                    </TooltipInfo>
                    <TooltipInfo>
                      <img
                        src={
                          review.reviewer.avatar
                            ? review.reviewer.avatar
                            : IconUser
                        }
                        alt="foto"
                      />
                      <span>
                        <div>
                          <strong>{review.reviewer.name}</strong>
                        </div>
                        <i>Revisor</i>
                      </span>
                    </TooltipInfo>
                  </span>
                </div>
              </BoxItem>
              <BoxItem>
                <IconSchool width={24} />
                <div>
                  <strong>Curadoria</strong>
                  <span className="gray">
                    Início:{' '}
                    {review.created_at &&
                      new Date(review.created_at).toLocaleDateString('pt-BR')}
                  </span>
                </div>
              </BoxItem>
              <BoxItem>
                <Status
                  className={`${
                    statusReview.find(
                      item => item.value === review.curatorStatus,
                    )?.value
                  }`}
                >
                  {
                    statusReview.find(
                      item => item.value === review.curatorStatus,
                    )?.label
                  }
                </Status>
              </BoxItem>
              <BoxItem>
                <IconSchool width={24} />
                <div>
                  <strong>Revisão</strong>
                  <span className="gray">
                    Início:{' '}
                    {review.created_at &&
                      new Date(review.created_at).toLocaleDateString('pt-BR')}
                  </span>
                </div>
              </BoxItem>
              <BoxItem>
                <Status
                  className={`${
                    statusReview.find(
                      item => item.value === review.reviewerStatus,
                    )?.value
                  }`}
                >
                  {
                    statusReview.find(
                      item => item.value === review.reviewerStatus,
                    )?.label
                  }
                </Status>
              </BoxItem>
            </ContentItem>
          </ContentItemReview>
        ))}
      </div>
      <Container>
        <Bottom>
          <Buttom label="Fechar" onClick={() => setIsOpen()} />
        </Bottom>
      </Container>
    </Modal>
  );
};

export default ModalReview;
