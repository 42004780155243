import React, { useState, useRef, useEffect, useCallback } from 'react';
import { BeatLoader } from 'react-spinners';
import { Link, useParams } from 'react-router-dom';

import { useToast } from '../../hooks/toast';

import { Holder, Container, Content, Title, RightSide } from './styles';
import { useAuth } from '../../hooks/auth';

import {
  Main,
  MainContent,
  Body,
} from '../../components/template/SellerTemplate/styles';
import NavTop from '../../components/template/NavTop';

import InputSearch from '../../components/forms/InputSearch';

import { IconBookmarkYellow, IconBookmarkBlack } from '../../styles/icons';
import IconUser from '../../assets/icons/icon-user-circle-lg.svg';

import {
  getProviderStoreDataById,
  getLevelOfLearning,
  IGetKnowledge,
  getKnowledge,
  filterStoreItemsProvider,
  getSellerDataById,
} from '../../services/api';
import { useCart } from '../../hooks/cart';

import Timer from '../../utils/timer';

interface IParams {
  id: string;
}

interface IProvider {
  loaded: boolean;
  id: string;
  name: string;
  description: string;
  email: string;
  cnpj: string;
  cpf: string;
  companyName: string;
  status:
    | 'active'
    | 'inative'
    | 'exclused'
    | 'review'
    | 'approved'
    | 'rejected'
    | 'pending'
    | 'draft';
  typePerson: 'Profissional Liberal' | 'Instituição de Ensino' | 'Fornecedor';
  condition: string;
  imgLogoUrl: string | null;
  isPrimium: boolean;
  unitCount: number | undefined;
  provider_total_items: number | undefined;
  plan_value: string | number | undefined;
  priceInNumber: string | number | undefined;
  isRented?: boolean;
}

interface ISeller {
  loaded: boolean;
  id: string;
  name: string;
  ownerId: string;
  cnpj: string;
  planId: string;
}

interface IItems {
  id: string;
  item_content_id: string;
  idAuthor: string;
  idProvider: string;
  title: string;
  authors: Array<string>;
  levelOfLearning: string[];
  status: string;
  knowledges: {
    id: string;
    title: string;
  }[];
  item: {
    id: string;
  };
}

const queryTimer = new Timer();

const StoreProvider: React.FC = props => {
  const { user } = useAuth();
  const { addToCart, cartItems } = useCart();

  const routeParames: IParams = useParams();
  const { addToast } = useToast();
  const [loading, setLoading] = useState(true);

  // SEARCH ===========
  const [search, setSearch] = useState('');

  // CHECKBOX ===========
  const inputParentLevel = useRef(document.createElement('div'));
  const [levelOfLearning, setLevelOfLearning] = useState([]);
  const [selectedLevel, setSelectedLevel] = useState<string[]>([]);

  const inputParentKnowledge = useRef(document.createElement('div'));
  const [knowledges, setKnowledges] = useState<IGetKnowledge[]>([]);
  const [selectedKnowledge, setSelectedKnowledge] = useState<any[]>([]);

  const [formattedItems, setFormattedItems] = useState({});
  const [itemQuantity, setItemQuantity] = useState(0);

  // SELLERS ===========
  const [seller, setSeller] = useState<ISeller>({} as ISeller);
  useEffect(() => {
    const load = async (): Promise<void> => {
      if (user.type === 'seller' && user.seller) {
        try {
          const { data: res } = await getSellerDataById(user.seller.id);
          setSeller(res);
        } catch (error) {
          addToast({
            title: 'Ops...',
            type: 'error',
            description:
              'Algo inesperado aconteceu, por favor recarrege sua página.',
          });
        }
      }
    };
    load();
  }, [user, addToast]);

  // MORE ITEMS ===========
  const [more, setMore] = useState({});
  const handleMore = useCallback(
    key => {
      setMore({
        ...more,
        [key]: !more[key],
      });
    },
    [more, setMore],
  );

  useEffect(() => {
    const load = async (): Promise<void> => {
      const level = await getLevelOfLearning();
      setLevelOfLearning(level as []);

      const know = await getKnowledge();
      setKnowledges(know);
    };
    load();
  }, [setLevelOfLearning, setKnowledges]);

  const handleLevel = useCallback(
    id => {
      let shadow: string[] = [...selectedLevel];
      if (shadow.includes(id)) {
        shadow = shadow.filter(level => level !== id);
      } else {
        shadow.push(id);
      }
      setSelectedLevel(shadow);
    },
    [selectedLevel, setSelectedLevel],
  );

  const handleKnowledge = useCallback(
    value => {
      let shadow = [...selectedKnowledge];
      if (shadow.includes(value)) {
        shadow = shadow.filter(level => level !== value);
      } else {
        shadow.push(value);
      }
      setSelectedKnowledge(shadow);
    },
    [selectedKnowledge, setSelectedKnowledge],
  );

  // CLEAR FILTER ===========
  const clearFilter = useCallback(() => {
    setSelectedKnowledge([]);
    const checkedKnowledge = inputParentKnowledge.current.querySelectorAll(
      'input[type=checkbox]',
    );
    Array.prototype.forEach.call(checkedKnowledge, function (el, i) {
      if (el.checked) el.checked = false;
    });

    setSelectedLevel([]);
    const checkedLevel = inputParentLevel.current.querySelectorAll(
      'input[type=checkbox]',
    );
    Array.prototype.forEach.call(checkedLevel, function (el, i) {
      if (el.checked) el.checked = false;
    });

    setSearch('');
  }, [inputParentLevel]);

  // PROVIDER ===========
  const [provider, setProvider] = useState<IProvider | { loaded: false }>({
    loaded: false,
  });
  const handleSetProvider = useCallback((providerItem: IProvider) => {
    const finalPrice = Number(providerItem.plan_value) || 10000;
    // console.log('providerItem',providerItem)
    setProvider({
      ...providerItem,
      loaded: true,
      isPrimium: providerItem.condition === 'premium',
      unitCount: providerItem.unitCount || 104,
      priceInNumber: finalPrice,
      plan_value: finalPrice.toLocaleString('pt-br', {
        style: 'currency',
        currency: 'BRL',
      }),
    });
  }, []);
  useEffect(() => {
    const load = async (): Promise<void> => {
      try {
        const { data: res } = await getProviderStoreDataById(
          routeParames.id,
          seller.planId,
          seller.id,
        );
        handleSetProvider(res);
      } catch (error) {
        addToast({
          title: 'Ops...',
          type: 'error',
          description:
            'Algo inesperado aconteceu, por favor recarrege sua página.',
        });
      }
    };
    if (seller.planId) load();
  }, [handleSetProvider, addToast, routeParames, seller]);

  // ITEMS ===========
  useEffect(() => {
    const load = async (): Promise<void> => {
      if (provider.loaded && seller.planId) {
        try {
          setLoading(true);

          const {
            data: { content },
          } = await filterStoreItemsProvider({
            providerId: provider.id,
            planId: seller.planId,
            companyName: search === '' ? undefined : search,
            levelOfLearning:
              selectedLevel.length === 0 ? undefined : selectedLevel.join(','),
            knowledges:
              selectedKnowledge.length === 0
                ? undefined
                : selectedKnowledge.join(','),
          });

          const formattedItemsObj = {};
          const moreObj = {};

          if (itemQuantity === 0) {
            setItemQuantity(content.length);
          }

          content.forEach((element: IItems) => {
            element.knowledges.forEach(know => {
              if (
                selectedKnowledge.length === 0 ||
                (selectedKnowledge.length !== 0 &&
                  selectedKnowledge.includes(know.id))
              ) {
                if (!Array.isArray(formattedItemsObj[know.title])) {
                  formattedItemsObj[know.title] = [];
                }
                formattedItemsObj[know.title].push(element);
              }
            });
          });

          Object.keys(formattedItemsObj).forEach(key => {
            moreObj[key] = formattedItemsObj[key].length > 4;
          });

          setMore(moreObj);
          setFormattedItems(formattedItemsObj);
        } catch (error) {
          addToast({
            title: 'Ops...',
            type: 'error',
            description:
              'Algo inesperado aconteceu, por favor recarrege sua página.',
          });
        } finally {
          setLoading(false);
        }
      }
    };
    queryTimer.register(() => load());
  }, [
    setFormattedItems,
    addToast,
    provider,
    search,
    selectedLevel,
    selectedKnowledge,
    itemQuantity,
    setItemQuantity,
    seller,
  ]);

  const renderItems = useCallback(() => {
    const itemCategories = Object.keys(formattedItems);

    if (itemCategories.length !== 0)
      return itemCategories.map((category: string) => {
        return (
          <Content Tiny={more[category]} className="slideUp">
            <h2>{category}</h2>
            <section>
              {formattedItems[category].map((item: IItems) => (
                <>
                  <article key={`${item.id}-tab-01`}>
                    <main>
                      <h1>{item.title}</h1>
                      <p>{provider.loaded && provider.companyName}</p>
                    </main>
                    <footer>
                      <Link
                        to={`/seller/store/provider/${
                          provider.loaded && provider.id
                        }/item/${item.item_content_id}`}
                      >
                        Ver
                      </Link>
                    </footer>
                  </article>
                </>
              ))}
              <div className="grow">
                <button type="button" onClick={() => handleMore(category)}>
                  <IconBookmarkBlack /> Mostrar mais
                </button>
              </div>
            </section>
          </Content>
        );
      });
  }, [formattedItems, provider, more, handleMore]);

  return (
    <Holder>
      <Main>
        <MainContent className="blur">
          <NavTop />
          <Body className="body">
            <Container className="slideDown">
              <Title>
                {provider.loaded === true && (
                  <div className="fadeIn">
                    <div className="bread-crumb">
                      <ul>
                        <li>
                          <Link to="/seller/store/">Loja</Link>
                        </li>
                        <li>{provider.companyName}</li>
                      </ul>
                    </div>
                    <div className="provider">
                      <img src={provider.imgLogoUrl || IconUser} alt="" />
                      <div>
                        <h1>{provider.companyName}</h1>
                        <div>
                          <div>
                            <p>{provider.description}</p>
                            <p className="units">
                              <IconBookmarkYellow />{' '}
                              <strong>{provider.provider_total_items}</strong>{' '}
                              unidades
                            </p>
                          </div>
                          <div>
                            <p>
                              <strong>{provider.plan_value}</strong>
                              <span> /mês</span>
                            </p>
                            {provider.isRented ? (
                              <Link
                                className="rented"
                                to="/seller/rented-units"
                              >
                                Alugado
                              </Link>
                            ) : (
                              <>
                                {cartItems.find(
                                  cartItem => cartItem.id === provider.id,
                                ) ? (
                                  <Link to="/seller/cart">
                                    Item no Carrinho
                                  </Link>
                                ) : (
                                  <button
                                    onClick={() => addToCart(provider)}
                                    type="button"
                                  >
                                    Alugar
                                  </button>
                                )}
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <h1>Lista de Unidades</h1>
                  </div>
                )}
              </Title>

              {loading && (
                <div className="global-loading">
                  <BeatLoader size={16} />
                </div>
              )}
              {renderItems()}
              {Object.keys(formattedItems).length <= 0 && !loading && (
                <div className="global-loading slideUp">
                  <p>Nenhum item encontrado.</p>
                </div>
              )}
            </Container>
          </Body>
        </MainContent>
      </Main>
      <RightSide className="slideLeft">
        <div>
          <h2>Filtrar unidades</h2>
          <InputSearch
            name="search"
            onChange={e => setSearch(e.target.value)}
            placeholder="Pesquisar"
            value={search}
          />
        </div>
        <div>
          <h3>Áreas de Conhecimento</h3>
          <div className="checkboxContainer" ref={inputParentKnowledge}>
            {knowledges.map(
              (
                know: { label: string; value: string; type: string },
                index: number,
              ) => (
                <label
                  className="checkboxContent"
                  htmlFor={`know-${index}`}
                  key={know.value}
                >
                  {know.label}
                  <input
                    name=""
                    type="checkbox"
                    id={`know-${index}`}
                    onChange={() => handleKnowledge(know.value)}
                  />
                  <span className="checkmark" />
                </label>
              ),
            )}
          </div>
        </div>
        <div>
          <h3>Nível de Aprendizagem</h3>
          <div className="checkboxContainer" ref={inputParentLevel}>
            {levelOfLearning.map(
              (level: { label: string; id: string }, index: number) => (
                <label
                  className="checkboxContent"
                  htmlFor={`level-${index}`}
                  key={level.id}
                >
                  {level.label}
                  <input
                    name=""
                    type="checkbox"
                    id={`level-${index}`}
                    onChange={() => handleLevel(level.id)}
                  />
                  <span className="checkmark" />
                </label>
              ),
            )}
          </div>
        </div>
        <button type="button" onClick={clearFilter}>
          Limpar Filtros
        </button>
      </RightSide>
    </Holder>
  );
};

export default StoreProvider;
