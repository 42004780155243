import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 20px;

  > label {
    font-weight: 700;
    color: var(--gray);
  }

  input {
    margin-right: 12px;
  }

  input[type='checkbox'] {
    position: relative;
    cursor: pointer;

    &:checked:after {
      content: '';
      display: block;
      width: 4px;
      height: 9px;
      border: solid white;
      border-width: 0 2px 2px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
      position: absolute;
      top: 0px;
      left: 6px;
    }

    &:checked:before {
      border: 2px solid var(--aurea);
      background-color: var(--aurea);
    }

    &::before {
      content: '';
      display: block;
      position: absolute;
      width: 16px;
      height: 16px;
      top: -3.5px;
      left: -1px;
      border: 2px solid rgba(141, 133, 133, 0.45);
      border-radius: 5px;
      background-color: white;
      transition: background 0.3s;
    }
  }

  small {
    color: var(--error);
  }
`;

export const Content = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 20px;
  > div {
    margin-left: 0 !important;
    display: flex;
    flex-direction: row;
    width: 100%;

    flex: 0 0 33%;
    max-width: 33%;
    margin: 0 0px 15px 0;
  }
  &.col-2 {
    > div {
      flex: 0 0 45%;
      max-width: 45%;
      margin: 0 12px 12px 0;
    }
  }
`;
