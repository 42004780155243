import styled from 'styled-components';

export const Container = styled.nav`
  margin-top: 32px;
  ul {
    list-style: none;
    padding: 0;
    margin: 0 0 9px 0;
    color: var(--gray);
    position: relative;
    li {
      display: inline;
      padding: 8px 14px;
      border: solid transparent;
      border-width: 0px 0px 3px 0px;
      margin: 0 5px 0 0;
      cursor: pointer;
      &.active {
        border: solid var(--aurea);
        border-width: 0px 0px 3px 0px;
        font-weight: bold;
      }
    }

    &:first-child {
      padding-left: 24px;
    }
  }

  &.secondary {
    ul {
      li {
        margin: 0;
        padding: 8px 24px;

        text-transform: uppercase;
        border: solid var(--aurea);
        border-width: 0px 0px 3px 0px;
        &.active {
          font-weight: bold;
          color: white;
          background: var(--aurea);
          border-top-left-radius: 5px;
          border-top-right-radius: 5px;
        }
      }
    }
  }
`;

export const TabItemContainer = styled.div`
  width: 100%;
  &.show-tab {
    &.on {
      display: block;
    }
    &.off {
      display: none;
    }
  }
`;
