const MONTH_NAMES = [
  'Janeiro',
  'Fevereiro',
  'Março',
  'Abril',
  'Maio',
  'Junho',
  'Julho',
  'Agosto',
  'Setembro',
  'Outubro',
  'Novembro',
  'Dezembro',
];

interface IGetFormat {
  date: Date;
  prefomattedDate?: boolean | string;
  hideYear?: boolean;
}

const getFormattedDate = ({
  date,
  prefomattedDate = false,
  hideYear = false,
}: IGetFormat): string => {
  const day = date.getDate();
  const month = MONTH_NAMES[date.getMonth()];
  const year = date.getFullYear();
  const hours = date.getHours();
  let minutes: number | string = '';
  minutes = date.getMinutes();

  if (minutes < 10) {
    // Adding leading zero to minutes
    minutes = `0${minutes}`;
  }

  if (prefomattedDate) {
    // Today at 10:20
    // Yesterday at 10:20
    return `${prefomattedDate} às ${hours}:${minutes}`;
  }

  if (hideYear) {
    // 10. January at 10:20
    return `${day} de ${month} às ${hours}:${minutes}`;
  }

  // 10. January 2017. at 10:20
  return `${day} de ${month} de ${year}. às ${hours}:${minutes}`;
};

export const timeAgo = (dateParam: string): string | null => {
  if (!dateParam) {
    return null;
  }

  const timezone = -3 * 60 * 60 * 1000;

  const date =
    typeof dateParam === 'object'
      ? dateParam
      : new Date(new Date(dateParam).getTime() + timezone);
  const DAY_IN_MS = 86400000; // 24 * 60 * 60 * 1000

  const today = new Date();
  const yesterday = new Date(today.getTime() - DAY_IN_MS);

  // const seconds = Math.round(today.getTime() - date.getTime());
  // const minutes = Math.round(seconds / 60);

  const isToday = today.toDateString() === date.toDateString();
  const isYesterday = yesterday.toDateString() === date.toDateString();
  const isThisYear = today.getFullYear() === date.getFullYear();

  // if (seconds < 5) {
  //   return 'agora';
  // }
  // if (seconds < 60) {
  //   return `${seconds} segundos atrás`;
  // }
  // if (seconds < 90) {
  //   return 'há alguns minutos atrás';
  // }
  // if (minutes < 60) {
  //   return `${minutes} minutos atrás`;
  // }
  if (isToday) {
    return getFormattedDate({ date, prefomattedDate: 'hoje' }); // Today at 10:20
  }
  if (isYesterday) {
    return getFormattedDate({ date, prefomattedDate: 'ontem' }); // Yesterday at 10:20
  }
  if (isThisYear) {
    return getFormattedDate({ date, prefomattedDate: false, hideYear: true }); // 10. January at 10:20
  }

  return getFormattedDate({ date }); // 10. January 2017. at 10:20
};
