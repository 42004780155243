import React from 'react';

import { Switch, Route, Redirect } from 'react-router-dom';
import MainTemplate from '../components/template/MainTemplate';
import SellerTemplate from '../components/template/SellerTemplate';
import ScrollToTop from '../components/template/ScrollToTop';

import Dashboard from '../pages/Dashboard';
import MyUnits from '../pages/MyUnits';
import MyServices from '../pages/MyServices';
import ItemUpdate from '../pages/MyUnits/itemUpdate';
import Units from '../pages/Units';
import Unit from '../pages/Units/unit';
import Review from '../pages/Units/review';
import Profile from '../pages/Profile';
import Studio from '../pages/Studio';
import Users from '../pages/Users';
import Plans from '../pages/Plans';
import Providers from '../pages/Users/providers';

import Store from '../pages/Store';
import StoreProvider from '../pages/StoreProvider';
import StoreItem from '../pages/StoreItem';
import Cart from '../pages/cart';
import MyRents from '../pages/myRents';
import SellerControl from '../pages/sellerControl';
import RentsControl from '../pages/rentsControl';
import RentedUnits from '../pages/RentedUnits';
import RentedUnit from '../pages/RentedUnit';
import Subjects from '../pages/Subjects';
import Subject from '../pages/Subject';
import SubjectItem from '../pages/SubjectItem';
import SellerLmsKey from '../pages/SellerLmsKey';
import StoreService from '../pages/PartnersPlatform';
import StoreServiceItem from '../pages/StoreServiceItem';
import Courses from '../pages/Courses';
import Course from '../pages/Course';
import ProvidersRents from '../pages/ProvidersRents';
import { AccessReport } from '../pages/Report/Access';
import { LogsReport } from '../pages/Report/Logs';
import { UnitAccessReport } from '../pages/Report/UnitAccess';

import Tutorials from '../pages/Tutorials';
import Settings from '../pages/Settings';
import { UnitReport } from '../pages/Report/Providers';

const AuthRoutes: React.FC = () => (
  <>
    <ScrollToTop />
    <Switch>
      <Route
        path="/dashboard"
        render={({ match: { url } }) => (
          <>
            <MainTemplate>
              <Route path={`${url}/`} exact component={Dashboard} />
              <Route
                path={`${url}/access-report`}
                exact
                component={AccessReport}
              />
              <Route path={`${url}/logs-report`} exact component={LogsReport} />
              <Route
                path={`${url}/units-report`}
                exact
                component={UnitReport}
              />

              <Route path={`${url}/profile`} exact component={Profile} />
              <Route path={`${url}/my-team`} exact component={Users} />
              <Route path={`${url}/my-units`} exact component={MyUnits} />
              <Route path={`${url}/my-services`} exact component={MyServices} />
              <Route path={`${url}/tutorials`} exact component={Tutorials} />
              <Route path={`${url}/settings`} exact component={Settings} />

              <Route
                path={`${url}/unit-access-report`}
                exact
                component={UnitAccessReport}
              />

              <Route
                path={`${url}/my-units/update/:id`}
                exact
                component={ItemUpdate}
              />
              <Route path={`${url}/units`} exact component={Units} />
              <Route
                path={`${url}/units/review/:id`}
                exact
                component={Review}
              />
              <Route path={`${url}/units/item/:id`} exact component={Unit} />
              <Route path={`${url}/plans`} exact component={Plans} />
              <Route path={`${url}/providers`} exact component={Providers} />
              <Route
                path={`${url}/providers-rents`}
                exact
                component={ProvidersRents}
              />
              <Route
                path={`${url}/providers/plan/:provider_id`}
                exact
                component={Plans}
              />
            </MainTemplate>
          </>
        )}
      />

      <Route
        path="/seller"
        render={({ match: { url } }) => (
          <>
            <SellerTemplate>
              <Route path={`${url}/store`} exact component={Store} />
              <Route
                path={`${url}/store/provider/:id`}
                exact
                component={StoreProvider}
              />
              <Route
                path={`${url}/store-service`}
                exact
                component={StoreService}
              />
              <Route
                path={`${url}/store-service/provider/:id`}
                exact
                component={StoreServiceItem}
              />
              <Route
                path={`${url}/store/provider/:id/item/:idItem`}
                exact
                component={StoreItem}
              />
              {/* Route cart */}
              <Route path={`${url}/cart`} exact component={Cart} />
              <Route path={`${url}/rents`} exact component={MyRents} />
              <Route path={`${url}/control`} exact component={SellerControl} />
              <Route
                path={`${url}/rents-control/:id`}
                exact
                component={RentsControl}
              />
              <Route path={`${url}/courses`} exact component={Courses} />
              <Route path={`${url}/course/:id`} exact component={Course} />
              {/* Rented units */}
              <Route
                path={`${url}/rented-units`}
                exact
                component={RentedUnits}
              />
              <Route
                path={`${url}/rented-unit/item/:id`}
                exact
                component={RentedUnit}
              />
              {/* Subjects */}
              <Route
                path={`${url}/manage-subjects`}
                exact
                component={Subjects}
              />
              <Route path={`${url}/subject/:id`} exact component={Subject} />
              <Route
                path={`${url}/subject/:idSubject/item/:id/:idSubjectItem`}
                exact
                component={SubjectItem}
              />
            </SellerTemplate>
          </>
        )}
      />

      <Route path="/studio" exact component={Studio} />
      <Route path="/studio/:id" exact component={Studio} />

      <Route path="/lms-key" exact component={SellerLmsKey} />

      <Redirect from="*" to="/dashboard" />
    </Switch>
  </>
);

export default AuthRoutes;
