/* eslint-disable prefer-destructuring */
/* eslint-disable dot-notation */
import { Editor } from '@tinymce/tinymce-react';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useParams } from 'react-router-dom';
import AsyncCreatableSelect from 'react-select/async-creatable';
import { RawEditorSettings } from 'tinymce';
import IconUser from '../../assets/icons/icon-user-circle.svg';
import InputMany from '../../components/forms/InputMany';
import Upload from '../../components/forms/Upload';
import AlertConfirm from '../../components/template/AlertConfirm';
import ModalDelegate from '../../components/template/ModalDelegate';
import ModalDetailsUnits from '../../components/template/ModalDetailsUnits';
import ModalResources, {
  IEditResource,
} from '../../components/template/ModalResource';
import ModalReview from '../../components/template/ModalReview';
import Render, { IContent } from '../../components/template/Render';
import Tab, { TabItem } from '../../components/template/Tabs';
import { Title } from '../../components/template/TemplateManager/styles';
import { itemsStaus } from '../../data/items';
import { useAuth } from '../../hooks/auth';
import { useToast } from '../../hooks/toast';
import {
  createTags,
  filterComment,
  filterReviewer,
  getShowReviewer,
  getTags,
  postCreateComment,
  putEdit,
  putItem,
  putReviewStatus,
  tagDeleteItem,
  tagItem,
  uploadFiles,
} from '../../services/api';
import {
  IconBookOpenArea,
  IconDocument,
  IconSchool,
  IconSendMessage,
  IconUniversity,
  IconUsersAlt,
} from '../../styles/icons';
import { buildTopics } from '../../utils/buildTopics';
import { generateId } from '../../utils/generateId';
import { removePaste } from '../../utils/removePasteTinymce';
import { timeAgo } from '../../utils/timeSince';
import {
  CommentBody,
  CommentBox,
  CommentContent,
  CommentFooter,
  CommentImg,
  CommentItem,
  CommentLoggedUserBox,
  CommentLoggedUserImg,
  CommentLoggedUserItem,
  CommentLoggedUserTime,
  CommentTime,
} from './comment.styles';
import {
  ButtonMode,
  ButtonWhite,
  Card,
  CardBody,
  CardFooter,
  CardItem,
  CardTitle,
  Container,
  ContentButtonsMode,
  ContentEditor,
  ContentLargeButton,
  ContentLeft,
  ContentPreview,
  ContentReview,
  ContentRight,
  ContentTab,
  ContentTabHeader,
  ContentTabItem,
  InputSendMensage,
  LargeButton,
  Status,
  TooltipInfo,
} from './styles';
import { TagItemDiv } from './tagStyles';

interface IParams {
  id: string;
}

interface IItem {
  id: string;
  authors: Array<string>;
  mediaUrl: string;
  questions: string;
  bibliographicReference: Array<string>;
  introduction: string;
  learningObjectives: Array<string>;
  synthesis: string;
  title: string;
  levelOfLearning: Array<string>;
  knowledges: {
    id: string;
    title: string;
  }[];
  topics: string;
  status: string;
  tags: ITag[];
  item: {
    id: string;
    provider: {
      name: string;
    };
  };
}

interface ITag {
  id: string;
  name: string;
  created_at: string;
  updated_at: string;
}

interface IReviwer {
  id: string;
  reviewerId: string;
  curatorId: string;
  managerStatus: string;
  reviewerStatus: string;
  curatorStatus: string;
  curator: {
    name: string;
    avatar: string;
  };
  reviewer: {
    name: string;
    avatar: string;
  };
  created_at: string;
  item: IItem;
}

interface IReviwerArr {
  id: string;
  itemContent: {
    id: string;
  };
  curator: {
    name: string;
    id: string;
    avatar: string;
  };
  reviewer: {
    name: string;
    id: string;
    avatar: string;
  };
  provider: {
    name: string;
  };
  managerStatus: string;
  reviewerStatus: string;
  curatorStatus: string;
  created_at: string;
  active: boolean;
}

interface IComments {
  id: string;
  author: {
    id: string;
    name: string;
    type: string;
    email: string;
    avatar: string;
  };
  comment: string;
  created_at: string;
}

const permissionEditMode = ['admin', 'review'];
const permissionAdm = ['manager', 'admin'];
const statusReview = [
  { value: null, label: 'Análise' },
  { value: 'approved', label: 'Aprovado' },
  { value: 'reproved', label: 'Reprovado' },
  { value: 'adjust', label: 'Correção' },
];

const UnitSingle: React.FC = () => {
  const routeParames: IParams = useParams();
  const screenCommentRef = useRef<HTMLDivElement>(null);
  const { addToast } = useToast();
  const { user } = useAuth();

  const [currentTab, setCurrentTab] = useState('tab-00');
  const [currentMode, setCurrentMode] = useState('preview');

  const [modalDelegate, setModalDelegate] = useState(false);
  const [modalReview, setModalReview] = useState(false);

  const [review, setReview] = useState<IReviwer>({} as IReviwer);
  const [reviews, setReviews] = useState<IReviwerArr[]>([]);
  const [countReview, setCountReview] = useState(0);

  const [comments, setComments] = useState<IComments[]>([]);
  const [comment, setComment] = useState('');

  // Item
  const [providerId, setProviderId] = useState('');
  const [authors, setAuthors] = useState(['']);
  const [title, setTitle] = useState('');
  const [mediaUrl, setMediaUrl] = useState('');
  const [questions, setQuestions] = useState('');
  const [introduction, setIntroduction] = useState('');
  const [synthesis, setSynthesis] = useState('');
  const [learningObjectives, setLearningObjectives] = useState(['']);
  const [bibliographicReference, setBibliographicReference] = useState(['']);
  const [modalDetailsUnits, setmodalDetailsUnits] = useState(false);
  const [loadBibliographicReference, setLoadBibliographicReference] =
    useState(false);
  const [content, setContent] = useState<IContent[]>([
    {
      title: 'Tópico 01',
      sections: [{ content: '', type: 'txt' }],
    },
  ]);
  const [itemTags, setItemTags] = useState<any[]>([]);

  const [openModalResource, setOpenModalResource] = useState(false);
  const [editResource, setEditResource] = useState<IEditResource>(
    {} as IEditResource,
  );
  const [propsEditor, setPropsEditor] = useState<RawEditorSettings>(
    {} as RawEditorSettings,
  );

  useEffect(() => {
    const load = async (): Promise<void> => {
      try {
        // Get Avaliação
        const { data: resReview } = await getShowReviewer({
          id: routeParames.id,
          type: 'review',
        });
        setProviderId(resReview.content.itemContent.item.provider.id);
        setReview({
          ...resReview.content,
          item: {
            ...resReview.content.itemContent,
          },
        });

        setAuthors(resReview.content.itemContent.authors);
        setMediaUrl(resReview.content.itemContent.mediaUrl);
        setQuestions(resReview.content.itemContent.questions);
        setTitle(resReview.content.itemContent.title);
        setLearningObjectives(resReview.content.itemContent.learningObjectives);
        setSynthesis(resReview.content.itemContent.synthesis);
        setIntroduction(resReview.content.itemContent.introduction);
        setBibliographicReference(
          resReview.content.itemContent.bibliographicReference,
        );
        setLoadBibliographicReference(true);
        setItemTags(
          resReview.content.itemContent.tags?.map((tag: any) => {
            return {
              value: tag.id,
              label: tag.name,
            };
          }),
        );

        // Get nas avaliações disponíveis
        const { data: resReviews } = await filterReviewer({
          itemContentId: resReview.content.itemContent.id,
          orderByCreatedAt: 'ASC',
        });
        setReviews(resReviews.content);

        const count = resReviews.content
          .map((item: any) => item.id)
          .indexOf(routeParames.id);
        setCountReview(count + 1);

        // Get nos comentários
        const { data: resComment } = await filterComment({
          reviewId: resReview.content.id,
        });
        setComments(resComment.content);
        screenCommentRef.current?.scrollTo(0, 1000000000);
      } catch (error) {
        addToast({
          title: 'Ops ...',
          description: 'Algo inesperado aconteceu',
          type: 'error',
        });
      }
    };
    load();
  }, [addToast, routeParames]);
  // Para aumentar o tamanho da tela
  useEffect(() => {
    document.querySelector('body')?.classList.add('width-all');
    return () => {
      document.querySelector('body')?.classList.remove('width-all');
    };
  }, []);

  const handleSendComment = useCallback(async () => {
    if (comment === '') {
      return;
    }
    try {
      await postCreateComment({
        comment,
        reviewId: review.id,
        itemId: review.item ? review.item.id : '',
      });
      setComment('');

      const { data: resComment } = await filterComment({
        reviewId: review.id,
      });
      setComments(resComment.content);
      screenCommentRef.current?.scrollTo(0, 1000000000);
    } catch (error) {
      addToast({
        title: 'Ops ...',
        type: 'error',
        description: 'Algo de inesperado aconteceu',
      });
    }
  }, [addToast, comment, review.id, review.item]);

  const render = useMemo(() => {
    if (!review.item) {
      return {
        title: '',
        authors: [''],
        mediaUrl: '',
        questions: '',
        knowlegdeLevel: [''],
        knowlegdeArea1: '',
        introduction: '',
        learningObjectives: [''],
        synthesis: '',
        bibliographicReference: [''],
        content: [],
      };
    }
    return {
      title: review.item.title,
      authors: review.item.authors,
      mediaUrl: review.item.mediaUrl,
      questions: review.item.questions,
      knowlegdeLevel: [''],
      knowlegdeArea1: '',
      introduction: review.item.introduction,
      learningObjectives: review.item.learningObjectives,
      synthesis: review.item.synthesis,
      bibliographicReference: review.item.bibliographicReference,
      content: JSON.parse(review.item.topics) as IContent[],
    };
  }, [review]);

  const statusCurratorLabel = useMemo(() => {
    return statusReview.find(item => item.value === review.curatorStatus)
      ?.label;
  }, [review.curatorStatus]);

  const statusCurratorValue = useMemo(() => {
    return statusReview.find(item => item.value === review.curatorStatus)
      ?.value;
  }, [review.curatorStatus]);

  const statusReviewerLabel = useMemo(() => {
    return statusReview.find(item => item.value === review.reviewerStatus)
      ?.label;
  }, [review.reviewerStatus]);

  const statusReviewerValue = useMemo(() => {
    return statusReview.find(item => item.value === review.reviewerStatus)
      ?.value;
  }, [review.reviewerStatus]);

  const dataReviews = useMemo(() => {
    return (
      review.created_at &&
      new Date(review.created_at).toLocaleDateString('pt-BR')
    );
  }, [review]);

  const seeCurator = useMemo(() => {
    return permissionAdm.includes(user.type) || user.type === 'curator';
  }, [user.type]);

  const seeReviewer = useMemo(() => {
    return permissionAdm.includes(user.type) || user.type === 'review';
  }, [user.type]);

  const itemStatus = useMemo(() => {
    if (!review.item) {
      return;
    }

    return itemsStaus.find(item => item.value === review.item.status)?.label;
  }, [review.item]);

  const stringTopics = useMemo(() => {
    let str = '';

    if (!review.item) {
      return '<h1>Tópico 01</h1>';
    }

    if (!review.item.topics) {
      return '<h1>Tópico 01</h1>';
    }
    const topics = review.item
      ? (JSON.parse(review.item.topics) as IContent[])
      : [];
    setContent(topics);
    topics.forEach(topic => {
      str += `<h1>${topic.title}</h1>`;
      topic.sections.forEach(section => {
        if (section.type === 'txt') {
          str += `${section.content}`;
        } else {
          str += `<div <div class="resource" data-interaction="true" data-type="${section.type}" data-subtype="${section.subtype}" data-resource='${section.content}'>${section.label}</div>`;
        }
      });
    });

    return str;
  }, [review.item]);

  const handleInitalState = useCallback(async () => {
    try {
      const { data: resReview } = await getShowReviewer({
        id: routeParames.id,
        type: 'review',
      });

      setReview({
        ...resReview.content,
        item: {
          ...resReview.content.itemContent,
        },
      });
    } catch (error) {
      addToast({
        title: 'Ops ...',
        description: 'Algo inesperado aconteceu ao recarregar os dados.',
        type: 'error',
      });
    }
  }, [routeParames.id, addToast]);

  const saveSettingKnowledge = async (knowledgeLevel: string, knowledges: string) => {
    try {
      await putEdit({
        id: review.item.id,
        levelOfLearning: [knowledgeLevel],
        knowledges: [knowledges],
      });

      const { data: resReview } = await getShowReviewer({
        id: routeParames.id,
        type: 'review',
      });

      setReview({
        ...resReview.content,
        item: {
          ...resReview.content.itemContent,
        },
      });

      addToast({
        title: 'Sucesso!',
        description: `Item foi Salvo com sucesso`,
        type: 'success',
      });
    } catch (error) {
      addToast({
        title: 'Ops ...',
        description: 'Algo inesperado aconteceu',
        type: 'error',
      });
    }
  }

  const handleChangeStatus = useCallback(
    async (status: string) => {
      const statusLabel = statusReview.find(
        item => item.value === status,
      )?.label;
      const confirm = await AlertConfirm({
        title: `Tem certeza que deseja alterar este item para ${statusLabel}?`,
        text: 'Esta ação pode ser desfeita',
        type: status,
      });

      if (!confirm) {
        return;
      }

      try {
        await putReviewStatus({
          ids: [review.id],
          status,
          comment: confirm || ' ',
        });

        handleInitalState();

        addToast({
          title: 'Sucesso!',
          description: `Item foi ${statusLabel} com sucesso`,
          type: 'success',
        });
      } catch (error) {
        addToast({
          title: 'Ops ...',
          description: 'Algo inesperado aconteceu',
          type: 'error',
        });
      }
    },
    [addToast, review.id, handleInitalState],
  );

  const handleUpdateItemStatus = useCallback(async () => {
    const confirm = await AlertConfirm({
      title: `Tem certeza que deseja alterar este item para revisão?`,
      text: 'Esta ação pode ser desfeita',
      type: 'adjust',
    });

    if (!confirm) {
      return;
    }

    try {
      await putReviewStatus({
        ids: review.item ? [review.id] : [''],
        status: 'adjust',
        comment: confirm || ' ',
      });

      handleInitalState();

      addToast({
        title: 'Sucesso!',
        description: `Item foi alterado com sucesso`,
        type: 'success',
      });
    } catch (error) {
      addToast({
        title: 'Ops ...',
        description: 'Algo inesperado aconteceu',
        type: 'error',
      });
    }
  }, [addToast, handleInitalState, review.id, review.item]);

  const handleModalDelegate = useCallback(() => {
    setModalDelegate(!modalDelegate);
  }, [modalDelegate]);

  const handleModalDetailsUnits = useCallback(() => {
    setmodalDetailsUnits(!modalDetailsUnits);
  }, [modalDetailsUnits]);

  const handleModalReview = useCallback(() => {
    setModalReview(!modalReview);
  }, [modalReview]);

  const handleTab = useCallback((id: string) => {
    setCurrentTab(id);
  }, []);

  const handleLearningObjectives = useCallback(
    (learningObjective: Array<string>) => {
      setLearningObjectives(learningObjective);
    },
    [],
  );
  const handleAuthors = useCallback((authorsArray: Array<string>) => {
    setAuthors(authorsArray);
  }, []);

  const handleBuildTopics = useCallback((e: string) => {
    const topics = buildTopics(e);
    setContent(topics);
  }, []);

  const handleSubmit = useCallback(async () => {
    const confirm = await AlertConfirm({
      title: 'Tem certeza que deseja  enviar suas alterações?',
      text: 'Essa ação pode ser desfeita',
    });

    if (!confirm) {
      return;
    }
    try {
      await putItem({
        id: review.item && review.item.id,
        title,
        introduction,
        mediaUrl: mediaUrl || undefined,
        questions: questions || undefined,
        synthesis,
        learningObjectives: learningObjectives.filter(item => item !== ''),
        topics: JSON.stringify(content),
        authors: authors.filter(input => input !== ''),
        bibliographicReference: bibliographicReference.filter(
          item => item !== '',
        ),
        knowledges: review.item.knowledges.map(item => item.id),
        levelOfLearning: review.item.levelOfLearning.filter(
          item => item !== '',
        ),
      });
      handleInitalState();
      addToast({
        title: 'Sucesso!',
        type: 'success',
        description: 'Alterações realizadas com sucesso!',
      });
    } catch (error) {
      addToast({
        title: 'Ops...',
        type: 'error',
        description: 'Não foi possível salvar suas alterações',
      });
    }
  }, [
    review.item,
    title,
    introduction,
    mediaUrl,
    questions,
    synthesis,
    learningObjectives,
    content,
    authors,
    bibliographicReference,
    handleInitalState,
    addToast,
  ]);

  const handleModalResource = useCallback(() => {
    setOpenModalResource(!openModalResource);
  }, [openModalResource]);
  const commentBody = useMemo(() => {
    return comments.map(commentItem => {
      const userPhoto = commentItem.author?.avatar || IconUser;
      if (commentItem.author.id === user.id) {
        return (
          <CommentLoggedUserItem key={`${commentItem.id}`}>
            <CommentContent>
              <CommentLoggedUserBox>{commentItem.comment}</CommentLoggedUserBox>
              <CommentLoggedUserTime>
                {timeAgo(commentItem.created_at)}
              </CommentLoggedUserTime>
            </CommentContent>
            <CommentLoggedUserImg>
              <img src={userPhoto} alt="foto" />
            </CommentLoggedUserImg>
          </CommentLoggedUserItem>
        );
      }
      return (
        <CommentItem key={`${commentItem.id}`}>
          <CommentImg>
            <img src={userPhoto} alt="foto" />
          </CommentImg>
          <CommentContent>
            <CommentBox>{commentItem.comment}</CommentBox>
            <CommentTime>{timeAgo(commentItem.created_at)}</CommentTime>
          </CommentContent>
        </CommentItem>
      );
    });
  }, [comments, user.id]);

  const handleTagOnChange = useCallback(
    async (newValue: any, actionMeta: any) => {
      if ([newValue[newValue.length - 1].label].toString().includes(';')) {
        const arrayString = newValue[newValue.length - 1].label.split(';');
        if (arrayString.length > 1) {
          for (let i = 0; i < arrayString.length; i++) {
            if (arrayString[i] !== '') {
              if (i === 0) {
                newValue[newValue.length - 1].label = arrayString[i];
                newValue[newValue.length - 1].value = arrayString[i];
              } else {
                const object = {
                  label: arrayString[i],
                  value: arrayString[i],
                  __isNew__: true,
                };
                newValue.push(object);
              }
            }
          }
        }
      }
      if (['create-option', 'select-option'].includes(actionMeta.action)) {
        const ids: string[] = [];
        if (actionMeta.action === 'create-option') {
          for (let i = 0; i < newValue.length; i++) {
            if (newValue[i]['__isNew__']) {
              // eslint-disable-next-line no-await-in-loop
              const response = (await createTags([newValue[i].label])).data.ids;
              if (response) {
                ids.push(response[0]);
                newValue[i].value = response[0];
                delete newValue[i]['__isNew__'];
              } else {
                // eslint-disable-next-line no-await-in-loop
                const select = (await getTags(newValue[i].label)).data
                  .content[0].id;
                ids.push(select);
                newValue[i].value = select;
                delete newValue[i]['__isNew__'];
              }
            }
          }
        } else {
          ids.push(newValue[newValue.length - 1].value);
        }
        if (ids) {
          await tagItem(ids, review.item.id);
        }
      }
      if (actionMeta.action === 'remove-value') {
        await tagDeleteItem(actionMeta.removedValue.value, review.item.id);
      }
      setItemTags(newValue);
    },
    [review],
  );

  const [loadUpload, setLoadUpload] = useState(false);
  const [loadUploadStorageQuestion, setLoadUploadStorageQuestion] =
    useState(false);

  const handleUploadStorageQuestion = useCallback(
    async (files: File[]) => {
      setLoadUploadStorageQuestion(true);
      try {
        const formData = new FormData();
        formData.append('questions', files[0]);
        const response = await uploadFiles({
          formData,
          provider_id: user.id || '',
          type: 'questions',
        });
        setQuestions(response?.data[0].url);
      } catch (error) {
        addToast({
          title: 'Ops ...',
          description: 'Ocorreu um erro durante seu upload.',
          type: 'error',
        });
      } finally {
        setLoadUploadStorageQuestion(false);
      }
    },
    [addToast, user.id],
  );

  const handleUpload = useCallback(
    async (files: File[]) => {
      setLoadUpload(true);

      try {
        const formData = new FormData();
        formData.append('images', files[0]);
        const response = await uploadFiles({
          formData,
          provider_id: user.id || '',
          type: 'images',
        });
        setMediaUrl(response?.data[0].url);
      } catch (error) {
        addToast({
          title: 'Ops ...',
          description: 'Ocorreu um erro durante seu upload.',
          type: 'error',
        });
      } finally {
        setLoadUpload(false);
      }
    },
    [addToast, user.id],
  );

  const loadTagOptions = useCallback(async (inputValue, callback) => {
    const func = async (): Promise<any> => {
      const { data: tags } = await getTags(inputValue);
      callback(
        tags.content.map(tag => {
          return {
            value: tag.id,
            label: tag.name,
          };
        }),
      );
    };
    func();
  }, []);
  return (
    <>
      <ModalResources
        isOpen={openModalResource}
        setIsOpen={handleModalResource}
        propsEditor={propsEditor}
        editResource={editResource}
        currentProviderId={providerId}
      />
      <ModalDelegate
        currentItemId={review.item ? [review.item.id] : ['']}
        handleInitalState={handleInitalState}
        isOpen={modalDelegate}
        setIsOpen={handleModalDelegate}
        isNewReview={review.item && review.id}
      />
      <ModalDetailsUnits
        handleInitalState={handleInitalState}
        isOpen={modalDetailsUnits}
        setIsOpen={handleModalDetailsUnits}
        handleSubmit={saveSettingKnowledge}
      />
      <ModalReview
        isOpen={modalReview}
        setIsOpen={handleModalReview}
        reviews={reviews}
      />
      <Container className="slideDown" style={{ maxWidth: '1200px' }}>
        <Title>
          <h1>Avaliação {countReview}</h1>
          {reviews.filter(
            (item, i) =>
              i === reviews.length - 1 && item.id === routeParames.id,
          ).length > 0 && (
            <Status className="correction" style={{ marginTop: '0', top: 0 }}>
              Revisão Vigente
            </Status>
          )}
        </Title>
        <p>{review.item && review.item.title}</p>
        <ContentReview>
          <ContentLeft>
            {permissionEditMode.includes(user.type) && (
              <ContentButtonsMode>
                <ButtonMode
                  onClick={() => setCurrentMode('preview')}
                  className={`${
                    currentMode === 'preview' ? 'active' : ''
                  } preview`}
                >
                  Modo preview
                </ButtonMode>
                <ButtonMode
                  onClick={() => setCurrentMode('edit')}
                  className={`${currentMode === 'edit' ? 'active' : ''} edit`}
                >
                  Modo edição
                </ButtonMode>
              </ContentButtonsMode>
            )}
            <ContentPreview
              style={{ display: currentMode === 'preview' ? 'block' : 'none' }}
            >
              <Render render={render} currentTopic={0} className="review" />
            </ContentPreview>
            <ContentEditor
              style={{ display: currentMode === 'edit' ? 'block' : 'none' }}
            >
              <ContentTabHeader>
                <ContentTab>
                  <Tab
                    className="secondary"
                    itens={[
                      { id: 'tab-00', label: 'CAPA' },
                      { id: 'tab-01', label: 'INTRODUÇÃO' },
                      { id: 'tab-02', label: 'OBJETIVOS' },
                      { id: 'tab-03', label: 'CONTEÚDO' },
                      { id: 'tab-04', label: 'SÍNTESE' },
                    ]}
                    handleTab={handleTab}
                    defaultValue={currentTab}
                  />
                </ContentTab>
                <button
                  type="button"
                  onClick={handleSubmit}
                  // disabled={
                  //   review.item &&
                  //   ['publish', 'reproved'].includes(review.item.status) &&
                  //   true
                  // }
                >
                  Salvar
                </button>
              </ContentTabHeader>
              <TabItem id="tab-00" defaultValue={currentTab}>
                <ContentTabItem>
                  <h2>Título</h2>
                  <div className="bg-white">
                    <input
                      className="inputSingle"
                      type="text"
                      placeholder="Título da unidade..."
                      name="title"
                      defaultValue={title}
                      onChange={e => setTitle(e.target.value)}
                    />
                  </div>
                  <h2>Autores</h2>
                  <div className="bg-white">
                    <InputMany
                      placeholder="NOVO AUTOR"
                      wrap={false}
                      defaultValue={authors.map(level => {
                        return { id: generateId(), value: level };
                      })}
                      handleInputs={handleAuthors}
                    />
                  </div>
                  <h2>Imagem para fundo de capa</h2>
                  <div className="bg-white">
                    <input
                      className="inputSingle"
                      type="text"
                      placeholder="Insira um link de uma imagem para o serviço..."
                      name="mediaUrl"
                      defaultValue={mediaUrl}
                      onChange={e => setMediaUrl(e.target.value)}
                    />
                    <div style={{ marginTop: '20px', alignSelf: 'center' }}>
                      <Upload
                        onUpload={handleUpload}
                        formts={[
                          { extension: '.jpg', types: 'image/jpg' },
                          { extension: '.jpeg', types: 'image/jpeg' },
                          { extension: '.png', types: 'image/png' },
                          { extension: '.gif', types: 'image/gif' },
                        ]}
                        loading={loadUpload}
                        name="uploadQuestions"
                        onChange={e => setMediaUrl(e.target.value)}
                        defaultValue={mediaUrl}
                      />
                    </div>
                  </div>
                  <h2>Banco de questões</h2>
                  <div className="bg-white">
                    <input
                      type="text"
                      placeholder="Faça o upload de um arquivo para o banco de questões..."
                      className="inputSingle"
                      name="questionStorageUrl"
                      defaultValue={questions}
                      onChange={e => setQuestions(e.target.value)}
                    />
                    <div style={{ marginTop: '20px', textAlign: 'center' }}>
                      <Upload
                        onUpload={handleUploadStorageQuestion}
                        formts={[
                          { extension: '.doc', types: 'application/msword' },
                          {
                            extension: '.docx',
                            types:
                              'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                          },
                          { extension: '.pdf', types: 'application/pdf' },
                        ]}
                        loading={loadUploadStorageQuestion}
                        name="questions"
                        defaultValue={questions}
                        onChange={e => setQuestions(e.target.value)}
                        style={{ margin: '0px 0px 20px 0px' }}
                      />
                    </div>
                  </div>
                </ContentTabItem>
              </TabItem>
              <TabItem id="tab-01" defaultValue={currentTab}>
                <ContentTabItem>
                  <h2>Introdução</h2>
                  {review.item && introduction ? (
                    <Editor
                      initialValue={introduction}
                      onEditorChange={e => setIntroduction(e)}
                      init={{
                        height: 490,
                        width: '100%',
                        menubar: false,
                        statusbar: false,
                        toolbar: false,
                        plugins: ['paste'],
                        setup: editor => {
                          // toolbar
                          editor.ui.registry.addContextToolbar(
                            'edit-text-listener',
                            {
                              predicate() {
                                return true;
                              },
                              items:
                                'bold italic | alignleft aligncenter alignright alignjustify',
                              position: 'node',
                              scope: 'node',
                            },
                          );
                        },
                        content_style: `
                        body {
                          padding: 0 20px;
                        }
                      `,
                      }}
                    />
                  ) : undefined}
                </ContentTabItem>
              </TabItem>
              <TabItem id="tab-02" defaultValue={currentTab}>
                <ContentTabItem>
                  <h2>Objetivos</h2>
                  <div className="bg-white">
                    <InputMany
                      placeholder="NOVO OBJETIVO DE APRENDIZAGEM"
                      wrap={false}
                      defaultValue={learningObjectives.map(level => {
                        return { id: generateId(), value: level };
                      })}
                      handleInputs={handleLearningObjectives}
                    />
                  </div>
                </ContentTabItem>
              </TabItem>
              <TabItem id="tab-03" defaultValue={currentTab}>
                <ContentTabItem>
                  <h2>Conteúdo</h2>
                  {review.item && (
                    <Editor
                      initialValue={stringTopics}
                      onEditorChange={handleBuildTopics}
                      init={{
                        setup: editor => {
                          setPropsEditor(editor as any);

                          // https://www.tiny.cloud/docs/api/tinymce/tinymce.editor/
                          editor.on('keydown', (eventKey: KeyboardEvent) => {
                            const element = editor.selection.getNode();
                            // INTERACTION SELECTION
                            if (!element.hasAttribute('data-interaction')) {
                              return;
                            }
                            switch (eventKey.key) {
                              case 'Backspace':
                              case 'Delete':
                                // Deletar o recurso quando delete ou backspace for pressionado
                                const selectedBlocks =
                                  editor.selection.getSelectedBlocks();
                                selectedBlocks.forEach(
                                  (item: any, i: number) => {
                                    const block = selectedBlocks[i];
                                    block.parentNode &&
                                      block.parentNode.removeChild(block);

                                    // if (block.dataset.interaction && selectedBlocks[i]) {
                                    //   block.parentNode.removeChild(block);
                                    // }
                                  },
                                );
                                break;
                              case 'Enter':
                                // Ir para próxima linha quando o enter for pressionado
                                eventKey.preventDefault();
                                const paragraph = document.createElement('p');
                                const text = document.createTextNode('\u00A0'); // &nbsp;
                                paragraph.appendChild(text);
                                element.parentNode &&
                                  element.parentNode.insertBefore(
                                    paragraph,
                                    element.nextSibling,
                                  );
                                editor.focus();
                                editor.selection.select(paragraph);
                                break;
                              default:
                                eventKey.preventDefault();
                                break;
                            }
                          });

                          // Recursos
                          editor.ui.registry.addButton('interaction', {
                            icon: 'plus',
                            text: 'Recursos',
                            onAction() {
                              setEditResource({} as IEditResource); // reset edit para modal abrir na main
                              handleModalResource();
                            },
                            onSetup(buttonResource) {
                              // NÃO DEIXA INSERIR EM LOCAIS DIFERENTES QUE O PARÁGRAFO SIMPLES
                              const eventButtonResourceCallback = (
                                event: any,
                              ): void => {
                                buttonResource.setDisabled(
                                  event.element.nodeName.toLowerCase() !== 'p',
                                );
                              };
                              editor.on(
                                'NodeChange',
                                eventButtonResourceCallback,
                              );

                              // /* onSetup should always return the unbind handlers */
                              return () => {
                                editor.off(
                                  'NodeChange',
                                  eventButtonResourceCallback,
                                );
                              };
                            },
                          });

                          // toolbar
                          editor.ui.registry.addContextToolbar(
                            'edit-interaction-listener',
                            {
                              predicate(node) {
                                return (
                                  node.nodeName.toLowerCase() === 'div' &&
                                  node.getAttribute('data-interaction') ===
                                    'true'
                                );
                              },
                              items:
                                'edit-interaction | copy-interaction | delete-interaction',
                              position: 'node',
                              scope: 'node',
                            },
                          );

                          // Edite componente
                          editor.ui.registry.addButton('edit-interaction', {
                            icon: 'edit-block',
                            text: 'Editar',
                            onAction() {
                              // handleOpenEdit();
                              // handleEditInteraction(editor);

                              // Convert to JSON and pass to modal
                              const contentJSON = {
                                type:
                                  editor.selection
                                    .getNode()
                                    .getAttribute('data-type') || '',
                                subtype:
                                  editor.selection
                                    .getNode()
                                    .getAttribute('data-subtype') || '',
                                resource:
                                  editor.selection
                                    .getNode()
                                    .getAttribute('data-resource') || '',
                                element: editor.selection.getNode(),
                              };

                              setEditResource(contentJSON);
                              handleModalResource();
                            },
                          });

                          // Delete componente
                          editor.ui.registry.addButton('delete-interaction', {
                            icon: 'remove',
                            async onAction() {
                              const confirm = await AlertConfirm({
                                title:
                                  'Tem certeza que deseja deletar este componente?',
                                text: 'Esta ação não poderá ser desfeita',
                              });
                              if (!confirm) {
                                return;
                              }

                              // Deletar o recurso quando delete ou backspace for pressionado
                              const element = editor.selection.getNode();
                              const selectedBlocks =
                                editor.selection.getSelectedBlocks();
                              selectedBlocks.forEach((item: any, i: number) => {
                                const block = selectedBlocks[i];
                                block.parentNode &&
                                  block.parentNode.removeChild(block);
                                // if (block.dataset.interaction) {
                                //   block.parentNode && block.parentNode.removeChild(block);
                                // }
                              });

                              // Insere prox linha com foco
                              const paragraph = document.createElement('p');
                              const text = document.createTextNode('\u00A0'); // &nbsp;
                              paragraph.appendChild(text);
                              element.insertBefore(
                                paragraph,
                                element.lastElementChild,
                              );
                              editor.focus();
                              editor.selection.select(paragraph);
                            },
                          });

                          // Copy compoente
                          editor.ui.registry.addButton('copy-interaction', {
                            icon: 'copy',
                            onAction() {
                              const element = editor.selection.getNode();
                              editor.focus();
                              editor.selection.select(element);
                              editor.execCommand('Copy');
                            },
                          });
                        },
                        // ...setup,
                        height: 490,
                        width: '100%',
                        menubar: 'edit tools table tc',
                        menu: {
                          tools: {
                            title: 'Tools',
                            items: 'code',
                          },
                        },
                        plugins: [
                          ' advlist anchor autolink codesample fullscreen help image imagetools code',
                          ' lists link media noneditable preview',
                          ' searchreplace table template visualblocks wordcount tiny_mce_wiris mathtype-editor',
                          'paste',
                        ],
                        external_plugins: {
                          tiny_mce_wiris:
                            'https://www.wiris.net/demo/plugins/tiny_mce/plugin.js',
                        },
                        branding: false,
                        statusbar: false,
                        content_style: `
                        body {
                          padding: 0 20px;
                        }
                        h1 {
                          font-size: 2em;
                          margin: 0.67em 0;
                          color: #2c3038;
                          border-bottom: 1px dashed #e0dede;
                        }
                        .resource {
                          width: 80% !important;
                          margin: 14px auto;
                          text-align: center;
                          padding: 18px;
                          background: #F6C866;
                          border-radius: 5px;
                          color: #3D3939;
                          font-weight: bold;
                          border: 1px solid #F6C866;
                          position: relative;
                        }
                        .latex {
                          font-family: monospace;
                          background-color: #ccc;
                        }
                        .resource::after{
                          content: '';
                          top: 8px;
                          left: 2%;
                          position: absolute;
                          border-radius: 5px;
                          font-weight: bold;
                          border: 1px dashed #F1F2F6;
                          background: transparent;
                          width: 96%;
                          height: 40px;
                        }
                      `,
                        block_formats:
                          'Parágrafo=p; Tópico=h1; Subtópico=h2; Título=h3; Subtítulo=h4; Latex=latex',
                        ...removePaste,
                        toolbar:
                          'formatselect | interaction | bold italic | forecolor backcolor | alignleft aligncenter alignright alignjustify | bullist numlist | link | image image-format | add-mathtype',
                        images_upload_handler(blobInfo, success, failure) {
                          // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
                          async function LoadImageServer() {
                            const formData = new FormData();

                            formData.append('images', blobInfo.blob());

                            const response = await uploadFiles({
                              formData,
                              provider_id: user.provider?.id as string,
                              type: 'images',
                            });
                            success(response?.data[0].url);
                          }
                          LoadImageServer();
                        },
                      }}
                    />
                  )}
                </ContentTabItem>
              </TabItem>
              <TabItem id="tab-04" defaultValue={currentTab}>
                {review.item && synthesis ? (
                  <ContentTabItem>
                    <h2>Síntese</h2>
                    <Editor
                      initialValue={synthesis}
                      onEditorChange={e => setSynthesis(e)}
                      init={{
                        height: 250,
                        width: '100%',
                        menubar: false,
                        statusbar: false,
                        toolbar: false,
                        plugins: ['paste'],
                        setup: editor => {
                          // toolbar
                          editor.ui.registry.addContextToolbar(
                            'edit-text-listener',
                            {
                              predicate() {
                                return true;
                              },
                              items:
                                'bold italic | alignleft aligncenter alignright alignjustify',
                              position: 'node',
                              scope: 'node',
                            },
                          );
                        },
                        content_style: `
                        body {
                          padding: 0 20px;
                        }
                      `,
                      }}
                    />
                  </ContentTabItem>
                ) : undefined}
                {review.item && loadBibliographicReference ? (
                  <ContentTabItem style={{ marginTop: '20px' }}>
                    <h2>Referências Bibliográficas</h2>

                    <>
                      <div className="bg-white">
                        <p style={{ marginBottom: '20px' }}>
                          Para inserir mais de um referencial, use * para
                          separar um do outro
                        </p>
                        <Editor
                          initialValue={bibliographicReference
                            .filter(item => item !== '')
                            .join('*')}
                          onEditorChange={e =>
                            setBibliographicReference(e.split('*'))
                          }
                          init={{
                            width: '100%',
                            height: '300',
                            menubar: false,
                            statusbar: false,
                            toolbar:
                              'bold italic | forecolor backcolor | alignleft aligncenter alignright alignjustify | bullist numlist | link image',
                            plugins: ['paste'],
                            entity_encoding: 'raw',
                            paste_auto_cleanup_on_paste: true,
                            paste_remove_styles: true,
                            paste_remove_styles_if_webkit: true,
                            paste_strip_class_attributes: true,
                            paste_word_valid_elements:
                              'p,b,strong,i,em,h1,h2,ul,li,ol,table,tr,td,th,sub,sup',
                            paste_postprocess(plugin, args) {
                              // console.log(args.node);
                            },
                          }}
                        />
                      </div>
                    </>
                  </ContentTabItem>
                ) : undefined}
              </TabItem>
            </ContentEditor>
          </ContentLeft>
          <ContentRight>
            <ContentLargeButton>
              <LargeButton
                className="disapproved"
                onClick={() => handleChangeStatus('reproved')}
                disabled={
                  review.item &&
                  ['approved', 'reproved'].includes(review.item.status) &&
                  true
                }
              >
                Reprovar
              </LargeButton>
              <LargeButton
                onClick={() => handleChangeStatus('approved')}
                disabled={
                  review.item &&
                  ['approved', 'reproved'].includes(review.item.status) &&
                  true
                }
              >
                {permissionAdm.includes(user.type) ? `Publicar` : `Aprovar`}
              </LargeButton>
            </ContentLargeButton>
            <Card>
              <CardTitle>
                <div>
                  <h2>Status da Avaliação</h2>
                  <span>
                    <strong>Atual: </strong> {itemStatus}
                  </span>
                </div>
                <div>
                  {permissionAdm.includes(user.type) && (
                    <button type="button" onClick={handleModalReview}>
                      Ver todas
                    </button>
                  )}
                </div>
              </CardTitle>
              <CardBody>
                {seeCurator && (
                  <CardItem>
                    <div className="icon">
                      <IconSchool width={18} />
                    </div>
                    <div className="options">
                      <div>
                        <strong>Curadoria</strong>
                        <span>
                          <strong>Início</strong> {dataReviews}
                        </span>
                      </div>
                      <div>
                        <Status className={`${statusCurratorValue}`}>
                          {statusCurratorLabel}
                        </Status>
                      </div>
                    </div>
                  </CardItem>
                )}
                {seeReviewer && (
                  <CardItem className="pt-0">
                    <div className="icon">
                      <IconDocument width={12} />
                    </div>
                    <div className="options">
                      <div>
                        <strong>Revisão</strong>
                        <span>
                          <strong>Início</strong> {dataReviews}
                        </span>
                      </div>
                      <div>
                        <Status className={`${statusReviewerValue}`}>
                          {statusReviewerLabel}
                        </Status>
                      </div>
                    </div>
                  </CardItem>
                )}
              </CardBody>
              <CardFooter>
                {permissionAdm.includes(user.type) ? (
                  <TooltipInfo>
                    <ButtonWhite
                      onClick={() => handleModalDelegate()}
                      disabled={
                        review.item &&
                        ['approved', 'reproved'].includes(review.item.status) &&
                        true
                      }
                    >
                      Nova Avaliação
                    </ButtonWhite>
                    {review.item &&
                      ['approved', 'reproved'].includes(review.item.status) && (
                        <span style={{ width: '250px' }}>
                          Novas avalizações só podem ser criadas quando o item
                          estiver em análise
                        </span>
                      )}
                  </TooltipInfo>
                ) : (
                  <TooltipInfo>
                    <ButtonWhite onClick={() => handleUpdateItemStatus()}>
                      Enviar para Correção
                    </ButtonWhite>
                    {comments.length === 0 && (
                      <span style={{ width: '250px' }}>
                        Envie um comentário justificando a correção!
                      </span>
                    )}
                  </TooltipInfo>
                )}
              </CardFooter>
            </Card>
            {['curator', 'admin'].includes(user.type) && (
              <Card>
                <CardTitle>
                  <div>
                    <h2>TAGS:</h2>
                    <span>
                      <strong>Inserir tag</strong>
                    </span>
                  </div>
                </CardTitle>
                <TagItemDiv>
                  <AsyncCreatableSelect
                    cacheOptions
                    defaultOptions
                    isMulti
                    defaultValue={itemTags}
                    value={itemTags}
                    isClearable={false}
                    placeholder="+ add tags"
                    formatCreateLabel={inputValue =>
                      `Criar a tag "${inputValue.split(';')}"`
                    }
                    loadingMessage={() => 'Carregando opções...'}
                    theme={theme => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary50: '#E0DEDE',
                        primary25: '#E0DEDE',
                        primary: '#fff',
                      },
                    })}
                    styles={{
                      multiValue: styles => ({
                        ...styles,
                        backgroundColor: 'var(--item-tag-body)',
                        color: 'var(--item-tag-letter)',
                        ':hover': {
                          border: 0,
                        },
                      }),
                      valueContainer: styles => ({
                        ...styles,
                        borderColor: 'pink',
                        margin: 0,
                        ':hover': {
                          border: 0,
                        },
                      }),
                      control: styles => ({
                        ...styles,
                        border: 0,
                        borderWidth: 0,
                        ':hover': {
                          border: 0,
                        },
                        ':focus': {
                          border: 0,
                        },
                      }),
                    }}
                    onChange={handleTagOnChange}
                    loadOptions={loadTagOptions}
                  />
                </TagItemDiv>
              </Card>
            )}
            <Card>
              <CardTitle>
                <div>
                  <h2>Detalhes da Unidade</h2>
                  <button
                    onClick={() => handleModalDetailsUnits()}
                    className="buttonEdit"
                    type="button"
                  >
                    Editar
                  </button>
                  <span>
                    <strong>Provider: </strong>
                    {review.item && review.item.item.provider.name}
                  </span>
                </div>
              </CardTitle>
              <CardBody>
                <CardItem>
                  <div className="icon">
                    <IconBookOpenArea width={18} />
                  </div>
                  <div className="options">
                    <div>
                      <strong>Área de Conhecimento:</strong>
                      <span className="large-font">
                        {review.item &&
                          review.item.knowledges
                            .map(item => item.title)
                            .join(',')}
                      </span>
                    </div>
                  </div>
                </CardItem>
                <CardItem>
                  <div className="icon">
                    <IconUniversity width={16} />
                  </div>
                  <div className="options">
                    <div>
                      <strong>Nível de Aprendizagem:</strong>
                      <span className="large-font">
                        {review.item && review.item.levelOfLearning.join(', ')}
                      </span>
                    </div>
                  </div>
                </CardItem>
                <CardItem className="pb-0">
                  <div className="icon">
                    <IconUsersAlt width={18} />
                  </div>
                  <div className="options">
                    <div>
                      <strong>Responsáveis:</strong>
                      <span className="responsibles">
                        <TooltipInfo>
                          <img
                            src={
                              review.item && review.curator.avatar
                                ? review.curator.avatar
                                : IconUser
                            }
                            alt="foto"
                          />
                          <span>
                            <div>
                              <strong>
                                {review.item && review.curator.name}
                              </strong>
                            </div>
                            <i>Curador</i>
                          </span>
                        </TooltipInfo>
                        <TooltipInfo>
                          <img
                            src={
                              review.item && review.reviewer.avatar
                                ? review.reviewer.avatar
                                : IconUser
                            }
                            alt="foto"
                          />
                          <span>
                            <div>
                              <strong>
                                {review.item && review.reviewer.name}
                              </strong>
                            </div>
                            <i>Revisor</i>
                          </span>
                        </TooltipInfo>
                      </span>
                    </div>
                  </div>
                </CardItem>
              </CardBody>
            </Card>
            <Card>
              <CardTitle className="coment-title">
                <div>
                  <h2>Comentários</h2>
                </div>
              </CardTitle>
              <CommentBody ref={screenCommentRef}>{commentBody}</CommentBody>
              <CommentFooter>
                <InputSendMensage>
                  <input
                    type="text"
                    onChange={e => setComment(e.target.value)}
                    value={comment}
                    placeholder="Digite uma mensagem"
                    onKeyDown={event =>
                      event.key === 'Enter' && handleSendComment()
                    }
                  />
                  <button type="button" onClick={() => handleSendComment()}>
                    <IconSendMessage width={23} />
                  </button>
                </InputSendMensage>
              </CommentFooter>
            </Card>
          </ContentRight>
        </ContentReview>
      </Container>
    </>
  );
};

export default UnitSingle;
