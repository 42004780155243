import styled from 'styled-components';

export const Container = styled.div`
  padding: 30px;
  p {
    color: var(--gray);
    font-size: 0.75rem;
    margin-top: 10px;
  }
`;

export const Bottom = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
`;

export const ContentItemReview = styled.div`
  border-top: 1px solid var(--gray-border);

  &.border-bottom {
    border-bottom: 1px solid var(--gray-border);
  }
`;

export const ItemReview = styled.div`
  padding: 20px 30px;
`;

export const ItemHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  h2 {
    font-weight: bold;
  }

  div {
    strong {
      font-weight: bold;
    }
  }
`;

export const ContentItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 0 30px 16px 30px;
  cursor: pointer;
`;
export const BoxItem = styled.div`
  display: flex;
  align-items: center;

  strong {
    font-weight: bold;
  }

  svg {
    margin-right: 5px;
  }

  > div {
    display: flex;
    flex-direction: column;
  }

  .responsibles {
    margin-top: 8px;
    display: flex;
    img {
      border-radius: 50%;
      border: 1px solid white;
    }

    div:last-child {
      position: relative;
      left: -12px;
    }
  }

  .gray {
    color: var(--gray);
    font-size: 0.875rem;
  }
`;
