import api from './api';

export interface IUserSignIn {
  email: string;
  password: string;
}

export enum UsersTypes {
  admin = 'admin',
  director = 'director',
  manager = 'manager',
  curator = 'curator',
  review = 'review',
  provider = 'provider',
  serviceProvider = 'serviceProvider',
  editor = 'editor',
  seller = 'seller',
  seller_assistent = 'seller_assistent',
  seller_content_manager = 'seller_content_manager',
  client = 'client',
  user = 'user',
}

export interface IUser {
  id: string;
  name: string;
  avatar: string;
  email: string;
  type: UsersTypes;
  status?: string;
  provider?: IProvider;
  seller?: ISeller;
  isServiceProvider: boolean;
}

export interface IProvider {
  id: string;
  companyName: string;
}

export interface ISeller {
  id: string;
  lms_key?: string;
}

export interface IUserResponse {
  user: IUser;
  token: string;
}

export const TOKEN_KEY = '@aurea:token';
export const USER_KEY = '@aurea:user';
export const LMS_KEY = '@aurea:seller:lms_key';
export const isAuthenticated = (): boolean => {
  return localStorage.getItem(TOKEN_KEY) !== null;
};
export const getToken = (): string | null => localStorage.getItem(TOKEN_KEY);

export const setToken = (token: string): void => {
  localStorage.setItem(TOKEN_KEY, token);
};

export const setUser = (user: {}): void => {
  localStorage.setItem(USER_KEY, JSON.stringify(user));
};
export const setLmsKey = (lmsKey: string): void => {
  localStorage.setItem(LMS_KEY, JSON.stringify(lmsKey));
};

export const getUser = (): IUser | undefined => {
  if (!localStorage.getItem(USER_KEY)) {
    return undefined;
  }
  return JSON.parse(localStorage.getItem(USER_KEY) as string);
};

export const logout = (): void => {
  localStorage.removeItem(TOKEN_KEY);
  localStorage.removeItem(USER_KEY);
  localStorage.removeItem(LMS_KEY);
};

export const postSignIn = async (
  data: IUserSignIn,
): Promise<IUserResponse | undefined> => {
  try {
    const response = await api.post('/signin', data);
    const { user } = response.data;
    const { token } = user;
    if (user.provider?.isServiceProvider) {
      user.type = 'serviceProvider';
    }
    delete user.token;
    return {
      user,
      token,
    };
  } catch (error) {
    return undefined;
  }
};
