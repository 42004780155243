import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import React, { useCallback, useRef, useState } from 'react';
import * as Yup from 'yup';
import { useToast } from '../../../hooks/toast';
import getValidationErrors from '../../../utils/getVaidationErrors';
import Buttom from '../../forms/Buttom';
import Input from '../../forms/Input';
import TextArea from '../../forms/TextArea';
import { editServiceProvider, uploadFiles } from '../../../services/api';

import Modal from '../Modal';

import { Container, Bottom, RowGroup } from './styles';
import { useAuth } from '../../../hooks/auth';
import Upload from '../../forms/Upload';

interface IService {
  id: string;
  title: string;
  description: string;
  link: string;
  image: string;
}

interface IModalProps {
  isOpen: boolean;
  setIsOpen: () => void;
  service?: IService;
}

const ModalAddService: React.FC<IModalProps> = ({
  isOpen,
  setIsOpen,
  service,
}) => {
  const { addToast } = useToast();
  const { user } = useAuth();
  const [loading, setLoading] = useState(false);
  const formRef = useRef<FormHandles>(null);
  const handleSubmit = useCallback(
    async data => {
      try {
        setLoading(true);
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          title: Yup.string(),
          image: Yup.string().url(),
          description: Yup.string(),
          price: Yup.string(),
          link: Yup.string().url(),
        });
        await schema.validate(data, {
          abortEarly: false,
        });
        await editServiceProvider({
          id: service?.id,
          title: data.title,
          description: data.description,
          image: data.image,
          price: data.price,
          link: data.link,
        });

        addToast({
          title: 'Serviço atualizado com sucesso!',
          type: 'success',
        });
        setIsOpen();
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);
          formRef.current?.setErrors(errors);
          return;
        }
        addToast({
          title: 'Ops...',
          type: 'error',
          description: 'Algo inesperado aconteceu.',
        });
      } finally {
        setLoading(false);
      }
    },
    [addToast, setIsOpen, service],
  );

  const [loadUpload, setLoadUpload] = useState(false);

  const handleUpload = useCallback(
    async (files: File[]) => {
      setLoadUpload(true);

      try {
        const formData = new FormData();
        formData.append('images', files[0]);
        const response = await uploadFiles({
          formData,
          provider_id: user.provider?.id || '',
          type: 'images',
        });
        const imageInput = document.getElementById(
          'imageInput',
        ) as HTMLInputElement;
        if (imageInput) {
          imageInput.value = response?.data[0].url;
        }
      } catch (error) {
        addToast({
          title: 'Ops ...',
          description: 'Ocorreu um erro durante seu upload.',
          type: 'error',
        });
      } finally {
        setLoadUpload(false);
      }
    },
    [addToast, user.provider],
  );

  return (
    <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
      <Container>
        <h1>Editar Serviço</h1>
        <Form ref={formRef} onSubmit={handleSubmit} initialData={service}>
          <RowGroup>
            <Input
              name="title"
              label="Título do serviço:*"
              type="text"
              placeholder="Serviço de correção de conteúdo"
            />

            <Input
              name="image"
              id="imageInput"
              label="Imagem para serviço:"
              type="text"
              placeholder="Link para colocar uma imagem de fundo"
            />
            <div style={{ padding: '10px 0px', textAlign: 'center' }}>
              <label>ou</label>
            </div>
            <Upload
              onUpload={handleUpload}
              formts={[
                { extension: '.jpg', types: 'image/jpg' },
                { extension: '.jpeg', types: 'image/jpeg' },
                { extension: '.png', types: 'image/png' },
                { extension: '.gif', types: 'image/gif' },
              ]}
              loading={loadUpload}
            />
            <TextArea
              name="description"
              label="Descrição do serviço:*"
              placeholder="Descreva seu serviço de forma detalhada"
            />
            <TextArea
              name="price"
              label="Descrição sobre o preço do serviço:*"
              placeholder="Descreva de forma clara como funciona a precificação do seu serviço"
            />
          </RowGroup>
          <Input
            name="link"
            label="Link para referência:"
            type="text"
            placeholder="Link para mais informações"
          />
          <button type="submit" hidden>
            submeter
          </button>
        </Form>
        <Bottom>
          <Buttom
            name="cancelar"
            type="button"
            onClick={() => setIsOpen()}
            label="Cancelar"
            outline
          />
          <Buttom
            name="salvar"
            onClick={() => formRef.current?.submitForm()}
            label="Salvar"
            isLoading={loading}
          />
        </Bottom>
      </Container>
    </Modal>
  );
};

export default ModalAddService;
