/* eslint-disable array-callback-return */
/* eslint-disable no-sequences */
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import * as Yup from 'yup';
import { useAuth } from '../../../hooks/auth';
import { useToast } from '../../../hooks/toast';
import {
  getProviderCourses,
  getSellersRents,
  importProviderCourses,
} from '../../../services/api';
import { IconBookmarkYellow } from '../../../styles/icons';
import getValidationErrors from '../../../utils/getVaidationErrors';
import Buttom from '../../forms/Buttom';
import ReactSelect from '../../forms/ReactSelect';
import Modal from '../Modal';
import { saveManageCoursesLog } from '../../../services/logs';
import {
  Both,
  Bottom,
  Container,
  EmptyTitle,
  Subject,
  SubjectContent,
  SubjectOptions,
} from './styles';

interface IModalProps {
  isOpen: boolean;
  setIsOpen: () => void;
  clearFilter: () => void;
}
interface IProviderRented {
  id: string;
  providerId: string;
  sellerId: string;
  paymentMethod: string;
  status: string;
  provider: {
    name: string;
    companyName: string;
    imgLogoUrl: string;
  };
}
interface IRentedProvider {
  label: string;
  value: string;
}
interface ICourse {
  id: string;
  name: string;
  courseItems: {
    courseId: string;
    id: string;
    order: number;
    subjectId: string;
  }[];
}

const ModalImportCourse: React.FC<IModalProps> = ({
  isOpen,
  setIsOpen,
  clearFilter,
}) => {
  const { user } = useAuth();
  const { addToast } = useToast();
  const [loading, setLoading] = useState(false);
  const formRef = useRef<FormHandles>(null);
  const [listOfProviders, setListOfProviders] = useState<
    Array<IRentedProvider>
  >([{ value: '', label: 'Selecione algum fornecedor' }]);
  const [selectedProvider, setSelectedProvider] = useState('');
  const [providerSelectedCourses, setProviderSelectedCourses] =
    useState<Array<ICourse>>();
  const [checkBoxCourses, setCheckBoxCourses] = useState('');

  useEffect(() => {
    const load = async (): Promise<void> => {
      try {
        setLoading(true);
        if (user.type === 'seller') {
          const { data: res } = await getSellersRents();

          res
            .filter(item => item.status === 'active')
            .map(item => {
              setListOfProviders(oldState => [
                ...oldState,
                {
                  label: item.provider.companyName,
                  value: item.providerId,
                },
              ]);
            });
        }
      } catch (error) {
        addToast({
          title: 'Ops...',
          type: 'error',
          description: 'Algo inesperado aconteceu.',
        });
      } finally {
        setLoading(false);
      }
    };
    load();
  }, [addToast, user.seller, user.type]);

  const handleSelectedProvider = useCallback((selectedProviderItem: string) => {
    setSelectedProvider(selectedProviderItem);
  }, []);

  useEffect(() => {
    const loadProviderCourses = async (): Promise<void> => {
      if (selectedProvider) {
        const { data: res } = await getProviderCourses(selectedProvider);
        setProviderSelectedCourses(res);
      }
    };
    loadProviderCourses();
  }, [selectedProvider]);

  const handleSubmit = useCallback(
    async data => {
      try {
        setLoading(true);
        formRef.current?.setErrors({});

        await importProviderCourses(checkBoxCourses);

        clearFilter();

        saveManageCoursesLog({
          userId: user.id,
          sellerId: user.seller?.id || '',
          action: 'imported_course',
          courseId: checkBoxCourses,
        });

        addToast({
          title: 'O Curso foi importado com sucesso!',
          description:
            'Porém, alguns cursos levam um tempo e notificaremos quando ele estiver disponível!',
          type: 'success',
        });
        setIsOpen();
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);
          formRef.current?.setErrors(errors);
          return;
        }
        addToast({
          title: 'Ops...',
          type: 'error',
          description: 'Algo inesperado aconteceu.',
        });
      } finally {
        setLoading(false);
      }
    },
    [checkBoxCourses, clearFilter, addToast, setIsOpen],
  );

  const handleSelectCourse = useCallback((id: string, divClick?: boolean) => {
    const checkbox = document.getElementById(
      `inputCheckBox-${id}`,
    ) as HTMLInputElement;

    const containerBox = document.getElementById(`containerBox-${id}`);
    const allContainers = document.getElementsByClassName(
      'containerBox',
    ) as HTMLCollectionOf<HTMLElement>;

    for (let i = 0; i < allContainers.length; i++) {
      allContainers[i].style.border = '1px solid var(--quaternary)';
    }

    if (divClick) {
      checkbox.checked = true;
    }

    if (checkbox.checked === true && containerBox) {
      setCheckBoxCourses(id);
      containerBox.style.border = '2px solid green';
    } else if (divClick && containerBox) {
      setCheckBoxCourses(id);
      checkbox.checked = true;
      containerBox.style.border = '2px solid green';
    } else {
      return;
    }
  }, []);

  return (
    <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
      <Container>
        <h1>Importar Disciplina</h1>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <div style={{ margin: '40px 0', height: 'auto' }}>
            {listOfProviders && (
              <ReactSelect
                name="type"
                options={listOfProviders}
                placeholder="Digite ou selecione algum fornecedor"
                handleValue={handleSelectedProvider}
              />
            )}
            {providerSelectedCourses?.length !== 0 &&
            providerSelectedCourses ? (
              providerSelectedCourses.map((item, i) => (
                <Subject
                  className="containerBox"
                  id={`containerBox-${item.id}`}
                  onClick={() => {
                    handleSelectCourse(item.id, true);
                  }}
                >
                  <SubjectContent>
                    <h3>{item.name}</h3>
                    <p className="units">
                      <IconBookmarkYellow />{' '}
                      <strong>{item.courseItems.length}</strong> unidades
                    </p>
                  </SubjectContent>
                  <SubjectOptions>
                    <input
                      name="choice"
                      type="radio"
                      className="inputCheckBox"
                      id={`inputCheckBox-${item.id}`}
                      value={item.id}
                      onChange={() => {
                        handleSelectCourse(item.id);
                      }}
                    />
                  </SubjectOptions>
                </Subject>
              ))
            ) : (
              <EmptyTitle>
                Não há cursos registrados nesse fornecedor...
              </EmptyTitle>
            )}
          </div>

          <Both />
          <button type="submit" hidden>
            submeter
          </button>
        </Form>
        <Bottom>
          <Buttom
            name="cancelar"
            type="button"
            onClick={() => setIsOpen()}
            label="Cancelar"
            outline
          />
          <Buttom
            name="salvar"
            onClick={() => formRef.current?.submitForm()}
            label="Salvar"
            isLoading={loading}
          />
        </Bottom>
      </Container>
    </Modal>
  );
};

export default ModalImportCourse;
