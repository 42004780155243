/* eslint-disable no-lone-blocks */
/* eslint-disable react/jsx-first-prop-new-line */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React from 'react';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { ContainerInput } from './styles';
import Api from '../../../services/api';
import { insertTemporaryLink } from '../../../services/temporaryLinkService';

interface IAlertConfirm {
  title: string;
  text?: string;
  type?: string;
  lmsKey?: string;
  subjectId?: string;
}

interface ITemporaryLink {
  lms_key: string;
  item_id?: string;
  expiration_date: string;
  link: string;
}

const AlertConfirm = async ({
  title,
  text,
  type,
  lmsKey,
  subjectId,
}: IAlertConfirm) => {
  const MySwal = withReactContent(Swal);
  if (type === 'reproved' || type === 'adjust') {
    const result = await MySwal.fire({
      title: <p>{title}</p>,
      text: `${text}, adicione um comentário:`,
      input: 'text',
      showCancelButton: true,
      confirmButtonColor: '#6BC196',
      cancelButtonColor: '#CC593E',
      confirmButtonText: 'SIM',
      cancelButtonText: 'NÃO',
    });
    if (result.value) {
      return result.value;
    }
    if (result.value === true) {
      return ' ';
    }
  } else if (type === 'lms') {
    const result = await MySwal.fire({
      title: <p>{title}</p>,
      text,
    });
    if (result.value) {
      return result.value;
    }
    if (result.value === true) {
      return ' ';
    }
  } else if (type === 'shareLink') {
    if (!lmsKey) {
      return null;
    }

    const lmsKeyFormatted = lmsKey.replace(/['"]+/g, '');

    let url = 'https://student.aurea.app';
    if (process.env.REACT_APP_API === 'https://api-staging.aurea.app') {
      url = 'https://student-staging.aurea.app';
    }
    let studentIdDefault = '<EMAIL/RA/IDENTIFICADOR>';

    // Fanorpi
    if (lmsKeyFormatted === '3f6a963ca5a77d85cc13fc265db61b0e') {
      studentIdDefault = '<userEmail>';
    }

    // ESIC
    if (lmsKeyFormatted === 'ea3bca20e6fb6d7890482f5f8c50112b') {
      url = 'https://lti.aurea.app';

      const urlLtiToCanvas = await MySwal.fire({
        title: <>{title}</>,
        html: (
          <a href=" " target="__blank">
            {`${url}/itemId=${subjectId}`}
          </a>
        ),
        confirmButtonText: 'Copiar URL',
        showCancelButton: true,
        denyButtonText: 'OK',
      });

      if (urlLtiToCanvas.isConfirmed) {
        const copyUrl = () => {
          const linkToCopy = `${url}/itemId=${subjectId}`;
          navigator.clipboard.writeText(linkToCopy);
          return url;
        };

        return copyUrl();
      }

      if (urlLtiToCanvas.value) {
        return urlLtiToCanvas.value;
      }
      if (urlLtiToCanvas.value === true) {
        return ' ';
      }
      return;
    }

    const result = await MySwal.fire({
      title: <p>{title}</p>,
      html: (
        <a
          href={`${url}/validation/post?lmsKey=${lmsKeyFormatted}&itemId=${subjectId}&studentId=${studentIdDefault}`}
          target="__blank"
        >
          {`${url}/validation/post?lmsKey=${lmsKeyFormatted}&itemId=${subjectId}&studentId=${studentIdDefault}`}
        </a>
      ),
    });

    if (result.value) {
      return result.value;
    }
    if (result.value === true) {
      return ' ';
    }
  } else if (type === 'shareTemporaryLink') {
    if (!lmsKey) {
      return null;
    }
    let url = 'https://student.aurea.app';
    if (process.env.REACT_APP_API === 'https://api-staging.aurea.app') {
      url = 'https://student-staging.aurea.app';
    }
    const { data: allSellers } = await Api.get('/sellers/');

    await MySwal.fire({
      title: 'Criar Link Temporário',

      html: (
        <ContainerInput>
          <label htmlFor="date">Data de expiração:</label>
          <input type="date" id="date" />
          <select name="seller" id="seller" defaultValue="">
            <option value="" disabled selected>
              Selecione
            </option>
            {allSellers.map(seller => (
              <option value={seller.lms_key}>{seller.name}</option>
            ))}
          </select>
        </ContainerInput>
      ),
      inputAttributes: {
        autocapitalize: 'off',
      },
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Criar Link',
      showLoaderOnConfirm: true,
      // if date is true then create link
      preConfirm: async () => {
        const expireDate = document.getElementById('date') as HTMLInputElement;
        const lmsKeySellerBySelect = document.getElementById(
          'seller',
        ) as HTMLSelectElement;

        if (
          expireDate.value &&
          expireDate.value !== '' &&
          lmsKeySellerBySelect.value
        ) {
          const urlCreated = `${url}/validation/post?lmsKey=${lmsKeySellerBySelect.value}&itemId=${subjectId}&expirationDate=${expireDate.value}`;
          const temporaryLink: ITemporaryLink = {
            lms_key: lmsKeySellerBySelect.value,
            item_id: subjectId,
            expiration_date: expireDate.value,
            link: urlCreated,
          };
          await insertTemporaryLink(temporaryLink);

          return urlCreated;
        }
        MySwal.showValidationMessage('Preencha a data de expiração');
      },
    }).then(result => {
      if (result) {
        MySwal.fire({
          title: 'Link a ser compartilhado',
          html: (
            <a href={result.value} target="_blank" rel="noreferrer">
              {result.value}
            </a>
          ),
        });
      }
    });
  } else {
    const result = await MySwal.fire({
      title: <p>{title}</p>,
      text,
      showCancelButton: true,
      confirmButtonColor: '#6BC196',
      cancelButtonColor: '#CC593E',
      confirmButtonText: 'SIM',
      cancelButtonText: 'NÃO',
    });
    if (result.value) {
      return result.value;
    }
    if (result.value === true) {
      return ' ';
    }
  }
  return false;
};

export default AlertConfirm;
