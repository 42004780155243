import styled from 'styled-components';

export const CommentBody = styled.div`
  padding: 18px;
  height: 250px;
  overflow-y: auto;
`;

export const CommentItem = styled.div`
  padding-top: 14px;
  display: flex;
  width: 100%;
`;

export const CommentLoggedUserItem = styled.div`
  padding-top: 14px;
  display: flex;
  float: right;
  clear: both;
`;

export const CommentImg = styled.div`
  width: 32px;
  margin-right: 4px;
  display: flex;
  img {
    border-radius: 50%;
    margin: 0 auto 14px auto;
    width: 30px;
    height: 30px;
    align-self: flex-end;
  }
`;

export const CommentLoggedUserImg = styled.div`
  width: 32px;
  margin-left: 4px;
  display: flex;
  img {
    border-radius: 50%;
    margin: 0 auto 14px auto;
    width: 30px;
    height: 30px;
    align-self: flex-end;
  }
`;

export const CommentContent = styled.div``;

export const CommentBox = styled.div`
  font-size: 0.75rem;
  padding: 12px;
  background: var(--aurea);

  border-radius: 8px;
  border-bottom-left-radius: 0px;
  word-break: break-word;
`;

export const CommentLoggedUserBox = styled.div`
  font-size: 0.75rem;
  padding: 12px;
  background: #f9f5f5;

  border-radius: 8px;
  border-bottom-right-radius: 0px;
  word-break: break-word;
`;

export const CommentTime = styled.div`
  font-size: 0.625rem;
  color: var(--gray);
`;

export const CommentLoggedUserTime = styled.div`
  font-size: 0.625rem;
  color: var(--gray);
  text-align: end;
`;

export const CommentFooter = styled.div`
  padding: 0 18px 14px 18px;
`;
