import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 100%;
`;

export const Main = styled.main`
  display: flex;
  flex: 1;
  height: 100vh;
  overflow-y: auto;
`;

export const MainContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const Body = styled.div`
  width: 100%;
  max-width: 812px;
  margin: 35px auto 0 auto;
  section.search {
    margin-top: 30px;
    margin-bottom: 41px;
    width: 252px;
  }
`;

export const Content = styled.div``;

export const Title = styled.header`
  display: flex;
  align-items: center;
  h1 {
    font-size: 2.25rem;
    margin-right: 30px;
  }
`;
