import styled from 'styled-components';

export const Container = styled.div`
  padding: 20px 18px;
`;

export const Row = styled.div`
  label {
    display: block;
    font-weight: bold;
    color: var(--gray);
    margin-top: 30px;
    margin-bottom: 20px;
  }
  &>div {
    margin-bottom: 150px;
  }
`;

export const Bottom = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  margin-top: 24px;

  div + div {
    margin-left: 18px;
  }
`;
