import React, { useCallback, useMemo, useRef } from 'react';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import { RawEditorSettings } from 'tinymce';
import Input from '../../forms/Input';
import Button from '../../forms/Buttom';

import { ContentPage } from './styles';
import { IEditResource, IPages } from '.';
import Editor from '../../forms/Editor';

interface IProps {
  handlePage(page: IPages): void;
  propsEditor: RawEditorSettings;
  setIsOpen: () => void;
  editResource: IEditResource;
}

const FormQuote: React.FC<IProps> = ({
  handlePage,
  propsEditor,
  setIsOpen,
  editResource,
}) => {
  const formRef = useRef<FormHandles>(null);

  const isEdit = useMemo(() => {
    return Object.keys(editResource).length > 0;
  }, [editResource]);

  const handleSubmit = useCallback(
    async data => {
      const quote = data.content;
      if (!quote) {
        return;
      }

      if (isEdit) {
        // editResource.element.remove();
        editResource.element.setAttribute('data-resource', quote);
        propsEditor.insertContent(propsEditor.selection.getContent());
      } else {
        propsEditor.insertContent(
          `<div class='resource' data-interaction='true' data-type='quote' data-subtype='' data-resource='${quote.replace(
            /[']/g,
            '&#39;',
          )}'>
                Citação
                </div>\n
                <p>&nbsp;</p>`,
        );
      }
      propsEditor.focus();
      setIsOpen();
    },
    [propsEditor, setIsOpen, isEdit, editResource.element],
  );
  return (
    <Form
      ref={formRef}
      onSubmit={handleSubmit}
      initialData={{ content: editResource.resource }}
    >
      <ContentPage>
        {!isEdit && (
          <button type="button" onClick={() => handlePage('main')}>
            Voltar
          </button>
        )}
        <div>
          <h2>Citação</h2>
          <Editor name="content" label="Digite uma citação" placeholder="" />
          <Input hidden label="" name="type" defaultValue="quote" />
          <Button label="Inserir" type="submit" />
        </div>
      </ContentPage>
    </Form>
  );
};

export default FormQuote;
