import styled from 'styled-components';

interface IContainerProps {
  isError?: boolean;
}

export const Container = styled.div<IContainerProps>`
  display: flex;
  flex-direction: column;

  width: 100%;
  color: #999;
  padding-top: 18px;
  margin: 0 !important;

  span {
    font-weight: 700;
    color: var(--gray);
  }

  select {
    flex: 1;
    width: 100%;
    color: var(--dark);
    background: transparent;
    border: 1px solid var(--quaternary);
    border-radius: 5px;
    padding: 10px 14px;
    margin-top: 14px;

    transition: 0.4s;
    overflow: hidden;
  }

  small {
    color: var(--danger);
  }
`;
