import { ApexOptions } from 'apexcharts';
import React from 'react';
import ReactApexChart from 'react-apexcharts';

interface IDashboardGraph {
  data: number[];
  categories: Date[];
}

const DashboardGraph: React.FC<IDashboardGraph> = ({ data, categories }) => {
  const options: ApexOptions = {
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'smooth',
      width: 2,
    },
    legend: {
      show: false,
    },
    markers: {
      discrete: [
        {
          seriesIndex: 0,
          dataPointIndex: 7,
          fillColor: '#e3e3e3',
          strokeColor: '#fff',
          size: 5,
        },
      ],
    },
    yaxis: {
      labels: {
        show: false,
      },
    },
    colors: ['#F6C866'],
    xaxis: {
      type: 'category',
      categories,
      labels: {
        show: false,
      },
      crosshairs: {
        show: true,
        position: 'back',
        stroke: {
          color: '#b6b6b6',
          width: 1,
          dashArray: 0,
        },
      },
    },
    grid: {
      show: false,
    },
    chart: {
      width: '300px',
      toolbar: {
        show: false,
      },
      sparkline: {
        enabled: true,
      },
    },
    tooltip: {
      x: {
        format: 'dd/MM/yy HH:mm',
      },
    },
  };
  return (
    <>
      <ReactApexChart
        options={options}
        series={[
          {
            name: 'Renda',
            data,
          },
        ]}
        type="area"
        height={160}
      />
    </>
  );
};

export default DashboardGraph;
