/* eslint-disable react/no-array-index-key */
/* eslint-disable no-nested-ternary */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { useCallback, useEffect, useState } from 'react';
import { format } from 'date-fns';
import { FaArrowDown } from 'react-icons/fa';
import ReactPaginate from 'react-paginate';
import { ptBR } from 'date-fns/locale';
import CountUp from 'react-countup';

import { Link } from 'react-router-dom';
import { startOfDay } from 'date-fns/esm';
import { useAuth } from '../../../hooks/auth';
import { useToast } from '../../../hooks/toast';
import {
  providerApprovedItems,
  providerMostPopularUnitys,
  providerRecentRents,
  providerRentsGraph,
} from '../../../services/api';
import {
  IconBusiness,
  IconDownload,
  IconMoreVertical,
  IconSchool,
  IconStar,
} from '../../../styles/icons';
import {
  Content,
  HighlightContainer,
  ReportContainer,
  RentsContainer,
  ApprovedContainer,
  LeftSideContainer,
  RightSideContainer,
  Container,
  LeftSide,
  RightSide,
  ButtonSeeMore,
  ApprovedItem,
  ApprovedList,
  KnowledgeItem,
  Navigation,
  ItemTitle,
  StatusContainer,
  ButtonFilterRents,
  RentsList,
  RentItem,
  TitleRent,
  DateRent,
  TypeRent,
  OptionsRent,
  ReportTitle,
  ReportContent,
  MonthLabel,
  HightlightList,
} from './styles';
import DashboardGraph from '../DashboardGraph';

interface IDataRents {
  month: Date;
  totalIncome: number;
}
interface IRecentRents {
  id: string;
  seller: { name: string };
  plan: { name: string };
  updatedAt: string;
}

interface IApprovedUnitys {
  lastApprovedItems: {
    id: string;
    title: string;
    knowledges: string;
  }[];
  count: number;
}
interface IPopularUnity {
  title: string;
  id: string;
  accessCounter: number;
}

const DashboardProvider: React.FC = () => {
  const { addToast } = useToast();
  const { user } = useAuth();
  const [mounthCount, setMounthCount] = useState<number>(6);
  const [mounthRent, setMounthRent] = useState<number>();
  const [dataGraph, setDataGraph] = useState<Array<IDataRents>>();
  const [unityApprovedList, setUnityApprovedList] = useState<IApprovedUnitys>();
  const [recentRents, setRecentRents] = useState<Array<IRecentRents>>();
  const [popularUnitys, setPopularUnitys] = useState<Array<IPopularUnity>>();
  const [loadRequest, setLoadRequest] = useState(false);

  function addMonths(date: Date, months: number) {
    date.setMonth(date.getMonth() + months);
    return date;
  }
  useEffect(() => {
    const load = async (): Promise<void> => {
      setLoadRequest(false);
      try {
        const [dataRents, approvedItems, recentRentsData, popularUnitysData] =
          await Promise.all([
            providerRentsGraph(
              startOfDay(new Date()),
              addMonths(new Date(), -5),
            ),
            providerApprovedItems(),
            providerRecentRents(),
            providerMostPopularUnitys(),
          ]);
        setDataGraph(
          dataRents.data.map(
            (item: { month: string | number | Date; totalIncome: any }) => {
              return {
                month:
                  format(new Date(item.month), 'MMM', {
                    locale: ptBR,
                  })
                    .charAt(0)
                    .toUpperCase() +
                  format(new Date(item.month), 'MMM', {
                    locale: ptBR,
                  }).slice(1),
                totalIncome: item.totalIncome,
              };
            },
          ),
        );
        setUnityApprovedList(approvedItems.data);
        setRecentRents(recentRentsData.data);
        setPopularUnitys(popularUnitysData.data);
        setLoadRequest(true);
      } catch (error) {
        addToast({
          title: 'Ops...',
          type: 'error',
          description:
            'Algo inesperado aconteceu, por favor recarrege sua página.',
        });
      }
    };
    load();
  }, [addToast]);
  const [totalIncomeGraph, setTotalIncomeGraph] = useState<Array<number>>([]);

  const handleInputRangeChange = useCallback(
    value => {
      setMounthCount(value);
      if (loadRequest && dataGraph) {
        setMounthRent(dataGraph[value - 1].totalIncome);
      }

      const label = document.getElementsByTagName('label');
      if (label) {
        for (let i = 0; i <= label.length; i++) {
          if (label[i]) {
            label[i].style.fontWeight = 'initial';
          }
        }
        label[value - 1].style.fontWeight = '600';
      }
    },
    [dataGraph, loadRequest],
  );

  useEffect(() => {
    if (mounthCount && dataGraph) {
      setTotalIncomeGraph([0]);
      if (dataGraph[mounthCount - 2]) {
        setTotalIncomeGraph([
          ...totalIncomeGraph,
          dataGraph[mounthCount - 2].totalIncome,
        ]);
      } else {
        setTotalIncomeGraph([0]);
      }
      setTotalIncomeGraph([dataGraph[mounthCount - 1].totalIncome]);
      if (dataGraph[mounthCount]) {
        setTotalIncomeGraph([
          ...totalIncomeGraph,
          dataGraph[mounthCount].totalIncome,
        ]);
      } else {
        setTotalIncomeGraph([0]);
      }
    }
  }, [mounthCount]);

  const [currentPage, setCurrentPage] = useState(0);

  const PER_PAGE = 3;

  const offset = currentPage * PER_PAGE;

  const pageCount = recentRents ? Math.ceil(recentRents.length / PER_PAGE) : 0;

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  function handlePageClick({ selected: selectedPage }) {
    setCurrentPage(selectedPage);
  }

  return (
    <Container>
      <h1 className="userTitle">Olá, {user.name} !</h1>
      <Content style={{ width: '100%' }}>
        <LeftSideContainer>
          <ApprovedContainer>
            <Navigation>
              <LeftSide>
                <h3>{unityApprovedList?.count} Itens aprovados</h3>
              </LeftSide>
              <RightSide>
                <ButtonSeeMore type="button">
                  <Link
                    to="/dashboard/my-units"
                    style={{ textDecoration: 'none', color: '#000' }}
                  >
                    Ver mais
                  </Link>
                </ButtonSeeMore>
              </RightSide>
            </Navigation>
            {unityApprovedList && (
              <ApprovedList>
                {unityApprovedList.lastApprovedItems.map(item => (
                  <ApprovedItem id={item.id} key={item.id}>
                    <ItemTitle>{item.title}</ItemTitle>
                    {item.knowledges && item.knowledges.length ? (
                      <KnowledgeItem>
                        <IconSchool width={15} /> {item.knowledges}
                      </KnowledgeItem>
                    ) : undefined}
                    <StatusContainer>Aprovada</StatusContainer>
                  </ApprovedItem>
                ))}
              </ApprovedList>
            )}
          </ApprovedContainer>
          <RentsContainer>
            <Navigation>
              <LeftSide>
                <h3>Aluguéis Recentes</h3>
              </LeftSide>
              <RightSide>
                <select>
                  <option value="default"> Filtrar por período</option>
                </select>
                <ButtonFilterRents type="button">
                  <FaArrowDown color="#F6C866" />
                  <div className="letter">
                    <div>a</div>
                    <div>z</div>
                  </div>
                </ButtonFilterRents>
              </RightSide>
            </Navigation>
            {recentRents && (
              <RentsList>
                {recentRents.slice(offset, offset + PER_PAGE).map(item => (
                  <RentItem id={item.id} key={item.id}>
                    <TitleRent>
                      {item.seller.name}
                      <DateRent>
                        {format(new Date(item.updatedAt), 'MMM/dd', {
                          locale: ptBR,
                        })
                          .charAt(0)
                          .toUpperCase() +
                          format(new Date(item.updatedAt), 'MMM/dd', {
                            locale: ptBR,
                          }).slice(1)}
                      </DateRent>
                    </TitleRent>

                    <TypeRent>
                      <IconBusiness width={15} color="#F6C866" />
                      {item.plan.name}
                    </TypeRent>
                    <OptionsRent>
                      <IconMoreVertical width={15} />
                    </OptionsRent>
                  </RentItem>
                ))}
                <ReactPaginate
                  previousLabel="←"
                  nextLabel="→"
                  pageCount={pageCount}
                  onPageChange={handlePageClick}
                  containerClassName="pagination__dashboard"
                  pageClassName="pagination__page"
                  breakClassName="pagination__break"
                  previousLinkClassName="pagination__link"
                  nextLinkClassName="pagination__link"
                  disabledClassName="pagination__link--disabled"
                  activeClassName="pagination__link--active"
                />
              </RentsList>
            )}
          </RentsContainer>
        </LeftSideContainer>
        <RightSideContainer>
          <ReportContainer>
            <Navigation>
              <LeftSide
                style={{
                  width: '50%',
                  display: 'inline-flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <IconDownload color="#8d8585" width={10} />{' '}
                <ReportTitle>Relatório</ReportTitle>
              </LeftSide>
              <RightSide>
                <ButtonSeeMore>Ver Mais</ButtonSeeMore>
              </RightSide>
            </Navigation>
            <ReportContent>
              <h3>Total do mês</h3>
              {(mounthRent && loadRequest) || mounthRent === 0 ? (
                <h1>
                  R$
                  <CountUp end={mounthRent} duration={1.3} />
                  ,00
                </h1>
              ) : dataGraph && loadRequest ? (
                <h1>
                  R$
                  <CountUp
                    end={dataGraph[dataGraph.length - 1].totalIncome}
                    duration={1.3}
                  />
                  ,00
                </h1>
              ) : undefined}

              <div
                style={{ width: '100%', height: '150px', paddingLeft: '-10px' }}
                id="chart"
              >
                {dataGraph && mounthCount && (
                  <DashboardGraph
                    data={dataGraph.map(item => {
                      return item.totalIncome;
                    })}
                    categories={dataGraph.map(item => {
                      return item.month;
                    })}
                  />
                )}
              </div>
              <MonthLabel>
                {dataGraph?.map((item, i) => (
                  <label id={`monthLabel-${i}`} key={`key-${i}`}>
                    {item.month}
                  </label>
                ))}
              </MonthLabel>
              <input
                type="range"
                className="inputRange"
                value={mounthCount}
                onChange={e => handleInputRangeChange(e.target.value)}
                min={1}
                max={6}
                step={1}
              />
            </ReportContent>
          </ReportContainer>
          <HighlightContainer>
            <Navigation className="highlightNav">
              <h2>Destaques</h2>
            </Navigation>
            <HightlightList>
              {popularUnitys?.map(item => (
                <div key={item.id}>
                  <IconStar color="#F6C866" fill="#F6C866" width={12} />
                  <li key={item.id}>{item.title}</li>
                </div>
              ))}
            </HightlightList>
          </HighlightContainer>
        </RightSideContainer>
      </Content>
    </Container>
  );
};

export default DashboardProvider;
