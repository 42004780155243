/* eslint-disable func-names */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/require-default-props */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/no-danger */
/* eslint-disable react/no-array-index-key */
import React, {
  HTMLAttributes,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import MathJax from 'react-mathjax-preview';
import TeXToSVG from 'tex-to-svg';
import {
  IconArrowBack,
  IconArrowForward,
  IconCheckCircleGreen,
  IconQuoteLeft,
  IconSidebarMenu,
  IconFeatherCheck,
  IconMapCircle,
  IconBookmark,
  IconWarning,
  IconFileAlt,
  IconMultitrackAudio,
} from '../../../styles/icons';
import {
  Container,
  Header,
  HeaderContent,
  InfoHeader,
  InfoAuthor,
  IconHat,
  ContentAuthor,
  Logo,
  Content,
  Section,
  SectionTopic,
  SectionTxt,
  SectionMedia,
  TitleMediaContent,
  TitleMedia,
  IconSectionVideo,
  SectionQuote,
  SectionQuoteContent,
  SectionGeneral,
  Body,
  Bottom,
  ButtonNext,
  ButtonPrev,
  List,
  Dropdown,
  SectionWarning,
  WarningContent,
  IconWarningContent,
} from './styles';
import { observeDOM } from '../../../utils/observe';

import logoDefault from '../../../assets/images/logo-new.svg';
import logoCeuma from '../../../assets/images/logo_branca_sem_fundo_o.png';
import LogoCertifikedu from '../../../assets/images/certifikedu.svg';
import RenderList from './renderList';
import RenderQuestion from './renderQuestion';
import RenderPicture from './renderPicture';
import { useAuth } from '../../../hooks/auth';

export interface IContent {
  title: string;
  sections: IContentSection[];
}

export interface IContentSection {
  content: string;
  label?: string;
  type:
    | 'txt'
    | 'media'
    | 'quote'
    | 'warning'
    | 'picture'
    | 'list'
    | 'question'
    | 'iframe';
  subtype?: 'videoaula' | 'podcast' | 'pdf' | 'danger' | 'emphasis' | '';
}

interface IRender extends HTMLAttributes<HTMLDivElement> {
  render: {
    title: string;
    authors: Array<string>;
    mediaUrl: string;
    questions: string;
    knowlegdeLevel: Array<string>;
    knowlegdeArea1: string;
    introduction: string;
    learningObjectives: Array<string>;
    synthesis: string;
    bibliographicReference: Array<string>;
    content: IContent[];
    competence?: string;
  };
  editor?: boolean;
  currentTopic: number;
}

interface IComponent {
  section: IContentSection;
  key: string;
}

interface IComponentPicture {
  section: IContentSection;
  key: string;
  count: number;
}

interface IPicture {
  url: string;
  position: number;
}

const Render: React.FC<IRender> = ({
  render,
  currentTopic,
  className,
  editor = false,
}) => {
  const bodyRef = useRef<HTMLDivElement>(null);
  const countImgs = useRef(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [dropdown, setDropdown] = useState(false);
  const [firstScroll, setFirstScroll] = useState(true);

  const { user } = useAuth();

  useEffect(() => {
    setCurrentPage(currentTopic);
  }, [currentTopic]);

  useEffect(() => {
    observeDOM(bodyRef.current, (modify: []) => {
      const tag = modify[modify.length - 1] as any;
      if (bodyRef.current && tag.target.tagName.toLowerCase() === 'div') {
        if (!firstScroll) {
          tag.target.scrollIntoView({
            block: 'start',
            behavior: 'smooth',
            inline: 'start',
          });
        }
      }
    });
  }, [bodyRef, firstScroll]);

  useEffect(() => {
    const latexes = bodyRef.current?.querySelectorAll('span.latex');
    Array.prototype.forEach.call(latexes, function (latex) {
      let latexFormula = latex.innerHTML;
      if (!latexFormula.includes('svg')) {
        latexFormula = latexFormula.substring(2);
        latexFormula = latexFormula.substring(0, latexFormula.length - 2);
        latex.innerHTML = TeXToSVG(latexFormula, {
          width: 1280,
          ex: 8,
          em: 14,
        });
      }
    });
  }, [currentPage]);

  // useMemo to load all pictures and set a position by order REFATORAR
  const images = useMemo(() => {
    let counter = 0;
    const arrPictures: Array<IPicture> = [];
    render.content
      .map(topic => {
        return topic.sections.filter(sec => sec.type === 'picture');
      })
      .forEach(picture => {
        picture.forEach(item => {
          counter += 1;
          arrPictures.push({ url: item.content, position: counter });
        });
      });
    return arrPictures;
  }, [render.content]) as Array<IPicture>;

  const authors = useMemo(() => {
    if (
      render.authors.length === 1 &&
      render.authors.filter(author => author !== '').length === 0
    ) {
      return render.authors;
    }
    return render.authors.filter(author => author !== '');
  }, [render.authors]);

  const learningObjectives = useMemo(() => {
    if (
      render.learningObjectives.length === 1 &&
      render.learningObjectives.filter(learn => learn !== '').length === 0
    ) {
      return render.learningObjectives;
    }
    return render.learningObjectives.filter(learn => learn !== '');
  }, [render.learningObjectives]);

  const bibliographicReference = useMemo(() => {
    if (
      render.bibliographicReference.length === 1 &&
      render.bibliographicReference.filter(learn => learn !== '').length === 0
    ) {
      return render.bibliographicReference;
    }
    return render.bibliographicReference.filter(learn => learn !== '');
  }, [render.bibliographicReference]);

  const handleNextPage = useCallback(() => {
    if (currentPage < render.content.length + 1) {
      setCurrentPage(currentPage + 1);
      bodyRef.current?.scrollTo(0, 0);
    }
  }, [currentPage, render.content]);

  const handlePrevPage = useCallback(() => {
    if (currentPage >= 1) {
      setCurrentPage(currentPage - 1);
      bodyRef.current?.scrollTo(0, 0);
    }
  }, [currentPage]);

  const txt = useCallback(({ section, key }: IComponent) => {
    const parser = new DOMParser();
    const htmlDoc = parser.parseFromString(section.content, 'text/html');
    const body = htmlDoc.querySelector('body');
    const text = body?.querySelector('p');
    const math = body?.querySelector('math');

    if (math) {
      const mathString = `${math.outerHTML}`;
      math.remove();
      const contentFiltered = text?.outerHTML;
      return (
        <>
          <SectionTxt
            key={key}
            dangerouslySetInnerHTML={{
              __html: contentFiltered || ' ',
            }}
          />
          <MathJax
            math={String.raw`${mathString}`}
            style={{ textAlign: 'center', fontSize: '18px' }}
            msDelayDisplay={9999}
          />
        </>
      );
    }

    return (
      <>
        <SectionTxt
          key={key}
          dangerouslySetInnerHTML={{
            __html: section.content,
          }}
        />
      </>
    );
  }, []);

  const media = useCallback(({ section, key }: IComponent) => {
    return (
      <SectionMedia key={key}>
        <TitleMediaContent>
          <TitleMedia>
            {section.subtype === 'pdf' && <IconFileAlt />}
            {section.subtype === 'videoaula' && <IconSectionVideo />}
            {section.subtype === 'podcast' && <IconMultitrackAudio />}
            {section.label}
          </TitleMedia>
        </TitleMediaContent>
        {section.subtype === 'pdf' && (
          <>
            <h2>Leia o material abaixo:</h2>
            <iframe
              src={section.content}
              style={{ width: '90%', height: '500px' }}
              title={key}
              frameBorder="0"
            />
          </>
        )}

        {section.subtype === 'videoaula' && (
          <iframe
            src={section.content}
            style={{ width: '60%', height: '250px' }}
            title={key}
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen"
            frameBorder="0"
          />
        )}

        {section.subtype === 'podcast' && (
          <iframe
            src={section.content}
            style={{ width: '80%', height: '150px' }}
            title={key}
            frameBorder="0"
          />
        )}
      </SectionMedia>
    );
  }, []);

  const quote = useCallback(({ section, key }: IComponent) => {
    return (
      <SectionQuote key={key}>
        <SectionQuoteContent>
          <IconQuoteLeft />
          <div
            dangerouslySetInnerHTML={{
              __html: section.content,
            }}
          />
        </SectionQuoteContent>
      </SectionQuote>
    );
  }, []);

  const iframe = useCallback(({ section, key }: IComponent) => {
    const iframeValue = JSON.parse(decodeURI(section.content));
    return (
      <iframe
        src={iframeValue.url}
        width={iframeValue.width}
        height={iframeValue.height}
        style={{ border: 'none' }}
        title={key}
      />
    );
  }, []);

  const warning = useCallback(({ section, key }: IComponent) => {
    return (
      <SectionWarning key={key}>
        <WarningContent
          className={`${section.subtype === 'danger' && 'danger'}`}
        >
          <IconWarningContent
            className={`${section.subtype === 'danger' && 'danger'}`}
          >
            <div>
              {section.subtype === 'danger' ? (
                <IconWarning />
              ) : section.subtype === 'emphasis' ? (
                <IconWarning />
              ) : (
                <IconBookmark />
              )}
            </div>
          </IconWarningContent>
          <h2>
            {section.subtype === 'danger'
              ? 'Atenção'
              : section.subtype === 'emphasis'
              ? 'Destaque'
              : 'Saiba mais!'}
          </h2>
          {/* <p>{section.content}</p> */}
          <div
            dangerouslySetInnerHTML={{
              __html: section.content,
            }}
          />
          {/* <button type="button">Ver material</button> */}
        </WarningContent>
      </SectionWarning>
    );
  }, []);

  const picture = useCallback(({ section, count }: IComponentPicture) => {
    return <RenderPicture section={section} count={count} />;
  }, []);

  const list = useCallback(({ section, key }: IComponent) => {
    return <RenderList section={section} key={key} />;
  }, []);

  const question = useCallback(({ section, key }: IComponent) => {
    return <RenderQuestion section={section} key={key} />;
  }, []);

  const renderTopics = useCallback(
    (page: number) => {
      countImgs.current = 0;
      return (
        <>
          {render.content
            .filter((_, i) => i + 1 === page)
            .map((topic, i) => (
              <SectionTopic key={`topic-0${i}`}>
                <Section>
                  <h1
                    dangerouslySetInnerHTML={{
                      __html: topic.title,
                    }}
                  />
                </Section>

                {topic.sections.map((section, j) => {
                  switch (section.type) {
                    case 'txt':
                      return txt({
                        section,
                        key: `txt-content-${j}`,
                      });
                    case 'media':
                      return media({ section, key: `media-content-${j}` });
                    case 'warning':
                      return warning({ section, key: `warning-content-${j}` });
                    case 'list':
                      return list({ section, key: `list-content-${j}` });
                    case 'question':
                      return question({ section, key: `list-content-${j}` });
                    case 'picture':
                      const currentImage = images.filter(
                        item => section.content === item.url,
                      );
                      let position = 0;
                      if (currentImage.length) {
                        position = currentImage[0].position;
                      }
                      return picture({
                        section,
                        key: `warning-content-${j}`,
                        count: position,
                      });
                    case 'iframe':
                      return iframe({ section, key: `iframe-content-${j}` });
                    default:
                      return quote({ section, key: `quote-content-${j}` });
                  }
                })}
              </SectionTopic>
            ))}
        </>
      );
    },
    [
      render.content,
      txt,
      media,
      warning,
      list,
      question,
      images,
      picture,
      iframe,
      quote,
    ],
  );

  const renderFirst = useCallback(() => {
    const parser = new DOMParser();
    const htmlDoc = parser.parseFromString(render.introduction, 'text/html');
    const body = htmlDoc.querySelector('body') as HTMLBodyElement;
    const paragraphs = body.querySelectorAll('p');

    return (
      <section>
        <Section>
          <h2>Introdução</h2>
          {Array.from(paragraphs).map(paragraph => {
            const math = paragraph.querySelector('math');

            if (math) {
              const parseMath = math.outerHTML;

              return (
                <MathJax
                  math={String.raw`${parseMath}`}
                  style={{ fontSize: '18px' }}
                  msDelayDisplay={9999}
                />
              );
            }

            return (
              <p
                dangerouslySetInnerHTML={{
                  __html: paragraph.innerHTML,
                }}
              />
            );
          })}
        </Section>
        <Section>
          <h2>Objetivos de Aprendizagem</h2>
          <List>
            {learningObjectives.map((learn, i) => (
              <li key={`learn-0${i}`}>
                <IconCheckCircleGreen />
                {learn || 'Insira os Objetivos de Aprendizagem'}
              </li>
            ))}
          </List>
        </Section>
        <Section>
          <strong>Vamos lá?</strong>
        </Section>
      </section>
    );
  }, [learningObjectives, render.introduction]);

  const renderLast = useCallback(() => {
    const parser = new DOMParser();
    const htmlDoc = parser.parseFromString(render.synthesis, 'text/html');
    const body = htmlDoc.querySelector('body') as HTMLBodyElement;
    const paragraphs = body.querySelectorAll('p');

    return (
      <>
        <section>
          <Section>
            <h2>Síntese</h2>
            {Array.from(paragraphs).map(paragraph => {
              const math = paragraph.querySelector('math');

              if (math) {
                const parseMath = math.outerHTML;

                return (
                  <MathJax
                    math={String.raw`${parseMath}`}
                    style={{ fontSize: '16px' }}
                    msDelayDisplay={9999}
                  />
                );
              }

              return (
                <p
                  dangerouslySetInnerHTML={{
                    __html: paragraph.innerHTML,
                  }}
                />
              );
            })}
          </Section>
        </section>
        <SectionGeneral className="gray-bg">
          <Section>
            <h2>Referências Bibliográficas</h2>
            {bibliographicReference.map((bibi, i) => (
              <p
                key={`learn-0${i}`}
                dangerouslySetInnerHTML={{
                  __html: bibi || 'Insira as Referências Bibliográficas',
                }}
              />
            ))}
          </Section>
        </SectionGeneral>
      </>
    );
  }, [render.synthesis, bibliographicReference]);

  const renderPages = useMemo(() => {
    if (currentPage === 0) {
      return renderFirst();
    }

    if (currentPage === render.content.length + 1) {
      return renderLast();
    }

    return renderTopics(currentPage);
  }, [
    currentPage,
    renderFirst,
    renderTopics,
    render.content.length,
    renderLast,
  ]);

  return (
    <Container>
      <Body className={className} ref={bodyRef} editor={editor}>
        <Header style={{ backgroundImage: `url(${render.mediaUrl})` }}>
          <HeaderContent>
            <InfoHeader>
              <h1>{render.title || 'Insira o título'}</h1>
              <InfoAuthor>
                <IconHat />
                <ContentAuthor>
                  {authors.map((author, i) => (
                    <li key={`author-0${i}`}>
                      <p>{author || 'Insira um autor'}</p>
                      <strong>Autor</strong>
                    </li>
                  ))}
                </ContentAuthor>
              </InfoAuthor>
            </InfoHeader>
            <Logo>
              <img
                src={
                  user.id === 'c8ab0423-2722-4d8f-b2b7-cb2fbc8f265c' // ceuma seller
                    ? logoCeuma
                    : logoDefault
                }
                alt="logo"
              />
            </Logo>
          </HeaderContent>
          <Dropdown>
            <IconSidebarMenu
              width={21}
              onClick={() => setDropdown(!dropdown)}
            />
            <div className={`${dropdown && `show`}`}>
              <header>
                <strong>UNIDADE</strong>
                <h2>{render.title || 'Insira o título'}</h2>
              </header>
              <section
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <strong>COMPETENCIAS</strong>
                <a
                  href="https://certifikedu.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={LogoCertifikedu}
                    width={80}
                    alt="LogoCertifikeduLogo"
                  />
                </a>
              </section>
              <div style={{ marginTop: 3, marginLeft: 5, fontSize: '1.1em' }}>
                {render.competence}
              </div>
              <header />
              <section>
                <ul>
                  <li className={`${render.introduction && `active`}`}>
                    {render.introduction ? (
                      <IconFeatherCheck width={20} />
                    ) : (
                      <IconMapCircle width={20} />
                    )}
                    <div>
                      <h2>Introdução</h2>
                      <p>Introdução à unidade de ensino</p>
                    </div>
                  </li>
                  {render.content.map((item, i) => (
                    <li className="active" key={`menu-topic-0${i}`}>
                      <IconFeatherCheck width={20} />
                      <div>
                        <h2>Tópico {String(i + 1).padStart(2, '0')}</h2>
                        <p dangerouslySetInnerHTML={{ __html: item.title }} />
                      </div>
                    </li>
                  ))}
                </ul>
              </section>
            </div>
          </Dropdown>
        </Header>
        <Content>{renderPages}</Content>
      </Body>
      <Bottom>
        <ButtonPrev
          className={`${currentPage !== 0 && `active`}`}
          onClick={() => handlePrevPage()}
        >
          <IconArrowBack /> Anterior
        </ButtonPrev>
        <ButtonNext
          className={`${currentPage < render.content.length + 1 && `active`}`}
          onClick={() => handleNextPage()}
        >
          Próximo
          <IconArrowForward />
        </ButtonNext>
      </Bottom>
    </Container>
  );
};

export default Render;
