import styled from 'styled-components';
import { IconAddressBook, IconAlert } from '../../../styles/icons';

export const Container = styled.div`
  padding: 20px 18px;
`;

export const RowGroup = styled.div`
  width: 100%;
  > div:first-child {
    width: 54%;
    margin-right: 12px;
    float: left;
  }

  > div:last-child {
    width: 42%;
    float: right;
    margin-top: 52px;
    > div {
      > div {
        min-height: 42px; // React Selec Hight
      }
    }
  }
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  width: 54%;

  div {
    width: 100%;
  }

  div + div {
    margin-left: 16px;
  }

  textarea {
    height: 188px;
  }

  &.fix-mt {
    > div {
      margin-top: 18px;
    }
  }
`;

export const Bottom = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  margin-top: 24px;

  div + div {
    margin-left: 18px;
  }
`;

export const Both = styled.div`
  clear: both;
`;

export const Colunm = styled.div`
  display: flex;
  flex-direction: column;
  width: 44%;

  div {
    width: 100%;
  }
`;

export const RowContent = styled.div`
  margin-top: 32px;
  h2 {
    font-size: 1rem;
    font-weight: bold;
    color: var(--dark);
  }
`;

export const AltText = styled.div`
  font-weight: 500;
  font-size: 16px;
  margin-top: 20px;
  border-radius: 10px;
  padding: 15px;
  background: #f8ebd2;
  color: #3d3939;
  display: flex;
  label {
    margin-left: 10px;
    margin-right: 25px;
  }
  svg {
    margin-top: -15px;
  }
`;

export const BottomForm = styled.div`
  margin-top: 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  a {
    color: var(--dark);
    text-decoration: underline;
  }
`;

export const IconAlertAlt = styled(IconAlert)`
  margin-left: 15px;
  width: 72px;
  height: 72px;
  margin-right: 8px;
  color: #f6c866;
`;

export const SelectForm = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  margin-top: 25px;

  div + div {
    margin-left: 18px;
  }
`;

export const SelectFormCircle = styled.div`
  width: 26px;
  height: 26px;
  border-radius: 50%;

  background: var(--aurea);
  opacity: 0.4;

  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  position: relative;
  &::after {
    content: '';
    position: absolute;
    display: block;
    width: 18px;
    height: 1px;
    background: var(--aurea);
    right: -18px;
  }
  &:last-child::after {
    display: none;
  }

  &.selected {
    opacity: 1;
  }
`;
