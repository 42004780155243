import React, { useCallback, useEffect } from 'react';
import { useState } from 'react';
import { useToast } from '../../../hooks/toast';
import Modal from '../Modal';
import Select from '../../forms/ReactSelect';
import {
  Bottom,
  Container,
  ContentField,
  ContentForm,
  SelectContent,
} from './styles';
import Buttom from '../../forms/Buttom';
import {
  getKnowledge,
  getLevelOfLearning,
  IGetKnowledge,
} from '../../../services/api';

interface IModalProps {
  isOpen: boolean;
  setIsOpen: () => void;
  handleInitalState(): void;
  handleSubmit: (
    knowledgeLevel: string,
    knowledgeArea: string,
  ) => Promise<void>;
  handleChange?: (value: string) => void;
}

const ModalDetailsUnits: React.FC<IModalProps> = ({
  isOpen,
  setIsOpen,
  handleSubmit,
}) => {
  interface ILevelOfLearning {
    label: string;
    id: string;
    type: string;
  }

  const { addToast } = useToast();
  const [levelOfLearning, setLevelOfLearning] = useState<ILevelOfLearning[]>(
    [],
  );
  const [knowledges, setKnowledges] = useState<IGetKnowledge[]>([]);
  const [selectedLevelOfLearning, setSelectedLevelOfLearning] = useState('');
  const [Selectknowledges, setSelectknowledges] = useState('');

  const handleKnowlegdeLevel = useCallback((value: string) => {
    console.log(value);
    setSelectedLevelOfLearning(value);
  }, []);

  const handleknowledges = useCallback((value: string) => {
    setSelectknowledges(value);
  }, []);

  const optionsLevel = levelOfLearning.map(item => ({
    label: item.label,
    value: item.id,
  }));

  useEffect(() => {
    const load = async (): Promise<void> => {
      try {
        const level = await getLevelOfLearning();
        setLevelOfLearning(level);
        const know = await getKnowledge();
        setKnowledges(know);
      } catch (error) {
        addToast({
          title: 'Ops ...',
          description: 'Aconteceu algo inesperado, por favor tente mais tarde',
          type: 'error',
        });
      }
    };
    load();
  }, []);

  return (
    <Modal isOpen={isOpen} setIsOpen={setIsOpen} width="35%">
      <Container>
        <h1>Editar Detalhes Da Unidade</h1>
        <ContentForm>
          <ContentField>
            <label htmlFor="area">Área de Conhecimento</label>
            <SelectContent>
              <Select
                name="nivel"
                options={knowledges}
                placeholder="Selecionar ..."
                handleValue={handleknowledges}
              />
            </SelectContent>
          </ContentField>

          <ContentField>
            <label htmlFor="nivel">Nível de Aprendizagem</label>
            <SelectContent>
              <Select
                name="area"
                options={optionsLevel}
                placeholder="Selecionar ..."
                handleValue={handleKnowlegdeLevel}
              />
            </SelectContent>
          </ContentField>
          <Bottom>
            <Buttom label="Cancelar" outline onClick={() => setIsOpen()} />
            <Buttom
              label="Salvar"
              onClick={() =>
                handleSubmit(selectedLevelOfLearning, Selectknowledges)
              }
            />
          </Bottom>
        </ContentForm>
      </Container>
    </Modal>
  );
};

export default ModalDetailsUnits;
