import styled from 'styled-components';

export const Holder = styled.div`
  display: flex;
  width: 100%;
  & > main {
    width: 100%;
  }
  & > aside {
    width: 300px;
  }
`;

export const Container = styled.div``;

export const Content = styled.div`
  section {
    padding-top: 20px;
    .dropper {
      margin-top: 15px;
      padding-top: 25px;
      border-top: 1px solid var(--gray-border);
    }
    .loading {
      width: 100%;
      padding: 20px;
      text-align: center;
      & > div {
        display: inline-block;
        opacity: 0.5;
      }
    }
  }
`;

export const Article = styled.article`
  background: #ffffff;
  border: 1px solid var(--quaternary);
  border-radius: 5px;
  margin: 10px 0;
  display: flex;
  &.unavailableItem {
    opacity: 0.5;
  }
  & > * {
    padding: 30px;
  }
  header {
    display: flex;
    justify-content: center;
    align-items: center;
    strong {
      font-size: 1.45rem;
      font-weight: bold;
      color: var(--gray);
      padding: 10px;
    }
  }
  main {
    width: 100%;
    padding-top: 20px;
    padding-bottom: 20px;
    position: relative;
    display: flex;
    align-items: center;
    h1 {
      font-size: 1.25rem;
      margin-bottom: 5px;
    }
    p {
      font-size: 1rem;
      color: var(--gray);
    }
  }
  footer {
    padding-top: 20px;
    padding-bottom: 20px;
    display: flex;
    align-items: center;
    a,
    button {
      width: 160px;
      height: 35px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 0.9rem;
    }
    a {
      text-align: center;
      padding: 5px 10px;
      border: none;
      color: var(--dark);
      background: var(--aurea);
      border-radius: 5px;
      font-weight: bold;
      transition: background 400ms;
      margin-right: 10px;
      &:hover {
        text-decoration: none;
        background: var(--hover);
      }
    }
    button {
      display: block;
      padding: 5px 10px;
      border: none;
      color: var(--dark);
      background: #ffffff;
      border: 1px solid var(--gray-border);
      border-radius: 5px;
      font-weight: bold;
      transition: background 400ms;
      &:hover {
        text-decoration: none;
        background: var(--gray-border);
      }
    }
    a,
    button {
      &.rented {
        opacity: 0.5;
        background: var(--white);
        border: 1px solid var(--gray-border);
        transition: opacity 0.3s;
        text-align: center;
        &:hover {
          opacity: 1;
        }
      }
    }
  }
  .dragger {
    padding: 0;
    span {
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: grab;
      padding: 30px;
      height: 100%;
      background: none;
      border: none;
      line-height: 0;
    }
  }
  .shareButton {
    border: 0;
    width: 60px;
    &:hover {
      opacity: initial;

      text-decoration: none;
      background: transparent;
    }
  }
`;

export const Title = styled.div`
  div.bread-crumb {
    margin-bottom: 20px;
    ul {
      display: flex;
      list-style: none;
      li {
        font-weight: bold;
        color: var(--aurea);
        a {
          font-weight: normal;
          color: var(--tertiary);
          text-decoration: none;
          &:hover {
            text-decoration: underline;
          }
          &:after {
            content: '>';
            display: inline-block;
            margin: 0 10px;
            font-size: 0.75rem;
          }
        }
        &:last-child {
          text-decoration: underline;
        }
      }
    }
  }
  h1 {
    margin-right: 24px;
  }
  h2 {
    color: var(--gray);
    font-weight: bold;
    text-transform: uppercase;
  }
`;
