/* eslint-disable react/jsx-no-bind */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import ReactPaginate from 'react-paginate';
import Axios from 'axios';
import { RawEditorSettings } from 'tinymce';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import { ContentPage, SectionGalery } from './styles';
import Input from '../../forms/Input';
import Button from '../../forms/Buttom';
import Editor from '../../forms/Editor';
import { IEditResource, IPages } from './index';
import Tabs, { TabItem } from '../Tabs';
import Upload from '../../forms/Upload';
import InputSearch from '../../forms/InputSearch';
import { useToast } from '../../../hooks/toast';
import getValidationErrors from '../../../utils/getVaidationErrors';
import { getGallery, uploadFiles } from '../../../services/api';
import { useAuth } from '../../../hooks/auth';

interface IProps {
  handlePage(page: IPages): void;
  propsEditor: RawEditorSettings;
  setIsOpen: () => void;
  editResource: IEditResource;
  providerId: string | undefined;
}

const apiPixabay = Axios.create({
  baseURL: 'https://pixabay.com/',
  timeout: 100000,
});

interface IImagesApi {
  id: string;
  previewURL: string;
  largeImageURL: string;
  url: string;
  alt: string;
}

interface IMyImagesApi {
  id: string;
  filename: string;
  url: string;
}

interface IPicture {
  name: string;
  resource: string;
  descripiton: string;
  url: string;
}

const FormPicture: React.FC<IProps> = ({
  handlePage,
  propsEditor,
  setIsOpen,
  editResource,
  providerId,
}) => {
  const formRef = useRef<FormHandles>(null);
  const { addToast } = useToast();
  const { user } = useAuth();

  const [imagesApi, setImagesApi] = useState<IImagesApi[]>([]);
  const [myImagesApi, setMyImagesApi] = useState<IMyImagesApi[]>([]);
  const [totalImages, setTotalImages] = useState(0);
  const [selectedImageApi, setSelectedImageApi] = useState('');
  const [currentPageImageApi, setCurrentPageImageApi] = useState(1);
  const [searchImageApi, setSearchImageApi] = useState('');
  const [loadingImageApi, setLoadingImageApi] = useState(false);
  const [currentTab, setCurrentTab] = useState('tab-01');
  const [loadUpload, setLoadUpload] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);

  const isEdit = useMemo(() => {
    return Object.keys(editResource).length > 0;
  }, [editResource]);

  const editPicture = useMemo<IPicture>(() => {
    if (editResource.resource) {
      const recoverPicture = JSON.parse(editResource.resource);
      return recoverPicture;
    }
    return {} as IPicture;
  }, [editResource.resource]);

  useEffect(() => {
    if (Object.keys(editPicture).length > 0) {
      setSelectedImageApi(editPicture.url);
    }
  }, [editPicture]);

  useEffect(() => {
    setLoadingImageApi(true);
    const timer = setTimeout(() => {
      apiPixabay
        .get('api/', {
          params: {
            key: process.env.REACT_APP_APIKEY_PIXABAY,
            q: searchImageApi,
            image_type: 'photo',
            pretty: true,
            page: currentPageImageApi,
            per_page: 21,
          },
        })
        .then((res: any) => {
          setImagesApi(res.data.hits);
          setTotalImages(res.data.total);
        })
        .finally(() => {
          setLoadingImageApi(false);
        });
    }, 1000);
    return () => clearTimeout(timer);
  }, [currentPageImageApi, searchImageApi]);

  useEffect(() => {
    setCurrentPageImageApi(1);
  }, [searchImageApi]);

  useEffect(() => {
    if (!user.provider?.id && providerId) {
      getGallery({
        provider_id: providerId,
        type: 'images',
      }).then((res: any) => {
        setMyImagesApi(res);
      });
    } else if (user.provider?.id) {
      getGallery({
        provider_id: user.provider.id,
        type: 'images',
      }).then((res: any) => {
        setMyImagesApi(res);
      });
    }
  }, [providerId, user.provider]);

  const handlePagesPrevImageApi = useCallback(
    (page: number) => {
      if (currentPageImageApi !== 1) {
        setCurrentPageImageApi(page);
      }
    },
    [currentPageImageApi],
  );

  const handlePagesNextImageApi = useCallback((page: number) => {
    setCurrentPageImageApi(page);
  }, []);

  const handleTab = useCallback((id: string) => {
    setCurrentTab(id);
  }, []);

  const handleUpload = useCallback(
    async (files: File[]) => {
      setLoadUpload(true);

      try {
        const formData = new FormData();
        formData.append('images', files[0]);
        if (providerId) {
          const response = await uploadFiles({
            formData,
            provider_id: providerId,
            type: 'images',
          });
          setSelectedImageApi(response?.data[0].url);
        } else {
          const response = await uploadFiles({
            formData,
            provider_id: user.provider?.id || '',
            type: 'images',
          });
          setSelectedImageApi(response?.data[0].url);
        }
        if (!user.provider?.id && providerId) {
          getGallery({
            provider_id: providerId,
            type: 'images',
          }).then((res: any) => {
            setMyImagesApi(res);
          });
        } else if (user.provider?.id) {
          getGallery({
            provider_id: user.provider.id,
            type: 'images',
          }).then((res: any) => {
            setMyImagesApi(res);
          });
        }
        setCurrentTab('tab-02');
      } catch (error) {
        addToast({
          title: 'Ops ...',
          description: 'Ocorreu um erro durante seu upload.',
          type: 'error',
        });
      } finally {
        setLoadUpload(false);
      }
    },
    [addToast, providerId, user.provider],
  );

  const handleSubmit = useCallback(
    async data => {
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          name: Yup.string().required('Campo Obrigatório'),
          resource: Yup.string().required('Campo Obrigatório'),
          descripiton: Yup.string().required('Campo Obrigatório'),
          url: Yup.string()
            .required('Campo Obrigatório')
            .url('Imagem deve ser uma url'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        if (isEdit) {
          // editResource.element.remove();
          editResource.element.setAttribute(
            'data-resource',
            JSON.stringify(data),
          );
          propsEditor.insertContent(propsEditor.selection.getContent());
        } else {
          propsEditor.insertContent(
            `<div
              class="resource"
              data-interaction="true"
              data-type="picture"
              data-subtype=""
              data-resource='${JSON.stringify(data)}'>
                Figura
              </div>\n
              <p>&nbsp;</p>`,
          );
        }
        propsEditor.focus();
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);
          formRef.current?.setErrors(errors);
          if (errors.url !== '' && Object.keys(errors).length === 1) {
            addToast({
              title: 'Ops ...',
              description: 'Escolha uma imagem neste recurso',
              type: 'error',
            });
          }
          return;
        }
      }

      propsEditor.focus();
      setIsOpen();
    },
    [propsEditor, setIsOpen, addToast, isEdit, editResource.element],
  );
  const PER_PAGE = 15;

  const offset = currentPage * PER_PAGE;
  const currentPageData = myImagesApi
    .slice(offset, offset + PER_PAGE)
    .map(imagem => {
      return (
        <div
          key={imagem.id}
          onClick={() => setSelectedImageApi(imagem.url)}
          className="galery-item"
          style={{
            backgroundImage: `url(${imagem.url})`,
            objectFit: 'cover',
            borderColor: `${
              imagem.url === selectedImageApi
                ? `var(--aurea)`
                : `var(--gray-border)`
            }`,
          }}
        />
      );
    });

  const pageCount = Math.ceil(myImagesApi.length / PER_PAGE);

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  function handlePageClick({ selected: selectedPage }) {
    setCurrentPage(selectedPage);
  }

  return (
    <Form
      ref={formRef}
      onSubmit={handleSubmit}
      initialData={{
        name: editPicture.name,
        resource: editPicture.resource,
        descripiton: editPicture.descripiton,
      }}
    >
      <ContentPage>
        {!isEdit && (
          <button type="button" onClick={() => handlePage('main')}>
            Voltar
          </button>
        )}
        <SectionGalery>
          <h2>Figura</h2>
          <Editor
            label="Nome da Figura*"
            name="name"
            placeholder="Digite Título da imagem"
          />
          <Input
            label="Fonte*"
            name="resource"
            placeholder="Digite a fonte da imagem"
          />
          <Input
            label="Acessibilidade"
            name="descripiton"
            placeholder="Digite a descrição dessa imagem"
          />
          <Input
            hidden
            label=""
            name="url"
            defaultValue="url"
            value={selectedImageApi}
          />

          <div className="container-img">
            <label htmlFor="imagem" className="title">
              Imagem*
            </label>
            <div className="content-img">
              <div
                className="thumb-img"
                style={{
                  backgroundImage: `url(${selectedImageApi})`,
                }}
              />
              <button type="button" onClick={() => setSelectedImageApi('')}>
                Remover Imagem
              </button>
            </div>
          </div>
          <div className="content-tabs">
            <Tabs
              className="secondary"
              itens={[
                { id: 'tab-01', label: 'Envia Imagem' },
                { id: 'tab-02', label: 'Galeria' },
                { id: 'tab-03', label: 'Imagens Livres' },
              ]}
              handleTab={handleTab}
              defaultValue={currentTab}
            />
          </div>
          <TabItem id="tab-01" defaultValue={currentTab}>
            <div className="container-imges">
              <h2>Enviar Imagem</h2>
              <Upload
                onUpload={handleUpload}
                formts={[
                  { extension: '.jpg', types: 'image/jpg' },
                  { extension: '.jpeg', types: 'image/jpeg' },
                  { extension: '.png', types: 'image/png' },
                  { extension: '.gif', types: 'image/gif' },
                ]}
                loading={loadUpload}
              />
            </div>
          </TabItem>
          <TabItem id="tab-02" defaultValue={currentTab}>
            <div className="container-imges">
              <h2>Minha Galeria</h2>

              <div className="content-show-galery">
                {myImagesApi.length <= 0 && <h3>Nada encontrado.</h3>}
                {currentPageData}
              </div>
              <ReactPaginate
                previousLabel="← Anterior"
                nextLabel="Próximo →"
                pageCount={pageCount}
                onPageChange={handlePageClick}
                containerClassName="pagination"
                previousLinkClassName="pagination__link"
                nextLinkClassName="pagination__link"
                disabledClassName="pagination__link--disabled"
                activeClassName="pagination__link--active"
              />
            </div>
          </TabItem>
          <TabItem id="tab-03" defaultValue={currentTab}>
            <div className="container-imges">
              <h2>Banco de Imagens Livres ({totalImages})</h2>
              <div>
                <InputSearch
                  type="text"
                  placeholder="Digite o nome de uma imagem"
                  name="term"
                  id="term"
                  value={searchImageApi}
                  onChange={e => setSearchImageApi(e.target.value)}
                />
              </div>
              <div className="content-show-galery">
                {loadingImageApi ? (
                  <h3>Carregando...</h3>
                ) : (
                  <>
                    {imagesApi.length ? (
                      imagesApi.map(imagem => (
                        <div
                          key={imagem.id}
                          onClick={() =>
                            setSelectedImageApi(imagem.largeImageURL)
                          }
                          className="galery-item"
                          style={{
                            backgroundImage: `url(${imagem.previewURL})`,
                            objectFit: 'cover',
                            borderColor: `${
                              imagem.largeImageURL === selectedImageApi
                                ? `var(--aurea)`
                                : `var(--gray-border)`
                            }`,
                          }}
                        />
                      ))
                    ) : (
                      <h3>Nada encontrado</h3>
                    )}
                    {imagesApi.length > 0 && (
                      <div className="galery-paginate">
                        <button
                          type="button"
                          onClick={() =>
                            handlePagesPrevImageApi(currentPageImageApi - 1)
                          }
                        >
                          Prev
                        </button>
                        <button
                          type="button"
                          onClick={() =>
                            handlePagesNextImageApi(currentPageImageApi + 1)
                          }
                        >
                          Next
                        </button>
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
          </TabItem>

          <Input hidden label="" name="type" defaultValue="picture" />
        </SectionGalery>
        <Button label="Inserir" type="submit" />
      </ContentPage>
    </Form>
  );
};

export default FormPicture;
