import styled, { css } from 'styled-components';
import { IconEducation, IconVideo } from '../../../styles/icons';

export const Container = styled.div`
  height: 100%;
  width: 100%;
  font-family: 'Poppins', sans-serif;
  font-size: 70%;

  button {
    font-family: 'Poppins', sans-serif;
  }

  strong {
    font-weight: bold;
  }

  h3 {
    font-size: 1.5rem;
  }

  h4 {
    font-size: 1.2rem;
  }
`;

export const Header = styled.div`
  background: var(--bg-dark);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: white;
  position: relative;

  iframe {
    position: absolute;
    width: 100%;
    height: auto;
  }
`;

export const HeaderContent = styled.div`
  width: 95%;
  margin: 0 auto;
  padding: 40px 20px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex: 1;
`;

export const InfoHeader = styled.div`
  display: flex;
  flex-direction: column;

  h1 {
    font-size: 1.5rem;
    font-weight: 500;
  }
`;

export const InfoAuthor = styled.div`
  display: flex;
  flex-direction: row;

  margin-top: 40px;

  svg {
    margin-right: 16px;
  }
`;

export const IconHat = styled(IconEducation)``;

export const ContentAuthor = styled.ul`
  display: flex;
  flex-direction: row;
  list-style: none;

  li {
    position: relative;
    p {
      font-weight: 200;
    }

    strong {
      font-style: italic;
    }

    & + li {
      margin-left: 24px;
      &::after {
        content: '';
        position: absolute;
        top: 0px;
        left: -16px;
        height: 100%;
        width: 1px;
        background: #fff;
        opacity: 0.1;
      }
    }
  }
`;

export const Logo = styled.div`
  text-align: right;
  img {
    width: 50%;
  }
`;

export const Content = styled.div`
  width: 100%;
  padding: 40px 0;
  background: white;

  section + section {
    margin-top: 50px;
  }
`;

export const Section = styled.section`
  width: 95%;
  margin: 0 auto;
  padding: 0 20px;
  h2 {
    font-size: 1.8rem;
    margin-bottom: 28px;
  }
  p {
    font-size: 1rem;
    margin-bottom: 24px;
    word-break: break-word;
  }
`;

export const SectionGeneral = styled.section`
  padding: 56px 0 56px 0;
  &.gray-bg {
    background: var(--theme-bg-gray);
  }
`;

export const SectionTxt = styled.div`
  width: 95%;
  margin: 0 auto;
  h2 {
    font-size: 1.8rem;
    margin-bottom: 28px;
  }
  p,
  div {
    font-size: 1rem;
    margin-bottom: 24px;
    word-break: break-word;
  }
  .react-mathjax-preview-result {
    display: contents;
  }
`;

export const SectionTopic = styled.section`
  margin-bottom: 42px;
  h1 {
    font-size: 2rem !important;
    margin-bottom: 28px;
    position: relative;
    font-weight: 800;
    font-size: 1.5rem;
    padding-left: 38px;
    &::after {
      content: '';
      position: absolute;
      top: 50%;
      left: 0px;
      height: 2px;
      width: 30px;
      background: var(--theme-blue);
    }
  }
  p {
    font-size: 1rem;
    margin-bottom: 24px;
    word-break: break-word;
  }

  section + section {
    margin-top: 50px;
  }

  ul,
  ol {
    margin: 30px 40px;
    li {
      font-size: 1rem;
    }
  }
  table {
    margin: 30px 0;
    border: none;
    td {
      padding: 10px;
      border: 1px solid var(--gray-border);
      font-size: 0.9rem;
    }
  }
`;

export const SectionMedia = styled.section`
  padding: 0 20px 30px 20px;
  margin: 50px 0 40px 0;
  background: var(--theme-bg-gray);
  border-top: 4px solid var(--theme-blue);
  color: white;

  text-align: center;
  position: relative;

  h2 {
    color: var(--bg-dark);
    font-size: 1.8rem;
    font-weight: bold;
    margin: 0 0 32px 0;
  }
  img {
    max-width: 100%;
  }
`;

export const IconSectionVideo = styled(IconVideo)``;

export const TitleMediaContent = styled.div`
  display: flex;
  position: relative;
  top: -32px;
`;

export const TitleMedia = styled.div`
  padding: 12px 28px;
  background: var(--theme-blue);
  font-size: 1.5rem;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;

  position: relative;
  svg {
    margin-right: 12px;
  }
`;

export const SectionQuote = styled.section`
  display: flex;
  align-items: center;
  width: 100%;
`;
export const SectionQuoteContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 30px 20px;
  div {
    font-size: 1rem;
    width: 75%;
    font-style: italic;
    padding: 4px 12px;
    margin-bottom: 0;
    color: var(--theme-color);
    word-break: break-word;
    position: relative;
    p {
      font-size: 0.8rem;
    }
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0px;
      height: 100%;
      width: 2px;
      background: var(--theme-blue);
      opacity: 0.2;
    }
  }
  svg {
    margin-right: 16px;
  }
`;

export const List = styled.ul`
  list-style: none;
  width: 100%;

  li {
    display: flex;
    align-items: center;

    padding: 14px 20px;
    font-weight: 300;
    color: var(--theme-color);
    font-size: 1rem;
    border: 1px solid var(--theme-border);
    border-radius: 5px;

    svg {
      min-width: 25px;
      margin-right: 8px;
    }

    & + li {
      margin-top: 12px;
    }
  }
`;

interface IBody {
  editor: boolean;
}

export const Body = styled.div<IBody>`
  width: 100%;
  overflow-y: auto;
  border-radius: 4px 4px 0 0;

  ${props =>
    props.editor &&
    css`
      height: calc(100vh - 120px - 62px);
      border-radius: 0;
    `}

  &.review {
    height: 95%;
  }
`;

export const Bottom = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 18px;
    background: white;
    border: 1px solid var(--theme-border);
    width: 100%;
    font-weight: 600;
    font-size: 1.125rem;
    color: var(--theme-color);
    opacity: 0.5;
    &.active {
      opacity: 1;
    }
    svg {
      margin: 0 10px;
    }
  }
`;

export const ButtonNext = styled.button``;

export const ButtonPrev = styled.button``;

export const Dropdown = styled.div`
  position: absolute;
  right: 12px;
  top: 12px;

  svg {
    cursor: pointer;
  }

  > div {
    display: none;
    position: absolute;
    right: 28px;
    max-height: 400px;
    overflow: auto;

    width: 300px;

    background: white;
    border-radius: 5px;
    border: 1px solid var(--gray-border);
    color: var(--dark);
    z-index: 1;
    &.show {
      display: block;
    }

    header {
      padding: 24px 24px 18px 24px;
      border-bottom: 1px solid var(--gray-border);

      strong {
        font-weight: 900;
        font-size: 0.8rem;
        color: var(--theme-color);
        margin-bottom: 12px;
      }

      h2 {
        margin-top: 12px;
      }
    }

    section {
      padding: 12px 24px 12px 24px;

      ul {
        list-style: none;
        li {
          display: flex;
          align-items: center;
          padding: 12px 0 12px 0;
          opacity: 0.5;
          &.active {
            opacity: 1;
          }
        }
      }

      svg {
        margin-right: 12px;
      }
    }
  }
`;

export const SectionWarning = styled.section`
  background: var(--bg-dark);
  padding: 40px;
  margin-top: 120px !important;
`;

export const WarningContent = styled.div`
  width: 80%;
  margin: 0 auto;
  background: white;

  border-radius: 5px;
  padding: 60px 30px 30px 30px;

  h2 {
    color: var(--theme-blue);
    font-size: 2rem;
    font-weight: 500;
    text-align: center;
    margin-bottom: 20px;
  }

  p,
  div {
    font-size: 1rem;
    color: var(--theme-text-color);
  }

  button {
    margin: 20px auto 0 auto;
    height: 60px;

    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 40px 0 40px;

    border: 0;
    border-radius: 5px;
    background: var(--theme-blue);

    font-weight: 500;
    color: white;
  }

  &.danger {
    h2 {
      color: var(--theme-red);
    }
  }
`;

export const IconWarningContent = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  div {
    position: absolute;
    bottom: 0;
    width: 160px;
    height: 160px;
    border-radius: 50%;
    border: 5px solid white;

    background: var(--theme-blue);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &.danger {
    div {
      background: var(--theme-red);
    }
  }
`;

export const SectionPicutre = styled.div`
  padding: 10px;
  margin: 30px 0 30px 0 !important;
`;

export const SectionPictureTitle = styled.h2`
  width: 100%;
  text-align: center;

  font-size: 1.5rem;
  color: var(--theme-color);

  margin: 16px 0;
  strong {
    font-weight: 700;
  }
`;

export const SectionPictureImage = styled.div`
  text-align: center;
  img {
    width: 80%;
    border-radius: 5px;
    border: 1px solid var(--gray-border);
  }

  div {
    height: 40px;
    width: 80%;
    margin: 0 auto;
    color: white;
    background: var(--theme-blue);
    font-size: 0.875rem;

    display: flex;
    align-items: center;
    justify-content: center;

    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }
`;

export const SectionList = styled.section`
  padding: 0 20px;
`;

export const ContainerList = styled.div`
  margin-bottom: 10px;
`;

export const ListBox = styled.div`
  padding: 18px 28px 18px 24px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  font-size: 1.125rem;
  font-weight: bold;
  color: var(--theme-color);

  border: 1px solid var(--gray-border);
  border-radius: 5px;

  cursor: pointer;
  svg {
    margin-left: 24px;
  }
`;

export const ContentList = styled.div`
  background: var(--theme-bg-gray);

  padding: 26px 32px;
  margin-top: 10px;

  font-size: 1rem;
  color: var(--dark);
  word-wrap: break-word;

  &.hidden {
    display: none;
  }
`;

export const SectionQuestion = styled.div`
  padding: 30px 20px;
  background: var(--theme-bg-gray);
`;
export const ContainerQuestion = styled.div`
  margin-top: 28px;
  margin-bottom: 10px;
  background: white;
  padding: 28px 32px;

  border: 1px solid var(--gra-border);
  border-radius: 5px;

  > h2 {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 10px;
  }

  > p {
    color: var(--theme-color);
    font-weight: 300;
  }
`;
export const ContentSectionTitle = styled.div`
  display: flex;
  align-items: center;

  h2 {
    font-size: 1.6rem;
  }

  svg {
    margin-right: 10px;
  }
`;

export const ContentQuestion = styled.div`
  margin-top: 36px;
  img {
    max-width: 100%;
    max-height: 30%;
  }
`;

export const ItemQuestion = styled.div`
  display: flex;
  align-items: center;

  background: var(--theme-bg-gray);
  padding: 12px 20px;

  border: 3px solid var(--theme-bg-gray);
  border-radius: 5px;
  cursor: pointer;

  & + div {
    margin-top: 10px;
  }

  span {
    color: var(--bg-dark);
    font-size: 1.2rem;
    font-weight: 700;
    margin-right: 10px;
    text-transform: uppercase;
  }

  p {
    color: var(--theme-color);

    margin: 0;
    font-size: 1rem;
    font-weight: 300;
  }

  &.active {
    border: 3px solid var(--theme-blue);

    span {
      color: var(--theme-blue);
    }
  }

  &.is-wrong {
    border-color: var(--theme-red);
    background: var(--theme-red);

    span {
      color: white;
    }

    p {
      color: white;
    }
  }

  &.is-right {
    border-color: var(--theme-green);
    background: var(--theme-green);

    span {
      color: white;
    }

    p {
      color: white;
    }
  }
`;

export const ButtonAnswer = styled.button`
  background: var(--theme-blue);
  width: 100%;
  height: 59px;
  margin-top: 20px;

  display: flex;
  justify-content: center;
  align-items: center;

  font-weight: bold;
  font-size: 1.125rem;

  border: 0;
  border-radius: 5px;
  color: white;
`;

export const Menssage = styled.div`
  display: flex;
  align-items: center;

  background: var(--theme-green);
  color: white;

  border-radius: 5px;
  padding: 20px;
  margin-top: 20px;

  div {
    strong {
      font-size: 1.125rem;
      margin-bottom: 6px;
    }

    p {
      margin: 0;
    }
  }

  svg {
    margin-right: 16px;
  }

  &.error {
    background: var(--theme-red);
  }
`;
