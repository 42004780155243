import React, { InputHTMLAttributes } from 'react';
import { IconSearch } from '../../../styles/icons';

import { Container } from './styles';

interface IInputSearchProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
}

const InputSearch: React.FC<IInputSearchProps> = ({ name, ...rest }) => {
  return (
    <Container>
      <IconSearch />
      <input type="search" name={name} {...rest} />
    </Container>
  );
};

export default InputSearch;
