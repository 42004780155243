/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable react/require-default-props */

import React, { useCallback, useEffect, useState } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import { RawEditorSettings } from 'tinymce';
import '@wiris/mathtype-tinymce5';
import { uploadFiles } from '../../../services/api';
import { useAuth } from '../../../hooks/auth';
import ModalResources, { IEditResource } from '../ModalResource';
import { IContent } from '../Render';
import AlertConfirm from '../AlertConfirm';
import { observeDOM } from '../../../utils/observe';
import { buildTopics } from '../../../utils/buildTopics';
import { removePaste } from '../../../utils/removePasteTinymce';

interface IStudioEditor {
  handleContent(content: any): void;
  handleCurrentTopic(i: number): void;
  defaultValue?: string;
}

const StudioEditor: React.FC<IStudioEditor> = ({
  handleContent,
  handleCurrentTopic,
  defaultValue,
}) => {
  const { user } = useAuth();
  const [editResource, setEditResource] = useState<IEditResource>(
    {} as IEditResource,
  );

  const [editorTxt, setEditorTxt] = useState(() => {
    const editortxtSaved = localStorage.getItem('@aurea:editor:editortxt');
    if (editortxtSaved) {
      return editortxtSaved;
    }

    if (defaultValue) {
      return defaultValue;
    }

    return '<h1>Tópico 01</h1>';
  });
  const [openModalResource, setOpenModalResource] = useState(false);
  const [propsEditor, setPropsEditor] = useState<RawEditorSettings>(
    {} as RawEditorSettings,
  );
  const [contentState, setContentState] = useState<IContent[]>(() => {
    const editorjson = localStorage.getItem('@aurea:editor:editorjson');
    if (editorjson) {
      return JSON.parse(editorjson);
    }

    return [
      {
        title: 'Tópico 01',
        sections: [{ content: '', type: 'txt' }],
      },
    ];
  });

  useEffect(() => {
    handleContent(contentState);
  }, [contentState, handleContent]);

  useEffect(() => {
    if (defaultValue) {
      const editortxtSaved = localStorage.getItem('@aurea:editor:editortxt');
      if (editortxtSaved) {
        setEditorTxt(editortxtSaved);
        const topics = buildTopics(editortxtSaved);
        handleContent(topics);
      } else {
        setEditorTxt(defaultValue);
        const topics = buildTopics(defaultValue);
        handleContent(topics);
      }
    }
  }, [defaultValue, handleContent]);

  const handleModalResource = useCallback(() => {
    setOpenModalResource(!openModalResource);
  }, [openModalResource]);

  const handleBuildTopics = useCallback((e: string) => {
    const topics = buildTopics(e);

    setContentState(topics);
    localStorage.setItem('@aurea:editor:editorjson', JSON.stringify(topics));
  }, []);

  const handleEditorChange = useCallback(
    (e: string): void => {
      handleBuildTopics(e);

      setEditorTxt(e);
      localStorage.setItem('@aurea:editor:editortxt', e);
    },
    [handleBuildTopics],
  );

  return (
    <>
      <ModalResources
        isOpen={openModalResource}
        setIsOpen={handleModalResource}
        propsEditor={propsEditor}
        editResource={editResource}
      />
      <Editor
        tagName="content"
        initialValue={editorTxt}
        init={{
          setup: editor => {
            setPropsEditor(editor as any);

            // Observar o tópico atual
            editor.on('LoadContent', () => {
              observeDOM(editor.dom.doc.activeElement, (modify: []) => {
                const tag = modify[modify.length - 1] as any;
                if (tag.target.tagName.toLowerCase() !== 'body') {
                  const childrenArray = [] as Array<Element>;
                  editor.dom.doc.body.childNodes.forEach((item: any) => {
                    childrenArray.push(item);
                  });
                  const indexTag = childrenArray.indexOf(tag.target);
                  let countTopic = 0;
                  childrenArray.find((item: any, i) => {
                    if (item.tagName.toLowerCase() === 'h1') {
                      countTopic += 1;
                    }
                    return i === indexTag;
                  });
                  handleCurrentTopic(countTopic);
                }
              });
            });
            editor.on('keydown', (eventKey: KeyboardEvent) => {
              const element = editor.selection.getNode();
              // INTERACTION SELECTION
              if (!element.hasAttribute('data-interaction')) {
                return;
              }
              switch (eventKey.key) {
                case 'Backspace':
                case 'Delete':
                  // Deletar o recurso quando delete ou backspace for pressionado
                  const selectedBlocks = editor.selection.getSelectedBlocks();
                  selectedBlocks.forEach((item: any, i: number) => {
                    const block = selectedBlocks[i];
                    block.parentNode && block.parentNode.removeChild(block);
                  });
                  break;
                case 'Enter':
                  // Ir para próxima linha quando o enter for pressionado
                  eventKey.preventDefault();
                  const paragraph = document.createElement('p');
                  const text = document.createTextNode('\u00A0'); // &nbsp;
                  paragraph.appendChild(text);
                  element.parentNode &&
                    element.parentNode.insertBefore(
                      paragraph,
                      element.nextSibling,
                    );
                  editor.focus();
                  editor.selection.select(paragraph);
                  break;
                default:
                  eventKey.preventDefault();
                  break;
              }
            });

            // Recursos
            editor.ui.registry.addButton('interaction', {
              icon: 'plus',
              text: 'Recursos',
              onAction() {
                setEditResource({} as IEditResource); // reset edit para modal abrir na main
                handleModalResource();
              },
              onSetup(buttonResource) {
                // NÃO DEIXA INSERIR EM LOCAIS DIFERENTES QUE O PARÁGRAFO SIMPLES
                const eventButtonResourceCallback = (event: any): void => {
                  buttonResource.setDisabled(
                    event.element.nodeName.toLowerCase() !== 'p',
                  );
                };
                editor.on('NodeChange', eventButtonResourceCallback);

                // /* onSetup should always return the unbind handlers */
                return () => {
                  editor.off('NodeChange', eventButtonResourceCallback);
                };
              },
            });

            // toolbar
            editor.ui.registry.addContextToolbar('edit-interaction-listener', {
              predicate(node) {
                return (
                  node.nodeName.toLowerCase() === 'div' &&
                  node.getAttribute('data-interaction') === 'true'
                );
              },
              items: 'edit-interaction | copy-interaction | delete-interaction',
              position: 'node',
              scope: 'node',
            });

            // Edite componente
            editor.ui.registry.addButton('edit-interaction', {
              icon: 'edit-block',
              text: 'Editar',
              onAction() {
                // handleOpenEdit();
                // handleEditInteraction(editor);

                // Convert to JSON and pass to modal
                const content = {
                  type:
                    editor.selection.getNode().getAttribute('data-type') || '',
                  subtype:
                    editor.selection.getNode().getAttribute('data-subtype') ||
                    '',
                  resource:
                    editor.selection.getNode().getAttribute('data-resource') ||
                    '',
                  element: editor.selection.getNode(),
                };

                setEditResource(content);
                handleModalResource();
              },
            });

            // Delete componente
            editor.ui.registry.addButton('delete-interaction', {
              icon: 'remove',
              async onAction() {
                const confirm = await AlertConfirm({
                  title: 'Tem certeza que deseja deletar este componente?',
                  text: 'Esta ação não poderá ser desfeita',
                });
                if (!confirm) {
                  return;
                }

                // Deletar o recurso quando delete ou backspace for pressionado
                const element = editor.selection.getNode();
                const selectedBlocks = editor.selection.getSelectedBlocks();
                selectedBlocks.forEach((item: any, i: number) => {
                  const block = selectedBlocks[i];
                  block.parentNode && block.parentNode.removeChild(block);
                });

                // Insere prox linha com foco
                const paragraph = document.createElement('p');
                const text = document.createTextNode('\u00A0'); // &nbsp;
                paragraph.appendChild(text);
                element.insertBefore(paragraph, element.lastElementChild);
                editor.focus();
                editor.selection.select(paragraph);
              },
            });

            // Copy compoente
            editor.ui.registry.addButton('copy-interaction', {
              icon: 'copy',
              onAction() {
                const element = editor.selection.getNode();
                editor.focus();
                editor.selection.select(element);
                editor.execCommand('Copy');
              },
            });
          },
          // ...setup,
          menubar: 'format edit tools table tc',
          menu: {
            format: {
              title: 'Format',
              items:
                'bold italic underline strikethrough superscript subscript ',
            },
            tools: {
              title: 'Tools',
              items: 'code',
            },
          },
          plugins: [
            ' advlist anchor autolink codesample fullscreen help image imagetools code',
            ' lists link media noneditable preview',
            ' searchreplace table template visualblocks wordcount',
            'paste tiny_mce_wiris mathtype-editor',
          ],
          external_plugins: {
            tiny_mce_wiris: `${window.location.href}/node_modules/@wiris/mathtype-tinymce5/plugin.min.js`,
          },
          paste_auto_cleanup_on_paste: true,
          paste_remove_styles: true,
          branding: false,
          statusbar: false,
          content_style: `
          h1 {
            font-size: 2em;
            margin: 0.67em 0;
            color: #2c3038;
            border-bottom: 1px dashed #e0dede;
          }
          .resource {
            width: 80% !important;
            margin: 14px auto;
            text-align: center;
            padding: 18px;
            background: #F6C866;
            border-radius: 5px;
            color: #3D3939;
            font-weight: bold;
            border: 1px solid #F6C866;
            position: relative;
          }
          .latex {
            font-family: monospace;
            background-color: #ccc;
          }
          .resource::after{
            content: '';
            top: 8px;
            left: 2%;
            position: absolute;
            border-radius: 5px;
            font-weight: bold;
            border: 1px dashed #F1F2F6;
            background: transparent;
            width: 96%;
            height: 40px;
          }
        `,
          block_formats:
            'Parágrafo=p; Tópico=h1; Subtópico=h2; Título=h3; Subtítulo=h4; Latex=latex',
          htmlAllowedTags: ['.*'],
          htmlAllowedAttrs: ['.*'],
          toolbar:
            'formatselect | interaction | bold italic | forecolor backcolor | alignleft aligncenter alignright alignjustify | bullist numlist | link | add-mathtype | image image-format ',
          ...removePaste,
          images_upload_handler(blobInfo, success) {
            async function LoadImageServer() {
              const formData = new FormData();

              formData.append('images', blobInfo.blob());

              const response = await uploadFiles({
                formData,
                provider_id: user.provider?.id as string,
                type: 'images',
              });
              success(response?.data[0].url);
            }
            LoadImageServer();
          },
        }}
        onEditorChange={handleEditorChange}
      />
    </>
  );
};

export default StudioEditor;
