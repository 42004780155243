/* eslint-disable react/require-default-props */
/* eslint-disable no-nested-ternary */

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import React, { useCallback, useRef, useState, useEffect } from 'react';

import { useToast } from '../../../hooks/toast';
import { IProviders } from '../../../pages/Store';
import { IPlans } from '../../../pages/Plans';
import {
  storeFilterProvider,
  getPlansProvider,
  postCreateRentsPlans,
} from '../../../services/api';
import Buttom from '../../forms/Buttom';
import Modal from '../Modal';
import { SelectContainer } from '../../../pages/sellerControl/styles';
import { MainContent, Body } from '../SellerTemplate/styles';
import { Holder, Container, Content, Title, Bottom } from './styles';
import { IconBookmarkYellow } from '../../../styles/icons';

interface IModalProps {
  isOpen: boolean;
  setIsOpen: () => void;
  seller: ISellerItem;
}

interface ISellerItem {
  rent: {
    id: string;
    status: string;
    createdAt: string;
  };
  provider: {
    imgLogoUrl: string | null;
    companyName: string;
    condition: string;
    itemsCount: string;
    plans_provider_value: {
      value: string;
      planId: string;
    };
  };
  seller: {
    id: string;
    planId: string;
  };
}

interface ICreateRentPlans {
  sellerId: string;
  providerId: string;
  planId: string;
}

const ModalCreateNewRent: React.FC<IModalProps> = ({
  isOpen,
  setIsOpen,
  seller,
}) => {
  const { addToast } = useToast();
  const [loading, setLoading] = useState(false);
  const formRef = useRef<FormHandles>(null);
  const [plans, setPlans] = useState<IPlans[]>();
  const [providers, setProviders] = useState<IProviders[]>([]);
  const [valuePlanSelect, setValuePlanSelect] = useState<any>('');
  const [providerSelect, setProviderSelect] = useState<string>();

  const handleSetProvider = useCallback((provider: IProviders[]) => {
    setProviders(
      provider.map(item => {
        const finalPrice = Number(item.plan_value) || 20000;
        return {
          ...item,
          isPrimium: item.condition === 'premium',
          provider_total_items: item.provider_total_items || 104,
          priceInNumber: finalPrice,
          plan_value: finalPrice.toLocaleString('pt-br', {
            style: 'currency',
            currency: 'BRL',
          }),
        };
      }),
    );
  }, []);

  useEffect(() => {
    const load = async (): Promise<void> => {
      try {
        setLoading(true);
        const planProvider = await getPlansProvider({});
        handleSetProvider([]);

        setPlans(planProvider.data);
      } catch (error) {
        addToast({
          title: 'Ops...',
          type: 'error',
          description:
            'Algo  inesperado aconteceu, por favor recarrege sua página.',
        });
      } finally {
        setLoading(false);
      }
    };
    load();
  }, [setIsOpen, handleSetProvider, addToast, seller, valuePlanSelect]);

  useEffect(() => {
    const load = async (): Promise<void> => {
      try {
        if (!seller) {
          const currentUrl = window.location.href;
          const currentUrlSplitted = currentUrl.split('/');

          const sellerId = currentUrlSplitted[5];

          const { data: res } = await storeFilterProvider({
            planId: valuePlanSelect,
            sellerId,
          });
          handleSetProvider(res);
          return;
        }

        const { data: res } = await storeFilterProvider({
          planId: valuePlanSelect,
          sellerId: seller.seller.id,
        });
        handleSetProvider(res);
      } catch (error) {
        addToast({
          title: 'Ops...',
          type: 'error',
          description:
            'Algodddd  inesperado aconteceu, por favor recarrege sua página.',
        });
      } finally {
        setLoading(false);
      }
    };
    if (valuePlanSelect.length > 0) load();
  }, [valuePlanSelect, addToast, handleSetProvider, seller]);

  function test(id: string): void {
    setProviderSelect(id);
  }

  const renderProviders = useCallback(() => {
    return (
      <>
        {providers.map((provider: IProviders) => (
          <article key={`${provider.id}-tab-01`} className="slideUp">
            <header>
              <input
                type="radio"
                name="providerSelected"
                onChange={() => test(provider.id)}
              />
              <h1>{provider.companyName}</h1>
              <p>{provider.isPrimium ? 'Premium' : 'Basic'}</p>
              <p className="units">
                <IconBookmarkYellow />{' '}
                <strong>{provider.provider_total_items}</strong> unidades
              </p>
            </header>
            <footer />
          </article>
        ))}
      </>
    );
  }, [providers]);

  const handleSubmit = useCallback(async () => {
    if (providerSelect) {
      try {
        const currentUrl = window.location.href;
        const currentUrlSplitted = currentUrl.split('/');
        const newSellerId = currentUrlSplitted[5];
        const createRentData: ICreateRentPlans = {
          sellerId: newSellerId,
          providerId: providerSelect,
          planId: valuePlanSelect,
        };
        await postCreateRentsPlans(createRentData);
        addToast({
          title: 'Sucesso',
          type: 'success',
          description: 'Aluguel criado!',
        });

        setTimeout(() => {
          window.location.reload();
        }, 1000);
      } catch (error) {
        const typedError: any = error;
        if (typedError.response.data.message === 'plan already used') {
          addToast({
            title: 'Ops...',
            type: 'error',
            description: 'Esse catálogo já está associado a essa instituição!',
          });
        } else {
          addToast({
            title: 'Ops...',
            type: 'error',
            description: 'Verifique todos os campos!',
          });
        }
      }
      return;
    }

    addToast({
      title: 'Ops ...',
      type: 'error',
      description: 'Verifique todos os campos!!!',
    });
  }, [providerSelect, addToast]);

  return (
    <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
      <Holder>
        <MainContent>
          <Form ref={formRef} onSubmit={handleSubmit} initialData={seller}>
            <Body className="body">
              <Container className="slideDown">
                <Title>
                  <h1>Loja de Fornecedores</h1>
                </Title>
                <Content>
                  {plans && (
                    <div style={{ marginTop: '20px' }}>
                      <SelectContainer>
                        <label htmlFor="select-status" className="selectTitle">
                          <h3>Selecione o Plano Desejado</h3>
                        </label>
                        <select
                          value={valuePlanSelect}
                          onChange={e => setValuePlanSelect(e.target.value)}
                          name="select-status"
                          id="select-status"
                        >
                          {plans.map((plan: IPlans) => (
                            <option value={plan.id}>{plan.name}</option>
                          ))}
                          <option value="" disabled>
                            Selecionar
                          </option>
                        </select>
                      </SelectContainer>
                    </div>
                  )}
                  <section>
                    {!loading && valuePlanSelect && renderProviders()}
                    {providers.length <= 0 && !loading && (
                      <div className="loading">
                        <p>Nenhum item encontrado.</p>
                      </div>
                    )}
                    {/* {loading && valuePlanSelect && (
                      <div className="loading">
                        <BeatLoader size={16} />
                      </div>
                    )} */}
                  </section>
                </Content>
              </Container>
            </Body>
          </Form>
          <Bottom>
            <Buttom
              name="cancelar"
              type="button"
              onClick={() => setIsOpen()}
              label="Cancelar"
              outline
            />
            <Buttom
              name="salvar"
              onClick={() => formRef.current?.submitForm()}
              label="Salvar"
              isLoading={loading}
            />
          </Bottom>
        </MainContent>
      </Holder>
    </Modal>
  );
};

export default ModalCreateNewRent;
