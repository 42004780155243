/* eslint-disable react/no-array-index-key */
import React, {
  SelectHTMLAttributes,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useField } from '@unform/core';

import { Container } from './styles';

interface IOptionsProps {
  label: string;
  value: string | number;
  status?: string;
}

interface ISelectProps extends SelectHTMLAttributes<HTMLSelectElement> {
  name: string;
  placeholder: string;
  options: IOptionsProps[];
}

const SelectOption: React.FC<ISelectProps> = ({
  name,
  placeholder,
  options,
  ...rest
}) => {
  const selectRef = useRef<HTMLSelectElement>(null);

  const { fieldName, defaultValue, registerField } = useField(name);
  const [selectValue, setSelectValue] = useState(defaultValue);

  const setNewValue = useCallback(value => {
    setSelectValue(value);
  }, []);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: selectRef.current,
      getValue: (ref: HTMLInputElement) => {
        const { value } = ref;

        if (!value) {
          return null;
        }

        return !Number.isNaN(Number(value)) ? Number(value) : String(value);
      },
      clearValue: () => {
        setSelectValue('');
      },
    });
  }, [fieldName, registerField]);

  return (
    <Container>
      <span>{placeholder}</span>
      <select
        ref={selectRef}
        disabled={options.length === 0}
        value={selectValue || defaultValue || ''}
        onChange={e => setNewValue(e.target.value)}
        name={name}
        {...rest}
      >
        <option value=""> </option>
        {options.length > 0 &&
          options.map((option, index) => (
            <option
              key={index}
              value={option.value}
              style={
                option.status === 'Ativo'
                  ? { color: '#1855c7', fontWeight: 'bold' }
                  : {}
              }
            >
              {/* {`${option.label} - ${option.status}`} */}
              {option.label}
            </option>
          ))}
      </select>
    </Container>
  );
};

export default SelectOption;
