import React, { InputHTMLAttributes, useCallback, useState } from 'react';

import { Container } from './styles';

interface ICheckboxProps extends InputHTMLAttributes<HTMLInputElement> {
  name?: string;
  handleCheck(check: Array<string>): void;
  checks?: Array<string>;
  id: string;
}

interface ICheckboxAllProps {
  ids: Array<string>;
  handleCheckAll(check: Array<string>): void;
}

export const CheckBoxAll: React.FC<ICheckboxAllProps> = ({
  ids,
  handleCheckAll,
}) => {
  const [checkedAll, setCheckedAll] = useState(false);
  const updateCheckAll = useCallback(() => {
    if (!checkedAll) {
      handleCheckAll(ids);
      return;
    }
    handleCheckAll([]);
  }, [checkedAll, handleCheckAll, ids]);

  return (
    <Container onClick={() => updateCheckAll()}>
      <input
        type="checkbox"
        name="check-all"
        onChange={e => setCheckedAll(e.target.checked)}
        defaultChecked={checkedAll}
        id="check-all"
        placeholder="teste"
      />
    </Container>
  );
};

const Checkbox: React.FC<ICheckboxProps> = ({
  name,
  handleCheck,
  checks,
  id,
  ...rest
}) => {
  const updateCheck = useCallback(
    (idChecked: string) => {
      if (!checks) {
        return;
      }
      const findCheck = checks.findIndex(check => check === idChecked);
      let newCheck;
      if (findCheck === -1) {
        newCheck = [...checks, idChecked];
        handleCheck(newCheck);
        return;
      }
      newCheck = checks.filter(item => item !== idChecked);
      handleCheck(newCheck);
    },
    [checks, handleCheck],
  );

  return (
    <Container>
      <input
        type="checkbox"
        {...rest}
        name={name}
        id={`check-${id}`}
        value={id}
        onChange={e => updateCheck(e.target.value)}
        checked={checks?.includes(id)}
        placeholder="teste"
      />
    </Container>
  );
};

export default Checkbox;
