export const removePaste = {
  formats: {
    removeformat: [{ selector: 'div', classes: 'none', remove: 'none' }],
    latex: { inline: 'span', classes: 'latex' },
  },
  paste_remove_styles_if_webkit: true,
  paste_strip_class_attributes: true,
  paste_word_valid_elements: 'p,b,strong,i,em,h1,h2,ul,li,ol,table,tr,td,th',
  paste_postprocess(plugin: any, args: any) {
    // args.node.setAttribute('id', '42');
    //
    // console.log('args.target.selection.getNode()',args);
    const target = args.target.selection.getNode();
    // CANT PASTE INTERACTION INSIDE ANOTHER PLACE
    if (target.nodeName.toLowerCase() !== 'p') {
      const elementsNew = args.node.querySelectorAll(':scope > *');
      // args.preventDefault();
      Array.prototype.forEach.call(elementsNew, el => {
        if (el.getAttribute('data-interaction') === 'true') {
          args.preventDefault();
        }
      });
    }

    function SanitizeElement(elx: any): void {
      const tag = elx.nodeName.toLowerCase();
      if (['div', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'span'].includes(tag)) {
        // console.log(elx);
        const p = document.createElement('p');
        p.textContent = elx.textContent;
        elx.parentNode.replaceChild(p, elx);
      }

      const { attributes } = elx;
      // DELETE CLASS ATTRIBUTE
      if (elx.getAttribute('class')) {
        elx.removeAttribute('class');
      }

      if (elx.getAttribute('style')) {
        elx.removeAttribute('style');
      }

      // DELETE ALL ATTRIBUTES EXCEPT...
      if (attributes.length !== 0) {
        for (let index = 0; index < attributes.length; index++) {
          const attribute = attributes[index].name;
          if (
            attribute !== 'href' &&
            attribute !== 'src' &&
            attribute !== 'alt' &&
            attribute !== 'title'
          ) {
            elx.removeAttribute(attribute);
          }
        }
      }
      // SANITIZE CHILDS
      const elementsx = elx.querySelectorAll(':scope > *');
      if (elementsx.length > 0) {
        Array.prototype.forEach.call(elementsx, (el: any) => {
          SanitizeElement(el);
        });
      }
    }

    const elements = args.node.querySelectorAll(':scope > *');
    Array.prototype.forEach.call(elements, el => {
      if (el.getAttribute('data-interaction') !== 'true') {
        SanitizeElement(el);
      }
    });
  },
};
