import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 32px;
  display: flex;
  > div {
    margin: 0 8px 8px 8px;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }
`;

export const CheckboxItem = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;

  width: 100%;
  flex: 0 0 30%;
  max-width: 30%;
  margin: 0 12px 12px 0;
  label {
    border: 1px solid var(--gray-border);
    padding: 12px 0px 12px 38px;
    border-radius: 10px;
    width: 100%;
    font-weight: bold;
    color: var(--gray);
    cursor: pointer;
    user-select: none;
  }

  input[type='checkbox'] {
    margin-right: 12px;
    margin-top: 4px;
    position: absolute;
    top: calc(50% - 12px);
    left: 10px;
    cursor: pointer;

    &:checked ~ label {
      border-color: var(--aurea);
    }

    &:checked:after {
      content: '';
      display: block;
      width: 12px;
      height: 12px;
      position: absolute;
      border-radius: 50%;
      top: 1px;
      left: 2px;
    }

    &:checked:before {
      border: 2px solid var(--aurea);
      background-color: var(--aurea);
    }

    &::before {
      content: '';
      display: block;
      position: absolute;
      width: 16px;
      height: 16px;
      top: -3px;
      left: -3px;
      border: 2px solid rgba(141, 133, 133, 0.45);
      border-radius: 50%;
      background-color: white;
      transition: background 0.3s;
    }
  }
`;
