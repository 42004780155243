import styled, { css, FlattenSimpleInterpolation } from 'styled-components';

interface UploadProps {
  isDragActive: boolean;
  isDragReject: boolean;
  refKey?: string;
  [key: string]: any;
  type?: 'error' | 'success' | 'default';
}

const dragActive = css`
  border-color: #12a454;
`;

const dragReject = css`
  border-color: #e83f5b;
`;

export const DropContainer = styled.div.attrs({
  className: 'dropzone',
})`
  border: 1.5px dashed #969cb3;
  border-radius: 5px;
  cursor: pointer;
  transition: height 0.2s ease;
  ${(props: UploadProps): false | FlattenSimpleInterpolation =>
    props.isDragActive && dragActive}
  ${(props: UploadProps): false | FlattenSimpleInterpolation =>
    props.isDragReject && dragReject}
`;

const messageColors = {
  default: 'var(--aurea)',
  error: 'var(--danger)',
  success: 'var(-success)',
};

export const UploadMessage = styled.div`
  display: flex;
  font-size: 1rem;
  line-height: 24px;
  padding: 16px 0;
  /* color: ${({ type }: UploadProps) => messageColors[type || 'default']}; */
  justify-content: center;
  align-items: center;
  strong {
    color: var(--aurea);
    font-weight: 700;
    margin-right: 4px;
    margin-left: 4px;
  }
`;
