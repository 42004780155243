import React, { HTMLAttributes, useCallback, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

import { Container } from './styles';

interface IProps extends HTMLAttributes<HTMLDivElement> {
  handleDropdown(set: boolean): void;
  setIsOpen: boolean;
}

const Dropdown: React.FC<IProps> = ({ handleDropdown, children, ...rest }) => {
  const location = useLocation();
  const dropdrownRef = useRef<HTMLDivElement>(null);

  const handleClickOutside = useCallback(
    (event: any) => {
      if (
        dropdrownRef.current &&
        !dropdrownRef.current.contains(event.target)
      ) {
        handleDropdown(false);
      }
    },
    [handleDropdown],
  );

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [handleClickOutside]);

  useEffect(() => {
    handleDropdown(false);
  }, [location.pathname, handleDropdown]);

  return (
    <>
      <Container ref={dropdrownRef} {...rest}>
        {children}
      </Container>
    </>
  );
};

export default Dropdown;
