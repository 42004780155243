/* eslint-disable func-names */
/* eslint-disable react/button-has-type */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import React, { useCallback, useState, useEffect, useRef } from 'react';
import { BeatLoader } from 'react-spinners';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import { ChromePicker } from 'react-color';
import Select from '../../components/forms/SelectForm';

import 'react-input-range/lib/css/index.css';

import {
  Holder,
  Container,
  Content,
  Title,
  RightSide,
  ModalContent,
} from './styles';
import { useAuth } from '../../hooks/auth';

import Modal from '../../components/template/Modal';
import ModalAddSubject from '../../components/template/ModalAddSubject';

import {
  AddButton,
  OptionLeft,
  OptionRight,
  Options,
} from '../../components/template/TemplateManager/styles';

import {
  Main,
  MainContent,
  Body,
} from '../../components/template/SellerTemplate/styles';

import NavTop from '../../components/template/NavTop';

import InputSearch from '../../components/forms/InputSearch';
import Input from '../../components/forms/Input';
import { saveManageSubjectsLog } from '../../services/logs';

import {
  IconBookmarkYellow,
  IconSubjectTrash,
  IconAddCircle,
  IconEdit,
  IconDownload,
  IconBookmarkBlack,
} from '../../styles/icons';

import { useToast } from '../../hooks/toast';
import {
  getListSellerSubject,
  deleteSellerSubject,
  getLevelOfLearning,
  IGetKnowledge,
  getKnowledge,
  updateSellerSubject,
  getListProviderSubjects,
  deleteProviderSubject,
  createSubjectCategory,
  getSubjectCategories,
  deleteSubjectCategory,
  getListProviderSubjectsByTitle,
} from '../../services/api';

import ModalImportSubject from '../../components/template/ModalImportSubject';

export interface ISubjects {
  subject_id: string;
  name: string;
  itemsCount: string;
  category_id?: string;
}

interface IFilter {
  knowledges: undefined | string[];
  levelOfLearning: undefined | string[];
  categories: undefined | string[];
}

interface IEditSubject {
  name: string;
  id: string;
}
interface ICategoryEdit {
  name: string;
  id: string;
  category_id?: string;
}

interface ISubjectCategory {
  name: string;
  id: string;
  color: string;
}

const Subjects: React.FC = () => {
  const { user } = useAuth();
  const { addToast } = useToast();

  const [loading, setLoading] = useState(true);

  // SEARCH ===========
  const [search, setSearch] = useState('');

  // RANGE ===========
  const [, setRange] = useState({ min: 550, max: 20000 });

  // CHECKBOX ===========
  const inputParentLevel = useRef(document.createElement('div'));
  const [levelOfLearning, setLevelOfLearning] = useState([]);
  const [selectedLevel, setSelectedLevel] = useState({});

  const inputParentKnowledge = useRef(document.createElement('div'));
  const [knowledges, setKnowledges] = useState<IGetKnowledge[]>([]);
  const [selectedKnowledge, setSelectedKnowledge] = useState({});
  const [submitType, setSubmitType] = useState('');

  const inputParentCategory = useRef(document.createElement('div'));
  const [categories, setCategories] = useState<ISubjectCategory[]>([]);
  const [selectedCategory, setSelectedCategory] = useState({});

  const handleLevel = useCallback(
    id => {
      const shadow = { ...selectedLevel };

      if (shadow[id]) {
        delete shadow[id];
      } else {
        shadow[id] = true;
      }

      setSelectedLevel(shadow);
    },
    [selectedLevel, setSelectedLevel],
  );

  const handleKnowledge = useCallback(
    value => {
      const shadow = { ...selectedKnowledge };

      if (shadow[value]) {
        delete shadow[value];
      } else {
        shadow[value] = true;
      }

      setSelectedKnowledge(shadow);
    },
    [selectedKnowledge, setSelectedKnowledge],
  );

  const handleCategory = useCallback(
    value => {
      const shadow = { ...selectedCategory };

      if (shadow[value]) {
        delete shadow[value];
      } else {
        shadow[value] = true;
      }

      setSelectedCategory(shadow);
    },
    [selectedCategory, setSelectedCategory],
  );

  // CLEAR FILTER ===========
  const clearFilter = useCallback(() => {
    setSelectedKnowledge({});
    const checkedKnowledge = inputParentKnowledge.current.querySelectorAll(
      'input[type=checkbox]',
    );

    Array.prototype.forEach.call(checkedKnowledge, function (el, i) {
      if (el.checked) el.checked = false;
    });

    setSelectedLevel({});
    const checkedLevel = inputParentLevel.current.querySelectorAll(
      'input[type=checkbox]',
    );

    Array.prototype.forEach.call(checkedLevel, function (el, i) {
      if (el.checked) el.checked = false;
    });

    setSelectedCategory({});
    const checkedCategory = inputParentCategory.current.querySelectorAll(
      'input[type=checkbox]',
    );

    Array.prototype.forEach.call(checkedCategory, function (el, i) {
      if (el.checked) el.checked = false;
    });

    setSearch('');
    setRange({ min: 550, max: 20000 });
  }, [inputParentLevel]);

  // PROVIDERS ===========
  const [subjects, setSubjects] = useState<ISubjects[]>([]);

  // ADD SUBJECT ======
  const [modalAddSubjectOpen, setModalAddSubjectOpen] = useState(false);
  const toggleModalAddSubject = useCallback(() => {
    setModalAddSubjectOpen(!modalAddSubjectOpen);
  }, [modalAddSubjectOpen]);

  // IMPORT SUBJECT ======
  const [modalImportSubjectOpen, setModalImportSubjectOpen] = useState(false);
  const toggleModalImportSubject = useCallback(() => {
    setModalImportSubjectOpen(!modalImportSubjectOpen);
  }, [modalImportSubjectOpen]);

  // REMOVE SUBJECT ======
  const [itemToRemove, setItemToRemove] = useState<false | string>(false);
  const [itemToEdit, setItemToEdit] = useState<IEditSubject>();
  const [isOpenEdit, setIsOpenEdit] = useState(false);
  const formRef = useRef<FormHandles>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [isCategorieOpen, setIsCategorieOpen] = useState(false);
  const [isCreateCategoryOpen, setIsCreateCategoryOpen] = useState(false);
  const [categoryColor, setCategoryColor] = useState('#000');
  const [categoryEdit, setCategoryEdit] = useState<ICategoryEdit>();
  const [removeCategory, setRemoveCategory] = useState(false);
  const [categoryDelete, setCategoryDelete] = useState('');

  const handleRemoveSubject = useCallback(async () => {
    if (itemToRemove !== false) {
      try {
        if (user.type.includes('seller')) {
          await deleteSellerSubject(itemToRemove);
        } else if (user.type === 'provider') {
          await deleteProviderSubject(itemToRemove);
        }

        saveManageSubjectsLog({
          userId: user.id,
          action: 'removed_subject',
          itemId: null,
          itemContentId: null,
          subjectId: itemToRemove,
        });

        addToast({
          title: 'Disciplina removida com sucesso!',
          type: 'success',
        });

        clearFilter();
      } catch (error) {
        console.log(error);
        addToast({
          title: 'Ops...',
          type: 'error',
          description:
            'Algo inesperado aconteceu, por favor recarrege sua página.',
        });
      }
    }

    setIsOpen(!isOpen);
  }, [isOpen, itemToRemove, addToast, clearFilter, user]);

  const handleEditSubjectSubmit = useCallback(
    async data => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          name: Yup.string().required('Campo Obrigatório'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        if (itemToEdit) {
          await updateSellerSubject({
            id: itemToEdit.id,
            name: data.name,
          });

          saveManageSubjectsLog({
            userId: user.id,
            action: 'update_subject_name',
            itemId: null,
            itemContentId: null,
            subjectId: itemToEdit.id,
          });
        }

        addToast({
          title: 'Disciplina editada com sucesso!',
          type: 'success',
        });

        setIsOpenEdit(false);
        clearFilter();
      } catch (error) {
        console.log(error);
        addToast({
          title: 'Ops...',
          type: 'error',
          description:
            'Algo inesperado aconteceu, por favor recarrege sua página.',
        });
      }
    },
    [addToast, clearFilter, itemToEdit, user.id],
  );

  const handleOpenModal = useCallback(
    (subjectId?: string) => {
      if (subjectId) setItemToRemove(subjectId);
      setIsOpen(!isOpen);
    },
    [isOpen],
  );

  const handleRemoveCategoryModal = useCallback(() => {
    setRemoveCategory(!removeCategory);
  }, [removeCategory]);

  const handleOpenModalEdit = useCallback(
    (id?: string, name?: string) => {
      if (id && name) setItemToEdit({ id, name });
      setIsOpenEdit(!isOpenEdit);
    },
    [isOpenEdit],
  );

  const handleOpenModalCategory = useCallback(
    (id?: string, name?: string) => {
      if (id && name) {
        setCategoryEdit({ id, name });
      }

      setIsCategorieOpen(!isCategorieOpen);
    },
    [isCategorieOpen],
  );

  // use useCallback to prevent infinite loop
  const getCategoryForSubject = useCallback(
    (subjectId: string): string | undefined => {
      const subject = subjects.find(
        currentSubject => currentSubject.subject_id === subjectId,
      );
      if (subject) {
        const category = categories.find(
          currentCategory => currentCategory.id === subject.category_id,
        );
        if (category) {
          return category.color;
        }
      }
    },
    [subjects, categories],
  );

  const renderProviders = useCallback(
    () =>
      subjects.map((subject: ISubjects) => (
        <article key={`${subject.subject_id}-tab-01`} className="slideUp">
          <p
            className="flag"
            style={{
              borderRadius: '5px',
              backgroundColor: getCategoryForSubject(subject.subject_id),
            }}
          />
          <header>
            {user.type !== 'seller_assistent' && (
              <>
                <button
                  type="button"
                  onClick={() => handleOpenModal(subject.subject_id)}
                >
                  <IconSubjectTrash title="remover" />
                </button>
                <button
                  type="button"
                  onClick={() =>
                    handleOpenModalEdit(subject.subject_id, subject.name)
                  }
                  style={{ right: '20px' }}
                >
                  <IconEdit style={{ width: '15px' }} title="Renomear" />
                </button>
                {user.type === 'seller' && (
                  <button
                    type="button"
                    onClick={() =>
                      handleOpenModalCategory(subject.subject_id, subject.name)
                    }
                    style={{ right: '45px' }}
                  >
                    <IconBookmarkBlack
                      style={{ width: '15px' }}
                      title="Categoria"
                    />
                  </button>
                )}
              </>
            )}
            <h1>{subject.name}</h1>
            <p className="units">
              <IconBookmarkYellow /> <strong>{subject.itemsCount}</strong>{' '}
              unidades
            </p>
          </header>
          <main />
          <footer>
            <Link to={`/seller/subject/${subject.subject_id}`}>
              Ver disciplina
            </Link>
          </footer>
        </article>
      )),
    [
      subjects,
      user.type,
      handleOpenModal,
      handleOpenModalEdit,
      handleOpenModalCategory,
      getCategoryForSubject,
    ],
  );

  const getCategories = useCallback(async () => {
    if (user.seller && user.seller.id) {
      const { data } = await getSubjectCategories(user.seller?.id);
      setCategories(data);
    }
  }, [user.seller]);

  const handleGetItens = useCallback(async () => {
    // Filter
    const filters: IFilter = {
      knowledges: undefined,
      levelOfLearning: undefined,
      categories: undefined,
    };

    for (const key in selectedKnowledge) {
      if (!filters.knowledges) filters.knowledges = [];
      filters.knowledges.push(key);
    }

    for (const key in selectedLevel) {
      if (!filters.levelOfLearning) filters.levelOfLearning = [];
      filters.levelOfLearning.push(key);
    }

    for (const key in selectedCategory) {
      if (!filters.categories) filters.categories = [];
      filters.categories.push(key);
    }

    try {
      if (user.provider?.id) {
        if (search && search.length > 0) {
          const providerId = user.provider.id;
          const name = search;
          const { data } = await getListProviderSubjectsByTitle(
            providerId,
            name,
          );
          setSubjects(data);
        } else {
          const { data } = await getListProviderSubjects(user.provider.id);
          setSubjects(data);
        }
      }

      if (user.seller && user.seller.id) {
        const params = {
          sellerId: user.seller.id,
          name: search || undefined,
          knowledges: filters.knowledges?.join(',') || undefined,
          levelOfLearning: filters.levelOfLearning?.join(',') || undefined,
          categories: filters.categories?.join(',') || undefined,
        };

        const { data } = await getListSellerSubject(params);
        setSubjects(data);
      }
    } catch (error) {
      addToast({
        title: 'Ops...',
        type: 'error',
        description:
          'Algo inesperado aconteceu, por favor recarrege sua página.',
      });
    }

    setLoading(false);
  }, [
    user,
    addToast,
    search,
    selectedKnowledge,
    selectedLevel,
    selectedCategory,
  ]);

  useEffect(() => {
    const load = async (): Promise<void> => {
      try {
        const level = await getLevelOfLearning();
        setLevelOfLearning(level as []);

        const know = await getKnowledge();
        setKnowledges(know);
      } catch (error) {
        addToast({
          title: 'Ops...',
          type: 'error',
          description:
            'Algo inesperado aconteceu, por favor recarrege sua página.',
        });
      }
    };

    load();
  }, [addToast]);

  useEffect(() => {
    handleGetItens();
    getCategories();
  }, [getCategories, handleGetItens]);

  const handleOpenModalCreate = useCallback(() => {
    setIsCreateCategoryOpen(!isCreateCategoryOpen);
    setCategoryColor('#DAA520');
  }, [isCreateCategoryOpen]);

  const colors = [
    { name: ' ', code: '#ff0000' },
    { name: ' ', code: '#0000ff' },
    { name: ' ', code: '#00ff00' },
    { name: ' ', code: '#BED7A3' },
    { name: ' ', code: '#682E35' },
    { name: ' ', code: '#424470' },
    { name: ' ', code: '#488522' },
  ];

  const ColorCategories = ({ colorsCategories, onSelect }): JSX.Element => {
    const handleClick = (event, code): void => {
      event.preventDefault();
      onSelect(code);
    };
    return (
      <div className="color-palette">
        {colorsCategories.map(color => (
          <button
            key={color.code}
            className="colorCategor"
            style={{ backgroundColor: color.code }}
            onClick={event => handleClick(event, color.code)}
          >
            {color.name}
          </button>
        ))}
      </div>
    );
  };

  const handleColorSelect = (colorcode): void => {
    setCategoryColor(colorcode);
  };

  const handleCreateNewCategory = async (data): Promise<void> => {
    const seller_id = user.seller?.id;
    const { name } = data;

    try {
      await createSubjectCategory({
        name,
        seller_id,
        color: categoryColor,
      });
      addToast({
        type: 'success',
        title: 'Atenção!',
        description: 'Categoria criado com sucesso!',
      });
    } catch (error) {
      addToast({
        type: 'error',
        title: 'Atenção!',
        description: 'Categoria já existente',
      });
    }
    setCategories([...categories]);
    handleOpenModalCreate();
    getCategories();
  };

  const handleRemoveCategory = useCallback(async () => {
    try {
      if (!categoryDelete) {
        addToast({
          title: 'Ops...',
          type: 'error',
          description: 'Selecione uma categoria para remover.',
        });
        return;
      }
      await deleteSubjectCategory(categoryDelete);
      addToast({
        title: 'Sucesso',
        type: 'success',
        description: 'Categoria removida com sucesso!',
      });

      // fechar modal
      setRemoveCategory(false);
    } catch (error) {
      addToast({
        title: 'Ops...',
        type: 'error',
        description:
          'Algo inesperado aconteceu, por favor recarrege sua página.',
      });
    }

    getCategories();
  }, [addToast, categoryDelete, getCategories]);

  const SelectOptionsCategories = categories.map(category => ({
    label: category.name,
    value: category.id,
  }));

  const handleSave = useCallback(
    async data => {
      const category_id = data.select_categories;
      if (!data.select_categories) {
        addToast({
          type: 'error',
          title: 'Atenção!',
          description: 'Categoria não encontrada',
        });
      }

      if (categoryEdit) {
        if (submitType === 'confirm') {
          try {
            await updateSellerSubject({
              id: categoryEdit.id,
              name: categoryEdit.name,
              category_id,
            });
            addToast({
              type: 'success',
              title: 'Atenção!',
              description: 'Categoria salva com sucesso!!',
            });
          } catch (error) {
            addToast({
              type: 'error',
              title: 'Atenção!',
              description: 'Alterar mensagem',
            });
          }
        }
        if (submitType === 'delete') {
          setCategoryDelete(category_id);
          handleRemoveCategoryModal();
        }
      }
    },
    [addToast, categoryEdit, submitType, handleRemoveCategoryModal],
  );

  return (
    <Holder>
      <Main>
        <MainContent className="blur">
          <NavTop />
          <Body className="body">
            <ModalAddSubject
              isOpen={modalAddSubjectOpen}
              setIsOpen={toggleModalAddSubject}
              clearFilter={clearFilter}
            />
            <ModalImportSubject
              isOpen={modalImportSubjectOpen}
              setIsOpen={toggleModalImportSubject}
              clearFilter={clearFilter}
            />

            {itemToRemove && (
              <Modal isOpen={isOpen} setIsOpen={() => handleOpenModal()}>
                <ModalContent cancel>
                  <h1 className="modalTitle">
                    Tem certeza que deseja remover a disciplina?
                  </h1>
                  <section>
                    <button onClick={() => handleRemoveSubject()}>SIM</button>
                    <button
                      onClick={() => handleOpenModal()}
                      className="cancel"
                    >
                      NÃO
                    </button>
                  </section>
                </ModalContent>
              </Modal>
            )}

            {itemToEdit && (
              <Modal
                isOpen={isOpenEdit}
                setIsOpen={() => handleOpenModalEdit()}
              >
                <ModalContent cancel>
                  <h1 className="modalTitle">
                    Insira um novo nome para dar a disciplina
                  </h1>
                  <Form ref={formRef} onSubmit={handleEditSubjectSubmit}>
                    <Input
                      label="Nome da disciplina"
                      name="name"
                      type="text"
                      placeholder={itemToEdit.name}
                    />
                    <section style={{ marginTop: '50px' }}>
                      <button type="submit">SIM</button>
                      <button
                        onClick={() => handleOpenModalEdit()}
                        className="cancel"
                      >
                        NÃO
                      </button>
                    </section>
                  </Form>
                </ModalContent>
              </Modal>
            )}

            <Modal
              isOpen={isCategorieOpen}
              setIsOpen={() => handleOpenModalCategory()}
            >
              <ModalContent cancel>
                <Form ref={formRef} onSubmit={handleSave}>
                  <h1 className="modalTitle">Adicione uma Categoria</h1>
                  {SelectOptionsCategories.length > 0 && (
                    <Select
                      name="select_categories"
                      placeholder="Categoria"
                      options={SelectOptionsCategories}
                      required
                    />
                  )}
                  <section style={{ marginTop: '50px' }}>
                    <button
                      onClick={() => setSubmitType('confirm')}
                      datatype="confirm"
                      type="submit"
                    >
                      Confirmar
                    </button>

                    <button
                      type="button"
                      onClick={() => handleOpenModalCreate()}
                    >
                      Criar Nova Categoria
                    </button>
                    <button
                      onClick={() => setSubmitType('delete')}
                      datatype="delete"
                      type="submit"
                    >
                      Excluir
                    </button>
                    <button
                      onClick={() => handleOpenModalCategory()}
                      className="cancel"
                    >
                      Sair
                    </button>
                  </section>
                </Form>
              </ModalContent>
            </Modal>

            <Modal
              isOpen={removeCategory}
              setIsOpen={() => handleRemoveCategoryModal()}
            >
              <ModalContent cancel>
                <h1 className="modalTitle">
                  Tem certeza que deseja excluir a categoria?
                </h1>
                <section>
                  <button onClick={() => handleRemoveCategory()}>Sim</button>
                  <button
                    onClick={() => handleRemoveCategoryModal()}
                    className="cancel"
                  >
                    Não
                  </button>
                </section>
              </ModalContent>
            </Modal>

            <Modal
              isOpen={isCreateCategoryOpen}
              setIsOpen={() => handleOpenModalCreate()}
            >
              <ModalContent cancel>
                <h1 className="modalTitle" style={{ marginTop: '10px' }}>
                  Criar Nova Categoria
                </h1>

                <Form onSubmit={handleCreateNewCategory}>
                  <Input
                    label="Nome da categoria"
                    name="name"
                    type="text"
                    placeholder="Arquitetura analítica"
                    required
                  />{' '}
                  <div
                    style={{
                      width: '100%',
                      height: '50px',
                      backgroundColor: categoryColor,
                      borderRadius: '5px',
                      marginBottom: '5px',
                      marginTop: '5px',
                    }}
                  />
                  <div className="Testcolor" style={{ marginBottom: '5px' }}>
                    <ColorCategories
                      colorsCategories={colors}
                      onSelect={handleColorSelect}
                    />
                  </div>
                  <div>
                    <ChromePicker
                      color={categoryColor}
                      onChange={color => setCategoryColor(color.hex)}
                    />
                  </div>
                  <section style={{ marginTop: '25px' }}>
                    <button type="submit">Criar</button>
                    <button
                      onClick={() => handleOpenModalCreate()}
                      className="cancel"
                    >
                      Sair
                    </button>
                  </section>
                </Form>
              </ModalContent>
            </Modal>

            <Container className="slideDown">
              <Content>
                <Options>
                  <OptionRight>
                    <Title>
                      <h1>Disciplinas</h1>
                    </Title>
                  </OptionRight>
                  {user.type !== 'seller_assistent' && (
                    <OptionLeft style={{ flexDirection: 'row-reverse' }}>
                      <AddButton onClick={toggleModalAddSubject}>
                        <IconAddCircle /> Nova Disciplina
                      </AddButton>
                      {user.seller && (
                        <AddButton onClick={toggleModalImportSubject}>
                          <IconDownload /> Importar Disciplina
                        </AddButton>
                      )}
                    </OptionLeft>
                  )}
                </Options>
                <section>
                  {loading && (
                    <div className="loading">
                      <BeatLoader size={16} />
                    </div>
                  )}

                  {!loading && renderProviders()}

                  {subjects.length <= 0 && !loading && (
                    <div className="loading">
                      <p>Nenhum item encontrado.</p>
                    </div>
                  )}
                </section>
              </Content>
            </Container>
          </Body>
        </MainContent>
      </Main>

      {user.seller ? (
        <RightSide className="slideLeft">
          <div>
            <h2>Filtrar disciplinas</h2>
            <InputSearch
              name="search"
              onChange={e => setSearch(e.target.value)}
              placeholder="Pesquisar"
              value={search}
            />
          </div>
          {user.type === 'seller' && (
            <div>
              <h3>Áreas de Conhecimento</h3>
              <div className="checkboxContainer" ref={inputParentKnowledge}>
                {knowledges.map(
                  (
                    know: { label: string; value: string; type: string },
                    index: number,
                  ) => (
                    <label
                      className="checkboxContent"
                      htmlFor={`know-${index}`}
                      key={know.value}
                    >
                      {know.label}
                      <input
                        name=""
                        type="checkbox"
                        id={`know-${index}`}
                        onChange={() => handleKnowledge(know.value)}
                      />
                      <span className="checkmark" />
                    </label>
                  ),
                )}
              </div>
            </div>
          )}
          <div>
            <h3>Nível de Aprendizagem</h3>
            <div className="checkboxContainer" ref={inputParentLevel}>
              {levelOfLearning.map(
                (level: { label: string; id: string }, index: number) => (
                  <label
                    className="checkboxContent"
                    htmlFor={`level-${index}`}
                    key={level.id}
                  >
                    {level.label}
                    <input
                      name=""
                      type="checkbox"
                      id={`level-${index}`}
                      onChange={() => handleLevel(level.id)}
                    />
                    <span className="checkmark" />
                  </label>
                ),
              )}
            </div>
          </div>
          {user.type === 'seller' && (
            <div>
              <h3>Categorias</h3>
              <div className="checkboxContainer" ref={inputParentCategory}>
                {categories.map(
                  (category: { name: string; id: string }, index: number) => (
                    <label
                      className="checkboxContent"
                      htmlFor={`category-${index}`}
                      key={category.id}
                    >
                      {category.name}
                      <input
                        name=""
                        type="checkbox"
                        id={`category-${index}`}
                        onChange={() => handleCategory(category.id)}
                      />
                      <span className="checkmark" />
                    </label>
                  ),
                )}
              </div>
            </div>
          )}
          <button type="button" onClick={clearFilter}>
            Limpar Filtros
          </button>
        </RightSide>
      ) : undefined}
    </Holder>
  );
};

export default Subjects;
