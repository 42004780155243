import styled from 'styled-components';
import { shade } from 'polished';

export const Holder = styled.div`
  display: flex;
  width: 100%;
  & > main {
    width: 100%;
  }
  & > aside {
    width: 300px;
  }
`;

interface IPropsCancel {
  cancel: boolean;
}

export const ModalContent = styled.div<IPropsCancel>`
  h1.modalTitle {
    margin-top: 33px;
    text-align: center;
    color: #3d3939;
    font-size: 22px;
    font-family: 'Source Sans Pro';
    font-weight: 700;
    margin-bottom: 40px;
  }
  section {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 25px;
    button {
      width: 90px;
      height: 50px;
      margin-left: 20px;
      color: #ffff;
      font-family: 'Source Sans Pro';
      font-weight: 700;
      background: #6bc196;
      border: 1px solid transparent;
      border-radius: 5px;
      transition: all 0.2s;
      &:hover {
        background: ${shade(0.1, '#6BC196')};
      }
    }
    button.cancel {
      background: #cc593e;
      &:hover {
        background: ${shade(0.1, '#CC593E')};
      }
    }
  }
`;

export const Container = styled.div``;

export const Content = styled.div`
  section {
    padding-top: 20px;
    .dropper {
      margin-top: 15px;
      padding-top: 25px;
      border-top: 1px solid var(--gray-border);
    }
    .loading {
      width: 100%;
      padding: 20px;
      text-align: center;
      & > div {
        display: inline-block;
        opacity: 0.5;
      }
    }
  }
`;

export const Article = styled.article`
  background: #ffffff;
  border: 1px solid var(--quaternary);
  border-radius: 5px;
  margin: 10px 0;
  display: flex;
  &.unavailableItem {
    opacity: 0.5;
  }
  & > * {
    padding: 30px;
  }
  header {
    display: flex;
    justify-content: center;
    align-items: center;
    strong {
      font-size: 1.45rem;
      font-weight: bold;
      color: var(--gray);
      padding: 10px;
    }
  }
  main {
    width: 100%;
    padding-top: 20px;
    padding-bottom: 20px;
    position: relative;
    display: flex;
    align-items: center;
    h1 {
      font-size: 1.25rem;
      margin-bottom: 5px;
    }
    p {
      font-size: 1rem;
      color: var(--gray);
    }
  }
  footer {
    padding-top: 20px;
    padding-bottom: 20px;
    display: flex;
    align-items: center;
    a,
    button {
      width: 140px;
      height: 35px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 0.9rem;
    }
    a {
      text-align: center;
      padding: 5px 10px;
      border: none;
      color: var(--dark);
      background: var(--aurea);
      border-radius: 5px;
      font-weight: bold;
      transition: background 400ms;
      margin-right: 10px;
      &:hover {
        text-decoration: none;
        background: var(--hover);
      }
    }
    button {
      display: block;
      padding: 5px 10px;
      border: none;
      color: var(--dark);
      background: #ffffff;
      border: 1px solid var(--gray-border);
      border-radius: 5px;
      font-weight: bold;
      transition: background 400ms;
      &:hover {
        text-decoration: none;
        background: var(--gray-border);
      }
    }
    a,
    button {
      &.rented {
        opacity: 0.5;
        background: var(--white);
        border: 1px solid var(--gray-border);
        transition: opacity 0.3s;
        text-align: center;
        &:hover {
          opacity: 1;
        }
      }
    }
  }
  .dragger {
    padding: 0;
    span {
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: grab;
      padding: 30px;
      height: 100%;
      background: none;
      border: none;
      line-height: 0;
    }
  }
  .shareButton {
    border: 0;
    width: 60px;
    &:hover {
      opacity: initial;

      text-decoration: none;
      background: transparent;
    }
  }
`;

export const Title = styled.div`
  div.bread-crumb {
    margin-bottom: 20px;
    ul {
      display: flex;
      list-style: none;
      li {
        font-weight: bold;
        color: var(--aurea);
        a {
          font-weight: normal;
          color: var(--tertiary);
          text-decoration: none;
          &:hover {
            text-decoration: underline;
          }
          &:after {
            content: '>';
            display: inline-block;
            margin: 0 10px;
            font-size: 0.75rem;
          }
        }
        &:last-child {
          text-decoration: underline;
        }
      }
    }
  }
  h1 {
    margin-right: 24px;
  }
  h2 {
    color: var(--gray);
    font-weight: bold;
    text-transform: uppercase;
  }
`;

export const RightSide = styled.aside`
  height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
  padding-top: 20px;
  background: #ffffff;
  border-left: 1px solid #e7e5e5;
  & > div {
    padding: 25px;
    & + div {
      border-top: 1px solid #e0dede;
    }
    h2 {
      font-size: 1.875rem;
      font-weight: bold;
      margin-bottom: 10px;
    }
    h3 {
      font-weight: bold;
      color: var(--gray);
    }
    .checkboxContainer {
      padding: 20px 5px;
      .checkboxContent {
        display: block;
        position: relative;
        color: var(--gray);
        cursor: pointer;
        padding-left: 35px;
        input {
          position: absolute;
          opacity: 0;
          cursor: pointer;
          height: 0;
          width: 0;
        }
        .checkmark {
          position: absolute;
          top: 0;
          left: 0;
          height: 20px;
          width: 20px;
          background-color: #ffffff;
          border-radius: 3px;
          border: 2px solid #c6c2c2;
          box-sizing: border-box;
          transition: all 100ms;
          &:after {
            content: '';
            position: absolute;
            display: none;

            left: 6px;
            top: 2px;
            width: 5px;
            height: 10px;
            border: solid white;
            border-width: 0 3px 3px 0;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
          }
        }
        &:hover input ~ .checkmark {
          background-color: #c6c2c2;
        }
        & input:checked ~ .checkmark {
          border: 0;
          background-color: var(--aurea);
          &:after {
            display: block;
          }
        }
      }
      label + label {
        margin-top: 10px;
      }
    }
    .input-range {
      margin-bottom: 20px;
      margin-top: 30px;
      .input-range__track--active {
        background: var(--aurea);
      }
      .input-range__slider {
        background: #ffffff;
        border: 1px solid var(--quaternary);
      }
      .input-range__label-container {
        left: 0;
      }
      .input-range__label--min,
      .input-range__label--max {
        display: none;
      }
      .input-range__track {
        & > .input-range__slider-container + .input-range__slider-container {
          .input-range__label-container {
            left: inherit;
            right: 0;
            top: 40px;
            position: absolute;
          }
        }
      }
    }
  }
  button {
    background: none;
    border: none;
    width: 100%;
    text-decoration: underline;
    color: var(--gray);
    margin-bottom: 50px;
  }
`;
