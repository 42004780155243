import React, { useEffect, useState } from 'react';

import { Link, useHistory, useLocation } from 'react-router-dom';
import { BeatLoader } from 'react-spinners';
import {
  Container,
  Content,
  Main,
  MainContent,
  Body,
  Sidebar,
  TxtSidebar,
  Card,
  Loading,
} from './styles';
import Logo from '../../assets/images/logo-new-yellow.svg';
import { IconUserAlt } from '../../styles/icons';
import { PrimaryButton } from '../../components/template/TemplateManager/styles';
import { useToast } from '../../hooks/toast';
import { getConfirmEmail } from '../../services/api';

const Confirmation: React.FC = () => {
  const history = useHistory();
  const location = useLocation();
  const { addToast } = useToast();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const load = async (): Promise<void> => {
      try {
        setLoading(true);
        const email = location.search.replace('?email=', '');
        await getConfirmEmail(email);

        addToast({
          title: 'Sucesso!',
          description: 'Email confirmado com sucesso!',
          type: 'success',
        });
      } catch (error) {
        addToast({
          title: 'Ops...',
          description: 'Não foi possível confirmar seu email',
          type: 'error',
        });

        history.push('/');
      } finally {
        setLoading(false);
      }
    };
    load();
  }, [location.search, addToast, history]);
  return (
    <>
      {loading ? (
        <Loading>
          <BeatLoader size={20} />
        </Loading>
      ) : (
        <Container>
          <Content>
            <Sidebar>
              <div>
                <img src={Logo} alt="" />
                <TxtSidebar>
                  <IconUserAlt width={12} height={12} /> Já tem cadastro?{' '}
                  <Link to="/">Faça login</Link>
                </TxtSidebar>
              </div>
            </Sidebar>
            <Main>
              <MainContent className="blur">
                <Body className="body">
                  <Card className="slideDown">
                    <h2>E-mail confirmado com sucesso!</h2>
                    <p>Uma senha padrão foi enviada para seu e-mail.</p>
                    <p>Acesse o sistema para redefinir.</p>
                    <PrimaryButton onClick={() => history.push('/')}>
                      Fazer Login
                    </PrimaryButton>
                  </Card>
                </Body>
              </MainContent>
            </Main>
          </Content>
        </Container>
      )}
    </>
  );
};

export default Confirmation;
