/* eslint-disable no-nested-ternary */
import React, { useCallback, useMemo, useRef } from 'react';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import { RawEditorSettings } from 'tinymce';
import Input from '../../forms/Input';
import Button from '../../forms/Buttom';

import { ContentPage } from './styles';
import { IEditResource, IPages } from '.';
import Editor from '../../forms/Editor';

interface IProps {
  handlePage(page: IPages): void;
  propsEditor: RawEditorSettings;
  setIsOpen: () => void;
  subtype: string;
  editResource: IEditResource;
}

const FormWarning: React.FC<IProps> = ({
  handlePage,
  propsEditor,
  setIsOpen,
  subtype,
  editResource,
}) => {
  const formRef = useRef<FormHandles>(null);

  const isEdit = useMemo(() => {
    return Object.keys(editResource).length > 0;
  }, [editResource]);

  const handleSubmit = useCallback(
    async data => {
      const warning = data.content;
      if (!warning) {
        return;
      }

      if (isEdit) {
        // editResource.element.remove();
        editResource.element.setAttribute('data-resource', warning);
        propsEditor.insertContent(propsEditor.selection.getContent());
      } else {
        propsEditor.insertContent(
          `<div class='resource' data-interaction='true' data-type='warning' data-subtype='${
            data.subtype
          }' data-resource='${warning.replace(/[']/g, '&#39;')}'>
                  ${
                    data.subtype === 'danger'
                      ? `Atenção`
                      : data.subtype === 'emphasis'
                      ? 'Destaque'
                      : `Saiba mais`
                  }
                </div>\n
                <p>&nbsp;</p>`,
        );
      }
      propsEditor.focus();
      setIsOpen();
    },
    [propsEditor, setIsOpen, isEdit, editResource.element],
  );

  return (
    <Form
      ref={formRef}
      onSubmit={handleSubmit}
      initialData={{ content: editResource.resource }}
    >
      <ContentPage>
        {!isEdit && (
          <button type="button" onClick={() => handlePage('main')}>
            Voltar
          </button>
        )}
        <div>
          <h2>Bloco de Texto</h2>
          <Editor name="content" label="Digite sua observação" placeholder="" />
          <Input hidden label="" name="type" defaultValue="warning" />
          <Input
            hidden
            label=""
            name="subtype"
            defaultValue={isEdit ? editResource.subtype : subtype}
          />
          <Button label="Inserir" type="submit" />
        </div>
      </ContentPage>
    </Form>
  );
};

export default FormWarning;
