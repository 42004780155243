import React, {
  useCallback,
  useRef,
  useState,
  ChangeEvent,
  FormEvent,
} from 'react';
import * as Yup from 'yup';
import { FormHandles } from '@unform/core';
import { useToast } from '../../../hooks/toast';
import getValidationErrors from '../../../utils/getVaidationErrors';
import Modal from '../Modal';
import { partnerNotification } from '../../../services/api';
import {
  ModalFormContainer,
  FormContainer,
  ModalOverlay,
} from '../../../pages/PartnersPlatform/styles';

interface IPartnerNotification {
  name: string;
  message: string;
  to: string;
  from?: string;
  subject: string;
}
interface IModalProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}

interface IFormData {
  email: string;
  phone: string;
  fullName: string;
}

const ModalFormPartners: React.FC<IModalProps> = ({ isOpen, setIsOpen }) => {
  const { addToast } = useToast();
  const formRef = useRef<FormHandles>(null);
  const [formData, setFormData] = useState<IFormData>({
    email: '',
    phone: '',
    fullName: '',
  });

  const closeModal = useCallback((): void => {
    setIsOpen(false);
  }, [setIsOpen]);

  const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = event.target;
    setFormData(prevFormData => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = useCallback(
    async (event: FormEvent) => {
      event.preventDefault();

      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          email: Yup.string()
            .email('Digite um e-mail válido')
            .required('Campo Obrigatório'),
          phone: Yup.string().required('Campo Obrigatório'),
          fullName: Yup.string().required('Campo Obrigatório'),
        });

        await schema.validate(formData, {
          abortEarly: false,
        });

        const dataToSend: IPartnerNotification = {
          message: `Acesso do cliente ${formData.fullName}`,
          name: formData.fullName,
          to: 'titiago2504@gmail.com',
          subject: 'Notification Subject',
        };

        addToast({
          title: 'Cliente cadastrado com sucesso!',
          type: 'success',
        });
        const { data } = await partnerNotification(dataToSend);
        if (data) {
          setFormData({
            email: '',
            phone: '',
            fullName: '',
          });
          closeModal();
        } else {
          addToast({
            title: 'Ops...',
            type: 'error',
            description: 'Algo inesperado aconteceu.',
          });
        }

        // Redirecionar para o link desejado
        window.open('https://www.youtube.com', '_blank'); // Substitua pelo URL desejado
        setIsOpen(false);
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);
          formRef.current?.setErrors(errors);
          return;
        }
        addToast({
          title: 'Ops...',
          type: 'error',
          description: 'Algo inesperado aconteceu.',
        });
      }
    },
    [formData, addToast, setIsOpen, closeModal],
  );

  return (
    <Modal isOpen={isOpen} setIsOpen={closeModal}>
      <div style={{ height: '420px', width: '300px' }}>
        <ModalOverlay>
          <ModalFormContainer>
            <FormContainer>
              <button
                type="button"
                className="close-button"
                onClick={closeModal}
                style={{
                  fontWeight: 'bold',
                  border: 'none',
                  position: 'relative',
                  left: '230px',
                  top: 'top',
                  background: 'none',
                  color: 'rgb(246, 200, 102)',
                  textDecoration: 'underline',
                }}
              >
                Fechar
              </button>
              <h2 style={{ fontWeight: 'bold', marginBottom: '20px' }}>
                Formulário de Cadastro
              </h2>
              <form onSubmit={handleSubmit} className="FormContainer">
                <label style={{ fontWeight: 'bold' }}>Email:</label>
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  placeholder="example@email.com"
                />
                <label style={{ fontWeight: 'bold' }}>Telefone:</label>
                <input
                  type="tel"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                  placeholder="Número de telefone"
                />
                <label style={{ fontWeight: 'bold' }}>Nome Completo:</label>
                <input
                  type="text"
                  name="fullName"
                  value={formData.fullName}
                  onChange={handleChange}
                  placeholder="Nome completo"
                />
                <button
                  type="submit"
                  style={{
                    margin: '0 auto',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  Enviar
                </button>
                <p> *Após enviar os dados será redirecionado para o site</p>
              </form>
              <div style={{ backgroundColor: 'rgb(246, 200, 102)' }} />
            </FormContainer>
          </ModalFormContainer>
        </ModalOverlay>
      </div>
    </Modal>
  );
};

export default ModalFormPartners;
