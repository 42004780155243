/* eslint-disable react/require-default-props */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Select from '../../forms/ReactSelect';

import Buttom from '../../forms/Buttom';
import Modal from '../Modal';
import {
  Container,
  Bottom,
  ContentForm,
  ContentField,
  SelectContent,
} from './styles';
import { useToast } from '../../../hooks/toast';
import {
  filterUsers,
  postCreateReview,
  postRecreateReview,
} from '../../../services/api';
import { useAuth } from '../../../hooks/auth';
import { saveManageDelegationUnitsLog } from '../../../services/logs';

interface IModalProps {
  isOpen: boolean;
  setIsOpen: () => void;
  currentItemId: Array<string>;
  handleInitalState(): void;
  isNewReview?: string;
}

const ModalDelegate: React.FC<IModalProps> = ({
  isOpen,
  setIsOpen,
  currentItemId,
  handleInitalState,
  isNewReview,
}) => {
  const { addToast } = useToast();
  const { user } = useAuth();
  const history = useHistory();
  const [curatorSelected, setCuratorSelected] = useState('');
  const [reviewSelected, setReviewSelected] = useState('');

  const [curatorList, setCuratorList] = useState([]);
  const [reviewList, setReviewList] = useState([]);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const load = async (): Promise<void> => {
      try {
        const [resCurator, resReview] = await Promise.all([
          filterUsers({
            type: 'curator',
          }),
          filterUsers({
            type: 'review',
          }),
        ]);
        setCuratorList(
          resCurator.data.map((item: any) => {
            return { label: item.name, value: item.id };
          }),
        );
        setReviewList(
          resReview.data.map((item: any) => {
            return { label: item.name, value: item.id };
          }),
        );
      } catch (error) {
        addToast({
          title: 'Ops ...',
          description: 'Aconteceu algo inesperado, por favor tente mais tarde',
          type: 'error',
        });
      }
    };
    load();
  }, [addToast]);

  const handleSubmit = useCallback(async () => {
    setLoading(true);
    if (curatorSelected === '' || reviewSelected === '') {
      addToast({
        title: 'Ops ...',
        type: 'error',
        description: 'Preencha todos os campos',
      });
      setLoading(false);

      return;
    }

    try {
      if (isNewReview) {
        const { data: resRecreateReview } = await postRecreateReview({
          curatorId: curatorSelected,
          reviewerId: reviewSelected,
          contentIds: currentItemId,
          managerId: user.id,
          id: isNewReview,
        });
        history.push(`/dashboard/units/review/${resRecreateReview.id[0]}`);
      } else {
        await postCreateReview({
          curatorId: curatorSelected,
          reviewerId: reviewSelected,
          contentIds: currentItemId,
          managerId: user.id,
        });
        handleInitalState();
      }

      saveManageDelegationUnitsLog({
        userId: user.id,
        action: 'delegate_unity',
        unityId: currentItemId,
        curatorId: curatorSelected,
        reviewerId: reviewSelected,
      });

      addToast({
        title: 'Sucesso!',
        type: 'success',
        description: 'Delegações realizada com sucesso!',
      });
      setIsOpen();
    } catch (error) {
      addToast({
        title: 'Ops ...',
        type: 'error',
        description: 'Algo inesperado aconteceu, por favor tente mais tarde',
      });
    } finally {
      setLoading(false);
    }
  }, [
    currentItemId,
    addToast,
    curatorSelected,
    reviewSelected,
    setIsOpen,
    user.id,
    handleInitalState,
    isNewReview,
    history,
  ]);

  const handleCurator = useCallback((value: string) => {
    setCuratorSelected(value);
  }, []);

  const handleReview = useCallback((value: string) => {
    setReviewSelected(value);
  }, []);
  return (
    <Modal isOpen={isOpen} setIsOpen={setIsOpen} width="35%">
      <Container>
        <h1>{isNewReview ? `Criar nova delegação` : `Delegar usuários`}</h1>
        <ContentForm>
          <ContentField>
            <label htmlFor="curador">Delegar Curador</label>
            <SelectContent>
              <Select
                name="curador"
                options={curatorList}
                placeholder="Selecionar ..."
                handleValue={handleCurator}
              />
            </SelectContent>
          </ContentField>

          <ContentField>
            <label htmlFor="revisor">Delegar Revisor</label>
            <SelectContent>
              <Select
                name="revisor"
                options={reviewList}
                placeholder="Selecionar ..."
                handleValue={handleReview}
              />
            </SelectContent>
          </ContentField>
          <Bottom>
            <Buttom label="Cancelar" outline onClick={() => setIsOpen()} />
            <Buttom
              label="Salvar"
              isLoading={loading}
              onClick={() => handleSubmit()}
            />
          </Bottom>
        </ContentForm>
      </Container>
    </Modal>
  );
};

export default ModalDelegate;
