/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-nested-ternary */
import React, {
  useState,
  useCallback,
  useEffect,
  useMemo,
  Fragment,
} from 'react';
import { BsBookmarkFill } from 'react-icons/bs';
import { BeatLoader } from 'react-spinners';
import { AxiosResponse } from 'axios';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import {
  Container,
  Content,
  Main,
  MainContent,
  Body,
  Title,
  TrContainer,
  TableContainer,
} from './styles';
import NavTop from '../../components/template/NavTop';
import { deleteSellersRent, getSellers } from '../../services/api';
import InputSearch from '../../components/forms/InputSearch';
import { TableContent } from '../../components/template/TemplateManager/styles';
import Tabs, { TabItem } from '../../components/template/Tabs';
import Checkbox, { CheckBoxAll } from '../../components/forms/Checkbox';
import Timer from '../../utils/timer';
import IconUser from '../../assets/icons/icon-user-circle-lg.svg';
import ChoiceEllipse from '../../components/forms/ChoiceEllipse';
import { useToast } from '../../hooks/toast';
import { useAuth } from '../../hooks/auth';
import Modal from '../../components/template/Modal';
import { ModalContent } from '../cart/styles';

// criar interface
interface ISellerItem {
  provider: {
    imgLogoUrl: string | null;
    companyName: string;
    condition: string;
    itemsCount: string;
    plans_provider_value: {
      value: string;
    };
  };
  rent: {
    id: string;
    status: string;
    createdAt: Date;
  };
}

const statusOptions = [
  { id: '1', value: 'active', label: 'Ativos' },
  { id: '2', value: 'requested', label: 'Solicitados' },
  { id: '3', value: 'waiting', label: 'Aguardando' },
];

const queryTimer = new Timer();

const MyRents: React.FC = () => {
  const { user } = useAuth();
  const { addToast } = useToast();
  const [search, setSearch] = useState('');
  const [checks, setChecks] = useState<Array<string>>([]);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState('all');
  const [currentTab, setCurrentTab] = useState('tab-02');
  const [ordernation, setOrdernation] = useState('ASC');
  const [sellerItems, setSellerItems] = useState<ISellerItem[]>([]);
  const [providersInitalCopy, setProvidersInitalCopy] = useState<ISellerItem[]>(
    [],
  );
  const [isOpen, setIsOpen] = useState(false);
  const [itemToRemove, setItemToRemove] = useState<ISellerItem>();

  const handleTab = useCallback(
    (id: string) => {
      if (currentTab !== id) setLoading(true);
      if (currentTab === 'tab-02') {
        setOrdernation('ASC');
      } else {
        setOrdernation('DESC');
      }
      setCurrentTab(id);
    },
    [currentTab],
  );

  const handleCheck = useCallback(check => {
    setChecks(check);
  }, []);

  const handleDelete = useCallback(async () => {
    try {
      if (itemToRemove) {
        await deleteSellersRent(itemToRemove.rent.id);

        addToast({
          type: 'success',
          title: 'Soliticitação de cancelamento',
          description: 'A solicitação será enviado ao fornecedor',
        });
      }
    } catch (error) {
      addToast({
        title: 'Ops...',
        type: 'error',
        description:
          'Algo inesperado aconteceu, por favor recarrege sua página.',
      });
    }
  }, [addToast, itemToRemove]);

  // memo
  const urlUserType = useMemo(() => {
    const type = [
      { id: '1', name: 'adm' },
      { id: '2', name: 'diretor' },
    ];
    let res = type.find(item => item.id === user.type)?.name;
    if (!res) {
      res = 'provider';
    }
    return res;
  }, [user.type]);

  const handleSetProvider = useCallback((sellers: ISellerItem[]) => {
    setSellerItems(
      sellers.map(item => {
        return {
          ...item,
        };
      }),
    );
  }, []);

  const statusOptionsParse = useMemo(() => {
    return statusOptions.map(item => {
      if (item.value === 'all') {
        return {
          ...item,
          label: `${item.label} (${providersInitalCopy.length})`,
        };
      }
      return {
        ...item,
        label: `${item.label} (${
          providersInitalCopy.filter(
            provider => provider.rent.status === item.value,
          ).length
        })`,
      };
    });
  }, [providersInitalCopy]);

  useEffect(() => {
    const load = async (): Promise<void> => {
      try {
        // list sellers
        setLoading(true);
        let data: AxiosResponse;
        if (user.seller?.id) {
          if (status === 'all') {
            data = await getSellers({
              id: user.seller?.id,
              order: ordernation,
              companyName: search,
            });
            setProvidersInitalCopy(data.data);
          } else {
            data = await getSellers({
              id: user.seller?.id,
              order: ordernation,
              status,
              companyName: search,
            });
            const loadInitial = await getSellers({
              id: user.seller?.id,
              // order: ordernation,
              // status: status,
              // companyName: search
            });
            setProvidersInitalCopy(loadInitial.data);
          }
          handleSetProvider(data.data);
          setChecks([]);
        }
      } catch (error) {
        addToast({
          title: 'Ops...',
          type: 'error',
          description:
            'Algo inesperado aconteceu, por favor recarrege sua página.',
        });
      } finally {
        setLoading(false);
      }
    };
    queryTimer.register(() => load());
  }, [
    user.seller,
    ordernation,
    search,
    currentTab,
    urlUserType,
    status,
    addToast,
    handleSetProvider,
  ]);

  const handleStatus = useCallback((value: string) => {
    setStatus(value);
  }, []);
  // modal
  const handleOpenModal = useCallback(
    (item: ISellerItem) => {
      setItemToRemove(item);
      setIsOpen(!isOpen);
    },
    [isOpen],
  );

  return (
    <Container>
      {itemToRemove && (
        <Modal isOpen={isOpen} setIsOpen={() => handleOpenModal(itemToRemove)}>
          <ModalContent cancel>
            <h1 className="modalTitle">
              Tem certeza que deseja remover o aluguel de{' '}
              {itemToRemove.provider.companyName}?
            </h1>
            <section>
              <button type="button" onClick={handleDelete}>
                SIM
              </button>
              <button
                type="button"
                onClick={() => handleOpenModal(itemToRemove)}
                className="cancel"
              >
                NÃO
              </button>
            </section>
          </ModalContent>
        </Modal>
      )}
      <Main>
        <MainContent>
          <NavTop />
          <Body className="body">
            <Content className="slideDown">
              <Title>
                <h1>Meus alugueis</h1>
                <ChoiceEllipse
                  name="status"
                  itens={statusOptionsParse}
                  defaultValue={status}
                  handleStatus={handleStatus}
                />
              </Title>
              <section className="search">
                <InputSearch
                  name="search"
                  onChange={e => setSearch(e.target.value)}
                  placeholder="Pesquisar"
                  value={search}
                />
              </section>
              <TableContent>
                <Tabs
                  itens={[
                    { id: 'tab-01', label: 'A-Z' },
                    { id: 'tab-02', label: 'Mais Recentes' },
                  ]}
                  defaultValue={currentTab}
                  handleTab={handleTab}
                />
                {loading ? (
                  <div className="loading">
                    <BeatLoader size={16} />
                  </div>
                ) : (
                  <>
                    <TabItem id="tab-01" defaultValue={currentTab}>
                      <TableContainer>
                        <thead>
                          <tr>
                            <th>
                              <CheckBoxAll
                                ids={sellerItems.map(item => item.rent.id)}
                                handleCheckAll={handleCheck}
                              />
                            </th>
                            <th>Provider</th>
                            <th>Unidades</th>
                            <th className="th-stats">Status</th>
                            <th>Data de aquisição</th>
                            <th className="th-value">Valor</th>
                            <th />
                          </tr>
                        </thead>
                        <tbody>
                          {sellerItems.length === 0 && (
                            <tr>
                              <td colSpan={5}>
                                <p style={{ textAlign: 'center' }}>
                                  Nenhum item encontrado{' '}
                                  <span role="img" aria-label="wow">
                                    😮
                                  </span>
                                </p>
                              </td>
                            </tr>
                          )}
                          {currentTab === 'tab-01' &&
                            sellerItems.map((seller: ISellerItem) => (
                              <Fragment key={seller.provider.companyName}>
                                <TrContainer status={seller.rent.status}>
                                  <td>
                                    <Checkbox
                                      name="check"
                                      handleCheck={handleCheck}
                                      checks={checks}
                                      id={seller.rent.id}
                                    />
                                  </td>
                                  <td className="td-container">
                                    <div className="provider-container">
                                      <img
                                        src={
                                          seller.provider.imgLogoUrl
                                            ? seller.provider.imgLogoUrl
                                            : IconUser
                                        }
                                        alt=""
                                      />
                                      <section>
                                        <h3>{seller.provider.companyName}</h3>
                                        <p>
                                          Fornecedor {seller.provider.condition}
                                        </p>
                                      </section>
                                    </div>
                                  </td>
                                  <td>
                                    <div className="units-container">
                                      <BsBookmarkFill
                                        size={15}
                                        color="#F6C866"
                                      />
                                      <p className="units">
                                        <span>
                                          {seller.provider.itemsCount}
                                        </span>
                                        unidades
                                      </p>
                                    </div>
                                  </td>
                                  <td>
                                    {seller.rent.status === 'active' ? (
                                      <span className="status">ATIVO</span>
                                    ) : seller.rent.status === 'requested' ? (
                                      <span className="status">SOLICITADO</span>
                                    ) : (
                                      <span className="status">AGUARDANDO</span>
                                    )}
                                  </td>
                                  <td>
                                    <p style={{ fontSize: '0.75em' }}>
                                      {format(
                                        new Date(seller.rent.createdAt),
                                        "dd 'de' MMMM 'de' yyyy",
                                        {
                                          locale: ptBR,
                                        },
                                      )}
                                    </p>
                                  </td>
                                  <td>
                                    <span className="price">
                                      R${' '}
                                      {
                                        seller.provider.plans_provider_value
                                          .value
                                      }
                                    </span>
                                  </td>
                                  <td>
                                    <button
                                      onClick={() => handleOpenModal(seller)}
                                      className="cancel-rent"
                                      type="button"
                                    >
                                      {/* <AiFillCloseCircle color="#3D3939"/> */}
                                      Cancelar
                                    </button>
                                  </td>
                                </TrContainer>
                                <tr>
                                  <td />
                                </tr>
                              </Fragment>
                            ))}
                        </tbody>
                      </TableContainer>
                    </TabItem>
                    <TabItem id="tab-02" defaultValue={currentTab}>
                      <TableContainer>
                        <thead>
                          <tr>
                            <th>
                              <CheckBoxAll
                                // arrumar
                                ids={sellerItems.map(item => item.rent.id)}
                                handleCheckAll={handleCheck}
                              />
                            </th>
                            <th className="th-center">Fornecedor</th>
                            <th className="th-center">unidades</th>
                            <th className="th-center">Status</th>
                            <th className="th-center">Data de aquisição</th>
                            <th className="th-value th-center">Valor</th>
                            <th />
                          </tr>
                        </thead>
                        <tbody>
                          {sellerItems.length === 0 && (
                            <tr>
                              <td colSpan={5}>
                                <p style={{ textAlign: 'center' }}>
                                  Nenhum item encontrado{' '}
                                  <span role="img" aria-label="wow">
                                    😮
                                  </span>
                                </p>
                              </td>
                            </tr>
                          )}
                          {currentTab === 'tab-02' &&
                            sellerItems.map((seller: ISellerItem) => (
                              <Fragment key={seller.provider.companyName}>
                                <TrContainer status={seller.rent.status}>
                                  <td>
                                    <Checkbox
                                      name="check"
                                      handleCheck={handleCheck}
                                      checks={checks}
                                      id={seller.rent.id}
                                    />
                                  </td>
                                  <td className="td-container">
                                    <div className="provider-container">
                                      <img
                                        src={
                                          seller.provider.imgLogoUrl
                                            ? seller.provider.imgLogoUrl
                                            : IconUser
                                        }
                                        alt=""
                                      />
                                      <section>
                                        <h3>{seller.provider.companyName}</h3>
                                        <p>
                                          Provider {seller.provider.condition}
                                        </p>
                                      </section>
                                    </div>
                                  </td>
                                  <td>
                                    <div className="units-container">
                                      <BsBookmarkFill
                                        size={15}
                                        color="#F6C866"
                                      />
                                      <p className="units">
                                        <span>
                                          {seller.provider.itemsCount}
                                        </span>
                                      </p>
                                    </div>
                                  </td>
                                  <td>
                                    {seller.rent.status === 'active' ? (
                                      <span className="status">ATIVO</span>
                                    ) : seller.rent.status === 'requested' ? (
                                      <span className="status">SOLICITADO</span>
                                    ) : (
                                      <span className="status">AGUARDANDO</span>
                                    )}
                                  </td>
                                  <td>
                                    <p style={{ fontSize: '0.75em' }}>
                                      {format(
                                        new Date(seller.rent.createdAt),
                                        "dd 'de' MMMM 'de' yyyy",
                                        {
                                          locale: ptBR,
                                        },
                                      )}
                                    </p>
                                  </td>
                                  <td>
                                    <span className="price">
                                      R${' '}
                                      {
                                        seller.provider.plans_provider_value
                                          .value
                                      }
                                    </span>
                                  </td>
                                  <td>
                                    <button
                                      onClick={() => handleOpenModal(seller)}
                                      className="cancel-rent"
                                      type="button"
                                    >
                                      {/* <AiFillCloseCircle color="#3D3939"/> */}
                                      cancelar
                                    </button>
                                  </td>
                                </TrContainer>
                              </Fragment>
                            ))}
                        </tbody>
                      </TableContainer>
                    </TabItem>
                  </>
                )}
              </TableContent>
            </Content>
          </Body>
        </MainContent>
      </Main>
    </Container>
  );
};

export default MyRents;
