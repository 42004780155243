import React, { ButtonHTMLAttributes } from 'react';
import BeatLoader from 'react-spinners/BeatLoader';

import { Container } from './styles';

interface IButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  label: string;
  block?: boolean;
  outline?: boolean;
  isLoading?: boolean;
}

const Buttom: React.FC<IButtonProps> = ({
  label,
  isLoading,
  block,
  outline,
  ...rest
}) => {
  return (
    <Container block={block} outline={outline}>
      <button type="submit" disabled={isLoading} {...rest}>
        {!isLoading ? label : <BeatLoader size={12} />}
      </button>
    </Container>
  );
};

export default Buttom;
