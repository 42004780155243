import React from 'react';

import { Container, Content } from './styles';

import Sidebar from '../Sidebar';

const SellerTemplate: React.FC = ({ children }) => {
  return (
    <Container className="fadeIn">
      <Content>
        <Sidebar />
        {children}
      </Content>
    </Container>
  );
};

export default SellerTemplate;
