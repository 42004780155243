import styled from 'styled-components';

export const Container = styled.div`
  padding: 20px 18px;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;

  div {
    width: 100%;
  }

  div + div {
    margin-left: 16px;
  }

  textarea {
    height: 188px;
  }

  &.fix-mt {
    > div {
      margin-top: 18px;
    }
  }
`;

export const Bottom = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  margin-top: 24px;

  div + div {
    margin-left: 18px;
  }
`;
