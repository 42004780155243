import styled from 'styled-components';
import { shade, lighten } from 'polished';

interface IPay {
  payAvailable: boolean;
}

interface IPropsCancel {
  cancel: boolean;
}

export const Container = styled.div`
  display: flex;
  width: 100%;
  & > main {
    width: 100%;
  }
  & > aside {
    width: 300px;
  }
  .descriptionService {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    -webkit-box-orient: vertical;
  }
  .warnPriceService {
    font-weight: 500;
    font-size: 16px;
    strong {
      font-weight: 700;
      color: var(--danger);
    }
  }
`;

export const Main = styled.main`
  display: flex;
  flex: 1;
  height: 100vh;
  overflow-y: auto;
`;

export const MainContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  button.continues {
    border: 1px solid #efc975;
    background: transparent;
    border-radius: 5px;
    color: #3d3939;
    font-size: 16px;
    font-family: 'Source Sans Pro';
    font-weight: 800;
    padding: 14px 15px;
    width: 220px;
    margin-bottom: 35px;
    transition: all 0.5s;
    &:hover {
      background: ${shade(0.05, '#EFC975')};
    }
  }
`;

export const Body = styled.div`
  width: 100%;
  max-width: 812px;
  margin: 0px auto 18px auto;
  h1 {
    font-size: 2.25rem;
    margin-bottom: 65px;
  }
  div.container {
    position: relative;
    width: 100%;
    margin-top: 24px;
    div.empty {
      position: absolute;
      right: 0px;
    }
  }
`;

export const CardContainer = styled.div`
  display: flex;
  margin-top: 15px;
  align-items: center;
  border: 1px solid #e0dede;
  border-radius: 5px;
  background: #ffffff;
  padding: 28px 37px 27px 36px;

  img {
    border-radius: 50%;
    width: 75px;
    height: 75px;
    object-fit: cover;
  }
  h2 {
    font: 'Source Sans Pro';
    font-weight: 700;
    color: #3d3939;
    font-size: 20px;
  }
  span {
    color: #8d8585;
  }
  section {
    margin-top: 25px;
    display: flex;
    align-items: center;
    div {
      display: flex;
      align-items: center;
      margin-right: 150px;
      p {
        display: flex;
        flex-direction: row;
        font-size: 14px;
        align-items: center;
        color: #f6c866;
        margin-right: 19px;
        strong {
          font-weight: 700;
          color: #f6c866;
          margin-left: 5px;
          margin-right: 3px;
        }
      }
      button {
        background: #cc593e;
        color: #ffffff;
        border-radius: 5px;
        padding: 4px;
        width: 160px;
        height: 35px;
        border: none;
        font: 'Source Sans Pro';
        font-weight: 500;
        font-size: 14px;
        align-self: center;
        justify-content: center;
        &:hover {
          background: ${shade(0.2, '#CC593E')};
        }
        svg {
          margin-right: 4px;
        }
      }
    }
    p {
      color: #3d3939;
      font-family: 'Source Sans Pro';
      font-size: 20px;
      font-weight: 800;
      span {
        font-size: 12px;
        color: #8d8585;
      }
    }
  }
`;

export const ContentContainer = styled.div`
  margin-left: 47px;
`;

export const RightSide = styled.aside`
  height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
  padding-top: 20px;
  background: #ffffff;
  border-left: 1px solid #e7e5e5;
  h1 {
    font-family: 'Source Sans Pro';
    font-weight: 700;
    font-size: 30px;
    margin: 84px 29px;
  }
  section.pay {
    display: flex;
    justify-content: center;
  }
  > p {
    color: #8d8585;
    margin: 13px 42px;
    font-size: 15px;
    text-align: center;
  }
`;

export const CheckoutContent = styled.div`
  display: flex;
  border: 1px solid #e0dede;
  margin-left: 26px;
  margin-right: 26px;
  border-left: none;
  border-right: none;
  padding: 12px 0;
  p {
    font-family: 'Source Sans Pro';
    font-size: 16px;
    color: #3d3939;
    flex: 1;
    font-weight: 600;
  }
  span {
    font-family: 'Source Sans Pro';
    font-size: 16px;
    color: #8d8585;
  }
`;

export const Total = styled.div`
  display: flex;
  align-items: center;
  font-family: 'Source Sans Pro';
  color: #f6c866;
  font-size: 20px;
  margin-bottom: 60px;
  h3 {
    flex: 1;
    margin: 9px 26px;
    font-weight: 700;
  }
  span {
    font-weight: 700;
    margin-right: 26px;
  }
`;

export const Titles = styled.div`
  margin-left: 26px;
  h2 {
    color: #3d3939;
    font-family: 'Source Sans Pro';
    font-weight: 600;
    font-size: 24px;
  }
  h3 {
    color: #8d8585;
    font-size: 14px;
  }
`;

export const FormRadio = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 25px;
  margin-left: 25px;
  section {
    display: flex;
    align-items: center;
    border: none;
    margin-bottom: 15px;
    input {
      cursor: pointer;
      width: 19px;
      height: 19px;
      margin-right: 10px;
    }
    label {
      cursor: pointer;
      font-family: 'Source Sans Pro';
      color: #8d8585;
      font-size: 16px;
      font-weight: 550;
    }
  }
`;

export const ButtonPay = styled.button<IPay>`
  border: none;
  background: ${props =>
    props.payAvailable ? '#F6C866' : lighten(0.15, '#F6C866')};
  color: ${props =>
    props.payAvailable ? '#3D3939' : lighten(0.15, '#3D3939')};
  border-radius: 5px;
  padding: 14px 51px;
  font-family: 'Source Sans Pro';
  font-weight: 800;

  &:hover {
    background: ${props => (props.payAvailable ? shade(0.1, '#F6C866') : '')};
    color: ${props => (props.payAvailable ? shade(0.1, '#3D3939') : '')};
    cursor: ${props => (props.payAvailable ? 'pointer' : 'inherit')};
  }
`;

export const EmptyCart = styled.div`
  display: flex;
  padding: 20px;
  align-self: center;
  align-items: center;
  border: 1px solid #e0dede;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  border-radius: 5px;
  background: #ffff;
  > h3.emptyCartMsg {
    margin: 10px;
    font-family: 'Source Sans Pro';
    color: #8d8585;
    font-weight: 800;
  }
  p {
    font-family: 'Source Sans Pro';
    color: #8d8585;
    font-weight: 500;
    font-size: 14px;
  }
`;

export const HeaderTitle = styled.div`
  ul {
    display: flex;
    margin-bottom: 10px;
    a {
      font-weight: normal;
      color: var(--tertiary);
      -webkit-text-decoration: none;
      text-decoration: none;
      display: inline-block;
      font-size: 16px;
      font-family: 'Source sans pro';
      &::after {
        content: '>';
        margin: 0px 10px;
        display: inline-block;
        font-size: 0.75rem;
      }
    }
    li {
      text-decoration: underline;
      font-weight: bold;
      color: var(--aurea);
      list-style: none;
    }
  }
`;

export const CheckoutContainerEmpty = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 84px;
  p {
    margin-top: 10px;
    font-style: 'Source Sans Pro';
    color: #8d8585;
  }
`;

export const ModalContent = styled.div<IPropsCancel>`
  h1.modalTitle {
    margin-top: 33px;
    text-align: center;
    color: #3d3939;
    font-size: 22px;
    font-family: 'Source Sans Pro';
    font-weight: 700;
    margin-bottom: 40px;
  }
  section {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 25px;
    button {
      width: 90px;
      height: 50px;
      margin-left: 20px;
      color: #ffff;
      font-family: 'Source Sans Pro';
      font-weight: 700;
      background: #6bc196;
      border: 1px solid transparent;
      border-radius: 5px;
      transition: all 0.2s;
      &:hover {
        background: ${shade(0.1, '#6BC196')};
      }
    }
    button.cancel {
      background: #cc593e;
      &:hover {
        background: ${shade(0.1, '#CC593E')};
      }
    }
  }
`;
