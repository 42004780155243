import { createGlobalStyle, keyframes } from 'styled-components';

export const fadeIn = keyframes`
  from {
    filter: opacity(0);
  }
  to {
    filter: opacity(1);
  }
`;

export const slideDown = keyframes`
  from {
    opacity: 0;
    transform: translate(0, -10px);
  }
  to {
    opacity: 1;
    transform: translate(0, 0px);
  }
`;

export const slideUp = keyframes`
  from {
    opacity: 0;
    transform: translate(0, 10px);
  }
  to {
    opacity: 1;
    transform: translate(0, 0px);
  }
`;

export const slideLeft = keyframes`
  from {
    opacity: 0;
    transform: translate(10px, 0);
  }
  to {
    opacity: 1;
    transform: translate(0px, 0);
  }
`;

export const slideRight = keyframes`
  from {
    opacity: 0;
    transform: translate(-10px, 0);
  }
  to {
    opacity: 1;
    transform: translate(0px, 0);
  }
`;

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: 0;
  }

  body {
    background: var(--white);
    color: var(--dark);
    -webkit-font-smoothing: antialiased;
    /* width: 100vw; */
    /* height: 100vh; */
    overflow: hidden;
  }

  body, input, select, button, textarea {
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 16px;
  }
  input {
    &::placeholder {
      font-family: 'Source Sans Pro', sans-serif;
    }
  }

  h1{
    font-weight: 700;
  }

  h2, h3, h4, h5, h6, strong {
    font-weight: 500;
  }

  button {
    cursor: pointer;
  }

  a{
    text-decoration: none;
  }

  a:hover{
    text-decoration: underline;
  }

  /* Desfoque na aparição do modal */
  .ReactModal__Body--open .blur {
    filter: blur(2px);
  }

  .ReactModal__Overlay {
    z-index: 9999;
  }

  .width-all .body {
    max-width: 95%;
  }

  :root {
    --primary: #F6C866;
    --secondary: #2f3136;
    --tertiary: #3D3939;
    --quaternary: #E0DEDE;
    --quinary: #393d42;
    --senary: #828386;

    --placeholder: #bbb9b9;
    --hover: #f3b431;

    --success: #6BC196;
    --success-opcaity: #D5EBE0;

    --danger: #CC593E;
    --danger-opacity: #EFCEC7;
    --warning: #DE8A47;
    --warning-opacity: #F4DCCA;
    --dark: #3D3939;
    --dark-soft: #707070;

    --white: #FCFAFA;
    --gray: #8D8585;
    --gray-border: #E0DEDE;
    --gray-opacity: #C6C2C2;

    --hover-menu: #efe9e9;
    --active-menu: #F9F5F5;

    --aurea: #F6C866;

    --link: #F6C866;
    --error: #CC593E;

    --bg-dark: #2C3038;
    --theme-text-color: #2C3038;
    --theme-border: #E1E2EC;
    --theme-color:#687184;
    --theme-blue: #1692D9;
    --theme-red: #EA6A58;
    --theme-bg-gray: #F1F2F6;
    --theme-green: #37D68C;

    --item-tag-body: #FDF6E7;
    --item-tag-letter: #F6C866;

    --animate: 0.4s;
  }

  .global-loading {
    text-align: center;
    padding: 20px;

    &.hidden {
      display: none;
    }
  }

  // ANIMATION =====
  .fadeIn {
    animation: ${fadeIn} var(--animate) linear 1;
  }
  .slideDown {
    animation: ${slideDown} var(--animate) linear 1;
  }
  .slideUp {
    animation: ${slideUp} var(--animate) linear 1;
  }
  .slideLeft {
    animation: ${slideLeft} var(--animate) linear 1;
  }
  .slideRight {
    animation: ${slideRight} var(--animate) linear 1;
  }

  // TINYMCE =====
  div .ReactModal__Overlay{
    z-index: auto;
  }
  .ReactModal__Content{
    max-height: 90%;
  }
  //Pagination
  .pagination {
  display: flex;
  justify-content: space-around;
  list-style: none;
  cursor: pointer;
  width: 100%;
  margin: 50px 0px;
}

.pagination__dashboard{
  display: flex;
  justify-content: space-around;
  list-style: none;
  cursor: pointer;
  width: 100%;
  margin: 50px 0px;

  display: flex;
  justify-content: space-between;
  list-style: none;
  cursor: pointer;
  width: 100%;
  margin: 30px 5px;
}
.pagination a, .pagination__dashboard a {
  padding: 10px;
  border-radius: 5px;
  border: 1px solid var(--aurea);
  color: var(--aurea);
}

.pagination__page{
  display: none;
}
.pagination__break{
  display: none;
}


.pagination__link {
  font-weight: bold;
}
.pagination__link--active a {
  color: #fff;
  background: var(--aurea);
}
.pagination__link--disabled a {
  color: rgb(198, 197, 202);
  border: 1px solid rgb(198, 197, 202);
}

/* MATHTYPE */
.wrs_modal_dialogContainer.wrs_modal_desktop.wrs_stack {
      display: none;
    }
`;
