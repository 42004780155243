/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { useRef, useCallback, useState } from 'react';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

import { Link } from 'react-router-dom';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import {
  Container,
  Content,
  ContentForm,
  BottomForm,
  ContentBottom,
} from './styles';

import { IUser } from '../../services/auth';
import {
  postCreateTermAcceptance,
  getSellerDataById,
} from '../../services/api';

import ModalTerms from '../../components/template/ModalTerms';

import LogoYellow from '../../assets/images/logo-new-yellow.svg';
import Input from '../../components/forms/Input';
import Buttom from '../../components/forms/Buttom';
import BackgroundSign from '../../components/template/BackgroundSign';
import { useToast } from '../../hooks/toast';
import getValidationErrors from '../../utils/getVaidationErrors';
import { useAuth } from '../../hooks/auth';

const MySwal = withReactContent(Swal);

const SignIn: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const formRef = useRef<FormHandles>(null);
  const { addToast } = useToast();
  const { signIn } = useAuth();

  const handleSubmit = useCallback(
    async data => {
      try {
        setLoading(true);
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          email: Yup.string()
            .required('Campo Obrigatório')
            .email('Email inválido'),
          password: Yup.string().required('Campo Obrigatório'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });
        const user: IUser = await signIn(data);

        addToast({
          title: 'Sucesso!',
          type: 'success',
          description: 'Login realizado com sucesso!',
        });

        if (user.seller?.id) {
          const { data: seller } = await getSellerDataById(user.seller.id);
          if (user.type === 'seller' && !seller.accepted_terms) {
            setTimeout(() => {
              MySwal.fire({
                title: '',
                showDenyButton: true,

                showConfirmButton: true,
                denyButtonText: 'Sair',
                confirmButtonText: 'Aceito os Termos',
                allowOutsideClick: false,
                html: <ModalTerms isOpen />,
                showLoaderOnConfirm: true,
                preConfirm: confirmation => {
                  const checkbox = document.getElementById(
                    'acceptCheckbox',
                  ) as HTMLInputElement | null;

                  if (checkbox && checkbox.checked) {
                    return checkbox;
                  }
                  Swal.showValidationMessage(
                    'Clique no botão acima para confirmar',
                  );
                },
              }).then(async result => {
                if (result.isConfirmed) {
                  const createdAccept = await postCreateTermAcceptance(
                    seller.id,
                  );
                  if (createdAccept) {
                    addToast({
                      title: 'Sucesso!',
                      type: 'success',
                      description: 'Os termos foram aceitos!',
                    });
                    return;
                  }
                  addToast({
                    title: 'Erro interno!',
                    type: 'error',
                    description: 'Entre em contato com o suporte',
                  });
                }
              });
            }, 1000);
          }
        }
        // console.log(user);
        // ----- structure for notice
        // setTimeout(() => {
        //   MySwal.fire({
        //     title: '',
        //     html: (
        //       <div>
        //         <h2 style={{ backgroundColor: '#f6c866' }}>
        //           COMUNICADO IMPORTANTE
        //         </h2>
        //         <br />
        //         <div style={{ textAlign: 'justify' }}>
        //           Prezados(as),
        //           <br />
        //           <br />        //
        //           <p>
        //             msg
        //           </p>        //
        //           <br />
        //         </div>
        //         <p>Qualquer dúvida, estamos à disposição.</p>
        //         <br />
        //         <img src={LogoYellow} alt="Logo" width={164} />
        //       </div>
        //     ),
        //   });
        // }, 1400);
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);
          formRef.current?.setErrors(errors);
          return;
        }
        addToast({
          title: 'Opss ..',
          type: 'error',
          description:
            'Ocorreu um erro ao fazer login, verifique suas credencias.',
        });
      } finally {
        setLoading(false);
      }
    },
    [signIn, addToast],
  );

  return (
    <Container>
      <BackgroundSign />
      <Content className="slideDown">
        <ContentForm>
          <h1>Bem-vindo novamente!</h1>
          <Form onSubmit={handleSubmit} ref={formRef}>
            <Input
              name="email"
              label="Seu Email:"
              type="text"
              placeholder="email@dominio.com.br"
            />

            <Input
              name="password"
              label="Sua Senha:"
              type="password"
              placeholder="*************"
            />

            <BottomForm>
              <Link to="/forgot-password">Esqueci a senha</Link>
              <Buttom isLoading={loading} label="Entrar" />
            </BottomForm>
          </Form>
        </ContentForm>
        {/* <ContentBottom>
          Não tem uma conta? {'  '}
          <Link to="/signup/seller">Cadastre-se</Link>
        </ContentBottom> */}
      </Content>
    </Container>
  );
};

export default SignIn;
