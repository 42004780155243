import {
  IconBookOpen,
  IconComputer,
  IconUsers,
  IconSetting,
} from '../styles/icons';

export enum PROVIDER_TYPES_ENUM {
  liberal = 'Profissional Liberal',
  instituicao = 'Instituição de Ensino',
  Fornecedor = 'Fornecedor',
}

export const subTypeProvider = [
  {
    id: 'Profissional Liberal',
    label: 'Profissional Liberal',
    icon: IconUsers,
  },
  {
    id: 'Instituição de Ensino',
    label: 'Instituição de Ensino',
    icon: IconBookOpen,
  },
  {
    id: 'Fornecedor',
    label: 'Fornecedor de conteúdo',
    icon: IconComputer,
  },
  {
    id: 'Fornecedor de Servico',
    label: 'Fornecedor de Serviço',
    icon: IconSetting,
  },
];
