import styled from 'styled-components';

export const Holder = styled.div`
  display: flex;
  width: 100%;
  & > main {
    width: 100%;
  }
  & > aside {
    width: 300px;
  }
`;

export const Container = styled.div``;

export const Content = styled.div`
  section {
    display: flex;
    flex-wrap: wrap;
    padding-top: 20px;
    article {
      width: calc(33.3333333% - 15px);
      background: #ffffff;
      border: 1px solid var(--quaternary);
      border-radius: 5px;
      margin: 7.5px;
      & > * {
        padding: 15px;
      }
      header {
        text-align: center;
        img {
          width: 74px;
          height: 74px;
          border-radius: 50%;
          object-fit: cover;
          margin-bottom: 15px;
        }
        h1 {
          font-size: 1rem;
        }
        p {
          font-size: 0.875rem;
          color: var(--gray);
          &.units {
            font-size: 0.75rem;
            color: var(--aurea);
            strong {
              font-weight: bold;
            }
          }
        }
      }
      main {
        border-top: 1px solid var(--gray-border);
        padding-top: 20px;
        display: flex;
        justify-content: space-between;
        p {
          color: var(--gray);
          strong {
            font-weight: bold;
          }
          span {
            font-size: 0.625rem;
          }
        }
        a {
          border: 1px solid var(--gray-border);
          padding: 5px 15px;
          border-radius: 5px;
          font-size: 0.75rem;
          font-weight: bold;
          color: var(--dark);
          transition: background 400ms;
          &:hover {
            text-decoration: none;
            background: var(--gray-border);
          }
        }
      }
      footer {
        a {
          text-align: center;
          display: block;
          width: 100%;
          padding: 5px 10px;
          border: none;
          color: var(--dark);
          background: var(--gray-opacity);
          border-radius: 5px;
          font-weight: bold;
          transition: background 400ms;
          &:hover {
            text-decoration: none;
            background: var(--gray-border);
          }
        }
        button {
          display: block;
          width: 100%;
          padding: 5px 10px;
          border: none;
          color: var(--dark);
          background: var(--aurea);
          border-radius: 5px;
          font-weight: bold;
          transition: background 400ms;
          &:hover {
            text-decoration: none;
            background: var(--hover);
          }
        }
        a,
        button {
          &.rented {
            opacity: 0.5;
            background: var(--white);
            border: 1px solid var(--gray-border);
            transition: opacity 0.3s;
            text-align: center;
            &:hover {
              opacity: 1;
            }
          }
        }
      }
    }
    .loading {
      width: 100%;
      padding: 20px;
      text-align: center;
      & > div {
        display: inline-block;
        opacity: 0.5;
      }
    }
  }
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  h1 {
    margin-right: 24px;
  }
`;

export const RightSide = styled.aside`
  height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
  padding-top: 20px;
  background: #ffffff;
  border-left: 1px solid #e7e5e5;
  & > div {
    padding: 25px;
    & + div {
      border-top: 1px solid #e0dede;
    }
    h2 {
      font-size: 1.875rem;
      font-weight: bold;
      margin-bottom: 10px;
    }
    h3 {
      font-weight: bold;
      color: var(--gray);
    }
    .checkboxContainer {
      padding: 20px 5px;
      .checkboxContent {
        display: block;
        position: relative;
        color: var(--gray);
        cursor: pointer;
        padding-left: 35px;
        input {
          position: absolute;
          opacity: 0;
          cursor: pointer;
          height: 0;
          width: 0;
        }
        .checkmark {
          position: absolute;
          top: 0;
          left: 0;
          height: 20px;
          width: 20px;
          background-color: #ffffff;
          border-radius: 3px;
          border: 2px solid #c6c2c2;
          box-sizing: border-box;
          transition: all 100ms;
          &:after {
            content: '';
            position: absolute;
            display: none;

            left: 6px;
            top: 2px;
            width: 5px;
            height: 10px;
            border: solid white;
            border-width: 0 3px 3px 0;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
          }
        }
        &:hover input ~ .checkmark {
          background-color: #c6c2c2;
        }
        & input:checked ~ .checkmark {
          border: 0;
          background-color: var(--aurea);
          &:after {
            display: block;
          }
        }
      }
      label + label {
        margin-top: 10px;
      }
    }
    .input-range {
      margin-bottom: 20px;
      margin-top: 30px;
      .input-range__track--active {
        background: var(--aurea);
      }
      .input-range__slider {
        background: #ffffff;
        border: 1px solid var(--quaternary);
      }
      .input-range__label-container {
        left: 0;
      }
      .input-range__label--min,
      .input-range__label--max {
        display: none;
      }
      .input-range__track {
        & > .input-range__slider-container + .input-range__slider-container {
          .input-range__label-container {
            left: inherit;
            right: 0;
            top: 40px;
            position: absolute;
          }
        }
      }
    }
  }
  button {
    background: none;
    border: none;
    width: 100%;
    text-decoration: underline;
    color: var(--gray);
    margin-bottom: 50px;
  }
`;
