import React from 'react';

import { IconSchoolGrey } from '../../../styles/icons';
import { ContainerCircle } from './styles';

interface IProps {
  percent: number;
}

const Circle: React.FC<IProps> = ({ percent }) => {
  return (
    <ContainerCircle>
      <svg
        width="150"
        height="150"
        viewBox="0 0 40 40"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          stroke="#efefef"
          strokeWidth="3"
          fill="none"
          cx="20"
          cy="20"
          r="16"
        />
        <circle
          stroke="#00acc1"
          strokeWidth="3"
          strokeDasharray={`${percent},100`}
          strokeLinecap="round"
          fill="none"
          cx="20"
          cy="20"
          r="16"
        />
      </svg>
      <IconSchoolGrey />
    </ContainerCircle>
  );
};

export { Circle };
