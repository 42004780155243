/* eslint-disable jsx-a11y/media-has-caption */
import React from 'react';
import { Container, Title } from './styles';
import KeyGeneratorVideo from '../../assets/images/aurea-key-generator.mp4';

const Docs: React.FC = () => {
  return (
    <>
      <Title>Como gerar sua chave LMS</Title>
      <Container>
        <video controls autoPlay loop>
          <source src={KeyGeneratorVideo} type="video/mp4" />
        </video>
      </Container>
    </>
  );
};

export default Docs;
