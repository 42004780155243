import React from 'react';

import { Switch, Route, Redirect } from 'react-router-dom';

import SignIn from '../pages/SignIn';
import SignUp from '../pages/SignUp';
import Confirmation from '../pages/Confirmation';
import SignUpSeller from '../pages/SignUp/seller';
import ForgotPassword from '../pages/ForgotPassword';
import ResetPassword from '../pages/ResetPassword';
import SellerLmsKey from '../pages/SellerLmsKey';
import Docs from '../pages/Docs';

const AppRoutes: React.FC = () => (
  <Switch>
    <Route path="/" exact component={SignIn} />
    <Route path="/signup" exact component={SignUp} />
    <Route path="/signup/seller" exact component={SignUpSeller} />
    <Route path="/forgot-password" exact component={ForgotPassword} />
    <Route path="/reset-password" exact component={ResetPassword} />
    <Route path="/confirm-email" exact component={Confirmation} />
    <Route path="/lms-key" exact component={SellerLmsKey} />
    <Route path="/docs/lms/key_generator" exact component={Docs} />
    <Redirect from="*" to="/" />
  </Switch>
);

export default AppRoutes;
