/* eslint-disable array-callback-return */
/* eslint-disable react/button-has-type */
/* eslint-disable react/jsx-no-bind */
import React, { useCallback, useEffect, useState } from 'react';
import { FiFilter, FiDownloadCloud } from 'react-icons/fi';
import { BeatLoader } from 'react-spinners';
import ReactPaginate from 'react-paginate';
import { CSVLink } from 'react-csv';

import { generateId } from '../../../utils/generateId';
import {
  getLevelOfLearning,
  getKnowledge,
  getAllContentsByProviderId,
} from '../../../services/api';

import { Container, Filters, FiltersWrapper, BtnDownload } from './styles';
import { useAuth } from '../../../hooks/auth';
import { useToast } from '../../../hooks/toast';
import Input from '../../../components/forms/Input';
import Select from '../../../components/forms/SelectForm';
import {
  Content,
  TableContent,
} from '../../../components/template/TemplateManager/styles';

interface IOptionsProps {
  label: string;
  value: string | number;
  status?: string;
}

interface IKnowledge {
  label: string;
  type: string;
  value: string | number;
}

interface IKnowledgeFinded {
  title: string;
}

interface IItemContents {
  id: string;
  title: string;
  knowledge: IKnowledgeFinded;
  levelOfLearning: string[];
  updatedMessage: string;
  status: string;
  created_at: string;
  updated_at: string;
}

const UnitReport: React.FC = () => {
  const { user } = useAuth();
  const { addToast } = useToast();
  const [dataSource, setDataSource] = useState<IItemContents[]>([]);
  const [allLevelsOfLearning, setallLevelOfLearning] = useState<
    IOptionsProps[]
  >([]);
  const [loading, setLoading] = useState(false);
  const [allKnowledge, setallKnowledge] = useState<IKnowledge[]>([]);
  const [currentPage, setCurrentPage] = useState(0);

  const itemsPerPage = 20;
  const offset = currentPage * itemsPerPage;
  const currentPageData = dataSource

    .slice(offset, offset + itemsPerPage)
    .map(item => {
      const formatedDate = new Date(item.updated_at).toLocaleDateString(
        'pt-BR',
      );
      if (item.status === 'publish') {
        return (
          <React.Fragment key={item.id}>
            <tr key={generateId()}>
              <td>{item.title}</td>
              <td>{item.knowledge.title}</td>
              <td>{item.levelOfLearning[0]}</td>
              <td>{formatedDate}</td>
            </tr>
          </React.Fragment>
        );
      }
    });

  const pageCount = Math.ceil(dataSource.length / itemsPerPage);

  function handlePageClick({ selected: selectedPage }): void {
    setCurrentPage(selectedPage);
  }

  const renderItems = useCallback(() => {
    return (
      <>
        <BtnDownload>
          <button>
            <CSVLink
              data={dataSource.map(
                ({ title, knowledge, levelOfLearning, updated_at }) => ({
                  UNIDADE: title,
                  ÁREA_DE_CONHECIMENTO: knowledge.title,
                  NÍVEL_DE_APRENDIZADO: levelOfLearning[0],
                  PUBLICADA_EM: updated_at,
                }),
              )}
              filename="Relatório-de-postagem.csv"
            >
              <div>
                <FiDownloadCloud /> Exportar Relatório
              </div>
            </CSVLink>
          </button>
        </BtnDownload>

        <table>
          <thead>
            <tr className="no-cursor no-hover">
              <th>Unidade</th>
              <th>Área de Conhecimento</th>
              <th>Nível de Aprendizado</th>
              <th>Publicada em</th>
            </tr>
          </thead>
          <tbody>
            {dataSource.length <= 0 && (
              <tr>
                <td colSpan={3}>
                  <p style={{ textAlign: 'center' }}>
                    Nenhum item encontrado{' '}
                    <span role="img" aria-label="wow">
                      😮
                    </span>
                  </p>
                </td>
              </tr>
            )}
            {currentPageData}
          </tbody>
        </table>

        <ReactPaginate
          previousLabel="← Anterior"
          nextLabel="Próximo →"
          pageCount={pageCount}
          onPageChange={handlePageClick}
          containerClassName="pagination"
          previousLinkClassName="pagination__link"
          nextLinkClassName="pagination__link"
          disabledClassName="pagination__link--disabled"
          activeClassName="pagination__link--active"
        />
      </>
    );
  }, [dataSource, pageCount, currentPageData]);

  async function handleSubmitForm(dataForm): Promise<void> {
    try {
      if (user.provider) {
        setLoading(true);
        dataForm.providerId = user.provider.id;
        const { data } = await getAllContentsByProviderId(dataForm);

        setDataSource([...data]);
        setLoading(false);
      }
    } catch (error) {
      addToast({
        type: 'error',
        title: 'Atenção!',
        description:
          'Não foi possível realizar esta operação, tente novamente.',
      });
    }
  }

  useEffect(() => {
    (async () => {
      try {
        const Knowledge = await getKnowledge();
        setallKnowledge(Knowledge);
      } catch (error) {
        addToast({
          type: 'error',
          title: 'Atenção!',
          description:
            'Não foi possível realizar esta operação, tente novamente.',
        });
      }

      try {
        const levelsOfLearning = await getLevelOfLearning();
        setallLevelOfLearning(levelsOfLearning);
      } catch (error) {
        addToast({
          type: 'error',
          title: 'Atenção!',
          description:
            'Não foi possível realizar esta operação, tente novamente.',
        });
      } finally {
        setLoading(false);
      }
    })();
  }, [addToast, user.provider]);

  return (
    <Container>
      <header>
        <h1>Relatório de Unidades Publicadas</h1>
        <span>{user.name}</span>
      </header>

      <Filters>
        <FiltersWrapper onSubmit={handleSubmitForm}>
          <button type="submit">
            <FiFilter />
            Filtrar
          </button>

          <Input label="Data Inicial*" required name="startDate" type="date" />
          <Input label="Data Final*" required name="endDate" type="date" />

          <Select
            name="levelOfLearning"
            placeholder="Nível de aprendizado"
            options={allLevelsOfLearning}
          />

          <Select
            name="knowledge"
            placeholder="Área de conhecimento"
            options={allKnowledge}
          />
        </FiltersWrapper>
      </Filters>

      <Content>
        <TableContent>
          {loading ? (
            <div className="loading">
              <BeatLoader size={16} />
            </div>
          ) : (
            <div className={`${loading ? `hidden` : ``} slideUp`}>
              {renderItems()}
            </div>
          )}
        </TableContent>
      </Content>
    </Container>
  );
};

export { UnitReport };
