import React, { useCallback } from 'react';
import Buttom from '../../components/forms/Buttom';
import AlertConfirm from '../../components/template/AlertConfirm';
import { useAuth } from '../../hooks/auth';
import { useToast } from '../../hooks/toast';
import { sendEmailLmsKey } from '../../services/api';
import { saveGenericLog } from '../../services/logs';
import { Bottom, Card, Container } from './styles';

const Settings: React.FC = () => {
  const { user } = useAuth();
  const { addToast } = useToast();

  const handleLmsKey = useCallback(async () => {
    try {
      await sendEmailLmsKey();

      await AlertConfirm({
        title:
          'Foi enviado uma mensagem para seu email contendo todas as instruções de como gerar a chave de LMS',
        text: 'Se não estiver achando na sua caixa de mensagem, verifique sua caixa de spam',
        type: 'lms',
      });
    } catch (error) {
      addToast({
        title: 'Opss ..',
        type: 'error',
        description: 'Ocorreu um erro ao editar o seu perfil.',
      });
    }
  }, [addToast]);

  return (
    <Container>
      <h1>Configurações</h1>
      <Card>
        <br />
        <h3>Chave LMS</h3>
        <Bottom>
          {user.seller ? (
            <Buttom
              onClick={() => {
                handleLmsKey();
                // saveGenericLog({
                //   userId: user.id,
                //   action: 'generated_lms_key',
                // });
                if (user.seller?.id) {
                  saveGenericLog({
                    userId: user.id,
                    sellerId: user.seller.id,
                    action: 'generated_lms_key',
                  });
                } else {
                  saveGenericLog({
                    userId: user.id,
                    action: 'generated_lms_key',
                  });
                }
              }}
              type="button"
              label="Gerar chave LMS"
            />
          ) : undefined}
        </Bottom>
      </Card>
    </Container>
  );
};

export default Settings;
