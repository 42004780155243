import React, { useEffect } from 'react';

import {
  IconClose,
  IconCloseCircle,
  IconCheckCircle,
} from '../../../../styles/icons';

import { IToastMessage, useToast } from '../../../../hooks/toast';
import { Container } from './styles';

interface IToastMessageProps {
  message: IToastMessage;
  style: object;
}

const icons = {
  success: <IconCheckCircle />,
  error: <IconCloseCircle />,
};

const Toast: React.FC<IToastMessageProps> = ({ message, style }) => {
  const { removeToast } = useToast();

  useEffect(() => {
    const timer = setTimeout(() => {
      removeToast(message.id);
    }, 5000);

    return () => {
      clearTimeout(timer);
    };
  });

  return (
    <Container
      hasDescription={!!message.description || false}
      type={message.type}
      style={style}
    >
      {icons && icons[message.type || 'success']}
      <div>
        <strong>{message.title}</strong>
        {message.description && <p>{message.description}</p>}
      </div>

      <button type="button" onClick={() => removeToast(message.id)}>
        <IconClose />
      </button>
    </Container>
  );
};

export default Toast;
