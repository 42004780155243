import React, { useCallback, useMemo, useRef } from 'react';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import { RawEditorSettings } from 'tinymce';
import Input from '../../forms/Input';
import Button from '../../forms/Buttom';

import { ContentPage } from './styles';
import { IEditResource, IPages } from '.';

interface IProps {
  handlePage(page: IPages): void;
  propsEditor: RawEditorSettings;
  setIsOpen: () => void;
  editResource: IEditResource;
}

const FormVideoAula: React.FC<IProps> = ({
  handlePage,
  propsEditor,
  setIsOpen,
  editResource,
}) => {
  const formRef = useRef<FormHandles>(null);

  const isEdit = useMemo(() => {
    return Object.keys(editResource).length > 0;
  }, [editResource]);

  const handleSubmit = useCallback(
    async data => {
      let linkSrc = '';
      if (data.link.includes('iframe')) {
        const newLink = data.link.split(' ');
        linkSrc = newLink.find((item: string) => item.includes('src="'));
        linkSrc = linkSrc.replace('src="', '').replace('"', '');
      } else if (data.link.includes('youtube') && data.link.includes('?v=')) {
        // https://www.youtube.com/watch?v=sqs5lyQxpmA
        // https://www.youtube.com/embed/sqs5lyQxpmA
        const idVideoYoutube = data.link.split('?v=');
        linkSrc = `https://www.youtube.com/embed/${idVideoYoutube[1]}`;
      } else {
        const newVimeo = data.link.split('/');
        linkSrc = `https://player.vimeo.com/video/${newVimeo[3]}?h=${newVimeo[4]}`;
      }

      if (isEdit) {
        // editResource.element.remove();
        editResource.element.setAttribute('data-resource', linkSrc);
        propsEditor.insertContent(propsEditor.selection.getContent());
      } else {
        propsEditor.insertContent(
          `<div class="resource" data-interaction="true" data-type="media" data-subtype="videoaula" data-resource="${linkSrc}">
                Videoaula
              </div>\n
              <p>&nbsp;</p>`,
        );
      }
      propsEditor.focus();
      setIsOpen();
    },
    [propsEditor, setIsOpen, isEdit, editResource.element],
  );
  return (
    <Form
      ref={formRef}
      onSubmit={handleSubmit}
      initialData={{ link: editResource.resource }}
    >
      <ContentPage>
        {!isEdit && (
          <button type="button" onClick={() => handlePage('main')}>
            Voltar
          </button>
        )}
        <div>
          <h2>Vídeoaula</h2>
          <Input
            label="Inserir uma videoaula"
            name="link"
            type="text"
            placeholder='https://youtube.com.br/video ou <iframe src="https://youtube.com.br/video" />'
          />
          <Input hidden label="" name="type" defaultValue="videoaula" />
          <Button label="Inserir" type="submit" />
        </div>
      </ContentPage>
    </Form>
  );
};

export default FormVideoAula;
