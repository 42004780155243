import React, { useCallback, useEffect, useRef, useState } from 'react';
import { BeatLoader } from 'react-spinners';
import { IconUniversity } from '../../../styles/icons';

import { Container, CheckboxItem } from './styles';

interface ICheckboxRadio {
  itens: {
    id: string;
    label: string;
  }[];
  defaultValue?: Array<string>;
  handleCheckbox(checkboxArray: Array<string>): void;
}

const CheckboxRadio: React.FC<ICheckboxRadio> = ({
  itens,
  handleCheckbox,
  defaultValue,
}) => {
  const firstLoad = useRef(false);

  const [checks, setChecks] = useState<Array<string>>(() => {
    if (defaultValue) {
      return defaultValue;
    }
    return [];
  });

  useEffect(() => {
    if (
      defaultValue &&
      defaultValue.filter(item => item !== '').length > 0 &&
      firstLoad.current === false
    ) {
      firstLoad.current = true;
      setChecks(defaultValue || []);
    }
  }, [defaultValue, itens]);

  useEffect(() => {
    handleCheckbox(checks);
  }, [checks, handleCheckbox]);

  const handleCheck = useCallback(
    (value: string) => {
      const check = checks.find(item => item === value);
      if (!check) {
        setChecks([...checks, value]);
        return;
      }
      const newChecks = checks.filter(item => item !== value);
      setChecks(newChecks);
    },
    [checks],
  );

  return (
    <Container>
      <IconUniversity />
      <div>
        {itens.length<=0&&(<div style={{marginLeft:'10px',marginTop:'10px'}}>
          <BeatLoader size={12} />
        </div>)}
        {itens.map(item => (
          <CheckboxItem key={`checkbox-id-${item.id}`}>
            <input
              type="checkbox"
              name="radiobox"
              id={`checkbox-${item.id}`}
              defaultValue={item.id}
              checked={!!checks.find(check => check === item.id)}
              onChange={e => handleCheck(e.target.value)}
            />
            <label htmlFor={`checkbox-${item.id}`}>{item.label}</label>
          </CheckboxItem>
        ))}
      </div>
    </Container>
  );
};

export default CheckboxRadio;
