import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
`;

export const Main = styled.main`
  display: flex;
  flex: 1;
  height: 100vh;
  overflow-y: auto;
`;
export const MainContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const Body = styled.div`
  width: 100%;

  h1 {
    font-size: 2.25rem;
  }
`;
