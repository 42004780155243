/* eslint-disable react/require-default-props */
import React, { useCallback, useRef, useState } from 'react';
import * as Yup from 'yup';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';

import { useToast } from '../../../hooks/toast';
import Buttom from '../../forms/Buttom';
import Input from '../../forms/Input';
import RowGroup from '../../forms/RowGroup';
import Modal from '../Modal';
import { Container, Bottom, SelectContainer } from './styles';
import {
  createNewLtiConnection,
  deleteLtiConnection,
} from '../../../services/ltiConnectionService';

interface IModalProps {
  isOpen: boolean;
  setIsOpen: () => void;
  seller?: {
    seller?: any;
  };
}

interface IDataDeleteConnectionForm {
  ltiData: {
    url: string;
    clientId: string;
  };
}

const ModalLtiConnection: React.FC<IModalProps> = ({
  isOpen,
  setIsOpen,
  seller,
}) => {
  const { addToast } = useToast();
  const formRef = useRef<FormHandles>(null);
  const [plataform, setPlataform] = useState('');
  const [addConnection, setAddConnection] = useState(false);
  const [deleteConnection, setDeleteConnection] = useState(false);

  const handleSubmitAddConnection = useCallback(
    async (data: any) => {
      try {
        const schemaFirst = Yup.object().shape({
          ltiData: Yup.object().shape({
            url: Yup.string().required('Campo Obrigatório'),
            clientId: Yup.string().required('Campo Obrigatório'),
          }),
        });
        await schemaFirst.validate(data, {
          abortEarly: false,
        });
        if (!plataform) {
          addToast({
            title: 'Ops ...',
            type: 'error',
            description: 'Verifique todos os campos!',
          });
          return;
        }
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          addToast({
            title: 'Ops ...',
            type: 'error',
            description: 'Verifique todos os campos!',
          });
          return;
        }
      }
      try {
        if (data && plataform) {
          const sellerId = seller?.seller?.id;
          const { name, url, clientId } = data.ltiData;

          await createNewLtiConnection({
            sellerId,
            name,
            url,
            clientId,
            plataform,
          });

          addToast({
            title: 'Sucesso',
            type: 'success',
            description: 'Conexão cadastrada com sucesso',
          });

          setIsOpen();
          setAddConnection(false);
        }
      } catch (error) {
        addToast({
          title: 'Ops ...',
          type: 'error',
          description: 'Verifique todos os campos!!!',
        });
        return;
      }
    },
    [plataform, addToast, seller, setIsOpen],
  );

  const handleSubmitDeleteConnection = useCallback(
    async (data: IDataDeleteConnectionForm) => {
      try {
        const schemaFirst = Yup.object().shape({
          ltiData: Yup.object().shape({
            url: Yup.string().required('Campo Obrigatório'),
            clientId: Yup.string().required('Campo Obrigatório'),
          }),
        });
        await schemaFirst.validate(data, {
          abortEarly: false,
        });
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          addToast({
            title: 'Ops ...',
            type: 'error',
            description: 'Verifique todos os campos!',
          });
          return;
        }
      }
      try {
        if (data) {
          const { url, clientId } = data.ltiData;

          await deleteLtiConnection({
            url,
            clientId,
          });

          addToast({
            title: 'Sucesso',
            type: 'success',
            description: 'Conexão deletada com sucesso',
          });

          setIsOpen();
          setDeleteConnection(false);
        }
      } catch (error) {
        addToast({
          title: 'Ops ...',
          type: 'error',
          description: 'Verifique todos os campos!!!',
        });
        return;
      }
    },
    [addToast, setIsOpen],
  );

  const modalAddConnection = (
    <>
      <Form ref={formRef} onSubmit={handleSubmitAddConnection}>
        <h2>Adicionar Conexão</h2>
        <SelectContainer>
          <label htmlFor="ltiData.lms" className="selectTitle">
            Selecione a LMS
          </label>
          <select
            value={plataform}
            onChange={e => setPlataform(e.target.value)}
            name="ltiData.lms"
          >
            <option value="" disabled>
              Selecione
            </option>
            <option value="canvas">Canvas</option>
            <option value="d2l">D2L</option>
            <option value="moodle">Moodle</option>
          </select>
        </SelectContainer>
        <RowGroup>
          <Input name="ltiData.name" label="Nome" type="text" required />
          <Input
            name="ltiData.clientId"
            label="ClientId"
            value=""
            type="text"
            required
          />
        </RowGroup>
        <RowGroup>
          <Input
            name="ltiData.url"
            label="URL da LMS"
            value=""
            type="text"
            required
          />
        </RowGroup>
      </Form>
      <Bottom>
        <Buttom
          label="Cancelar"
          outline
          onClick={() => {
            setIsOpen();
            setAddConnection(false);
          }}
        />
        <Buttom label="Salvar" onClick={() => formRef.current?.submitForm()} />
      </Bottom>
    </>
  );

  const modalDeleteConnection = (
    <>
      <Form ref={formRef} onSubmit={handleSubmitDeleteConnection}>
        <h2>Deletar Conexão</h2>

        <RowGroup>
          <Input
            name="ltiData.clientId"
            label="ClientId"
            value=""
            type="text"
            required
          />
        </RowGroup>
        <RowGroup>
          <Input
            name="ltiData.url"
            label="URL da LMS"
            value=""
            type="text"
            required
          />
        </RowGroup>
      </Form>
      <Bottom>
        <Buttom
          label="Cancelar"
          outline
          onClick={() => {
            setIsOpen();
            setDeleteConnection(false);
          }}
        />
        <Buttom label="Salvar" onClick={() => formRef.current?.submitForm()} />
      </Bottom>
    </>
  );

  return (
    <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
      <Container>
        <h1>Gerenciar Conexão LTI</h1>
        {addConnection && modalAddConnection}

        {deleteConnection && modalDeleteConnection}

        {!addConnection && !deleteConnection && (
          <div style={{ marginTop: '50px', display: 'flex' }}>
            <Buttom
              label="Adicionar Conexão"
              onClick={() => setAddConnection(true)}
              style={{ marginRight: '20px' }}
            />

            <Buttom
              label="Deletar Conexão"
              onClick={() => setDeleteConnection(true)}
            />
          </div>
        )}
      </Container>
    </Modal>
  );
};

export default ModalLtiConnection;
