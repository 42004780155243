import styled from 'styled-components';

export const Container = styled.div`
  max-width: 812px;
  margin: 0 auto 18px auto;
`;

export const Card = styled.div`
  margin-top: 22px;
  background-color: white;
  padding: 12px 32px 32px 32px;

  border: 1px solid var(--gray-border);
  border-radius: 20px;
`;

export const Bottom = styled.div`
  display: flex;
  width: 100%;
  margin-top: 24px;
  margin-right: 100%;
`;