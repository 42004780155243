/* eslint-disable react/require-default-props */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { RawEditorSettings } from 'tinymce';
import Modal from '../Modal';

import { Container, Content, Box, ContentBox } from './styles';

import FormPicture from './formPicture';
import FormPDF from './formPDF';
import FormList from './formList';
import FormQuestion from './formQuestion';
import FormPodcast from './formPodcast';
import FormVideoAula from './formVideoAula';
import FormWarning from './formWarning';
import FormQuote from './formQuote';
import FormIframe from './formIframe';
import FormInfographic from './formInfographic';

export interface IEditResource {
  type: string;
  subtype: string;
  resource: string;
  element: Element;
}

interface IModalProps {
  isOpen: boolean;
  setIsOpen: () => void;
  propsEditor: RawEditorSettings;
  editResource: IEditResource;
  currentProviderId?: string;
}

export type IPages =
  | 'main'
  | 'videoaula'
  | 'pdf'
  | 'podcast'
  | 'quote'
  | 'list'
  | 'warning'
  | 'danger'
  | 'picture'
  | 'question'
  | 'iframe'
  | 'emphasis'
  | 'infographic';

const ModalResources: React.FC<IModalProps> = ({
  isOpen,
  setIsOpen,
  propsEditor,
  editResource,
  currentProviderId,
}) => {
  const [pages, setPages] = useState<IPages>('main');
  const [widthModal, setWidthModal] = useState('75%');

  useEffect(() => {
    // Para preencher o objeto que será editado
    if (editResource && Object.keys(editResource).length > 0) {
      if (editResource.type === 'media') {
        setPages(editResource.subtype as IPages);
      } else {
        setPages(editResource.type as IPages);
      }
    } else {
      setPages('main');
    }
  }, [isOpen, editResource]);

  useEffect(() => {
    setWidthModal(pages !== 'main' ? '50%' : '75%');
  }, [pages]);

  const handlePages = useCallback((page: IPages) => {
    setPages(page);
  }, []);

  const renderVideoaula = useCallback(() => {
    return (
      <FormVideoAula
        handlePage={handlePages}
        propsEditor={propsEditor}
        setIsOpen={setIsOpen}
        editResource={editResource}
      />
    );
  }, [handlePages, propsEditor, setIsOpen]);

  const renderQuote = useCallback(() => {
    return (
      <FormQuote
        handlePage={handlePages}
        propsEditor={propsEditor}
        setIsOpen={setIsOpen}
        editResource={editResource}
      />
    );
  }, [handlePages, propsEditor, setIsOpen]);

  const renderIframe = useCallback(() => {
    return (
      <FormIframe
        handlePage={handlePages}
        propsEditor={propsEditor}
        setIsOpen={setIsOpen}
        editResource={editResource}
      />
    );
  }, [handlePages, propsEditor, setIsOpen]);

  const renderWarning = useCallback(
    (subtype?: string) => {
      return (
        <FormWarning
          handlePage={handlePages}
          propsEditor={propsEditor}
          setIsOpen={setIsOpen}
          subtype={subtype || ''}
          editResource={editResource}
        />
      );
    },
    [handlePages, propsEditor, setIsOpen],
  );

  const renderPodcast = useCallback(() => {
    return (
      <FormPodcast
        handlePage={handlePages}
        propsEditor={propsEditor}
        setIsOpen={setIsOpen}
        editResource={editResource}
      />
    );
  }, [propsEditor, setIsOpen, handlePages]);

  const renderPicture = useCallback(() => {
    return (
      <FormPicture
        propsEditor={propsEditor}
        setIsOpen={setIsOpen}
        handlePage={handlePages}
        editResource={editResource}
        providerId={currentProviderId}
      />
    );
  }, [propsEditor, setIsOpen, handlePages, currentProviderId]);

  const renderPDF = useCallback(() => {
    return (
      <FormPDF
        handlePage={handlePages}
        propsEditor={propsEditor}
        setIsOpen={setIsOpen}
        editResource={editResource}
      />
    );
  }, [handlePages, setIsOpen, propsEditor]);

  const renderInfographic = useCallback(() => {
    return (
      <FormInfographic
        handlePage={handlePages}
        propsEditor={propsEditor}
        setIsOpen={setIsOpen}
        editResource={editResource}
      />
    );
  }, [handlePages, setIsOpen, propsEditor]);

  const renderList = useCallback(() => {
    return (
      <FormList
        handlePage={handlePages}
        propsEditor={propsEditor}
        setIsOpen={setIsOpen}
        editResource={editResource}
      />
    );
  }, [handlePages, propsEditor, setIsOpen]);

  const renderQuestion = useCallback(() => {
    return (
      <FormQuestion
        handlePage={handlePages}
        propsEditor={propsEditor}
        setIsOpen={setIsOpen}
        editResource={editResource}
      />
    );
  }, [handlePages, propsEditor, setIsOpen]);

  const renderMainPage = useCallback(() => {
    return (
      <>
        <h1>Recursos</h1>
        <Content className="fadeIn">
          <Box>
            <h2>Inserir Mídia</h2>
            <ContentBox>
              <button type="button" onClick={() => setPages('videoaula')}>
                Videoaula
              </button>
              <button type="button" onClick={() => setPages('podcast')}>
                Podcast
              </button>
              <button type="button" onClick={() => setPages('picture')}>
                Figura
              </button>
              <button type="button" onClick={() => setPages('pdf')}>
                PDF
              </button>
              <button type="button" onClick={() => setPages('infographic')}>
                infográfico
              </button>
            </ContentBox>
          </Box>
          <Box>
            <h2>Inserir Bloco</h2>
            <ContentBox>
              <button type="button" onClick={() => setPages('warning')}>
                Saiba Mais
              </button>
              <button type="button" onClick={() => setPages('danger')}>
                Atenção
              </button>
              <button type="button" onClick={() => setPages('emphasis')}>
                Destaque
              </button>
              <button type="button" onClick={() => setPages('list')}>
                Lista
              </button>
              <button type="button" onClick={() => setPages('quote')}>
                Citação
              </button>
              <button type="button" onClick={() => setPages('iframe')}>
                Iframe Externo
              </button>
            </ContentBox>
          </Box>

          <Box>
            <h2>Inserir Questionários</h2>
            <ContentBox>
              <button type="button" onClick={() => setPages('question')}>
                Questão
              </button>
            </ContentBox>
          </Box>
        </Content>
      </>
    );
  }, []);

  const render = useMemo(() => {
    return {
      main: renderMainPage(),
      videoaula: renderVideoaula(),
      quote: renderQuote(),
      warning: renderWarning(),
      emphasis: renderWarning('emphasis'),
      danger: renderWarning('danger'),
      picture: renderPicture(),
      pdf: renderPDF(),
      infographic: renderInfographic(),
      podcast: renderPodcast(),
      list: renderList(),
      question: renderQuestion(),
      iframe: renderIframe(),
    };
  }, [
    renderMainPage,
    renderVideoaula,
    renderQuote,
    renderWarning,
    renderPicture,
    renderPDF,
    renderInfographic,
    renderPodcast,
    renderList,
    renderQuestion,
    renderIframe,
  ]);

  return (
    <Modal isOpen={isOpen} setIsOpen={setIsOpen} width={widthModal}>
      <Container className="slideUp">{render[pages]}</Container>
    </Modal>
  );
};

export default ModalResources;
