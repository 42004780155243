import styled from 'styled-components';
import Tooltip from '../Tooltip';

export const Container = styled.div`
  max-width: 812px;
  margin: 0 auto 18px auto;
`;

export const Content = styled.div``;

export const Title = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  h1 {
    margin-right: 24px;
  }

  p {
    display: block;
    font-size: 1.25rem;
    font-weight: 900;
    color: var(--gray);
    text-transform: uppercase;
  }
`;

export const Options = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-between;

  margin-top: 36px;
  .hidden {
    display: none;
  }
`;

export const OptionRight = styled.div`
  /* display: flex; */
  align-items: center;
  width: 60%;
  > div {
    float: left;
  }
  &.clear {
    > div {
      float: none;
    }
  }

  .search {
    width: 220px;
    &.mT {
      margin-top: 20px;
    }
  }

  /* Controla propriedades do ReactSelect */
  .select {
    width: 190px;
    margin-left: 8px;
    > div {
      > div {
        min-height: 40px;
      }
    }

    div {
      color: var(--gray) !important;
    }

    span {
      display: none;
    }
  }

  /* div + div {
    margin-left: 10px;
  } */
`;

export const Both = styled.div`
  clear: both;
`;

export const OptionLeft = styled.div`
  width: 45%;
  display: flex;
  button,
  a {
    background: var(--aurea);
    padding: 6px 12px;

    font-weight: bold;

    border: 1px solid var(--aurea);
    border-radius: 5px;

    margin-left: 8px;
    transition: background 0.4s;
    color: var(--dark);

    display: flex;
    align-items: center;

    &:hover {
      background: var(--hover);
      border-color: var(--hover);

      text-decoration: none;
    }

    svg {
      margin-right: 6px;
    }
  }
  &.hidden {
    display: none;
  }
`;

export const DeleteButton = styled.button`
  background: var(--danger);
  height: 40px;
  border: 0;
  border-radius: 5px;
  padding: 10px 12px;
  margin-left: 10px;
  transition: background 0.4s;
`;

export const ImportButton = styled.button`
  background: white;
  padding: 6px 12px;

  font-weight: bold;
  color: var(--dark);

  border: 1px solid var(--gray-border);
  border-radius: 5px;
  transition: background 0.4s;

  svg {
    margin-right: 6px;
  }
`;

export const AddButton = styled.button`
  background: var(--aurea);
  padding: 6px 14px;

  font-weight: bold;

  border: 1px solid var(--aurea);
  border-radius: 5px;

  margin-left: 8px;
  transition: background 0.4s;
  color: var(--dark);

  display: flex;
  align-items: center;
  &:hover {
    background: var(--hover);
  }

  svg {
    margin-right: 6px;
  }
`;

export const PrimaryButton = styled.button`
  background: var(--aurea);

  padding: 7px 22px !important;

  font-weight: bold;
  font-size: 0.75rem;
  border: 0;
  border-radius: 5px;

  transition: background 0.4s;

  &:hover {
    background: var(--hover);
  }

  &.outline-white {
    color: white;
    background: transparent;
    border: 1px solid white;
  }

  & + button {
    margin-left: 8px;
  }
`;

export const TableContent = styled.div`
  width: 100%;
  .th-stats {
    text-align: center !important;
  }

  table {
    width: 100%;
    border-top: 1px solid var(--gray-border);
    border-collapse: collapse;

    th {
      text-align: left;
      padding: 18px 0 18px 8px;
      color: var(--gray);
      text-transform: uppercase;
      border-top: 1px solid var(--gray-border);
    }
    td {
      border-top: 1px solid var(--gray-border);
      padding: 12px 22px 12px 8px;
    }
    tr {
      cursor: pointer;
      transition: all 0.3s;
      &:hover {
        background: #ffffff;
        .show {
          display: flex;
        }
      }
    }

    .no-hover:hover {
      background: var(--white);
    }

    .no-cursor {
      cursor: auto;
    }

    .hidden {
      display: none;
    }

    .dropdown {
      background: white;
      .arrow {
        &::after {
          -webkit-transform: rotate(-45deg);
          -ms-transform: rotate(-45deg);
          transform: rotate(-45deg);
        }
      }

      .show {
        display: flex;
      }
    }

    .more-info {
      background: white;

      td:first-child {
        border: 0;
      }

      td:last-child {
        border: 0;
        position: relative;
        &::after {
          content: '';
          position: absolute;
          left: 0;
          top: -1px;
          height: 1px;
          width: 95%;
          border-bottom: 1px solid var(--gray-border);
        }
      }
    }

    .arrow {
      position: relative;

      &::after {
        content: '';
        display: block;
        width: 6px;
        height: 6px;
        border: solid var(--gray);
        border-width: 0 2px 2px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
        position: absolute;
        top: 22px;
        right: 10px;
      }
    }

    .link-options {
      a {
        font-weight: bold;
        color: var(--gray);
      }
    }
  }

  .loading {
    text-align: center;
    padding: 20px;

    &.hidden {
      display: none;
    }
  }
  .hidden {
    display: none;
  }

  .color-aurea {
    color: var(--aurea);
  }

  .text-gray {
    font-size: 0.75rem;
    color: var(--gray);
  }

  .link-options {
    button {
      font-weight: bold;
      color: var(--gray);
      background: transparent;
      border: 0;

      &:hover {
        text-decoration: underline;
      }
    }
  }
`;

export const NameContent = styled.div`
  display: flex;
  align-items: center;
  svg {
    margin-right: 14px;
  }

  svg:last-child {
    margin-left: 18px;
  }
`;

export const Bagde = styled.div`
  width: 90px;
  height: 26px;
  border-radius: 13px;
  background: var(--gray-border);
  color: var(--gray);
  display: flex;
  align-items: center;
  justify-content: center;

  &.active,
  &.publish {
    background: var(--success);
    color: white;
  }
  &.danger,
  &.reproved,
  &.deleted {
    background: var(--danger);
    color: white;
  }
  &.warning,
  &.correction,
  &.analyze {
    background: var(--warning);
    color: white;
  }
`;

export const ActionsUnit = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  button {
    background-color: var(--aurea);
    border: 0;
    border-radius: 5px;
    padding: 8px 12px;
    font-weight: bold;
    margin: 0 24px 0 24px;
    color: var(--dark);
    transition: background 0.3s;
    &:hover {
      background-color: var(--hover);
    }
  }
  button ~ div {
    position: absolute;
    right: -5px;
  }

  .price {
    color: var(--gray);
    span {
      color: var(--aurea);
    }
  }
`;

export const Actions = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  right: 0;
  .show {
    display: none;
  }

  .hidden {
    display: none;
  }

  /* Show button from user status */
  .Análise {
    justify-content: flex-end;

    div:first-child {
      visibility: visible;
    }
    div:last-child {
      visibility: visible;
    }
  }
  .Reprovado {
    div:first-child {
      visibility: hidden;
    }
    div:last-child {
      visibility: visible;
    }
  }
  .Aprovado {
    div:first-child {
      visibility: visible;
    }
    div:last-child {
      visibility: hidden;
    }
  }
`;

export const ActionsContent = styled.div`
  display: flex;
  align-items: center;
  svg {
    cursor: pointer;
    transition: opacity 0.3s;
    &:hover {
      opacity: 0.7;
    }
    & + svg {
      margin-left: 12px;
    }
  }

  div {
    display: flex;
    justify-content: center;
    align-items: center;
    background: white;
    border: 1px solid var(--gray-border);
    border-radius: 5px;
    width: 30px;
    height: 30px;

    &.primary {
      background: var(--aurea);
      border: 1px solid var(--aurea);
    }

    & + div {
      margin-left: 8px;
    }
  }
`;

export const TooltipInfo = styled(Tooltip)`
  height: 20px;
  margin-left: 16px;
  span {
    background: var(--dark);
    color: #fff;
    &::before {
      border-color: var(--dark) transparent;
    }
  }
  svg {
    margin-right: 0;
  }
`;

export const MoreInfo = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const MoreInfoItem = styled.div`
  display: flex;
  align-items: center;

  span {
    font-weight: bold;
    color: var(--gray);
  }
  svg {
    margin-right: 12px;
  }
`;
