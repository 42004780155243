import styled, { css } from 'styled-components';

interface ContainerProps {
  isErrored?: boolean;
}

export const Container = styled.div<ContainerProps>`
  margin-top: 20px;
  label {
    font-weight: 700;
    color: var(--gray);
  }

  textarea {
    background: #fff;
    border: 1px solid var(--quaternary);
    border-radius: 5px;
    padding: 10px 14px;
    margin-top: 14px;

    position: relative;

    width: 100%;
    height: 80px;
    resize: none;
    color: var(--dark);
    display: flex;
    align-items: center;

    &::placeholder {
      color: var(--placeholder);
    }

    ${props =>
      props.isErrored &&
      css`
        border-color: var(--error);
      `}
  }

  small {
    color: var(--error);
  }
`;
