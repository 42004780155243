export default class Timer {
  seconds: number;

  action: ReturnType<typeof setTimeout> | undefined;

  constructor() {
    this.seconds = 1000;
  }

  register(action: Function) {
    if (this.action) clearTimeout(this.action);
    this.action = setTimeout(
      function () {
        action();
      },
      !this.action ? 0 : this.seconds,
    );
  }
}
