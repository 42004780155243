import styled from 'styled-components';

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
`;

export const Main = styled.main`
  display: flex;
  flex: 1;
  height: 0vh;
`;
export const MainContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  .navtop {
    position: fixed;
    top: 0;
    right: 0;
  }
`;

export const Container = styled.div`
  display: flex;
  width: 100%;
  margin: 100px auto 18px auto;
  overflow-y: auto;
  div.provider {
    background: #ffffff;
    border: 1px solid var(--quaternary);
    padding: 30px;
    margin: 20px 0;
    border-radius: 10px;
    width: 60%;
    display: flex;
    margin: 20px auto;
    img {
      width: 130px;
      height: 115px;
      object-fit: cover;
      margin-right: 30px;
      border-radius: 50%;
    }
    & > div {
      width: 100%;
      h1 {
        font-size: 1.875rem;
        margin-bottom: 10px;
      }
      & > div {
        display: flex;
        align-items: flex-end;
        & > div {
          width: 50%;
        }
        & > div:first-child {
          p.units {
            margin-top: 20px;
            font-size: 0.95rem;
            color: var(--aurea);
            strong {
              font-weight: bold;
            }
          }
        }
        & > div:last-child {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          p {
            color: var(--gray);
            strong {
              font-weight: bold;
            }
            span {
              font-size: 0.625rem;
            }
          }
          a {
            font-size: 0.9rem;
            display: block;
            width: 140px;
            padding: 15px;
            border: none;
            color: var(--dark);
            background: var(--gray-opacity);
            border-radius: 5px;
            font-weight: bold;
            transition: background 400ms;
            margin-left: 20px;
            &:hover {
              text-decoration: none;
              background: var(--gray-border);
            }
          }
          button {
            display: block;
            width: 140px;
            padding: 15px;
            border: none;
            color: var(--dark);
            background: var(--aurea);
            border-radius: 5px;
            font-weight: bold;
            transition: background 400ms;
            margin-left: 20px;
            &:hover {
              text-decoration: none;
              background: var(--hover);
            }
          }
          a,
          button {
            &.rented {
              opacity: 0.5;
              background: var(--white);
              border: 1px solid var(--gray-border);
              transition: opacity 0.3s;
              text-align: center;
              &:hover {
                opacity: 1;
              }
            }
          }
        }
      }
    }
  }
`;
export const Service = styled.div`
  max-width: 900px;
  margin-top: 20px;
  border: 1px solid var(--gray-border);
  border-radius: 5px;
  display: block;
  text-align: left !important;

  h3 {
    font-weight: 600;
  }

  p {
    margin-top: 8px;
    width: 90%;
  }
`;
export const TextLink = styled.p`
  margin-top: 10px;
`;

export const ServiceContainer = styled.div`
  width: 90%;
  padding: 20px 15px;
`;

export const ServiceImage = styled.div`
  display: block;
  width: 100%;
  height: 100px;
  display: inline-block;
  background-position: center;
  border-radius: 5px 5px 0px 0px;
`;

export const IconContainer = styled.div`
  width: 14%;
  align-self: center;
  margin-right: 4%;

  svg {
    margin-right: 8px;
  }
`;

export const Button = styled.button`
  background: transparent;
  border: none;
`;
export const ButtonAddToCart = styled.button`
  background: var(--aurea);
  padding: 6px 14px;

  font-weight: bold;

  border: 1px solid var(--aurea);
  border-radius: 5px;

  transition: background 0.4s;
  color: var(--dark);

  display: flex;
  align-items: center;
`;

export const SectionService = styled.div`
  text-align: -webkit-center;
  text-align: -moz-center;
`;
