import React from 'react';

import { Container, Background, Content } from './styles';
import Logo from '../../../assets/images/logo-new.svg';

const BackgroundSign: React.FC = () => {
  return (
    <Container>
      <Background />
      <Content>
        <img src={Logo} alt="Logo" width={164} />
        <h1>
          Compartilhar conhecimento <br /> é inspirar sonhos!
        </h1>
        <p>
          Já imaginou se fosse possível, em um só lugar, <br />
          conectar produtores de conteúdo educacional, <br />
          instituições de ensino e estudantes?
        </p>
      </Content>
    </Container>
  );
};

export default BackgroundSign;
