import React, { useRef, useCallback, useState } from 'react';

import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import { Link, useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import {
  Container,
  Content,
  ContentForm,
  BottomForm,
  ContentBottom,
} from './styles';
import BackgroundSign from '../../components/template/BackgroundSign';
import Input from '../../components/forms/Input';
import Buttom from '../../components/forms/Buttom';
import getValidationErrors from '../../utils/getVaidationErrors';
import { useToast } from '../../hooks/toast';
import { postForgotPassword } from '../../services/api';

interface ISubmitProps {
  email: string;
}

const ForgotPassword: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const formRef = useRef<FormHandles>(null);
  const { addToast } = useToast();
  const history = useHistory();
  const handleSubmit = useCallback(
    async (data: ISubmitProps) => {
      try {
        setLoading(true);
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          email: Yup.string()
            .required('Campo Obrigatório')
            .email('Email inválido'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        await postForgotPassword({
          email: data.email,
        });
        addToast({
          title: 'Sucesso!',
          description: 'Um email foi enviado para sua caixa de entrada.',
          type: 'success',
        });

        history.push('/');
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErrors(error);
          formRef.current?.setErrors(errors);
          return;
        }

        addToast({
          title: 'Erro!',
          description: 'Não encontramos o email informado.',
          type: 'error',
        });
      } finally {
        setLoading(false);
      }
    },
    [addToast, history],
  );

  return (
    <Container>
      <BackgroundSign />
      <Content className="fadeIn">
        <ContentForm>
          <h1>Recuperação de senha</h1>
          <Form ref={formRef} onSubmit={handleSubmit}>
            <Input
              name="email"
              placeholder="Digite seu email"
              label="Email:*"
              type="email"
            />

            <BottomForm>
              <Buttom isLoading={loading} label="Enviar" />
            </BottomForm>
          </Form>
        </ContentForm>
        <ContentBottom>
          Já tem cadastro? <Link to="/">Faça seu login</Link>
        </ContentBottom>
      </Content>
    </Container>
  );
};

export default ForgotPassword;
