import styled from 'styled-components';

export const TagItemDiv = styled.div`
  margin-top: 10px;
  padding: 0px 16px 0 16px;
  div {
    span {
      display: none;
    }

    svg[width='20'] {
      display: none;
    }
  }
`;
