import styled from 'styled-components';

export const Container = styled.div`
  > label {
    font-weight: 700;
    color: var(--gray);
  }

  small {
    color: var(--error);
  }
`;

export const Content = styled.div`
  margin-top: 10px;
`;

export const ItemRadio = styled.div`
  padding: 0 14px 0 36px;
  user-select: none;

  background: white;
  border: 1px solid var(--gray-border);
  border-radius: 5px;

  display: flex;
  align-items: center;

  cursor: pointer;
  & + div {
    margin-top: 10px;
  }

  strong {
    font-weight: bold;
  }

  label {
    padding: 13px 0;
    width: 100%;
    cursor: pointer;
    display: flex;
    div {
      width: 30px;
      text-align: center;
      margin-right: 10px;
    }
  }

  position: relative;
  input[type='radio'],
  input[type='checkbox'] {
    margin-right: 12px;
    margin-top: 4px;
    position: absolute;
    top: calc(50% - 12px);
    left: 10px;
    cursor: pointer;

    &:checked ~ label {
      border-color: var(--aurea);
    }

    &:checked:after {
      content: '';
      display: block;
      width: 9px;
      height: 9px;
      position: absolute;
      border-radius: 50%;
      top: 2px;
      left: 2px;

      background: white;
    }

    &:checked:before {
      background-color: var(--aurea);
    }

    &::before {
      content: '';
      display: block;
      position: absolute;
      width: 15px;
      height: 15px;
      top: -1px;
      left: -1px;
      /* border: 2px solid rgba(141, 133, 133, 0.45); */
      border-radius: 50%;
      background-color: var(--gray-border);
      transition: background 0.1s;
    }
  }
`;
